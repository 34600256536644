import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  listClients: ['filter'], // LIST_CLIENTS
  listClientsLoading: null, // LIST_CLIENTS_LOADING
  listClientsSuccess: ['data'], // LIST_CLIENTS_SUCCESS
  listClientsFailure: ['error'], // LIST_CLIENTS_FAILURE
  //
  listClientsOpenModal: ['flag', 'target'], // LIST_CLIENTS_OPEN_MODAL
  //
  getClient: ['id'], // GET_CLIENT
  getClientLoading: null, // GET_CLIENT_LOADING
  getClientSuccess: ['data'], // GET_CLIENT_SUCCESS
  getClientFailure: ['error'] // GET_CLIENT_FAILURE
}, { prefix: 'CLIENT_' })

export const ClientTypes = Types

export default Creators
