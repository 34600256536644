import React from 'react'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import format from 'date-fns/format'

import ts from 'Helpers/thousandsSeparator'

const styles = (theme: Theme) => createStyles({
  paper: {
    margin: 'auto',
    marginBottom: 48,
    overflow: 'hidden'
  },
  table: {
    minWidth: 700
  }
})

interface Props extends WithStyles<typeof styles> {
  data: any
}

const ClientsMyDistributions = (props: Props) => {
  const { classes, data } = props

  return (
    <Paper elevation={2} className={classes.paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Datum</TableCell>
            <TableCell align="left">Emise</TableCell>
            <TableCell align="left">Mince</TableCell>
            <TableCell align="right">Číslo smlouvy</TableCell>
            <TableCell align="right">Cena</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item: any) => (
            <TableRow key={item.id} hover={true}>
              <TableCell align='left'>{format(new Date(item.createdAt), 'dd.MM.yyyy')}</TableCell>
              <TableCell align='left'>{item.coin.emission.name}</TableCell>
              <TableCell align='left'>{item.coin.denominationMotif}</TableCell>
              <TableCell align='right'>{item.contract.contractNumber}</TableCell>
              <TableCell align='right'><strong>{ts(item.price)}&nbsp;Kč</strong></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default withStyles(styles)(ClientsMyDistributions)
