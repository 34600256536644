import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import InvoiceActions from 'Stores/Invoice/Actions'
import * as InvoiceSelect from 'Stores/Invoice/Select'

import useReactRouter from 'use-react-router'

import DefaultLayout from 'Components/_Common/Layout/Default'
import Loading from 'Components/Loading'
import InvoiceForm from 'Components/Invoice/Form'

import Button from '@material-ui/core/Button'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import WaitDialog from 'Components/_Common/Dialogs/Wait'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import { format } from 'date-fns'

import isEmpty from 'Helpers/isEmpty'

import * as ROUTES from 'routes'

import ClientList from 'Components/Client/List/List'
import PaymentsList from 'Components/Payment/List/List'

const Invoice = (props: any) => {
  const {
    match: {
      params: { id }
    }
  }: any = useReactRouter()

  useEffect(() => {
    props.getInvoice(id)
  }, [id])

  const [tab, setTab] = React.useState(0)

  function handleSwitchTab(event: any, newValue: number): void {
    setTab(newValue)
  }

  const [anchorElement, setAnchorElement] = useState(null)

  const handleMenuClick = (event: any): void => {
    setAnchorElement(event.currentTarget)
  }

  const handleCloseMenu = (): void => {
    setAnchorElement(null)
  }

  const handleCreateInvoice = () => {

  }

  const handleSendInvoice = () => {
    
  }

  const handleDownloadInvoice = () => {
    
  }

  const handleRegenerateInvoice = () => {
    
  }

  const actionMenu = (data: any): any => (
    <React.Fragment>
      <Button
        aria-owns={anchorElement ? 'simple-menu' : undefined}
        aria-haspopup='true'
        onClick={handleMenuClick}
        variant='outlined'
        color='secondary'
        size='large'
      >
        Akce
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleCreateInvoice}>
          <ListItemText primary='Vystavit fakturu' secondary='Přidělí faktuře číslo a připraví ji v elektronické podobě' />
        </MenuItem>
        <MenuItem onClick={handleSendInvoice}>
          <ListItemText primary='Odeslat fakturu' secondary={!isEmpty(props.invoiceResult) && 'invoiceSentAt' in props.invoiceResult && props.invoiceResult.invoiceSentAt ? `Naposledy odesláno ${format(new Date(props.invoiceResult.invoiceSentAt), 'dd.MM.yyyy HH:mm')}` : `Odešle fakturu emailem`} />
        </MenuItem>
        <MenuItem onClick={handleDownloadInvoice}>
          <ListItemText primary='Stáhnout fakturu' secondary='Připraví fakturu pro stažení ve formátu PDF' />
        </MenuItem>
        {props.user.group === 'Admins' && <MenuItem onClick={handleRegenerateInvoice}>
          <ListItemText primary='Přegenerovat fakturu' secondary='Znovu vygeneruje fakturu ve formátu PDF' />
        </MenuItem>}
      </Menu>
    </React.Fragment>
  )

  return (
    <DefaultLayout
      title={`Faktury`}
      tabs={[{
        route: ROUTES.ADMIN_INVOICES, title: 'Seznam faktur'
      }, {
        route: '/faktury/nova', title: 'Nová faktura'
      }, {
        route: `/faktury/detail/${id}`, title: 'Editace faktury'
      }, {
        route: '/faktury/napoveda', title: 'Nápověda'
      }]}
      activeTab={2}
    >
      {props.invoiceLoading && <Loading />}
      {!props.invoiceLoading && props.invoiceResult && (
        <>
          {id && <AppBar position='static' color='default' style={{ width: `${props.dimensions.width - 72}px`, margin: 'auto' }}>
            <Tabs
              value={tab}
              onChange={handleSwitchTab}
              variant='scrollable'
              scrollButtons='off'
              indicatorColor='primary'
              textColor='primary'
            >
              <Tab label='Faktura' icon={<FontAwesomeIcon icon={['fal', 'file-signature']} size='2x' />} />
              <Tab label='Klient' icon={<FontAwesomeIcon icon={['fal', 'user']} size='2x' />} />
              <Tab label='Platby' icon={<FontAwesomeIcon icon={['fal', 'money-check-alt']} size='2x' />} />
            </Tabs>
          </AppBar>}

          {tab === 0 && <InvoiceForm
            // actionMenu={!!id ? actionMenu(props.invoiceResult) : null}
          />}

          {/* @todo SORT */}
          {tab === 1 && props.invoiceResult.client && <ClientList
            rows={[props.invoiceResult.client]}
            heightOffset={-72}
          />}

          {/* @todo SORT */}
          {tab === 2 && props.invoiceResult.payments.items && <PaymentsList
            rows={props.invoiceResult.payments.items.slice()}
            heightOffset={-72}
          />}
        </>
      )}

      <WaitDialog />
    </DefaultLayout>
  )
}

const mapStateToProps = (state: any) => ({
  invoiceLoading: InvoiceSelect.invoiceLoading(state),
  invoiceResult: InvoiceSelect.invoiceResult(state),
  //
  dimensions: state.app.dimensions,
  user: state.user
})

const mapDispatchToProps = (dispatch: any) => ({
  getInvoice: (id: any) => dispatch(InvoiceActions.getInvoice(id)),
  //
  createInvoice: (id: any) => dispatch(InvoiceActions.createInvoice(id)),
  downloadInvoie: (id: any, generate: boolean) => dispatch(InvoiceActions.downloadInvoie(id, generate)),
  sendInvoice: (id: any) => dispatch(InvoiceActions.sendInvoice(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Invoice)
