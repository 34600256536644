import { Auth } from 'aws-amplify'

import { createClient, ClientOptions, createRequest, RequestPolicy } from 'urql'
import { pipe, subscribe } from 'wonka'

import gql from 'graphql-tag'

const createGraphql = (clientParams: ClientOptions) => {
  const client = createClient(clientParams)

  const _getJwtToken = async (): Promise<string> => {
    const { idToken }: any = await Auth.currentSession()
    return idToken.jwtToken
  }

  const _getQueryDocument = (q: any) => typeof q === 'string' ? gql(q) : q

  // const _getExecuteMethod = (type: any): string => `execute${type.charAt(0).toUpperCase() + type.slice(1)}`

  /**
   * @param {string} type
   * @param {DocumentNode} q
   * @param {object} variables
   * @param {OperationContext} opts
   * @returns {OperationResult}
   */
  const _execute = async (type: string, q: any, variables: any) => {
    const token = await _getJwtToken()
    const opts = {
      fetchOptions: {
        headers: {
          Authorization: token
        }
      },
      requestPolicy: 'network-only' as RequestPolicy
    }

    const request = createRequest(_getQueryDocument(q), variables)

    let promise

    // @todo
    if (type === 'query') {
      promise = new Promise(resolve => {
        pipe(
          client.executeQuery(request, opts),
          subscribe(resolve)
        )
      })
    } else if (type === 'mutation') {
      promise = new Promise(resolve => {
        pipe(
          client.executeMutation(request, opts),
          subscribe(resolve)
        )
      })
    }

    const result = await promise
    return result
  }

  // query Alias.
  const query = (q: any, variables: any) =>
    _execute('query', q, variables)

  // mutation Alias.
  const mutation = async (q: any, variables: any) =>
    _execute('mutation', q, variables)

  return {
    client,
    query,
    mutation
  }
}

export default createGraphql
