import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import ShutdownActions from 'Stores/Shutdown/Actions'
import StartupActions from 'Stores/Startup/Actions'

import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'

import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'

import Loading from 'Components/Loading'

import Login from 'Routes/Auth/Login'

// Admin
import AdminHome from 'Routes/Admin/Home'

import AdminClient from 'Routes/Admin/Clients/Detail'
import AdminClients from 'Routes/Admin/Clients/List'
import AdminContractDetail from 'Routes/Admin/Contracts/Detail'
import AdminContracts from 'Routes/Admin/Contracts/List'
import AdminContractNew from 'Routes/Admin/Contracts/New'
import AdminDistributions from 'Routes/Admin/Distributions/List'
import AdminEmail from 'Routes/Admin/Emails/Detail'
import AdminEmails from 'Routes/Admin/Emails/List'
import AdminCoin from 'Routes/Admin/Emissions/Coin'
import AdminEmissions from 'Routes/Admin/Emissions/List'
import AdminInvoiceDetail from 'Routes/Admin/Invoices/Detail'
import AdminInvoicesHelp from 'Routes/Admin/Invoices/Help'
import AdminInvoices from 'Routes/Admin/Invoices/List'
import AdminInvoiceNew from 'Routes/Admin/Invoices/New'
import AdminMyContract from 'Routes/Admin/MyContract'
import AdminMyContracts from 'Routes/Admin/MyContracts'
import AdminPayment from 'Routes/Admin/Payments/Detail'
import AdminPayments from 'Routes/Admin/Payments/List'
import AdminPrediction from 'Routes/Admin/Prediction'
import AdminSettings from 'Routes/Admin/Settings'
import AdminTools from 'Routes/Admin/Tools'
import AdminStockItem from 'Routes/Admin/Stock/Detail'
import AdminStockItemNew from 'Routes/Admin/Stock/New'
import AdminStock from 'Routes/Admin/Stock/List'

// Client
import ClientHome from 'Routes/Client/Home'

// import ClientEmissions from 'Routes/Client/Emissions'
import ClientMyCoins from 'Routes/Client/MyCoins'
import ClientMyContract from 'Routes/Client/MyContract'
import ClientMyContracts from 'Routes/Client/MyContracts'
import ClientMyInvoices from 'Routes/Client/MyInvoices'
import ClientMyPayments from 'Routes/Client/MyPayments'
import ClientMyProfile from 'Routes/Client/MyProfile'
import ClientSettings from 'Routes/Client/Settings'
import ClientPricelist from 'Routes/Client/Pricelist'

import Http404NotFound from 'Routes/404'

import Layout from 'Components/_Common/Layout/App'
import theme from 'theme'

import * as ROUTES from 'routes'

import './App.css'

interface PrivateRouteProps extends RouteProps {
  user?: any,
  groups?: any
}

const PrivateRoute = connect(
  (state: any): any => ({
    user: state.user
  })
)(({ user, groups, ...rest }: PrivateRouteProps): any => {
  if (groups && groups instanceof Array && groups.includes(user.group)) {
    return <Route {...rest} />
  } else {
    return <Redirect to={{ pathname: '/prihlaseni' }} />
  }
})

const InnerApp = (props: any) => {
  if (props.user.group === 'Clients') {
    const render = () => (
      <Layout>
        <Switch>
          <PrivateRoute groups={['Clients']} path={ROUTES.CLIENT_HOME} exact={true} component={ClientHome} />
          <PrivateRoute groups={['Clients']} path={ROUTES.CLIENT_MY_CONTRACTS} exact={true} component={ClientMyContracts} />
          <PrivateRoute groups={['Clients']} path={ROUTES.CLIENT_MY_CONTRACT} exact={true} component={ClientMyContract} />
          <PrivateRoute groups={['Clients']} path={ROUTES.CLIENT_MY_PAYMENTS} exact={true} component={ClientMyPayments} />
          <PrivateRoute groups={['Clients']} path={ROUTES.CLIENT_MY_INVOICES} exact={true} component={ClientMyInvoices} />
          <PrivateRoute groups={['Clients']} path={ROUTES.CLIENT_MY_COINS} exact={true} component={ClientMyCoins} />
          <PrivateRoute groups={['Clients']} path={ROUTES.CLIENT_MY_PROFILE} exact={true} component={ClientMyProfile} />
          <PrivateRoute groups={['Clients']} path={ROUTES.CLIENT_MY_SETTINGS} exact={true} component={ClientSettings} />
          <PrivateRoute groups={['Clients']} path={ROUTES.CLIENT_EMISSIONS} exact={true} component={AdminEmissions} />
          <PrivateRoute groups={['Clients']} path={ROUTES.CLIENT_COIN} exact={true} component={AdminCoin} />
          <PrivateRoute groups={['Clients']} path={ROUTES.CLIENT_PRICELIST} exact={true} component={ClientPricelist} />
          <Route path={ROUTES.LOGIN} exact={true} component={Login} />
          <Route component={Http404NotFound} />
        </Switch>
      </Layout>
    )

    return (
      <Route render={render} />
    )
  } else {
    const render = () => (
      <Layout>
        <Switch>
          <PrivateRoute groups={['Admins', 'SalesRepresentatives']} path={ROUTES.ADMIN_HOME} exact={true} component={AdminHome} />
          <PrivateRoute groups={['Admins', 'SalesRepresentatives']} path={ROUTES.ADMIN_CLIENTS} exact={true} component={AdminClients} />
          <PrivateRoute groups={['Admins', 'SalesRepresentatives']} path={ROUTES.ADMIN_CLIENT} exact={true} component={AdminClient} />
          <PrivateRoute groups={['Admins', 'SalesRepresentatives']} path={ROUTES.ADMIN_CONTRACTS} exact={true} component={AdminContracts} />
          <PrivateRoute groups={['Admins', 'SalesRepresentatives']} path={ROUTES.ADMIN_CONTRACT_NEW} exact={true} component={AdminContractNew} />
          <PrivateRoute groups={['Admins', 'SalesRepresentatives']} path={ROUTES.ADMIN_CONTRACT_DETAIL} exact={true} component={AdminContractDetail} />
          <PrivateRoute groups={['Admins']} path={ROUTES.ADMIN_INVOICES} exact={true} component={AdminInvoices} />
          <PrivateRoute groups={['Admins']} path={ROUTES.ADMIN_INVOICES_HELP} exact={true} component={AdminInvoicesHelp} />
          <PrivateRoute groups={['Admins']} path={ROUTES.ADMIN_INVOICE_NEW} exact={true} component={AdminInvoiceNew} />
          <PrivateRoute groups={['Admins']} path={ROUTES.ADMIN_INVOICE_DETAIL} exact={true} component={AdminInvoiceDetail} />
          <PrivateRoute groups={['Admins', 'SalesRepresentatives']} path={ROUTES.ADMIN_MY_CONTRACTS} exact={true} component={AdminMyContracts} />
          <PrivateRoute groups={['Admins', 'SalesRepresentatives']} path={ROUTES.ADMIN_MY_CONTRACT} exact={true} component={AdminMyContract} />
          <PrivateRoute groups={['Admins']} path={ROUTES.ADMIN_PAYMENTS} exact={true} component={AdminPayments} />
          <PrivateRoute groups={['Admins']} path={ROUTES.ADMIN_PAYMENT} exact={true} component={AdminPayment} />
          <PrivateRoute groups={['Admins']} path={ROUTES.ADMIN_EMAILS} exact={true} component={AdminEmails} />
          <PrivateRoute groups={['Admins', 'SalesRepresentatives']} path={ROUTES.ADMIN_EMAIL} exact={true} component={AdminEmail} />
          <PrivateRoute groups={['Admins', 'SalesRepresentatives']} path={ROUTES.ADMIN_SETTINGS} exact={true} component={AdminSettings} />
          <PrivateRoute groups={['Admins']} path={ROUTES.ADMIN_TOOLS} exact={true} component={AdminTools} />
          <PrivateRoute groups={['Admins', 'SalesRepresentatives']} path={ROUTES.ADMIN_EMISSIONS} exact={true} component={AdminEmissions} />
          <PrivateRoute groups={['Admins', 'SalesRepresentatives']} path={ROUTES.ADMIN_COIN} exact={true} component={AdminCoin} />
          <PrivateRoute groups={['Admins', 'SalesRepresentatives']} path={ROUTES.CLIENT_PRICELIST} exact={true} component={ClientPricelist} />
          <PrivateRoute groups={['Admins']} path={ROUTES.ADMIN_STOCK} exact={true} component={AdminStock} />
          <PrivateRoute groups={['Admins']} path={ROUTES.ADMIN_STOCK_ITEM_NEW} exact={true} component={AdminStockItemNew} />
          <PrivateRoute groups={['Admins']} path={ROUTES.ADMIN_STOCK_ITEM_DETAIL} exact={true} component={AdminStockItem} />
          <PrivateRoute groups={['Admins']} path={ROUTES.ADMIN_DISTRIBUTIONS} exact={true} component={AdminDistributions} />
          <PrivateRoute groups={['Admins']} path={ROUTES.ADMIN_PREDICTION} exact={true} component={AdminPrediction} />
          <Route path={ROUTES.LOGIN} exact={true} component={Login} />
          <Route component={Http404NotFound} />
        </Switch>
      </Layout>
    )

    return (
      <Route render={render} />
    )
  }
}

const App = (props: any) => {
  useEffect(() => {
    props.startup()
  }, [])

  return (
    <MuiThemeProvider theme={theme}>       
      <CssBaseline />
      {props.app.startedUp && <InnerApp user={props.user} />}
      {props.user.logoutIsLoading ? <Loading fullscreen={true} /> : null}
    </MuiThemeProvider>
  )
}

const mapStateToProps = (state: any): any => ({
  app: state.app,
  user: state.user
})

const mapDispatchToProps = (dispatch: any): any => ({
  startup: () => dispatch(StartupActions.startup()),
  shutdown: () => dispatch(ShutdownActions.shutdown())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
