import gql from 'graphql-tag'

const mutation = gql`
  mutation SET_STATUS_ON_CONTRACT_MUTATION (
    $id: ID!,
    $status: String
    $expectedVersion: Int!
  ) {
    updateContract(input: {
      id: $id,
      status: $status,
      expectedVersion: $expectedVersion
    }) {
      id
      contractNumber
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      signedAt
      contractSentAt
      exportedAt
      lastPaymentReceivedAt
      deleted
      deletedAt
      deletedBy
      monthlyAmount
      totalYears
      emission1
      emission2
      firstDeliveryDate
      actualDeliveryEntitlementDate
      firstEmission {
        id
        __typename
      }
      firstCoin {
        id
        __typename
      }
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        contracts {
          nextToken
        }
        emails {
          nextToken
        }
        invoices {
          nextToken
        }
        payments {
          nextToken
        }
        distributions {
          nextToken
        }
        version
      }
      clientBenefitId
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      clientBankAccountOwner
      clientBankAccountOwnerRelation
      defaultPriceOfCoin
      salesMargin
      entryFeePerCoin
      priceOfCoin
      estimatedValue
      numberOfCoins
      qualityOfCoins
      entryPriceIncrease
      entryPriceIncreaseOneTimePayment
      entryPriceIncreaseIsPaid
      entryPriceIncreaseWasPaidAt
      entryPriceIncreasePaidAmount
      entryPriceIncreaseInvoiceId
      entryPriceIncreaseInvoiceNumber
      indicativeTargetAmount
      savedAmount
      status
      emails {
        items {
          id
          receivedAt
          from
          to
          cc
          bcc
          recipients
          subject
          body
          bodyText
          bodyHtml
          attachments
          status
          version
        }
        nextToken
      }
      invoices {
        items {
          id
          invoiceNumber
          owners
          createdAt
          createdBy
          updatedAt
          updatedBy
          deleted
          deletedAt
          deletedBy
          clientTitleBefore
          clientFirstname
          clientLastname
          clientTitleAfter
          clientPersonalNumber
          clientBirthDate
          clientEmail
          clientPhone
          clientAddressFormatted
          clientAddressStreet
          clientAddressStreetNumber
          clientAddressZip
          clientAddressTown
          clientHasDifferentDeliveryAddress
          clientDeliveryTitleBefore
          clientDeliveryFirstname
          clientDeliveryLastname
          clientDeliveryTitleAfter
          clientDeliveryCompanyName
          clientDeliveryPhone
          clientDeliveryBirthYear
          clientDeliveryAddressFormatted
          clientDeliveryAddressStreet
          clientDeliveryAddressStreetNumber
          clientDeliveryAddressZip
          clientDeliveryAddressTown
          clientBankAccountNumber
          clientBankCode
          textAboveItems
          textBelowItems
          price
          dateOfIssue
          dueDate
          variableSymbol
          constantSymbol
          specificSymbol
          status
          version
        }
        nextToken
      }
      payments {
        items {
          id
          owners
          createdAt
          createdBy
          updatedAt
          updatedBy
          deleted
          deletedAt
          deletedBy
          statement
          date
          transactionType
          reference
          bankReference
          amount
          currency
          details
          extraDetails
          bankAccountName
          bankAccountNumber
          bankAccountNumberWithBankCode
          bankCode
          variableSymbol
          constantSymbol
          specificSymbol
          message
          notes
          status
          partClient
          partCommission
          partGoldCoins
          commissionExportedAt
          version
        }
        nextToken
      }
      distributions {
        items {
          id
          owners
          createdAt
          createdBy
          updatedAt
          updatedBy
          deleted
          deletedAt
          deletedBy
          owners
          client {
            firstname
            lastname
          }
          contract {
            contractNumber
          }
          coin {
            denominationMotif
          }
          price
          status
          version
        }
        nextToken
      }
      version
    }
  }
`

export default mutation
