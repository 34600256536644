import gql from 'graphql-tag'

const query = gql`
  query LIST_EMAILS_QUERY (
    $filter: ModelEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        __typename
        receivedAt
        to
        subject
        status
      }
      nextToken
    }
  }
`

export default query
