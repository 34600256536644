import { all, takeLatest } from 'redux-saga/effects'

import { ToolsTypes } from 'Stores/Tools/Actions'
import Tools from 'Sagas/Tools'

export default function * root() {
  yield all([
    takeLatest(ToolsTypes.EXPORT_DEPOSITS_BALANCE_TO_DATE, Tools.exportDepositsBalanceToDate),
    takeLatest(ToolsTypes.EXPORT_DEPOSITS_BALANCE_TO_DATE_SUCCESS, Tools.exportDepositsBalanceToDateSuccess),
    takeLatest(ToolsTypes.EXPORT_DEPOSITS_BALANCE_TO_DATE_FAILURE, Tools.exportDepositsBalanceToDateFailure)
  ])
}
