import React from 'react'

import { connect } from 'react-redux'
import CloseContractDialogActions from 'Stores/CloseContractDialog/Actions'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Typography from '@material-ui/core/Typography'

import DraggablePaper from 'Components/_Common/Dialogs/DraggablePaper'

import Loading from 'Components/Loading'

// tslint:disable
const useStyles = makeStyles(theme => ({
  root: {
    width: '90%'
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  active: {
    color: '#baa436'
  }
}))
// tslint:enable

interface Props {
  data?: any,
  close?: any
}

const getSteps = () => {
  return ['Účet', 'Smlouva', 'Email', 'Hotovo']
}

const getStepContent = (stepIndex: number) => {
  switch (stepIndex) {
    case 0:
      return 'Vytváření účtu pro přístup do klientské zóny'
    case 1:
      return 'Generování smlouvy v PDF'
    case 2:
      return 'Odeslání smlouvy na emailovou adresu klienta'
    case 3:
      return 'Smlouva uzavřena'

    default:
      return ' '
  }
}

const CloseContractDialog = (props: Props) => {
  const { open, activeStep, loading, error } = props.data

  const classes = useStyles()
  const steps = getSteps()

  return (
    <Dialog
      PaperComponent={DraggablePaper}
      open={open}
      onClose={props.close}
      aria-labelledby='form-dialog-title'
      maxWidth='sm'
      fullWidth={true}
    >
      <DialogTitle id='form-dialog-title'>{`Uzavření smlouvy`}</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel={true}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <DialogContentText>
          <Typography className={classes.instructions}>
            1. {getStepContent(0)} ... <strong className={classes.active}>{activeStep === 0 ? `probíhá` : ``}{activeStep > 0 ? `hotovo` : ``}</strong>
          </Typography>
          <Typography className={classes.instructions}>
            2. {getStepContent(1)} ... <strong className={classes.active}>{activeStep === 1 ? `probíhá` : ``}{activeStep > 1 ? `hotovo` : ``}</strong>
          </Typography>
          <Typography className={classes.instructions}>
            3. {getStepContent(2)} ... <strong className={classes.active}>{activeStep === 2 ? `probíhá` : ``}{activeStep > 2 ? `hotovo` : ``}</strong>
          </Typography>
          <Typography className={classes.instructions}>
            4. {getStepContent(3)} ... {activeStep === 3 ? <strong className={classes.active}>hotovo.</strong> : ``}
          </Typography>
        </DialogContentText>

        {loading && <Loading />}
        {error !== null &&  <DialogContentText style={{ color: `red` }}>{error}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close}>
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state: any) => ({
  data: state.closeContractDialog
})

const mapDispatchToProps = (dispatch: any) => ({
  close: () => dispatch(CloseContractDialogActions.close())
})

export default connect(mapStateToProps, mapDispatchToProps)(
  CloseContractDialog
)
