import React from 'react'

import DefaultLayout from 'Components/_Common/Layout/Default'

import * as ROUTES from 'routes'

import EmailList from 'Components/Email/List'

const Emails = () => {
  return (
    <DefaultLayout
      title={`Emaily`}
      tabs={[{
        route: ROUTES.ADMIN_EMAILS, title: 'Seznam emailů'
      }]}
      activeTab={0}
    >
      <EmailList />
    </DefaultLayout>
  )
}

export default Emails
