import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import InvoiceActions from 'Stores/Invoice/Actions'
import * as InvoiceSelect from 'Stores/Invoice/Select'

import Loading from 'Components/Loading'
import List from 'Components/Invoice/List/List'

const InvoiceList = () => {
  const invoicesLoading = useSelector(InvoiceSelect.invoicesLoading)
  const invoicesResult = useSelector(InvoiceSelect.invoicesResult)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(InvoiceActions.listInvoices())
  }, [])

  return (
    <>
      <List rows={invoicesResult || []} />
      {invoicesLoading && <Loading />}
    </>
  )
}

export default InvoiceList
