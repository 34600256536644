// @todo
// Refactor

import { addMonths, setDate, format } from 'date-fns'

const calculateEstimatedValue = (mesicniCastka: number, dobaSporeni: number, vstupniCenoveNavyseni: number, jednorazovaUhradaVCN: boolean, pocetMinci: number, aktualniCenaMince: number, startFrom: Date | null = null, firstDelivery: Date | null = null) => {
  let emission1 = false
  let emission2 = false

  let startingMonth
  let startingDate
  if (startFrom === null) {
    startingDate = new Date()
    startingMonth = startingDate.getMonth() + 1
  } else {
    startingDate = startFrom
    startingMonth = startFrom.getMonth() + 1
  }

  const firstDeliveryMonth = firstDelivery?.getMonth()
  const deliveryMonths = []
  
  if (mesicniCastka === 1750) {
    emission1 = firstDeliveryMonth === 4
    emission2 = firstDeliveryMonth === 9
    deliveryMonths.push(firstDeliveryMonth)
  } else {
    emission1 = true
    emission2 = true
    deliveryMonths.push(4)
    deliveryMonths.push(9)
  }

  let actualDeliveryEntitlementDate = null

  const data = []

  const rocniZhodnoceni = 10.78 // 10.78% - GH#69
  const mesicniZhodnoceni = Math.round((rocniZhodnoceni / 12) * 10000) / 10000
  const orientacniCilovaCastka = (pocetMinci * aktualniCenaMince)
  const orientacniCilovaCastkaProPocetMesicuSporeni = orientacniCilovaCastka + (jednorazovaUhradaVCN ? 0 : vstupniCenoveNavyseni)
  const pocetMesicuSporeni = Math.ceil(orientacniCilovaCastkaProPocetMesicuSporeni / mesicniCastka)

  let vstupniCenoveNavyseniJizUhrazeno = jednorazovaUhradaVCN ? vstupniCenoveNavyseni : 0
  let nasporenoNaMince = 0
  let predpokladanaHodnota = 0
  let nakoupenoMinci = 0

  let actualMonth = startingMonth
  for (let i = 1; i <= pocetMesicuSporeni; i++) {
    // iterujeme vsema mesicama ...
    
    const row = {} as any // @todo
    row.month = i
    row.monthly = mesicniCastka
    row.total = (jednorazovaUhradaVCN ? vstupniCenoveNavyseni : 0) + i * mesicniCastka

    // kontrola uhrady vstupniho poplatku
    if (vstupniCenoveNavyseniJizUhrazeno < vstupniCenoveNavyseni) {
      // neni uhrazeno
      // nejdrive splacime vstupni cenove navyseni ze 100% 
      vstupniCenoveNavyseniJizUhrazeno += mesicniCastka
      row.entryFeePaid = false
      row.entryFeeAmount = vstupniCenoveNavyseniJizUhrazeno

      if (vstupniCenoveNavyseniJizUhrazeno > vstupniCenoveNavyseni) {
        // pokud je splaceno vice, tak rozdil nad je uz pouzit do sporeni na mince
        nasporenoNaMince += vstupniCenoveNavyseniJizUhrazeno - vstupniCenoveNavyseni
        row.entryFeePaid = true
        row.entryFeeAmount = 0
      }

      // data
      row.saved = nasporenoNaMince
      row.value = 0
    } else {
      // vstupni cenove navyseni je jiz uhrazeno
      // mesicni castka se tedy pouziva jiz na sporeni na mince
      nasporenoNaMince += mesicniCastka
      predpokladanaHodnota += Math.round(predpokladanaHodnota * (mesicniZhodnoceni / 100))

      row.entryFeePaid = true
      row.entryFeeAmount = 0
      row.saved = nasporenoNaMince
      row.value = predpokladanaHodnota

      // zkontrolujeme, zda-li ma nasporeno na minci a je 5 nebo 10 mesic (mesic distribuce)
      if (nasporenoNaMince >= aktualniCenaMince && deliveryMonths.includes(actualMonth)) {
        const pocetMinci = 1 // Math.floor(nasporenoNaMince / aktualniCenaMince)
        // pokud ano, koupime minci
        row.buy = true
        row.boughtCount = pocetMinci
        nakoupenoMinci += pocetMinci
        nasporenoNaMince -= pocetMinci * aktualniCenaMince
        // a cenu mince pripocteme k predpokladane hodnote
        predpokladanaHodnota += pocetMinci * aktualniCenaMince

        row.savedDueToBuy = nasporenoNaMince
        row.valueDueToBuy = predpokladanaHodnota

        if (nakoupenoMinci === 1) {
          actualDeliveryEntitlementDate = format(setDate(addMonths(startingDate, i), 1), 'yyyy-MM-dd')
        }
      } else {
        row.buy = false
        // pokud nema nasporeno na minci, tak se v dany mesic zhodnoti jen to, co jiz ma v mincich
      }
    }

    row.coins = nakoupenoMinci

    data.push(row)

    actualMonth = actualMonth === 12 ? 1 : actualMonth + 1
  }

  return {
    data,
    estimatedValue: Math.round(predpokladanaHodnota + nasporenoNaMince),
    actualDeliveryEntitlementDate,
    //
    emission1,
    emission2
  }
}

export default calculateEstimatedValue
