import gql from 'graphql-tag'

const query = gql`
  query LIST_INVOICES_QUERY (
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        __typename
        type
        invoiceNumber
        owners
        createdAt
        dateOfIssue
        client {
          id
          __typename
        }
        clientFirstname
        clientLastname
        clientCompanyName
        variableSymbol
        price
        status
      }
      nextToken
    }
  }
`

export default query
