import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import DefaultLayout from 'Components/_Common/Layout/Default'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import * as ROUTES from 'routes'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2)
  }
}))

const InvoicesHelp = () => {
  const classes = useStyles()

  const now = new Date()

  return (
    <DefaultLayout
      title={`Faktury`}
      tabs={[{
        route: ROUTES.ADMIN_INVOICES, title: 'Seznam faktur'
      }, {
        route: '/faktury/nova', title: 'Nová faktura'
      }, {
        route: '/faktury/napoveda', title: 'Nápověda'
      }]}
      activeTab={2}
    >
      <Paper className={classes.root}>
        <Typography variant="h6" color="primary" gutterBottom={true}>
          Číselné řady
        </Typography>

        <Typography variant="subtitle1" color="primary">
          {now.getFullYear()}<strong>00</strong>0001
        </Typography>
        <Typography component="p" gutterBottom={true}>
          Automaticky generované faktury pro výplatu provize z GC do BI.
          <br />&nbsp;
        </Typography>

        <Typography variant="subtitle1" color="primary">
          {now.getFullYear()}<strong>01</strong>0001
        </Typography>
        <Typography component="p" gutterBottom={true}>
          Zálohové faktury.
          <br />&nbsp;
        </Typography>

        <Typography variant="subtitle1" color="primary">
          {now.getFullYear()}<strong>02</strong>0001
        </Typography>
        <Typography component="p" gutterBottom={true}>
          Jednorázové obchody.
          <br />&nbsp;
        </Typography>

        <Typography variant="subtitle1" color="primary">
          {now.getFullYear()}<strong>03</strong>0001
        </Typography>
        <Typography component="p" gutterBottom={true}>
          Klasické faktury za již obdržené platby.
          <br />&nbsp;
        </Typography>
      </Paper>
    </DefaultLayout>
  )
}

export default InvoicesHelp
