import { all, put, select, takeLatest } from 'redux-saga/effects'

import ContractActions from 'Stores/Contract/Actions'
import SearchForClientActions, { SearchForClientTypes } from 'Stores/SearchForClient/Actions'

import { API, graphqlOperation } from 'aws-amplify'
import * as queries from 'Graphql/queries'

import { parsePhoneNumberFromString } from 'libphonenumber-js'

import concatWs from 'Helpers/concatWs'
import getBirthDateFromPersonalNumber from 'Helpers/getBirthDateFromPersonalNumber'
import isEmpty from 'Helpers/isEmpty'

export function * searchForClient ({ personalNumber }: any) {
  try {
    const getUser = (state: any) => state.user
    const user = yield select(getUser)

    // yield put(ContractActions.formClean())
    yield put(SearchForClientActions.searchForClientLoading())
    yield put(SearchForClientActions.searchForGcClientLoading())

    const variables = { filter: { personalNumber: { eq: personalNumber } }, limit: 1000 }
    const result = (yield API.graphql(graphqlOperation(queries.listClients, variables))) as any
    if (result && 'data' in result && 'listClients' in result.data && 'items' in result.data.listClients &&
      result.data.listClients.items instanceof Array && result.data.listClients.items.length === 1) {
      const clientGc = result.data.listClients.items[0]

      if (parseInt(clientGc.benefitId, 10) !== parseInt(user.benefitId, 10)) {
        // @todo
        const client = {} as any

        client.clientId = clientGc.id
        client.clientBenefitId = `${clientGc.benefitId}`
        client.clientFirstname = clientGc.firstname
        client.clientLastname = clientGc.lastname
        client.clientPersonalNumber = clientGc.personalNumber
        client.clientEmail = clientGc.email
        client.clientPhone = clientGc.phone

        client.clientAddressStreet = clientGc.addressStreet
        client.clientAddressStreetNumber = clientGc.addressStreetNumber
        client.clientAddressZip = clientGc.addressZip
        client.clientAddressTown = clientGc.addressTown

        // @todo
        const clientHasDifferentDeliveryAddress = clientGc.hasDifferentDeliveryAddress
        
        client.clientHasDifferentDeliveryAddress = clientHasDifferentDeliveryAddress
        if (clientHasDifferentDeliveryAddress) {
          client.clientDeliveryFirstname = clientGc.deliveryFirstname
          client.clientDeliveryLastname = clientGc.deliveryLastname
          client.clientDeliveryAddressStreet = clientGc.deliveryAddressStreet
          client.clientDeliveryAddressStreetNumber = clientGc.deliveryAddressStreetNumber
          client.clientDeliveryAddressZip = clientGc.deliveryAddressZip
          client.clientDeliveryAddressTown = clientGc.deliveryAddressTown
          client.clientDeliveryPhone = clientGc.deliveryPhone
          client.clientDeliveryBirthYear = clientGc.birthYear
        }

        client.clientBankAccountNumber = clientGc.bankAccountNumber
        client.clientBankCode = clientGc.bankCode

        client.clientIsFs = clientGc.isFs
       
        yield put(SearchForClientActions.searchForGcClientSuccess())
        yield put(SearchForClientActions.searchForClientSuccess())
        yield put(ContractActions.formUpdate(client))
      } else {
        yield put(SearchForClientActions.searchForGcClientFailure(`Nemůžete uzavřít smlouvu sami sobě!`))
      }
    } else {
      yield put(SearchForClientActions.searchForGcClientFailure())

      yield put(SearchForClientActions.searchForBiClientLoading())

      const url = `${process.env.REACT_APP_BENEFIT_API_URL}/associates?personal_identification_number=${personalNumber}`
      const response = yield fetch(url, {
        headers: {
          'Authorization': `Bearer ${user.accessToken}`,
          'Content-Type': 'application/json',
          'X-Source': 'goldcoins.as'
        },
        method: 'GET'
      })
  
      if (response.ok && response.status === 200) {
        const clientBi = yield response.json()
        const client = {} as any
  
        if (parseInt(clientBi.id, 10) !== parseInt(user.benefitId, 10)) {
          client.clientBenefitId = `${clientBi.id}`
          client.clientFirstname = clientBi.name
          client.clientLastname = clientBi.surname
          client.clientPersonalNumber = clientBi.personal_identification_number
          client.clientEmail = clientBi.email
  
          const phoneNumber = parsePhoneNumberFromString(String(clientBi.telephone_number), 'CZ')
          if (phoneNumber !== undefined) {
            client.clientPhone = phoneNumber.format('E.164')
          }
  
          client.clientAddressStreet = clientBi.place_street
          client.clientAddressStreetNumber = concatWs(clientBi.place_cp, clientBi.place_co, '/')
          client.clientAddressZip = clientBi.place_psc
          client.clientAddressTown = clientBi.place_town
  
          // @todo
          const clientHasDifferentDeliveryAddress = !isEmpty(clientBi.correspon_street) || 
            !isEmpty(clientBi.correspon_cp) || !isEmpty(clientBi.correspon_co) ||
            !isEmpty(clientBi.correspon_psc) || !isEmpty(clientBi.correspon_town)
  
          client.clientHasDifferentDeliveryAddress = clientHasDifferentDeliveryAddress
          if (clientHasDifferentDeliveryAddress) {
            client.clientDeliveryFirstname = clientBi.name
            client.clientDeliveryLastname = clientBi.surname
            client.clientDeliveryAddressStreet = clientBi.correspon_street
            client.clientDeliveryAddressStreetNumber = concatWs(clientBi.correspon_cp, clientBi.correspon_co, '/')
            client.clientDeliveryAddressZip = clientBi.correspon_psc
            client.clientDeliveryAddressTown = clientBi.correspon_town
            
            if (phoneNumber !== undefined) {
              client.clientDeliveryPhone = phoneNumber.format('E.164')
            }
  
            const birthDate = getBirthDateFromPersonalNumber(client.clientPersonalNumber)
            if (birthDate !== undefined) {
              client.clientDeliveryBirthYear = String(birthDate).substr(0, 4)
            }
          }
  
          client.clientBankAccountNumber = concatWs(clientBi.account_pre_number, clientBi.account_number, '-')
          client.clientBankCode = clientBi.bank_number
  
          client.clientIsFs = clientBi.is_manager

          yield put(SearchForClientActions.searchForBiClientSuccess())
          yield put(SearchForClientActions.searchForClientSuccess())
          yield put(ContractActions.formUpdate(client))
        } else {
          yield put(SearchForClientActions.searchForBiClientFailure(`Nemůžete uzavřít smlouvu sami sobě!`))
        }
      } else {
        yield put(SearchForClientActions.searchForBiClientFailure())
        yield put(SearchForClientActions.searchForClientFailure())
      }
    }    
  } catch (e) {
    yield put(SearchForClientActions.searchForClientFailure(e.message))
  }
}

export default function * root() {
  yield all([
    takeLatest(SearchForClientTypes.SEARCH_FOR_CLIENT, searchForClient)
  ])
}
