import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import ContractActions from 'Stores/Contract/Actions'

import useReactRouter from 'use-react-router'

import DefaultLayout from 'Components/_Common/Layout/Default'
import Loading from 'Components/Loading'
import ContractForm from 'Components/Contract/Form'

import Button from '@material-ui/core/Button'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import WaitDialog from 'Components/_Common/Dialogs/Wait'

import ChangeEmailDialog from 'Components/_Common/Dialogs/ChangeEmail'
import ChangeAddressDialog from 'Components/_Common/Dialogs/ChangeAddress'

import CloseContractDialog from 'Routes/Admin/Contracts/components/CloseContractDialog'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import { format } from 'date-fns'

import isEmpty from 'Helpers/isEmpty'
import sortBy from 'Helpers/sortBy'

import * as ROUTES from 'routes'

import ClientsList from 'Components/Client/List/List'
import DistributionsList from 'Components/Distribution/List/List'
import EmailsList from 'Components/Email/List/List'
import InvoicesList from 'Components/Invoice/List/List'
import PaymentsList from 'Components/Payment/List/List'

import { history } from '../../../index'

const Contract = (props: any) => {
  const {
    match: {
      params: { id }
    }
  }: any = useReactRouter()

  useEffect(() => {
    props.getContract(id)
  }, [id])

  const [tab, setTab] = useState(0)

  function handleSwitchTab(event: any, newValue: number): void {
    setTab(newValue)
  }

  const handleSendContract = () => {
    handleCloseMenu()
    props.sendContract(id)
  }

  const handleDownloadContract = () => {
    handleCloseMenu()
    props.downloadContract(id, false)
  }

  const handleRegenerateContract = () => {
    handleCloseMenu()
    props.downloadContract(id, true)
  }

  const handleChangeEmail = () => {
    handleCloseMenu()
    props.openChangeEmailDialog(id)
  }

  const handleChangeAddress = () => {
    handleCloseMenu()
    props.openChangeAddressDialog(id)
  }

  const handleDeleteContract = () => {
    handleCloseMenu()
    props.deleteContract(id)
    history.push(`/smlouvy`)   
  }

  //

  const [anchorElement, setAnchorElement] = useState(null)

  const handleMenuClick = (event: any): void => {
    setAnchorElement(event.currentTarget)
  }

  const handleCloseMenu = (): void => {
    setAnchorElement(null)
  }

  const actionMenu = (data: any): any => (
    <React.Fragment>
      <Button
        aria-owns={anchorElement ? 'simple-menu' : undefined}
        aria-haspopup='true'
        onClick={handleMenuClick}
        variant='outlined'
        color='secondary'
        size='large'
      >
        Akce
      </Button>
      <Menu id='simple-menu' anchorEl={anchorElement} open={Boolean(anchorElement)} onClose={handleCloseMenu}>
        {props.user.group === 'Admins' &&
          <MenuItem onClick={handleDeleteContract}>
            <ListItemText primary='Smazat smlouvu' secondary='Označí smlouvu za smazanou' />
          </MenuItem>}

        <MenuItem onClick={handleSendContract} disabled={!isEmpty(data) && (data.contractNumber === null || (data.client && data.client.cognitoUserId === null))}>
          <ListItemText primary='Odeslat Návrh smlouvy' secondary={!isEmpty(data) && 'contractSentAt' in data && data.contractSentAt ? `Naposledy odesláno ${format(new Date(data.contractSentAt), 'dd.MM.yyyy HH:mm')}` : `Odešle klientovi návrh smlouvy emailem`} />
        </MenuItem>

        <MenuItem onClick={handleDownloadContract} disabled={!isEmpty(data) && data.contractNumber === null}>
          <ListItemText primary='Stáhnout smlouvu' secondary='Připraví smlouvu pro stažení ve formátu PDF' />
        </MenuItem>

        {props.user.group === 'Admins' &&
          <MenuItem onClick={handleRegenerateContract} disabled={!isEmpty(data) && data.contractNumber === null}>
            <ListItemText primary='Přegenerovat smlouvu' secondary='Znovu vygeneruje smlouvu ve formátu PDF' />
          </MenuItem>}

        {props.user.group === 'Admins' &&
        <MenuItem onClick={handleChangeEmail} disabled={!isEmpty(data) && data.contractNumber === null}>
          <ListItemText primary='Změnit email' secondary='Změní email a odešle nové přihlašovací údaje' />
        </MenuItem>}

        {props.user.group === 'Admins' &&
        <MenuItem onClick={handleChangeAddress} disabled={!isEmpty(data) && data.contractNumber === null}>
          <ListItemText primary='Změnit adresu' secondary='Změní adresu na smlouvě i u klienta' />
        </MenuItem>}
      </Menu>
    </React.Fragment>
  )

  return (
    <DefaultLayout
      title={`Smlouva`}
      tabs={[{
        route: ROUTES.ADMIN_CONTRACTS, title: 'Seznam smluv'
      }, {
        route: `/smlouvy/detail/${id}`, title: 'Editace smlouvy'
      }]}
      activeTab={1}
    >
      {props.data.loading ? <Loading /> : (
        <>
          {id && <AppBar position='static' color='default' style={{ width: `${props.dimensions.width - 72}px`, margin: 'auto' }}>
            <Tabs
              value={tab}
              onChange={handleSwitchTab}
              variant='scrollable'
              scrollButtons='off'
              indicatorColor='primary'
              textColor='primary'
            >
              <Tab label='Smlouva' icon={<FontAwesomeIcon icon={['fal', 'file-signature']} size='2x' />} />
              <Tab label='Klient' icon={<FontAwesomeIcon icon={['fal', 'user']} size='2x' />} />
              <Tab label='Platby' icon={<FontAwesomeIcon icon={['fal', 'money-check-alt']} size='2x' />} />
              <Tab label='Distribuce' icon={<FontAwesomeIcon icon={['fal', 'truck-container']} size='2x' />} />
              <Tab label='Faktury' icon={<FontAwesomeIcon icon={['fal', 'file-invoice']} size='2x' />} />
              <Tab label='Komunikace' icon={<FontAwesomeIcon icon={['fal', 'mail-bulk']} size='2x' />} />
            </Tabs>
          </AppBar>}

          {tab === 0 && <ContractForm
            actionMenu={!!id ? actionMenu(props.getContractResult) : null}
          />}

          {/* @todo SORT */}
          {tab === 1 && <ClientsList
            rows={[props.getContractResult.client]}
            heightOffset={-72}
          />}

          {/* @todo SORT */}
          {tab === 2 && <PaymentsList
            rows={sortBy(props.getContractResult.payments.items.slice(), 'bankReference', 'DESC')}
            heightOffset={-72}
          />}

          {/* @todo SORT */}
          {tab === 3 && <DistributionsList
            rows={sortBy(props.getContractResult.distributions.items.slice(), 'createdAt', 'DESC', (v: any): any => new Date(v))}
            heightOffset={-72}
          />}

          {/* @todo SORT */}
          {tab === 4 && <InvoicesList
            rows={sortBy(props.getContractResult.invoices.items.slice(), 'invoiceNumber', 'ASC')}
            heightOffset={-72}
          />}

          {/* @todo SORT */}
          {tab === 5 && <EmailsList
            rows={sortBy(props.getContractResult.emails.items.slice(), 'receivedAt', 'DESC', (v: any): any => new Date(v))}
            heightOffset={-72}
          />}
        </>
      )}

      <WaitDialog />
      <CloseContractDialog />

      {props.changeEmailDialogOpened ? <ChangeEmailDialog contract={props.getContractResult} /> : null}
      {props.changeAddressDialogOpened ? <ChangeAddressDialog contract={props.getContractResult} /> : null}
    </DefaultLayout>
  )
}

const mapStateToProps = (state: any) => ({
  dimensions: state.app.dimensions,
  getContractResult: state.contract.getContractResult,
  data: state.contract.form,
  user: state.user,
  //
  changeEmailDialogOpened: state.contract.changeEmailDialogOpened,
  changeAddressDialogOpened: state.contract.changeAddressDialogOpened
})

const mapDispatchToProps = (dispatch: any) => ({
  getContract: (id: any) => dispatch(ContractActions.getContract(id)),
  //
  sendContract: (id: any) => dispatch(ContractActions.sendContract(id)),
  downloadContract: (id: any, generate: boolean) => dispatch(ContractActions.downloadContract(id, generate)),
  openChangeEmailDialog: (id: any) => dispatch(ContractActions.openChangeEmailDialog(id)),
  openChangeAddressDialog: (id: any) => dispatch(ContractActions.openChangeAddressDialog(id)),
  deleteContract: (id: any) => dispatch(ContractActions.deleteContract(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  Contract
)
