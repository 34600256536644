import { createReducer } from 'reduxsauce'

import { UserTypes } from './Actions'
import INITIAL_STATE from './InitialState'

import { cs } from 'Config/amplify-i18n'

export const loginLoading = (state: any): any => {
  return {
    ...state,
    loginIsLoading: true,
    loginErrorMessage: null
  }
}

export const loginSuccess = (state: any, { response }: any): any => {
  // console.log('response', response) // tslint:disable-line
  // @todo
  return {
    ...state,
    loginIsLoading: false,
    accessToken: response.signInUserSession.accessToken.jwtToken,
    benefitId: response.attributes['custom:bi_number'],
    group: response.signInUserSession.idToken.payload['cognito:groups'][0],
    idToken: response.signInUserSession.idToken.jwtToken,
    isAuthenticated: true,
    username: response.username
  }
}

export const loginFailure = (state: any, { error }: any): any => {
  const message = error.message in cs ? cs[error.message] : error.message

  return {
    ...state,
    loginIsLoading: false,
    loginErrorMessage: message
  }
}

export const logoutLoading = (state: any): any => {
  return {
    ...state,
    logoutIsLoading: true
  }
}

export const logoutSuccess = (): any => ({
  ...INITIAL_STATE,
  logoutIsLoading: false
})

export const logoutFailure = (): any => ({
  ...INITIAL_STATE,
  logoutIsLoading: false
})

export const forgotPasswordLoading = (state: any): any => {
  return {
    ...state,
    forgotPasswordIsLoading: true
  }
}

export const forgotPasswordRequestSuccess = (state: any, { username, deliveryMedium, destination }: any): any => ({
  ...state,
  forgotPasswordIsLoading: false,
  forgotPasswordErrorMessage: null,
  forgotPasswordState: 'CONFIRM',
  forgotPasswordUsername: username,
  forgotPasswordDeliveryMedium: deliveryMedium,
  forgotPasswordDestination: destination
})

export const forgotPasswordRequestFailure = (state: any, { error }: any): any => {
  const message = error.message in cs ? cs[error.message] : error.message
  
  return {
    ...state,
    forgotPasswordIsLoading: false,
    forgotPasswordErrorMessage: message,
    forgotPasswordState: 'REQUEST',
    forgotPasswordUsername: null,
    forgotPasswordDeliveryMedium: null,
    forgotPasswordDestination: null
  }
}

export const forgotPasswordConfirmSuccess = (state: any): any => ({
  ...state,
  forgotPasswordIsLoading: false,
  forgotPasswordErrorMessage: null,
  forgotPasswordState: 'REQUEST',
  forgotPasswordDeliveryMedium: null,
  forgotPasswordDestination: null
})

export const forgotPasswordConfirmFailure = (state: any, { error }: any): any => {
  const message = error.message in cs ? cs[error.message] : error.message

  return {
    ...state,
    forgotPasswordIsLoading: false,
    forgotPasswordErrorMessage: message
  }
}

export const fetchUserSuccess = (state: any, { username, group, benefitId, idToken, accessToken }: any): any => ({
  ...state,
  loginIsLoading: false,
  accessToken,
  benefitId,
  group,
  idToken,
  isAuthenticated: true,
  username
})

export const fetchUserFailure = (): any => ({
  ...INITIAL_STATE,
  loginIsLoading: false
})

export const reducer = createReducer(INITIAL_STATE, {
  [UserTypes.LOGIN_LOADING]: loginLoading,
  [UserTypes.LOGIN_SUCCESS]: loginSuccess,
  [UserTypes.LOGIN_FAILURE]: loginFailure,
  [UserTypes.LOGOUT_LOADING]: logoutLoading,
  [UserTypes.LOGOUT_SUCCESS]: logoutSuccess,
  [UserTypes.LOGOUT_FAILURE]: logoutFailure,
  [UserTypes.FORGOT_PASSWORD_LOADING]: forgotPasswordLoading,
  [UserTypes.FORGOT_PASSWORD_REQUEST_SUCCESS]: forgotPasswordRequestSuccess,
  [UserTypes.FORGOT_PASSWORD_REQUEST_FAILURE]: forgotPasswordRequestFailure,
  [UserTypes.FORGOT_PASSWORD_CONFIRM_SUCCESS]: forgotPasswordConfirmSuccess,
  [UserTypes.FORGOT_PASSWORD_CONFIRM_FAILURE]: forgotPasswordConfirmFailure,
  [UserTypes.FETCH_USER_SUCCESS]: fetchUserSuccess,
  [UserTypes.FETCH_USER_FAILURE]: fetchUserFailure
})
