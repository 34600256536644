import { put } from 'redux-saga/effects'

import PaymentActions from 'Stores/Payment/Actions'

import { recursivelyLoadAllData } from 'Sagas'
import listPaymentsQuery from 'Graphql/Queries/listPaymentsQuery'

export function * listPayments ({ filter }: any) {
  try {
    yield put(PaymentActions.listPaymentsLoading())

    const variables = {} as any

    const data = yield recursivelyLoadAllData(listPaymentsQuery, variables, 'listPayments')

    // @todo
    data.sort((a: any, b: any) => {
      return b.bankReference - a.bankReference
    })

    yield put(PaymentActions.listPaymentsSuccess(data))
  } catch (error) {
    yield put(PaymentActions.listPaymentsFailure(error))
  }
}

export default listPayments
