import { all, takeLatest } from 'redux-saga/effects'

import { ContractTypes } from 'Stores/Contract/Actions'
import Contract from 'Sagas/Contract'

export default function * root() {
  yield all([
    takeLatest(ContractTypes.LIST_CONTRACTS, Contract.listContracts),
    takeLatest(ContractTypes.LIST_CONTRACTS_SUCCESS, Contract.listContractsSuccess),
    takeLatest(ContractTypes.LIST_CONTRACTS_FAILURE, Contract.listContractsFailure),
    //
    takeLatest(ContractTypes.GET_CONTRACT, Contract.getContract),
    takeLatest(ContractTypes.GET_CONTRACT_SUCCESS, Contract.getContractSuccess),
    takeLatest(ContractTypes.GET_CONTRACT_FAILURE, Contract.getContractFailure),
    //
    takeLatest(ContractTypes.GET_MY_CONTRACT, Contract.getMyContract),
    takeLatest(ContractTypes.GET_MY_CONTRACT_SUCCESS, Contract.getMyContractSuccess),
    takeLatest(ContractTypes.GET_MY_CONTRACT_FAILURE, Contract.getMyContractFailure),
    //
    takeLatest(ContractTypes.PREFILL_CLIENT, Contract.prefillClient),
    takeLatest(ContractTypes.PREFILL_CLIENT_SUCCESS, Contract.prefillClientSuccess),
    takeLatest(ContractTypes.PREFILL_CLIENT_FAILURE, Contract.prefillClientFailure),
    //
    takeLatest(ContractTypes.SAVE_CONTRACT, Contract.saveContract),
    takeLatest(ContractTypes.SAVE_CONTRACT_SUCCESS, Contract.saveContractSuccess),
    takeLatest(ContractTypes.SAVE_CONTRACT_FAILURE, Contract.saveContractFailure),
    //
    takeLatest(ContractTypes.CREATE_ACCOUNT, Contract.createAccount),
    takeLatest(ContractTypes.CREATE_ACCOUNT_SUCCESS, Contract.createAccountSuccess),
    takeLatest(ContractTypes.CREATE_ACCOUNT_FAILURE, Contract.createAccountFailure),
    //
    takeLatest(ContractTypes.SET_STATUS, Contract.setStatus),
    takeLatest(ContractTypes.SET_STATUS_SUCCESS, Contract.setStatusSuccess),
    takeLatest(ContractTypes.SET_STATUS_FAILURE, Contract.setStatusFailure),
    //
    takeLatest(ContractTypes.SEND_CONTRACT, Contract.sendContract),
    takeLatest(ContractTypes.SEND_CONTRACT_SUCCESS, Contract.sendContractSuccess),
    takeLatest(ContractTypes.SEND_CONTRACT_FAILURE, Contract.sendContractFailure),
    //
    takeLatest(ContractTypes.DOWNLOAD_CONTRACT, Contract.downloadContract),
    takeLatest(ContractTypes.DOWNLOAD_CONTRACT_SUCCESS, Contract.downloadContractSuccess),
    takeLatest(ContractTypes.DOWNLOAD_CONTRACT_FAILURE, Contract.downloadContractFailure),
    //
    takeLatest(ContractTypes.CHANGE_ADDRESS, Contract.changeAddress),
    takeLatest(ContractTypes.CHANGE_ADDRESS_SUCCESS, Contract.changeAddressSuccess),
    takeLatest(ContractTypes.CHANGE_ADDRESS_FAILURE, Contract.changeAddressFailure),
    //
    takeLatest(ContractTypes.CHANGE_EMAIL, Contract.changeEmail),
    takeLatest(ContractTypes.CHANGE_EMAIL_SUCCESS, Contract.changeEmailSuccess),
    takeLatest(ContractTypes.CHANGE_EMAIL_FAILURE, Contract.changeEmailFailure),
    //
    takeLatest(ContractTypes.DELETE_CONTRACT, Contract.deleteContract),
    takeLatest(ContractTypes.DELETE_CONTRACT_SUCCESS, Contract.deleteContractSuccess),
    takeLatest(ContractTypes.DELETE_CONTRACT_FAILURE, Contract.deleteContractFailure),
    //
    takeLatest(ContractTypes.CLOSE_CONTRACT, Contract.closeContract),
    takeLatest(ContractTypes.CLOSE_CONTRACT_SUCCESS, Contract.closeContractSuccess),
    takeLatest(ContractTypes.CLOSE_CONTRACT_FAILURE, Contract.closeContractFailure)
  ])
}
