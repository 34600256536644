import React from 'react'

import { connect } from 'react-redux'
import ClientActions from 'Stores/Client/Actions'
import * as ClientSelect from 'Stores/Client/Select'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import DraggablePaper from 'Components/_Common/Dialogs/DraggablePaper'

import ClientList from 'Components/Client/List'

interface Props {
  open?: boolean,
  handleOpenDialog?: any,
  handleCloseDialog?: any
}

class ClientList_Modal extends React.Component<Props> {
  render () {
    return (
      <>
        <Dialog
          PaperComponent={DraggablePaper}
          open={(this.props.open) as boolean}
          onClose={this.props.handleCloseDialog}
          aria-labelledby='client-search-modal-title'
          fullWidth={true}
          maxWidth='lg'
        >
          <DialogTitle id='client-search-modal-title'>Vyhledání klienta</DialogTitle>
          <DialogContent style={{ padding: 0 }}>
            <ClientList />
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  open: ClientSelect.clientsModalOpened(state)
})

const mapDispatchToProps = (dispatch: any) => ({
  handleOpenDialog: () => dispatch(ClientActions.listClientsOpenModal(true)),
  handleCloseDialog: () => dispatch(ClientActions.listClientsOpenModal(false))
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientList_Modal)
