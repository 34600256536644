import { createReducer } from 'reduxsauce'

import { EmissionTypes } from './Actions'
import INITIAL_STATE from './InitialState'

// LIST_EMISSIONS_LOADING
export const listEmissionsLoading = (state: any): any => ({
  ...state,
  listEmissionsLoading: true,
  listEmissionsResult: [],
  listEmissionsError: null
})

// LIST_EMISSIONS_SUCCESS
export const listEmissionsSuccess = (state: any, { data }: any): any => ({
  ...state,
  listEmissionsLoading: false,
  listEmissionsResult: data,
  listEmissionsError: null
})

// LIST_EMISSIONS_FAILURE
export const listEmissionsFailure = (state: any, { error }: any): any => ({
  ...state,
  listEmissionsLoading: false,
  listEmissionsError: error
})

//

export const reducer = createReducer(INITIAL_STATE, {
  [EmissionTypes.LIST_EMISSIONS_LOADING]: listEmissionsLoading,
  [EmissionTypes.LIST_EMISSIONS_SUCCESS]: listEmissionsSuccess,
  [EmissionTypes.LIST_EMISSIONS_FAILURE]: listEmissionsFailure
})
