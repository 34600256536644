import React from 'react'

import DefaultLayout from 'Components/_Common/Layout/Default'

import * as ROUTES from 'routes'

import ContractList from 'Components/Contract/List'

const Contracts = () => {
  return (
    <DefaultLayout
      title={`Smlouvy`}
      tabs={[{
        route: ROUTES.ADMIN_CONTRACTS, title: 'Seznam smluv'
      }, {
        route: '/smlouvy/nova', title: 'Nová smlouva'
      }]}
      activeTab={0}
    >
      <ContractList />
    </DefaultLayout>
  )
}

export default Contracts
