import gql from 'graphql-tag'

const query = gql`
  query LIST_EMISSIONS_QUERY (
    $filter: ModelEmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        __typename
        type
        name
        description
        yearFrom
        yearTo
        coins (limit: 100) {
          items {
            id
            value
            denominationMotif
            description
            designer
            mint
            dateOfIssue
            decreeNo
            diameter
            thickness
            weightFine
            weightGross
            mintageLimitNormal
            mintageLimitProof
            normalEdge
            normalActualMintage
            proofEdge
            proofActualMintage
          }
        }
      }
      nextToken
    }
  }
`

export default query
