const sortBy = (data: any, column: string, direction: string = 'ASC', cb = (v: any): any => v): any => {
  data.sort((a: any, b: any) => {
    if (direction === 'DESC') {
      return cb(b[column]) - cb(a[column])
    } else {
      return cb(a[column]) - cb(b[column])
    }
  })

  return data
}

export default sortBy
