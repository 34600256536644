import { getContext, put } from 'redux-saga/effects'

import PaymentActions from 'Stores/Payment/Actions'

import getPaymentQuery from 'Graphql/Queries/getPaymentQuery'

export function * getPayment ({ id }: any) {
  try {
    yield put(PaymentActions.getPaymentLoading())

    const graphql = yield getContext('graphql')
    const variables = { id } as any

    const result = yield graphql.query(getPaymentQuery, variables)

    // @todo
    if (result && result.data && result.data.getPayment) {
      yield put(PaymentActions.getPaymentSuccess(result.data.getPayment))
    }
  } catch (error) {
    yield put(PaymentActions.getPaymentFailure(error))
  }
}

export default getPayment
