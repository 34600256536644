import React from 'react'

import { connect } from 'react-redux'
import ClientActions from 'Stores/Client/Actions'
import InvoiceActions from 'Stores/Invoice/Actions'
import StockActions from 'Stores/Stock/Actions'
import * as InvoiceSelect from 'Stores/Invoice/Select'
import SearchForClientActions from 'Stores/SearchForClient/Actions'

import { Field, Form, FormSpy } from 'react-final-form'

import ClientListModal from 'Components/Client/List/Modal'
import StockListModal from 'Components/Stock/List/Modal'

import Alert from '@material-ui/lab/Alert'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import MuiTextField from '@material-ui/core/TextField'

import { createStyles, Theme, withStyles } from '@material-ui/core/styles'

import DatePicker from 'Components/_Common/Form/Inputs/DatePicker'
import Select from 'Components/_Common/Form/Inputs/Select'
import Switch from 'Components/_Common/Form/Inputs/Switch'
import TextField from 'Components/_Common/Form/Inputs/TextField'

import ts from 'Helpers/thousandsSeparator'
import isEmpty from 'Helpers/isEmpty'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import validate from './validate'
import { history } from '../../../index'

import FormSection from 'Components/_Common/Form/Section'
import InputRow from 'Components/_Common/Form/InputRow'

const styles = (theme: Theme) => createStyles({
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },

  sliderWrapper: {
    // marginTop: `13px`,
    padding: `15px`
  },

  grow: {
    flexGrow: 1,
  },
  appBar: {
    bottom: 0,
    height: 66,
    justifyContent: 'center',
    top: 'auto'
  }
})

const InvoiceForm = (props: any) => {
  const { actionMenu, classes } = props

  const isEditing = 'id' in props.invoiceForm && props.invoiceForm.id !== undefined
  const disableEdit = isEditing && 'invoiceNumber' in props.invoiceForm && props.invoiceForm.invoiceNumber !== null
  // const invoiceNumber = 'invoiceNumber' in props.invoiceForm ? props.invoiceForm.invoiceNumber : null

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const updateFormState = (state: any): any => {
    // console.log(state)
    Object.keys(state.modified).map((key: any): void => {
      if (state.modified[key] === true) {
        if (key.substr(0, 6) === 'items[') {
          props.invoiceFormUpdate({ items: state.values.items })
        } else {
          props.invoiceFormUpdate({ [key]: state.values[key] })
        }
      }

      return key
    })
  }

  const renderForm = ({ handleSubmit, form: { mutators: { push, pop } } }: any) => (
    <form onSubmit={handleSubmit}>
      <FormSpy onChange={updateFormState} />

      <FormSection>
        <InputRow>
          <Grid item={true} xs={6}>
            <Select
              name='type'
              label='Typ faktury'
              disabled={disableEdit}
            >
              <MenuItem value='0-invoice' disabled={!disableEdit}>00 - Automatická faktura (BI)</MenuItem>
              <Divider />
              <MenuItem value='1-proforma'>01 - Zálohová faktura</MenuItem>
              <Divider />
              <MenuItem value='2-invoice' disabled={!disableEdit}>02 - Řádná faktura (Jednorázový obchod)</MenuItem>
              <Divider />
              <MenuItem value='3-invoice' disabled={!disableEdit}>03 - Řádná faktura (Pravidelné platby)</MenuItem>
            </Select>
          </Grid>

          <Grid item={true} xs={6}>
            <TextField
              name='invoiceNumber'
              label='Číslo faktury'
              helperText='Systém přidělí automaticky po vystavení faktury.'
              disabled={true}
            />
          </Grid>
        </InputRow>

        <InputRow>
          <Grid item={true} xs={6}>
            <DatePicker
              name='dateOfIssue'
              label='Datum vystavení'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={6}>
            <DatePicker
              name='dueDate'
              label='Datum splatnosti'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>
      </FormSection>

      <FormSection>
        {!disableEdit && <InputRow>
          <Grid item={true} xs={12}>
            <Button
              variant='contained'
              color='secondary'
              onClick={props.openClientListModal}
            >
              Vyhledat klienta
            </Button>
          </Grid>
        </InputRow>}

        <InputRow>
          <Grid item={true} xs={2}>
            <TextField
              name='clientTitleBefore'
              label='Titul'
              disabled={disableEdit}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item={true} xs={4}>
            <TextField
              name='clientFirstname'
              label='Jméno *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={4}>
            <TextField
              name='clientLastname'
              label='Příjmení *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={2}>
            <TextField
              name='clientTitleAfter'
              label='Titul'
              disabled={disableEdit}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </InputRow>

        <InputRow>
          <Grid item={true} xs={6}>
            <TextField
              name='clientPhone'
              label='Telefon *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={6}>
           <TextField
              name='clientEmail'
              label='Email *'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>

        <InputRow>
          <Grid item={true} xs={6}>
            <TextField
              name='clientAddressStreet'
              label='Ulice *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={6}>
            <TextField
              name='clientAddressStreetNumber'
              label='Číslo popisné/orientační *'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>

        <InputRow>
          <Grid item={true} xs={6}>
            <TextField
              name='clientAddressZip'
              label='PSČ *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={6}>
            <TextField
              name='clientAddressTown'
              label='Město *'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>
      </FormSection>

      <FormSection>
        <InputRow>
          <Grid item={true} xs={6}>
            <FormControlLabel
              control={
                <Field name='clientHasDifferentDeliveryAddress' component={Switch} type='checkbox' />
              }
              label={`Odlišná doručovací adresa`}
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>

        {props.invoiceForm.clientHasDifferentDeliveryAddress && <InputRow>
          <Grid item={true} xs={6}>
            <TextField
              name='clientDeliveryFirstname'
              label='Jméno *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={6}>
            <TextField
              name='clientDeliveryLastname'
              label='Příjmení *'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>}

        {props.invoiceForm.clientHasDifferentDeliveryAddress && <InputRow>
          <Grid item={true} xs={12}>
            <TextField
              name='clientDeliveryCompanyName'
              label='Název firmy'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>}

        {props.invoiceForm.clientHasDifferentDeliveryAddress && <InputRow>
          <Grid item={true} xs={6}>
            <TextField
              name='clientDeliveryAddressStreet'
              label='Ulice *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={6}>
            <TextField
              name='clientDeliveryAddressStreetNumber'
              label='Číslo popisné/orientační *'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>}

        {props.invoiceForm.clientHasDifferentDeliveryAddress && <InputRow>
          <Grid item={true} xs={6}>
            <TextField
              name='clientDeliveryAddressZip'
              label='PSČ *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={6}>
            <TextField
              name='clientDeliveryAddressTown'
              label='Město *'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>}

        {props.invoiceForm.clientHasDifferentDeliveryAddress && <InputRow>
          <Grid item={true} xs={6}>
            <TextField
              name='clientDeliveryPhone'
              label='Telefon *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={6}>
            <TextField
              name='clientDeliveryBirthYear'
              label='Rok narození *'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>}
      </FormSection>

      <FormSection last>
        <InputRow>
          <Grid item={true} xs={12}>
            <TextField
              label='Text nad položkama faktury'
              name='textAboveItems'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>

        {/* <pre style={{ color: 'white' }}>
          {JSON.stringify(props.invoiceForm.items, null, 2)}
        </pre> */}

        <InputRow>
          <Grid item xs={12}>
            {isEmpty(props.invoiceForm.items) && <Alert severity='warning'>Přidejte alespoň 1 položku ze Skladu.</Alert>}

            <List className={classes.root}>
            {props.invoiceForm.items.map((item: any, index: any): any => (
              <>
                {index === 0 && <Divider />}
                <ListItem alignItems='flex-start' style={{ paddingRight: 0, paddingLeft: 0 }}>
                  <ListItemText
                    primary={item.text}
                  />
                  <ListItemSecondaryAction style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', right: 0 }}>
                    {!disableEdit && <IconButton
                      edge='end'
                      aria-label='Odebrat'
                      onClick={() => {
                        props.invoiceFormRemoveItem(index)
                      }}
                    >
                      <FontAwesomeIcon icon={['fal', 'times']} fixedWidth={true} />
                    </IconButton>}
                    <ListItemText
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: '15px' }}
                      primary={`${ts(item.price)} Kč`}
                      secondary={!disableEdit ? <Link onClick={handleClickOpen}>Změnit cenu</Link> : null}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </>
            ))}
            </List>
          </Grid>
        </InputRow>

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle>Změnit cenu</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <strong>Pokud si přejete změnit cenu, můžete tak udělat zde.</strong><br />
              Tato cena se při uložení faktury automaticky upraví i ve Skladu.
            </DialogContentText>
            <MuiTextField
              autoFocus
              margin="dense"
              label="Nová cena"
              type="price"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Zrušit
            </Button>
            <Button onClick={handleClose} color="primary">
              Změnit cenu
            </Button>
          </DialogActions>
        </Dialog>

        {!disableEdit && <>
          <Grid
            container={true}
            direction='row'
            spacing={1}
          >
            <Grid item={true} xs={12}>
              <Button variant="contained" size="small" color='secondary'
                onClick={() => {
                  props.openStockListModal()
                }}
                disabled={disableEdit}
              >
                Vybrat položku ze Skladu
              </Button>
            </Grid>
          </Grid>
          <br />
        </>}

        {/*
        <InputRow>
          <Grid item={true} xs={12}>
            <TextField
              label='Text pod položkama faktury'
              name='textBelowItems'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>
        */}

        <InputRow>
          <Grid item={true} xs={9} style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            display: 'flex',
            fontSize: '1rem',
            paddingTop: '10px'
          }}>
            CELKEM
          </Grid>

          <Grid item={true} xs={3}style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            display: 'flex',
            fontSize: '1rem',
            paddingTop: '10px'
          }}>
            <strong>{props.invoiceForm.price ? ts(props.invoiceForm.price) : 0} Kč</strong>
          </Grid>
        </InputRow>
      </FormSection>

      <AppBar color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            &nbsp;
          </Typography>
          {actionMenu}&nbsp;
          {props.invoiceForm.dirty === true && <Button type='submit' variant={isEditing ? 'outlined' : 'contained'} size="large" color='secondary'>
            Uložit fakturu
          </Button>}
          &nbsp;
          {!disableEdit && props.invoiceForm.id && props.invoiceForm.dirty === false && <Button onClick={() => props.createInvoice(props.invoiceForm.id)} variant="contained" size="large" color='secondary'>
            Vystavit fakturu
          </Button>}
          {disableEdit && props.invoiceForm.id && props.invoiceForm.dirty === false && <Button onClick={() => props.downloadInvoice(props.invoiceForm.id)} variant="contained" size="large" color='secondary'>
            Stáhnout fakturu
          </Button>}
          &nbsp;
          {!isEditing && <Button size='large' disabled={disableEdit} onClick={() => {
            props.invoiceFormClean()
          }}>
            Vyčistit formulář
          </Button>}
          &nbsp;
          <Button size='large' disabled={disableEdit} onClick={() => {
            props.invoiceFormClean()
            history.push('/faktury')
          }}>
            Zrušit bez uložení
          </Button>
        </Toolbar>
      </AppBar>

      {/* <pre style={{ color: 'white' }}>
        {JSON.stringify(values, null, 2)}
      </pre> */}
    </form>
  )

  return (
    <>
      <Form
        onSubmit={props.saveInvoice}
        initialValues={props.invoiceForm}
        validate={validate}
        render={renderForm}
      />
      <ClientListModal />
      <StockListModal />
    </>
  )
}

const mapStateToProps = (state: any): any => ({
  getInvoiceLoading: InvoiceSelect.invoiceLoading(state),
  invoiceForm: state.invoice.form,
  searchForClient: state.searchForClient,
  //
  user: state.user
})

const mapDispatchToProps = (dispatch: any) => ({
  invoiceFormUpdate: (data: any) => dispatch(InvoiceActions.formUpdate(data)),
  invoiceFormAddItem: () => dispatch(InvoiceActions.formAddItem()),
  invoiceFormRemoveItem: (index: number) => dispatch(InvoiceActions.formRemoveItem(index)),
  invoiceFormClean: () => dispatch(InvoiceActions.formClean()),
  invoiceFormCleanClient: () => dispatch(InvoiceActions.formCleanClient()),
  //
  saveInvoice: () => dispatch(InvoiceActions.saveInvoice()),
  createInvoice: (id: string) => dispatch(InvoiceActions.createInvoice(id)),
  downloadInvoice: (id: string) => dispatch(InvoiceActions.downloadInvoice(id)),
  //
  openClientListModal: () => dispatch(ClientActions.listClientsOpenModal(true, 'invoice')),
  searchForClient: (personalNumber: any) => dispatch(SearchForClientActions.searchForClient(personalNumber)),
  //
  openStockListModal: () => dispatch(StockActions.listStocksOpenModal(true, 'invoice'))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(InvoiceForm)
)
