import gql from 'graphql-tag'

const query = gql`
  query GET_COIN_QUERY($id: ID!) {
    getCoin(id: $id) {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      emission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        coins {
          nextToken
        }
        stock {
          nextToken
        }
        version
      }
      value
      denominationMotif
      description
      fineness
      diameter
      thickness
      weightFine
      weightGross
      decreeNo
      mint
      designer
      dateOfIssue
      mintageLimitTotal
      mintageLimitNormal
      mintageLimitProof
      normalEdge
      normalActualMintage
      normalPriceCalculated
      normalPriceActual
      proofEdge
      proofActualMintage
      proofPriceCalculated
      proofPriceActual
      stock {
        items {
          id
          createdAt
          createdBy
          updatedAt
          updatedBy
          deleted
          deletedAt
          deletedBy
          emission {
            id
            __typename
            name
          }
          coin {
            id
            __typename
            denominationMotif
          }
          pricePurchase
          priceSelling
          status
          sold
          soldAt
          version
        }
        nextToken
      }
      version
    }
  }
`

export default query
