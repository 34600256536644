import personalNumberValidator from 'Components/_Common/Form/validators/personalNumber'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

const validate = (values: any): object => {
  const errors = {} as any

  if (!values.personalNumber) {
    errors.personalNumber = 'Rodné číslo je povinné.'
  } else {
    if (!personalNumberValidator(String(values.personalNumber))) {
      errors.personalNumber = 'Rodné číslo není validní.'
    }
  }

  if (!values.firstname) {
    errors.firstname = 'Jméno je povinné.'
  }

  if (!values.lastname) {
    errors.lastname = 'Příjmení je povinné.'
  }

  if (!values.phone) {
    errors.phone = 'Telefon je povinný.'
  } else {
    const phoneNumber = parsePhoneNumberFromString(String(values.phone), 'CZ')
    if (phoneNumber === undefined || !phoneNumber.isValid()) {
      errors.phone = 'Telefon není validní.'
    }
  }

  if (!values.email) {
    errors.email = 'Email je povinný.'
  }

  if (!values.addressStreet) {
    errors.addressStreet = 'Ulice je povinná.'
  }

  if (!values.addressStreetNumber) {
    errors.addressStreetNumber = 'Číslo popisné/orientační je povinné.'
  }

  if (!values.addressZip) {
    errors.addressZip = 'PSČ je povinné.'
  }

  if (!values.addressTown) {
    errors.addressTown = 'Město je povinné.'
  }

  if (values.hasDifferentDeliveryAddress === true) {
    if (!values.deliveryFirstname) {
      errors.deliveryFirstname = 'Jméno je povinné.'
    }
  
    if (!values.deliveryLastname) {
      errors.deliveryLastname = 'Příjmení je povinné.'
    }

    if (!values.deliveryAddressStreet) {
      errors.deliveryAddressStreet = 'Ulice je povinná.'
    }
  
    if (!values.deliveryAddressStreetNumber) {
      errors.deliveryAddressStreetNumber = 'Číslo popisné/orientační je povinné.'
    }
  
    if (!values.deliveryAddressZip) {
      errors.deliveryAddressZip = 'PSČ je povinné.'
    }
  
    if (!values.deliveryAddressTown) {
      errors.deliveryAddressTown = 'Město je povinné.'
    }

    if (!values.deliveryPhone) {
      errors.deliveryPhone = 'Telefon je povinný.'
    } else {
      const phoneNumber = parsePhoneNumberFromString(String(values.deliveryPhone), 'CZ')
      if (phoneNumber === undefined || !phoneNumber.isValid()) {
        errors.deliveryPhone = 'Telefon není validní.'
      }
    }

    if (!values.deliveryBirthYear) {
      errors.deliveryBirthYear = 'Rok narození osoby je povinný.'
    }
  }

  if (!values.bankAccountNumber) {
    errors.bankAccountNumber = 'Číslo účtu je povinné.'
  }

  if (!values.bankCode) {
    errors.bankCode = 'Kód banky je povinný.'
  }

  return errors
}

export default validate
