import React, { ReactNode } from 'react'

import classNames from 'classnames'

import Divider from '@material-ui/core/Divider'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Typography from '@material-ui/core/Typography'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  column33: {
    flexBasis: '33.33%',
  },
  column66: {
    flexBasis: '66.66%',
  },
  column100: {
    flexBasis: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  helper: {
    '& a': {
      '&:hover': {
        textDecoration: 'underline',
      },
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },

    borderLeft: `2px solid ${theme.palette.divider}`,
    marginLeft: `24px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  helperNoPadding: {
    borderLeft: `2px solid ${theme.palette.divider}`
  }
})

interface Props extends WithStyles<typeof styles> {
  title: string,
  children?: ReactNode,
  help?: ReactNode,
  actions?: ReactNode,
  defaultExpanded?: boolean
}

const FormGroup = (props: Props) => {
  const { actions, classes, children = null, help, title, defaultExpanded = true } = props

  return (
    <ExpansionPanel defaultExpanded={defaultExpanded}>
      {title !== '' && <ExpansionPanelSummary expandIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} size='xs' />}>
        <Typography className={classes.heading}>{title}</Typography>
      </ExpansionPanelSummary>}
      <ExpansionPanelDetails>
        <div className={help !== undefined ? classes.column66 : classes.column100}>
          {children}
        </div>
        {help !== undefined && <div className={classNames(classes.column33, classes.helper)}>
          <Typography variant="caption">
            {help}
          </Typography>
        </div>}
      </ExpansionPanelDetails>
      {actions && <Divider />}
      {actions && <ExpansionPanelActions>
        {actions}
      </ExpansionPanelActions>}
    </ExpansionPanel>
  )
}

export default withStyles(styles)(FormGroup)
