import { all, takeLatest } from 'redux-saga/effects'

import { EmissionTypes } from 'Stores/Emission/Actions'
import Emission from 'Sagas/Emission'

export default function * root() {
  yield all([
    takeLatest(EmissionTypes.LIST_EMISSIONS, Emission.listEmissions),
    takeLatest(EmissionTypes.LIST_EMISSIONS_SUCCESS, Emission.listEmissionsSuccess),
    takeLatest(EmissionTypes.LIST_EMISSIONS_FAILURE, Emission.listEmissionsFailure)
  ])
}
