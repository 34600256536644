import React from 'react'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

const styles = (theme: Theme) => createStyles({
  card: {
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    width: 445
  },
  pos: {
    marginBottom: 12
  },
  title: {
    fontSize: 14
  }
})

interface Props extends WithStyles<typeof styles> {
  icon: any, // @todo
  title: string,
  subtitle: string,
  description: string,
  buttonText: string,
  buttonLink: any,
  buttonDisabled?: boolean
}

const DashboardCard = (props: Props) => {
  const { classes, icon, title, subtitle, description, buttonText, buttonLink, buttonDisabled = false } = props

  return (
    <Card className={classes.card}>
      <CardContent>
        <div style={{ display: `flex`, flexDirection: `row` }}>
          <div style={{ width: `55px`, paddingTop: `10px` }}>{icon}</div>
          <div>
            <Typography variant="h5" component="h2">
              {title}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {subtitle}
            </Typography>
            <Typography component="p">
              {description}
            </Typography>
          </div>
        </div>

      </CardContent>
      <CardActions style={{ paddingLeft: `60px` }}>
        <Button component={buttonLink} disabled={buttonDisabled} size="small" color="secondary">{buttonText}</Button>
      </CardActions>
    </Card>
  )
}

export default withStyles(styles)(DashboardCard)
