import { getContext, put, select } from 'redux-saga/effects'

import ContractActions from 'Stores/Contract/Actions'

import deleteContractMutation from 'Graphql/Mutations/deleteContractMutation'

import gql from 'graphql-tag'

export function * deleteContract ({ id }: any) {
  try {
    yield put(ContractActions.deleteContractLoading())

    const graphql = yield getContext('graphql')
    const query = gql(`query GetContract($id: ID!) {
      getContract(id: $id) {
        id
        version
      }
    }
    `)
    const queryVariables = { id }
    const contract = yield graphql.query(query, queryVariables)

    const username = yield select(state => state.user.username)
    const expectedVersion = contract.data.getContract.version

    const mutationVariables = {
      contractId: id,
      deletedAt: (new Date()).toISOString(),
      deletedBy: username,
      expectedVersion
    }
    
    yield graphql.mutation(deleteContractMutation, mutationVariables)

    yield put(ContractActions.deleteContractSuccess())
  } catch (error) {
    yield put(ContractActions.deleteContractFailure(error))
  }
}

export default deleteContract

