import React, { useEffect, useState } from 'react'

import ClientsMyDistributionsList from 'Components/_Client/MyDistributions/index'
import DefaultLayout from 'Components/_Common/Layout/Default'
import Loading from 'Components/Loading'

import { API, graphqlOperation } from 'aws-amplify'

import * as ROUTES from 'routes'

import gql from 'graphql-tag'
const query = gql`query ($nextToken: String) {
  listDistributions(filter: { status: { eq: "done" } }, limit: 1000, nextToken: $nextToken) {
    items {
      id
      __typename
      owners
      createdAt
      client {
        id
        __typename
        firstname
        lastname
      }
      contract {
        id
        __typename
        contractNumber
      }
      coin {
        id
        __typename
        denominationMotif
        emission {
          id
          __typename
          name
        }
      }
      price
      status
    }
    nextToken
  }
}`

const loadDistributions = async (nextToken: any = null) => {
  const distributions: any[] = []

  const result = (await API.graphql(graphqlOperation(query, { nextToken }))) as any

  if (result && 'data' in result && 'listDistributions' in result.data && 'items' in result.data.listDistributions &&
    result.data.listDistributions.items instanceof Array && result.data.listDistributions.items.length > 0) {
    result.data.listDistributions.items.map((contract: any) => {
      distributions.push(contract)
      return contract
    })
  }

  if (result && 'data' in result && 'listDistributions' in result.data && 'nextToken' in result.data.listDistributions && result.data.listDistributions.nextToken) {
    const distributionsMore = await loadDistributions(result.data.listDistributions.nextToken)
    distributions.push(...distributionsMore)
  }

  return distributions
} 

const MyCoins = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)

    const distributions: any[] = await loadDistributions()

    setIsLoading(false)

    setData(distributions)
  }

  return (
    <DefaultLayout
      title={`Moje mince`}
      tabs={[{
        route: ROUTES.CLIENT_MY_COINS, title: 'Moje mince'
      }]}
      activeTab={0}
    >
      {isLoading ? <Loading /> : <ClientsMyDistributionsList data={data} />}
    </DefaultLayout>
  )
}

export default MyCoins
