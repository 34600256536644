import calculateEstimatedValue from 'Components/Contract/Form/calculateEstimatedValue'
import { parseISO } from 'date-fns'

const monthlyAmount = '3500'
const totalYears = '20'
const entryPriceIncrease = 66000
const entryPriceIncreaseOneTimePayment = false
const numberOfCoins = 40

const priceOfCoin = 21000
const salesMargin = 1.07 // 7%

const startFrom = new Date()
const firstDeliveryDate = '2020-10-01' // @todo

const { data: progressionData, estimatedValue, actualDeliveryEntitlementDate, emission1, emission2 } = calculateEstimatedValue(
  parseInt(monthlyAmount, 10), 
  parseInt(totalYears, 10), 
  entryPriceIncrease, 
  entryPriceIncreaseOneTimePayment, 
  numberOfCoins, 
  priceOfCoin,
  startFrom,
  parseISO(firstDeliveryDate)
)

const INITIAL_STATE = {
  loading: false,
  prefill: false,
  dirty: false,

  clientBenefitId: null,
  clientId: null,

  monthlyAmount,
  totalYears,

  progressionData,
  
  qualityOfCoins: 'PROOF',
  defaultPriceOfCoin: Math.round(priceOfCoin / salesMargin),
  entryFeePerCoin: 1650,
  entryPriceIncrease,
  entryPriceIncreaseOneTimePayment,
  estimatedValue,
  indicativeTargetAmount: parseInt(monthlyAmount, 10) * 12 * parseInt(totalYears, 10),
  numberOfCoins,
  priceOfCoin,
  salesMargin: salesMargin,

  emission1,
  emission2,

  // @todo
  contractFirstEmissionId: 'e6dbf9cc-ddfb-4f68-8e11-ba9028a3170b', // Městské památkové rezervace
  contractFirstCoinId: 'e7a7023f-9d04-463e-b98a-1fc4af5ebee5', // Litoměřice
  firstDeliveryDate,
  actualDeliveryEntitlementDate
}

export default INITIAL_STATE
