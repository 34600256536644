const INITIAL_STATE = {
  listPaymentsLoading: false,
  listPaymentsResult: [],
  listPaymentsError: null,
  //
  getPaymentLoading: false,
  getPaymentResult: null,
  getPaymentError: null
}

export default INITIAL_STATE
