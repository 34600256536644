import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  listStocks: ['filter'], // LIST_STOCKS
  listStocksLoading: null, // LIST_STOCKS_LOADING
  listStocksSuccess: ['data'], // LIST_STOCKS_SUCCESS
  listStocksFailure: ['error'], // LIST_STOCKS_FAILURE
  //
  listStocksOpenModal: ['flag', 'target'], // LIST_STOCKS_OPEN_MODAL
  //
  getStock: ['id'], // GET_STOCK
  getStockLoading: null, // GET_STOCK_LOADING
  getStockSuccess: ['data'], // GET_STOCK_SUCCESS
  getStockFailure: ['error'], // GET_STOCK_FAILURE
  //
  formUpdate: ['data'], // FORM_UPDATE
  formClean: null, // FORM_CLEAN
  //
  saveStock: null, // SAVE_STOCK
  saveStockLoading: null, // SAVE_STOCK_LOADING
  saveStockSuccess: ['data'], // SAVE_STOCK_SUCCESS
  saveStockFailure: ['error'], // SAVE_STOCK_FAILURE
}, { prefix: 'STOCK_' })

export const StockTypes = Types

export default Creators
