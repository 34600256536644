import React from 'react'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { Link as RouterLink } from 'react-router-dom'

const InvoiceListColumnAction = ({ row, column }: any): any => {
  const invoiceId = column.getInvoiceId(row)

  if (invoiceId === null) {
    return <span>-</span>
  }

  return (
    <Link to={`/faktury/detail/${invoiceId}`} component={RouterLink}>
      <Typography variant='caption'>Detail</Typography>
    </Link>
  )
}

export default InvoiceListColumnAction
