import React from 'react'

import thousandsSeparator from 'Helpers/thousandsSeparator'

const ContractListColumnEntryPriceIncrease = ({ row, column }: any): any => {
  const value = row.entryPriceIncrease
  const isOneTime = row.entryPriceIncreaseOneTimePayment === true
  const isPaid = row.entryPriceIncreaseIsPaid === true
  const percentPaid = Math.round((row.entryPriceIncreasePaidAmount / row.entryPriceIncrease) * 100)

  const monoStyle = { fontFamily: `monospace`, color: '#baa436' }
  const valueStyle = isPaid ? { color: '#baa436' } : {}

  if (value === 0) {
    return ` - `
  }

  return (
    <>
      <span style={monoStyle}>[{ isOneTime ? `J;` : `` }{percentPaid}%]&nbsp;</span>
      <span style={valueStyle}>{thousandsSeparator(value)}&nbsp;Kč</span>
    </>
  )
}

export default ContractListColumnEntryPriceIncrease
