import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faBars,
  faBell,
  faCartPlus,
  faCompactDisc,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCogs,
  faCoins,
  faDownload,
  faEllipsisV,
  faFileContract,
  faFileInvoice,
  faFileSignature,
  faFilter,
  faHistory,
  faHome,
  faIdCard,
  faInfoSquare,
  faMailBulk,
  faMoneyBillWaveAlt,
  faMoneyCheckAlt,
  faQuestionSquare,
  faSearch,
  faShoppingCart,
  faSignOut,
  faTimes,
  faTools,
  faTrashAlt,
  faTruckContainer,
  faUnlockAlt,
  faUser,
  faUserChart,
  faUsers,
  faWarehouseAlt
} from '@fortawesome/pro-light-svg-icons'

export default () => {
  library.add(
    faBars,
    faBell,
    faCartPlus,
    faCompactDisc,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronRight,
    faChevronUp,
    faCogs,
    faCoins,
    faDownload,
    faEllipsisV,
    faFileContract,
    faFileInvoice,
    faFileSignature,
    faFilter,
    faHistory,
    faHome,
    faIdCard,
    faInfoSquare,
    faMailBulk,
    faMoneyBillWaveAlt,
    faMoneyCheckAlt,
    faQuestionSquare,
    faSearch,
    faShoppingCart,
    faSignOut,
    faTimes,
    faTools,
    faTrashAlt,
    faTruckContainer,
    faUnlockAlt,
    faUser,
    faUserChart,
    faUsers,
    faWarehouseAlt
  )
}
