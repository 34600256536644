import React from 'react'

import { connect } from 'react-redux'
import UserActions from 'Stores/User/Actions'

import classNames from 'classnames'

import { makeStyles, Theme } from '@material-ui/core/styles'

import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { Scrollbars } from 'react-custom-scrollbars'

import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import * as ROUTES from 'routes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const categoriesAdmin = [
  {
    id: 'Administrátor',

    children: [
      { id: 'Klienti', icon: <FontAwesomeIcon icon={['fal', 'users']} fixedWidth={true} />, route: ROUTES.ADMIN_CLIENTS },
      { id: 'Smlouvy', icon: <FontAwesomeIcon icon={['fal', 'file-signature']} fixedWidth={true} />, route: ROUTES.ADMIN_CONTRACTS },
      { id: 'Faktury', icon: <FontAwesomeIcon icon={['fal', 'file-invoice']} fixedWidth={true} />, route: ROUTES.ADMIN_INVOICES },
      { id: 'Platby', icon: <FontAwesomeIcon icon={['fal', 'money-check-alt']} fixedWidth={true} />, route: ROUTES.ADMIN_PAYMENTS },
      // { id: 'Emaily', icon: <FontAwesomeIcon icon={['fal', 'mail-bulk']} fixedWidth={true} />, route: ROUTES.ADMIN_EMAILS },
      { id: 'Nastavení', icon: <FontAwesomeIcon icon={['fal', 'cogs']} fixedWidth={true} />, route: ROUTES.ADMIN_SETTINGS },
      { id: 'Nástroje', icon: <FontAwesomeIcon icon={['fal', 'tools']} fixedWidth={true} />, route: ROUTES.ADMIN_TOOLS }
    ],
  }, {
    id: 'Klient',

    children: [
      { id: 'Moje smlouvy', icon: <FontAwesomeIcon icon={['fal', 'file-contract']} fixedWidth={true} />, route: ROUTES.ADMIN_MY_CONTRACTS }
    ]
  }, {
    id: 'Mince',

    children: [
      { id: 'Emise', icon: <FontAwesomeIcon icon={['fal', 'coins']} fixedWidth={true} />, route: ROUTES.ADMIN_EMISSIONS },
      { id: 'Ceník', icon: <FontAwesomeIcon icon={['fal', 'money-bill-wave-alt']} fixedWidth={true} />, route: ROUTES.ADMIN_PRICELIST },
      // { id: 'Objednávky', icon: <FontAwesomeIcon icon={['fal', 'shopping-cart']} fixedWidth={true} />, route: ROUTES.ADMIN_ORDERS },
      { id: 'Sklad', icon: <FontAwesomeIcon icon={['fal', 'warehouse-alt']} fixedWidth={true} />, route: ROUTES.ADMIN_STOCK },
      { id: 'Distribuce', icon: <FontAwesomeIcon icon={['fal', 'truck-container']} fixedWidth={true} />, route: ROUTES.ADMIN_DISTRIBUTIONS },
      { id: 'Predikce', icon: <FontAwesomeIcon icon={['fal', 'user-chart']} fixedWidth={true} />, route: ROUTES.ADMIN_PREDICTION }
    ]
  }
]

const categoriesSalesRepresentative = [
  {
    id: 'Poradce',

    children: [
      { id: 'Klienti', icon: <FontAwesomeIcon icon={['fal', 'users']} fixedWidth={true} />, route: ROUTES.ADMIN_CLIENTS },
      { id: 'Smlouvy', icon: <FontAwesomeIcon icon={['fal', 'file-signature']} fixedWidth={true} />, route: ROUTES.ADMIN_CONTRACTS },
      { id: 'Nastavení', icon: <FontAwesomeIcon icon={['fal', 'cogs']} fixedWidth={true} />, route: ROUTES.ADMIN_SETTINGS }
    ],
  }, {
    id: 'Klient',

    children: [
      { id: 'Moje smlouvy', icon: <FontAwesomeIcon icon={['fal', 'file-contract']} fixedWidth={true} />, route: ROUTES.ADMIN_MY_CONTRACTS }
    ]
  }, {
    id: 'Mince',

    children: [
      { id: 'Emise', icon: <FontAwesomeIcon icon={['fal', 'coins']} fixedWidth={true} />, route: ROUTES.ADMIN_EMISSIONS },
      { id: 'Ceník', icon: <FontAwesomeIcon icon={['fal', 'money-bill-wave-alt']} fixedWidth={true} />, route: ROUTES.ADMIN_PRICELIST },
      // { id: 'Sklad', icon: <FontAwesomeIcon icon={['fal', 'warehouse-alt']} fixedWidth={true} />, route: ROUTES.ADMIN_STOCK }
      // { id: 'Analýza', icon: <FontAwesomeIcon icon={['fal', 'user-chart']} fixedWidth={true} />, route: ROUTES.ADMIN_ANALYSIS }
    ]
  }
]

const categoriesClient = [
  {
    id: 'Klientská zóna',

    children: [
      { id: 'Smlouvy', icon: <FontAwesomeIcon icon={['fal', 'file-contract']} fixedWidth={true} />, route: ROUTES.CLIENT_MY_CONTRACTS },
      { id: 'Platby', icon: <FontAwesomeIcon icon={['fal', 'money-check-alt']} fixedWidth={true} />, route: ROUTES.CLIENT_MY_PAYMENTS },
      // { id: 'Faktury', icon: <FontAwesomeIcon icon={['fal', 'file-invoice']} fixedWidth={true} />, route: ROUTES.CLIENT_MY_INVOICES },
      { id: 'Mince', icon: <FontAwesomeIcon icon={['fal', 'coins']} fixedWidth={true} />, route: ROUTES.CLIENT_MY_COINS },
      { id: 'Emise', icon: <FontAwesomeIcon icon={['fal', 'coins']} fixedWidth={true} />, route: ROUTES.CLIENT_EMISSIONS },
      { id: 'Ceník', icon: <FontAwesomeIcon icon={['fal', 'money-bill-wave-alt']} fixedWidth={true} />, route: ROUTES.CLIENT_PRICELIST },
      { id: 'Profil', icon: <FontAwesomeIcon icon={['fal', 'id-card']} fixedWidth={true} />, route: ROUTES.CLIENT_MY_PROFILE },
      { id: 'Nastavení', icon: <FontAwesomeIcon icon={['fal', 'cogs']} fixedWidth={true} />, route: ROUTES.CLIENT_MY_SETTINGS }
    ],
  }
]

const useStyles = makeStyles((theme: Theme) => ({
  categoryHeader: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  goldcoins: {
    alignItems: 'center',
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    fontSize: 24,
    height: '96px',
    justifyContent: 'center',
    margin: 0,
    padding: 1,
  },
  item: {
    color: 'rgba(255, 255, 255, 0.7)',
    paddingBottom: 4,
    paddingTop: 4,
  },
  itemCategory: {
    backgroundColor: '#231f20',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingBottom: 16,
    paddingTop: 16,
  },
  link: {
    textDecoration: 'none',
  },
  logo: {
    border: 0
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemActiveItem: {
    color: theme.palette.primary.main,
  },
  itemPrimary: {
    color: 'inherit',
    fontSize: theme.typography.fontSize,
  },
  itemActive: {
    color: theme.palette.primary.main
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  signOut: {
    marginTop: theme.spacing(2),
  }
}))

interface Props extends RouteComponentProps {
  PaperProps: any,
  variant?: "permanent" | "persistent" | "temporary" | undefined,
  open?: boolean,
  onClose?: any,
  user?: any,
  logout?: any,
  dispatch?: any

}

const Navigator = (props: Props) => {
  const isActive = (path: string, sub = false) => {
    const { pathname } = props.location
    return (sub
      ? pathname.substring(0, path.length) === path
      : pathname === path) ? true : false
  }

  const userGroup = props.user.group

  const home = userGroup === 'Admins' || userGroup === 'SalesRepresentatives' ? ROUTES.ADMIN_HOME : ROUTES.CLIENT_HOME

  const { staticContext, user, logout, dispatch, ...other } = props
  const classes = useStyles()

  let categories
  if (userGroup === 'Admins') {
    categories = categoriesAdmin
  } else if (userGroup === 'SalesRepresentatives') {
    categories = categoriesSalesRepresentative
  } else {
    categories = categoriesClient
  }

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding={true}>
        <Link to={home}>
          <ListItem className={classNames(classes.goldcoins, classes.item, classes.itemCategory)}>
            <img src={require('../Assets/images/logo-inline.png')} className={classNames(classes.logo)} alt='logo' width={225} />
          </ListItem>
        </Link>
        <Link to={home} className={classes.link}>
          <ListItem
            button={true}
            className={classNames(classes.item, classes.itemCategory, isActive(home) && classes.itemActive)}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={['fal', 'home']} fixedWidth={true} />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary
              }}
            >
              Dashboard
            </ListItemText>
          </ListItem>
        </Link>
      </List>

      <Scrollbars autoHide={true}>
        <List disablePadding={true}>
          {categories.map(({ id, children }, key) => (
            <React.Fragment key={key}>
              <ListItem className={classes.categoryHeader}>
                <ListItemText
                  classes={{
                    primary: classes.categoryHeaderPrimary,
                  }}
                >
                  {id}
                </ListItemText>
              </ListItem>
              {children.map(({ id: childId, icon, route }, k) => (
                <Link key={k} to={route} className={classes.link}>
                  <ListItem
                    button={true}
                    dense={true}
                    key={childId}
                    className={classNames(
                      classes.item,
                      classes.itemActionable,
                      isActive(route, true) && classes.itemActiveItem,
                    )}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: classes.itemPrimary
                      }}
                    >
                      {childId}
                    </ListItemText>
                  </ListItem>
                </Link>
              ))}
              <Divider className={classes.divider} />
            </React.Fragment>
          ))}

          <ListItem
            button={true}
            dense={true}
            className={classNames(
              classes.item,
              classes.itemActionable,
              classes.signOut
            )}
            onClick={props.logout}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={['fal', 'sign-out']} fixedWidth={true} />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary
              }}
            >
              Odhlásit
            </ListItemText>
          </ListItem>
        </List>
      </Scrollbars>
    </Drawer>
  )
}

const mapStateToProps = (state: any) => ({
  user: state.user
})

const mapDispatchToProps = (dispatch: any) => ({
  logout: () => dispatch(UserActions.logout())
})


export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(Navigator)
)
