export const INVOICE_DATA_STATUS_WAITING_FOR_PAYMENT = 'waiting-for-payment'
export const INVOICE_DATA_STATUS_PAID = 'paid'

const invoiceDataStatus = (value: any): any => {
  switch (value) {
    case INVOICE_DATA_STATUS_WAITING_FOR_PAYMENT:
      return `Čeká na platbu`

    case INVOICE_DATA_STATUS_PAID:
      return 'Zaplacena'
  }
}

export const getAvailableInvoiceStatuses = () => {
  return {
    INVOICE_DATA_STATUS_WAITING_FOR_PAYMENT: invoiceDataStatus(INVOICE_DATA_STATUS_WAITING_FOR_PAYMENT),
    INVOICE_DATA_STATUS_PAID: invoiceDataStatus(INVOICE_DATA_STATUS_PAID)
  }
}

export default invoiceDataStatus
