export const tableMessages = {
  noData: 'Žádné záznamy ...'
}

export const filterRowMessages = {
  filterPlaceholder: '',
  contains: 'obsahuje',
  notContains: 'neobsahuje',
  startsWith: 'začíná',
  endsWith: 'končí',
  equal: 'je rovno',
  notEqual: 'není rovno',
  greaterThan: 'větší než',
  greaterThanOrEqual: 'větší nebo rovno',
  lessThan: 'menší než',
  lessThanOrEqual: 'menší nebo rovno'
}

export const summaryRowMessages = {
  count: 'Počet',
  sum: 'Σ'
}
