import React from 'react'

import Grid from '@material-ui/core/Grid'

const Row = (props: any) => (
  <Grid container={true} xs={12} md={10} lg={8} direction='row'>
    <Grid container={true} alignContent='center' style={{ minHeight: `66px` }} spacing={1}>
      {props.children}
    </Grid>
  </Grid>
)

export default Row
