import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  listPayments: ['filter'], // LIST_PAYMENTS
  listPaymentsLoading: null, // LIST_PAYMENTS_LOADING
  listPaymentsSuccess: ['data'], // LIST_PAYMENTS_SUCCESS
  listPaymentsFailure: ['error'], // LIST_PAYMENTS_FAILURE
  //
  getPayment: ['id'], // GET_PAYMENT
  getPaymentLoading: null, // GET_PAYMENT_LOADING
  getPaymentSuccess: ['data'], // GET_PAYMENT_SUCCESS
  getPaymentFailure: ['error'] // GET_PAYMENT_FAILURE
}, { prefix: 'PAYMENT_' })

export const PaymentTypes = Types

export default Creators
