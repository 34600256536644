import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  listInvoices: ['filter'], // LIST_INVOICES
  listInvoicesLoading: null, // LIST_INVOICES_LOADING
  listInvoicesSuccess: ['data'], // LIST_INVOICES_SUCCESS
  listInvoicesFailure: ['error'], // LIST_INVOICES_FAILURE
  //
  getInvoice: ['id'], // GET_INVOICE
  getInvoiceLoading: null, // GET_INVOICE_LOADING
  getInvoiceSuccess: ['data'], // GET_INVOICE_SUCCESS
  getInvoiceFailure: ['error'], // GET_INVOICE_FAILURE
  //
  formUpdate: ['data'], // FORM_UPDATE
  formAddItem: null, // FORM_ADD_ITEM
  formAddItemFromStock: ['id', 'text', 'price'], // FORM_ADD_ITEM_FROM_STOCK
  formRemoveItem: ['index'], // FORM_REMOVE_ITEM
  formClean: null, // FORM_CLEAN
  formCleanClient: null, // FORM_CLEAN_CLIENT
  //
  prefillClient: ['id'], // PREFILL_CLIENT
  prefillClientLoading: null, // PREFILL_CLIENT_LOADING
  prefillClientSuccess: ['data'], // PREFILL_CLIENT_SUCCESS
  prefillClientFailure: ['error'], // PREFILL_CLIENT_FAILURE
  //
  createInvoice: ['id'], // CREATE_INVOICE
  createInvoiceLoading: null, // CREATE_INVOICE_LOADING
  createInvoiceSuccess: ['url'], // CREATE_INVOICE_SUCCESS
  createInvoiceFailure: ['error'], // CREATE_INVOICE_FAILURE
  //
  downloadInvoice: ['id', 'generate'], // DOWNLOAD_INVOICE
  downloadInvoiceLoading: null, // DOWNLOAD_INVOICE_LOADING
  downloadInvoiceSuccess: ['url'], // DOWNLOAD_INVOICE_SUCCESS
  downloadInvoiceFailure: ['error'], // DOWNLOAD_INVOICE_FAILURE
  //
  saveInvoice: null, // SAVE_INVOICE
  saveInvoiceLoading: null, // SAVE_INVOICE_LOADING
  saveInvoiceSuccess: ['data'], // SAVE_INVOICE_SUCCESS
  saveInvoiceFailure: ['error'], // SAVE_INVOICE_FAILURE
  //
  sendInvoice: ['id'], // SEND_INVOICE
  sendInvoiceLoading: null, // SEND_INVOICE_LOADING
  sendInvoiceSuccess: ['messageId'], // SEND_INVOICE_SUCCESS
  sendInvoiceFailure: ['error'] // SEND_INVOICE_FAILURE
}, { prefix: 'INVOICE_' })

export const InvoiceTypes = Types

export default Creators
