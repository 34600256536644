import { parsePhoneNumberFromString } from 'libphonenumber-js'

const validate = (values: any): object => {
  const errors = {} as any

  if (!values.type) {
    errors.type = 'Zvolte typ faktury.'
  }

  if (!values.dateOfIssue) {
    errors.dateOfIssue = 'Datum vystavení je povinný.'
  }

  if (!values.dueDate) {
    errors.dueDate = 'Datum splatnosti je povinný.'
  }

  if (!values.clientFirstname) {
    errors.clientFirstname = 'Jméno je povinné.'
  }

  if (!values.clientLastname) {
    errors.clientLastname = 'Příjmení je povinné.'
  }

  if (!values.clientPhone) {
    errors.clientPhone = 'Telefon je povinný.'
  } else {
    const phoneNumber = parsePhoneNumberFromString(String(values.clientPhone), 'CZ')
    if (phoneNumber === undefined || !phoneNumber.isValid()) {
      errors.clientPhone = 'Telefon není validní.'
    }
  }

  if (!values.clientEmail) {
    errors.clientEmail = 'Email je povinný.'
  }

  if (!values.clientAddressStreet) {
    errors.clientAddressStreet = 'Ulice je povinná.'
  }

  if (!values.clientAddressStreetNumber) {
    errors.clientAddressStreetNumber = 'Číslo popisné/orientační je povinné.'
  }

  if (!values.clientAddressZip) {
    errors.clientAddressZip = 'PSČ je povinné.'
  }

  if (!values.clientAddressTown) {
    errors.clientAddressTown = 'Město je povinné.'
  }

  if (values.clientHasDifferentDeliveryAddress === true) {
    if (!values.clientDeliveryFirstname) {
      errors.clientDeliveryFirstname = 'Jméno je povinné.'
    }
  
    if (!values.clientDeliveryLastname) {
      errors.clientDeliveryLastname = 'Příjmení je povinné.'
    }

    if (!values.clientDeliveryAddressStreet) {
      errors.clientDeliveryAddressStreet = 'Ulice je povinná.'
    }
  
    if (!values.clientDeliveryAddressStreetNumber) {
      errors.clientDeliveryAddressStreetNumber = 'Číslo popisné/orientační je povinné.'
    }
  
    if (!values.clientDeliveryAddressZip) {
      errors.clientDeliveryAddressZip = 'PSČ je povinné.'
    }
  
    if (!values.clientDeliveryAddressTown) {
      errors.clientDeliveryAddressTown = 'Město je povinné.'
    }

    if (!values.clientDeliveryPhone) {
      errors.clientDeliveryPhone = 'Telefon je povinný.'
    } else {
      const phoneNumber = parsePhoneNumberFromString(String(values.clientDeliveryPhone), 'CZ')
      if (phoneNumber === undefined || !phoneNumber.isValid()) {
        errors.clientDeliveryPhone = 'Telefon není validní.'
      }
    }

    if (!values.clientDeliveryBirthYear) {
      errors.clientDeliveryBirthYear = 'Rok narození osoby je povinný.'
    }
  }

  return errors
}

export default validate
