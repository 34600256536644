import { getContext, put } from 'redux-saga/effects'

import CoinActions from 'Stores/Coin/Actions'

import getCoinQuery from 'Graphql/Queries/getCoinQuery'

export function * getCoin ({ id }: any) {
  try {
    yield put(CoinActions.getCoinLoading())

    const graphql = yield getContext('graphql')
    const variables = { id } as any

    const result = yield graphql.query(getCoinQuery, variables)

    // @todo
    if (result && result.data && result.data.getCoin) {
      yield put(CoinActions.getCoinSuccess(result.data.getCoin))
    }
  } catch (error) {
    yield put(CoinActions.getCoinFailure(error))
  }
}

export default getCoin
