import React from 'react'

import DefaultLayout from 'Components/_Common/Layout/Default'

import Typography from '@material-ui/core/Typography'

const Home = () => {
  return (
    <DefaultLayout
      title={`Nastavení`}
      tabs={[]}
      activeTab={0}
    >
      <Typography variant='h6'>Připravujeme ...</Typography>
    </DefaultLayout>
  )
}

export default Home
