import { all, getContext, fork } from 'redux-saga/effects'

import AppSaga from 'Sagas/AppSaga'
import ClientSaga from 'Sagas/ClientSaga'
import CoinSaga from 'Sagas/CoinSaga'
import ContractSaga from 'Sagas/ContractSaga'
import DistributionSaga from 'Sagas/DistributionSaga'
import EmailSaga from 'Sagas/EmailSaga'
import EmissionSaga from 'Sagas/EmissionSaga'
import InvoiceSaga from 'Sagas/InvoiceSaga'
import LoginSaga from 'Sagas/LoginSaga'
import PaymentSaga from 'Sagas/PaymentSaga'
import PredictionSaga from 'Sagas/PredictionSaga'
import SearchForClientSaga from 'Sagas/SearchForClientSaga'
import ShutdownSaga from 'Sagas/ShutdownSaga'
import StartupSaga from 'Sagas/StartupSaga'
import StockSaga from 'Sagas/StockSaga'
import ToolsSaga from 'Sagas/ToolsSaga'

export default function * root () {
  yield all([
    fork(AppSaga),
    fork(ClientSaga),
    fork(CoinSaga),
    fork(ContractSaga),
    fork(DistributionSaga),
    fork(EmailSaga),
    fork(EmissionSaga),
    fork(InvoiceSaga),
    fork(LoginSaga),
    fork(PaymentSaga),
    fork(PredictionSaga),
    fork(SearchForClientSaga),
    fork(ShutdownSaga),
    fork(StartupSaga),
    fork(StockSaga),
    fork(ToolsSaga)
  ])
}

//

export function * recursivelyLoadAllData (query: any, variables: any, key: string): any {
  let data: any = []

  const graphql = yield getContext('graphql')
  variables.limit = 10000 // @todo
  const result = yield graphql.query(query, variables)
  if (result && result.data && result.data[key] &&
    result.data[key].items instanceof Array) {
    data = data.concat(result.data[key].items)
    if (result.data[key].nextToken) {
      variables.nextToken = result.data[key].nextToken
      const nextData = yield recursivelyLoadAllData(query, variables, key)
      data = data.concat(nextData)
    }
  }

  return data
}
