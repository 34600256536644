import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  listEmissions: ['filter'], // LIST_EMISSIONS
  listEmissionsLoading: null, // LIST_EMISSIONS_LOADING
  listEmissionsSuccess: ['data'], // LIST_EMISSIONS_SUCCESS
  listEmissionsFailure: ['error'] // LIST_EMISSIONS_FAILURE
}, { prefix: 'EMISSION_' })

export const EmissionTypes = Types

export default Creators
