import React from 'react'

import { connect } from 'react-redux'
import ClientActions from 'Stores/Client/Actions'
import ContractActions from 'Stores/Contract/Actions'
import * as ContractSelect from 'Stores/Contract/Select'
import SearchForClientActions from 'Stores/SearchForClient/Actions'

import ClientListModal from 'Components/Client/List/Modal'

import { Field, Form, FormSpy } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

import { Alert } from '@material-ui/lab'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import { createStyles, Theme, withStyles } from '@material-ui/core/styles'

import Checkbox from 'Components/_Common/Form/Inputs/Checkbox'
import Radio from 'Components/_Common/Form/Inputs/Radio'
import Select from 'Components/_Common/Form/Inputs/Select'
import Switch from 'Components/_Common/Form/Inputs/Switch'
import TextField from 'Components/_Common/Form/Inputs/TextField'

import ProgressionTable from 'Components/_Common/Dialogs/ProgressionTable'

import ts from 'Helpers/thousandsSeparator'

import validate from 'Components/Contract/Form/validate'

import personalNumberValidator from 'Components/_Common/Form/validators/personalNumber'

import { history } from '../../../index'

import { format, parseISO, isBefore } from 'date-fns'

import FormSection from 'Components/_Common/Form/Section'
import BasicRow from 'Components/_Common/Form/BasicRow'
import InputRow from 'Components/_Common/Form/InputRow'

import SelectFirstCoin from 'Components/Contract/Form/SelectFirstCoin'
import SelectedFirstCoin from 'Components/Contract/Form/SelectedFirstCoin'

const styles = (theme: Theme) => createStyles({
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },

  sliderWrapper: {
    paddingTop: `25px`
  },

  grow: {
    flexGrow: 1,
  },
  appBar: {
    bottom: 0,
    height: 66,
    justifyContent: 'center',
    top: 'auto'
  },
  value: {
    color: theme.palette.primary.main,
  }
})

const ContractForm = (props: any) => {
  const { actionMenu, classes } = props

  const isEditing = 'id' in props.contractForm && props.contractForm.id !== undefined
  const disableEdit = isEditing && 'contractNumber' in props.contractForm && props.contractForm.contractNumber !== null
  const contractNumber = 'contractNumber' in props.contractForm ? props.contractForm.contractNumber : null

  let numberOfDistributedCoins: any = null
  let paymentsAmount: any = null
  let distributionsAmount: any = null
  let savedAmount: any = null

  if (contractNumber) {
    numberOfDistributedCoins = props.contractForm.distributions && 'items' in props.contractForm.distributions && props.contractForm.distributions.items instanceof Array
      ? props.contractForm.distributions.items.length
      : 0
    paymentsAmount = props.contractForm.payments && 'items' in props.contractForm.payments && props.contractForm.payments.items instanceof Array
      ? props.contractForm.payments.items.reduce((accumulator: any, item: any) => accumulator + parseFloat(item.amount), 0.0)
      : 0
    distributionsAmount = props.contractForm.distributions && 'items' in props.contractForm.distributions && props.contractForm.distributions.items instanceof Array
      ? props.contractForm.distributions.items.reduce((accumulator: any, item: any) => accumulator + parseFloat(item.price), 0.0)
      : 0
    savedAmount = paymentsAmount - props.contractForm.entryPriceIncrease - distributionsAmount
  }

  const isDirty = () => {
    return props.contractForm.dirty === true ||
      (props.getContractResult && props.getContractResult.firstCoin && props.contractForm.contractFirstCoinId !== props.getContractResult.firstCoin.id)
  }

  const updateFormState = (state: any): any => {
    Object.keys(state.modified).map((key: any): void => {
      if (state.modified[key] === true) {
        props.contractFormUpdate({ [key]: state.values[key] })
      }
      return key
    })
  }

  const renderForm = ({ handleSubmit }: any) => (
    <form onSubmit={handleSubmit}>
      <FormSpy onChange={updateFormState} />

      <FormSection>
        {contractNumber && <>
          <BasicRow label='Smlouva'>
            <strong className={classes.value}>{props.contractForm.contractNumber ? props.contractForm.contractNumber : ' - '}</strong>
            &nbsp;ze dne&nbsp;
            <strong className={classes.value}>{props.contractForm.signedAt ? format(new Date(props.contractForm.signedAt), 'dd.MM.yyyy') : ` - `}</strong>
          </BasicRow>
          <Divider />
        </>}

        <BasicRow label='Měsíční částka'>
          {contractNumber
            ? (
              <strong className={classes.value}>{ts(props.contractForm.monthlyAmount)} Kč</strong>
            )
            : (
              <>
                <FormControlLabel
                  control={<Field name='monthlyAmount' component={Radio} type='radio' value='1750' />}
                  label={<span>1 750 Kč</span>}
                  disabled={disableEdit}
                />
                <FormControlLabel
                  control={<Field name='monthlyAmount' component={Radio} type='radio' value='3500' />}
                  label={<span>3 500 Kč</span>}
                  disabled={disableEdit}
                />
              </>
            )
          }
        </BasicRow>
        <Divider />

        <BasicRow label='Délka trvání'>
          {contractNumber
            ? (
              <strong className={classes.value}>{props.contractForm.totalYears} let</strong>
            )
            : (
              <Select
                name='totalYears'
                disabled={disableEdit}
              >
                {[10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((years: any) => (
                  <MenuItem value={years}>{years} let</MenuItem>
                ))}
              </Select>
            )
          }
        </BasicRow>
        <Divider />

        <BasicRow label='Orientační cílová částka'>
          <strong className={classes.value}>{ts(props.contractForm.indicativeTargetAmount)} Kč</strong>
        </BasicRow>
        <Divider />

        <BasicRow label='Kvalita mincí'>
          <FormControlLabel
            control={<Field name='qualityOfCoins' component={Radio} type='radio' value='BK' />}
            label={`BK`}
            disabled={disableEdit}
          />
          <FormControlLabel
            control={<Field name='qualityOfCoins' component={Radio} type='radio' value='PROOF' />}
            label={`PROOF`}
            disabled={disableEdit}
          />
        </BasicRow>
        <Divider />

        <BasicRow label='Počet mincí'>
          <strong className={classes.value}>{props.contractForm.numberOfCoins} ks</strong>
          {contractNumber && numberOfDistributedCoins > 0
            ? <span>, z toho dodáno: <strong className={classes.value}>{numberOfDistributedCoins} ks</strong> za celkem <strong className={classes.value}>{ts(distributionsAmount)} Kč</strong></span>
            : null}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {!contractNumber && <>Aktuální cena mince:&nbsp;<strong className={classes.value}>{ts(props.contractForm.priceOfCoin)} Kč</strong></>}
        </BasicRow>
        <Divider />

        <BasicRow label='Vstupní cenové navýšení'>
          <strong className={classes.value}>{ts(props.contractForm.entryPriceIncrease)} Kč</strong>
          {contractNumber && 
            <>
              {props.contractForm.entryPriceIncreaseIsPaid
                ? <span>, zaplaceno {format(new Date(props.contractForm.entryPriceIncreaseWasPaidAt), 'dd.MM.yyyy')}</span>
                : <span>, zatím uhrazeno {props.contractForm.entryPriceIncreasePaidAmount ? `${ts(props.contractForm.entryPriceIncreasePaidAmount)} Kč` : `0 Kč`}</span>
              }
            </>
          }
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {props.contractForm.clientIsFs !== true && <FormControlLabel
            control={
              <Field name='entryPriceIncreaseOneTimePayment' component={Checkbox} type='checkbox' />
            }
            label='Uhradit VCN jednorázově'
            disabled={disableEdit && props.user.group !== 'Admins'}
          />}
        </BasicRow>
        <Divider />

        <>
          <BasicRow label='První mince'>
            <SelectedFirstCoin />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {(!disableEdit || props.user.group === 'Admins') && <SelectFirstCoin />}
          </BasicRow>
          <Divider />
        </>

        <BasicRow label='Nárok z emise'>
          <>
            <FormControlLabel
              control={<Field name='emission1' component={Checkbox} type='checkbox' checked={props.contractForm.emission1} />}
              label={<span>Emise I.</span>}
              disabled
            />
            <FormControlLabel
              control={<Field name='emission2' component={Checkbox} type='checkbox' checked={props.contractForm.emission2} />}
              label={<span>Emise II.</span>}
              disabled
            />
          </>
        </BasicRow>
        <Divider />

        <BasicRow label='Dodání první mince'>
          {props.contractForm.actualDeliveryEntitlementDate && props.contractForm.firstDeliveryDate
           ? (
            <>
              <strong className={classes.value}>{format(parseISO(props.contractForm.actualDeliveryEntitlementDate), 'dd.MM.yyyy')}</strong>
              {isBefore(parseISO(props.contractForm.firstDeliveryDate), parseISO(props.contractForm.actualDeliveryEntitlementDate))
                ? (
                  <Alert severity='warning' style={{ marginTop: 5, marginBottom: 5, marginLeft: 20, paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10 }}>
                    Klient bude muset provést mimořádný vklad, aby dostal zvolenou minci.
                  </Alert>
                )
                : null
              }
            </>
           )
           : null}
        </BasicRow>
        <Divider />

        {props.user.group === 'Admins' && <>
          <BasicRow label='Partner'>
            <FormControlLabel
              control={<Field name='clientIsFs' component={Checkbox} type='checkbox' />}
              label=''
              disabled={disableEdit}
            />
          </BasicRow>
          <Divider />
        </>}

        <BasicRow label='Předpokládaná hodnota'>
          <strong className={classes.value}>{ts(props.contractForm.estimatedValue)} Kč</strong>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <ProgressionTable data={props.contractForm.progressionData} />
        </BasicRow>
        <Divider />

        {contractNumber && <>
          <BasicRow label='Platby celkem'>
            <strong className={classes.value}>{ts(paymentsAmount)} Kč</strong>
            {props.contractForm.lastPaymentReceivedAt ? ` (poslední ${format(new Date(props.contractForm.lastPaymentReceivedAt), 'dd.MM.yyyy')})` : ``}
          </BasicRow>
          <Divider />
          
          <BasicRow label='Stav účtu (zůstatek)'>
            <strong className={classes.value}>
              {savedAmount ? ts(savedAmount) : 0} Kč
            </strong>
          </BasicRow>
        </>}

        {contractNumber && <>
          <Divider />
          <BasicRow label='Stav'>
            <ButtonGroup color='primary' aria-label='outlined primary button group' disabled={!props.contractForm.contractNumber || props.contract.setStatusLoading}>
              <Tooltip title='Stav "Akceptována" se nastavuje automaticky zaplacením.'>
                {!props.contractForm.lastPaymentReceivedAt && props.contractForm.status !== 'active' && props.contractForm.status !== 'not-accepted'
                  ? (
                    <Button variant='contained'>Čeká na platbu</Button>
                  )
                  : (
                    <Button
                      variant={props.contractForm.status === 'active' ? 'contained' : 'outlined'}
                      disabled={props.user.group !== 'Admins' && props.contractForm.status === 'not-accepted'}
                      onClick={() => {
                        if (props.contractForm.status !== 'active' && props.user.group === 'Admins') {
                          if (props.contractForm.lastPaymentReceivedAt) {
                            props.setStatusOnContract(props.contractForm.id, 'active')
                          } else {
                            props.setStatusOnContract(props.contractForm.id, 'waiting-for-payment') 
                          }
                        }
                      }}
                    >
                      Akceptována
                    </Button>
                  )}
              </Tooltip>
              <Tooltip title='Stav "Neakceptována" můžete nastavit např. u chybné smlouvy. Nebude se tak zobrazovat v přehledu smluv.'>
                <Button
                  variant={props.contractForm.status === 'not-accepted' ? 'contained' : 'outlined'}
                  disabled={props.contractForm.status === 'active'}
                  onClick={() => {
                    props.setStatusOnContract(props.contractForm.id, 'not-accepted')
                  }}
                >
                  Neakceptována
                </Button>
              </Tooltip>
            </ButtonGroup>
            &nbsp;
            {props.contract.setStatusLoading && <CircularProgress size={20} color="secondary" />}
          </BasicRow>
        </>}
      </FormSection>

      <FormSection>
        {!isEditing && <InputRow>
          <Button
            variant='contained'
            color='secondary'
            onClick={props.openClientListModal}
          >
            Vyhledat klienta
          </Button>
        </InputRow>}

        <InputRow>
          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientPersonalNumber'
              label={`Rodné číslo${props.searchForClient.gcClientFound ? ` (nalezeno v GC)` : ``}${props.searchForClient.biClientFound ? ` (nalezeno v BI)` : ``}${props.searchForClient.gcClientFound === false && props.searchForClient.biClientFound === false ? ` (nenalezeno)`: ``} *`}
              disabled={disableEdit}
            />

            {props.searchForClient.gcClientSearching && !props.searchForClient.searchingError && <Typography variant='caption'>Hledám v databázi Gold Coins ...</Typography>}
            {props.searchForClient.biClientSearching && !props.searchForClient.searchingError && <Typography variant='caption'>Hledám v databázi Benefit investment ...</Typography>}
            {props.searchForClient.searchingError && <Typography variant='caption' style={{ color: '#f00' }}>{props.searchForClient.searchingErrorMessage !== null ? props.searchForClient.searchingErrorMessage : `Došlo k chybě při hledání klienta.`}</Typography>}

            <OnChange name='clientPersonalNumber'>
              {(value: any): void => {
                if (!isEditing) {
                  try {
                    if ((String(value).length === 10 || (String(value).length === 9 && parseInt(String(value).substr(0, 2), 10) < 54)) && personalNumberValidator(value)) {
                      props.searchForClient(value)
                    } else {
                      props.contractFormCleanClient()
                    }
                  } catch (error) {
                    // console.log(error) // tslint:disable-line
                  }
                }
              }}
            </OnChange>
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientBirthDate'
              label='Datum narození'
              disabled={true}
            />
          </Grid>
        </InputRow>

        <InputRow>
          <Grid item={true} xs={4} md={2}>
            <TextField
              name='clientTitleBefore'
              label='Titul'
              disabled={disableEdit}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item={true} xs={8} md={4}>
            <TextField
              name='clientFirstname'
              label='Jméno *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={8} md={4}>
            <TextField
              name='clientLastname'
              label='Příjmení *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={4} md={2}>
            <TextField
              name='clientTitleAfter'
              label='Titul'
              disabled={disableEdit}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </InputRow>

        <InputRow>
          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientPhone'
              label='Telefon *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={12} md={6}>
          <TextField
              name='clientEmail'
              label='Email *'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>

        <InputRow>
          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientAddressStreet'
              label='Ulice *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientAddressStreetNumber'
              label='Číslo popisné/orientační *'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>

        <InputRow>
          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientAddressZip'
              label='PSČ *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientAddressTown'
              label='Město *'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>
      </FormSection>

      <FormSection>
        <InputRow>
          <Grid item={true} xs={12} md={6}>
            <FormControlLabel
              control={
                <Field name='clientHasDifferentDeliveryAddress' component={Switch} type='checkbox' />
              }
              label={`Odlišná adresa pro zasílání`}
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>

        {props.contractForm.clientHasDifferentDeliveryAddress && <InputRow>
          <Grid item={true} xs={4} md={2}>
            <TextField
              name='clientDeliveryTitleBefore'
              label='Titul'
              disabled={disableEdit}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item={true} xs={8} md={4}>
            <TextField
              name='clientDeliveryFirstname'
              label='Jméno *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={8} md={4}>
            <TextField
              name='clientDeliveryLastname'
              label='Příjmení *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={4} md={2}>
            <TextField
              name='clientDeliveryTitleAfter'
              label='Titul'
              disabled={disableEdit}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </InputRow>}

        {props.contractForm.clientHasDifferentDeliveryAddress && <InputRow>
          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientDeliveryCompanyName'
              label='Název firmy'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>}

        {props.contractForm.clientHasDifferentDeliveryAddress && <InputRow>
          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientDeliveryAddressStreet'
              label='Ulice *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientDeliveryAddressStreetNumber'
              label='Číslo popisné/orientační *'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>}

        {props.contractForm.clientHasDifferentDeliveryAddress && <InputRow>
          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientDeliveryAddressZip'
              label='PSČ *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientDeliveryAddressTown'
              label='Město *'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>}

        {props.contractForm.clientHasDifferentDeliveryAddress && <InputRow>
          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientDeliveryPhone'
              label='Telefon *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientDeliveryBirthYear'
              label='Rok narození *'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>}
      </FormSection>

      <FormSection last>
        <InputRow>
          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientBankAccountNumber'
              label='Číslo bankovního účtu *'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientBankCode'
              label='Kód banky *'
              disabled={disableEdit}
            />
          </Grid>
        </InputRow>
      </FormSection>

      <AppBar color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            &nbsp;
          </Typography>
          {actionMenu}
          &nbsp;
          {isDirty() && <Button type='submit' variant={isEditing ? 'outlined' : 'contained'} size="large" color='secondary' disabled={disableEdit && !isDirty()}>
            Uložit smlouvu
          </Button>}
          &nbsp;
          {props.contractForm.id && !isDirty() && <Button onClick={props.closeContract} variant="contained" size="large" color='secondary' disabled={disableEdit}>
            Uzavřít smlouvu
          </Button>}
          &nbsp;
          {!isEditing && <Button size='large' disabled={disableEdit} onClick={() => {
            props.contractFormClean()
          }}>
            Vyčistit formulář
          </Button>}
          &nbsp;
          <Button size='large' disabled={disableEdit} onClick={() => {
            props.contractFormClean()
            history.push('/smlouvy')
          }}>
            Zrušit bez uložení
          </Button>
        </Toolbar>
      </AppBar>

      <ClientListModal />

      {/*
      <pre style={{ color: 'white' }}>
        {JSON.stringify(props.contractForm, null, 2)}
      </pre>
      */}
    </form>
  )

  return (
    <Form
      onSubmit={props.saveContract}
      initialValues={props.contractForm}
      validate={validate}
      render={renderForm}
    />
  )
}

const mapStateToProps = (state: any) => ({
  getContractLoading: ContractSelect.contractLoading(state),
  contractForm: state.contract.form,
  getContractResult: ContractSelect.contractResult(state),
  searchForClient: state.searchForClient,
  //
  contract: state.contract, // @todo
  user: state.user
})

const mapDispatchToProps = (dispatch: any) => ({
  contractFormUpdate: (data: any) => dispatch(ContractActions.formUpdate(data)),
  contractFormClean: () => dispatch(ContractActions.formClean()),
  contractFormCleanClient: () => dispatch(ContractActions.formCleanClient()),
  //
  saveContract: () => dispatch(ContractActions.saveContract()),
  closeContract: () => dispatch(ContractActions.closeContract()), // @todo Rename to createContract
  //
  setStatusOnContract: (id: string, status: string, expectedVersion: number) =>
    dispatch(ContractActions.setStatus(id, status, expectedVersion)), // @todo
  //
  openClientListModal: () => dispatch(ClientActions.listClientsOpenModal(true, 'contract')),
  searchForClient: (personalNumber: any) => dispatch(SearchForClientActions.searchForClient(personalNumber))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(ContractForm)
)
