import { put, select } from 'redux-saga/effects'

import InvoiceActions from 'Stores/Invoice/Actions'

export function * sendInvoice ({ id }: any) {
  try {
    yield put(InvoiceActions.sendInvoiceLoading())

    const lambdaFunction = `send-invoice`
    const url = `${process.env.REACT_APP_EXECUTE_API_URL}/${lambdaFunction}`
    const idToken = yield select(state => state.user.idToken)

    const response = yield fetch(url, {
      body: JSON.stringify({ invoiceId: id }),
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Authorization': idToken,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      mode: 'cors'
    })

    if (response.ok && response.status === 200) {
      const json = yield response.json()
      if ('response' in json && 'postmarkMessageId' in json.response) {
        const messageId = json.response.postmarkMessageId
        yield put(InvoiceActions.sendInvoiceSuccess(messageId))
        yield put(InvoiceActions.getInvoice(id))
      } else {
        const error = new Error('Došlo k chybě při odesílání Faktury (003).')
        yield put(InvoiceActions.sendInvoiceFailure(error))
      }
    } else {
      const { response: resp } = yield response.json()
      const error = `Došlo k chybě při odesílání Faktury (002 - ${resp.code}: ${resp.message}).`
      yield put(InvoiceActions.sendInvoiceFailure(error))
    }
  } catch (e) {
    const error = new Error('Došlo k chybě při získávání Faktury v PDF (001).')
    yield put(InvoiceActions.sendInvoiceFailure(error))
  }
}

export default sendInvoice
