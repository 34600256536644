import React from 'react'

import Link from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom'

const InvoiceListColumnInvoiceNumber = ({ row, column }: any): any => {
  const invoiceId = column.getInvoiceId(row)
  const cellValue = column.getCellValue(row)

  if (invoiceId === null) {
    return <span>{cellValue}</span>
  }

  return (
    <Link to={`/faktury/detail/${invoiceId}`} component={RouterLink}>
      <strong>{cellValue}</strong>
    </Link>
  )
}

export default InvoiceListColumnInvoiceNumber
