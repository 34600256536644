import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  listContracts: ['filter'], // LIST_CONTRACTS
  listContractsLoading: null, // LIST_CONTRACTS_LOADING
  listContractsSuccess: ['data'], // LIST_CONTRACTS_SUCCESS
  listContractsFailure: ['error'], // LIST_CONTRACTS_FAILURE
  //
  getContract: ['id'], // GET_CONTRACT
  getContractLoading: null, // GET_CONTRACT_LOADING
  getContractSuccess: ['data'], // GET_CONTRACT_SUCCESS
  getContractFailure: ['error'], // GET_CONTRACT_FAILURE
  //
  getMyContract: ['id'], // GET_MY_CONTRACT
  getMyContractLoading: null, // GET_MY_CONTRACT_LOADING
  getMyContractSuccess: ['data'], // GET_MY_CONTRACT_SUCCESS
  getMyContractFailure: ['error'], // GET_MY_CONTRACT_FAILURE
  //
  formUpdate: ['data'], // FORM_UPDATE
  formSelectFirstCoin: ['emissionId', 'coinId', 'dateOfIssue'], // FORM_SELECT_FIRST_COIN
  formClean: null, // FORM_CLEAN
  formCleanClient: null, // FORM_CLEAN_CLIENT
  //
  prefillClient: ['id'], // PREFILL_CLIENT
  prefillClientLoading: null, // PREFILL_CLIENT_LOADING
  prefillClientSuccess: ['data'], // PREFILL_CLIENT_SUCCESS
  prefillClientFailure: ['error'], // PREFILL_CLIENT_FAILURE
  //
  saveContract: null, // SAVE_CONTRACT
  saveContractLoading: null, // SAVE_CONTRACT_LOADING
  saveContractSuccess: ['data'], // SAVE_CONTRACT_SUCCESS
  saveContractFailure: ['error'], // SAVE_CONTRACT_FAILURE
  //
  createAccount: ['id'], // CREATE_ACCOUNT
  createAccountLoading: null, // CREATE_ACCOUNT_LOADING
  createAccountSuccess: null, // CREATE_ACCOUNT_SUCCESS
  createAccountFailure: ['error'], // CREATE_ACCOUNT_FAILURE
  //
  setStatus: ['id', 'status'], // SET_STATUS
  setStatusLoading: null, // SET_STATUS_LOADING
  setStatusSuccess: ['data'], // SET_STATUS_SUCCESS
  setStatusFailure: ['error'], // SET_STATUS_FAILURE
  //
  sendContract: ['id'], // SEND_CONTRACT
  sendContractLoading: null, // SEND_CONTRACT_LOADING
  sendContractSuccess: null, // SEND_CONTRACT_SUCCESS
  sendContractFailure: ['error'], // SEND_CONTRACT_FAILURE
  //
  downloadContract: ['id', 'generate'], // DOWNLOAD_CONTRACT
  downloadContractLoading: null, // DOWNLOAD_CONTRACT_LOADING
  downloadContractSuccess: ['url'], // DOWNLOAD_CONTRACT_SUCCESS
  downloadContractFailure: ['error'], // DOWNLOAD_CONTRACT_FAILURE
  //
  openChangeEmailDialog: null, // OPEN_CHANGE_EMAIL_DIALOG
  closeChangeEmailDialog: null, // CLOSE_CHANGE_EMAIL_DIALOG
  changeEmail: ['id', 'data'], // CHANGE_EMAIL
  changeEmailLoading: null, // CHANGE_EMAIL_LOADING
  changeEmailSuccess: null, // CHANGE_EMAIL_SUCCESS
  changeEmailFailure: ['error'], // CHANGE_EMAIL_FAILURE
  //
  openChangeAddressDialog: null, // OPEN_CHANGE_ADDRESS_DIALOG
  closeChangeAddressDialog: null, // CLOSE_CHANGE_ADDRESS_DIALOG
  changeAddress: ['id', 'data'], // CHANGE_ADDRESS
  changeAddressLoading: null, // CHANGE_ADDRESS_LOADING
  changeAddressSuccess: null, // CHANGE_ADDRESS_SUCCESS
  changeAddressFailure: ['error'], // CHANGE_ADDRESS_FAILURE
  //
  deleteContract: ['id'], // DELETE_CONTRACT
  deleteContractLoading: null, // DELETE_CONTRACT_LOADING
  deleteContractSuccess: null, // DELETE_CONTRACT_SUCCESS
  deleteContractFailure: ['error'], // DELETE_CONTRACT_FAILURE
  //
  closeContract: [], // CLOSE_CONTRACT
  closeContractLoading: null, // CLOSE_CONTRACT_LOADING
  closeContractSuccess: null, // CLOSE_CONTRACT_SUCCESS
  closeContractFailure: ['error'] // CLOSE_CONTRACT_FAILURE
}, { prefix: 'CONTRACT_' })

export const ContractTypes = Types

export default Creators
