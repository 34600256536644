import React from 'react'

import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui'
import TableCell from '@material-ui/core/TableCell'

const FilterCell = (props: any): any => {
  const { column } = props

  if (column.name.includes('action')) {
    return <TableCell {...props}>&nbsp;</TableCell>
  }

  return <TableFilterRow.Cell {...props} />
}

export default FilterCell
