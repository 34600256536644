import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  login: ['username', 'password'],
  loginLoading: null,
  loginSuccess: ['response'],
  loginFailure: ['error'],
  //
  logout: null,
  logoutLoading: null,
  logoutFailure: ['error'],
  logoutSuccess: null,
  //
  forgotPasswordLoading: null,
  forgotPasswordRequest: ['username'],
  forgotPasswordRequestSuccess: ['username', 'deliveryMedium', 'destination'],
  forgotPasswordRequestFailure: ['error'],
  forgotPasswordConfirm: ['code', 'password', 'callback'],
  forgotPasswordConfirmSuccess: null,
  forgotPasswordConfirmFailure: ['error'],
  //
  fetchUserSuccess: ['username', 'group', 'benefitId', 'idToken', 'accessToken'],
  fetchUserFailure: ['error']
})

export const UserTypes = Types

export default Creators
