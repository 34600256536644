import { listContracts } from 'Sagas/Contract/listContracts/listContracts'
import { listContractsSuccess } from 'Sagas/Contract/listContracts/listContractsSuccess'
import { listContractsFailure } from 'Sagas/Contract/listContracts/listContractsFailure'
//
import { getContract } from 'Sagas/Contract/getContract/getContract'
import { getContractSuccess } from 'Sagas/Contract/getContract/getContractSuccess'
import { getContractFailure } from 'Sagas/Contract/getContract/getContractFailure'
//
import { getMyContract } from 'Sagas/Contract/getMyContract/getMyContract'
import { getMyContractSuccess } from 'Sagas/Contract/getMyContract/getMyContractSuccess'
import { getMyContractFailure } from 'Sagas/Contract/getMyContract/getMyContractFailure'
//
import { prefillClient } from 'Sagas/Contract/prefillClient/prefillClient'
import { prefillClientSuccess } from 'Sagas/Contract/prefillClient/prefillClientSuccess'
import { prefillClientFailure } from 'Sagas/Contract/prefillClient/prefillClientFailure'
//
import { saveContract } from 'Sagas/Contract/saveContract/saveContract'
import { saveContractSuccess } from 'Sagas/Contract/saveContract/saveContractSuccess'
import { saveContractFailure } from 'Sagas/Contract/saveContract/saveContractFailure'
//
import { createAccount } from 'Sagas/Contract/createAccount/createAccount'
import { createAccountSuccess } from 'Sagas/Contract/createAccount/createAccountSuccess'
import { createAccountFailure } from 'Sagas/Contract/createAccount/createAccountFailure'
//
import { setStatus } from 'Sagas/Contract/setStatus/setStatus'
import { setStatusSuccess } from 'Sagas/Contract/setStatus/setStatusSuccess'
import { setStatusFailure } from 'Sagas/Contract/setStatus/setStatusFailure'
//
import { sendContract } from 'Sagas/Contract/sendContract/sendContract'
import { sendContractSuccess } from 'Sagas/Contract/sendContract/sendContractSuccess'
import { sendContractFailure } from 'Sagas/Contract/sendContract/sendContractFailure'
//
import { downloadContract } from 'Sagas/Contract/downloadContract/downloadContract'
import { downloadContractSuccess } from 'Sagas/Contract/downloadContract/downloadContractSuccess'
import { downloadContractFailure } from 'Sagas/Contract/downloadContract/downloadContractFailure'
//
import { changeAddress } from 'Sagas/Contract/changeAddress/changeAddress'
import { changeAddressSuccess } from 'Sagas/Contract/changeAddress/changeAddressSuccess'
import { changeAddressFailure } from 'Sagas/Contract/changeAddress/changeAddressFailure'
//
import { changeEmail } from 'Sagas/Contract/changeEmail/changeEmail'
import { changeEmailSuccess } from 'Sagas/Contract/changeEmail/changeEmailSuccess'
import { changeEmailFailure } from 'Sagas/Contract/changeEmail/changeEmailFailure'
//
import { deleteContract } from 'Sagas/Contract/deleteContract/deleteContract'
import { deleteContractSuccess } from 'Sagas/Contract/deleteContract/deleteContractSuccess'
import { deleteContractFailure } from 'Sagas/Contract/deleteContract/deleteContractFailure'
//
import { closeContract } from 'Sagas/Contract/closeContract/closeContract'
import { closeContractSuccess } from 'Sagas/Contract/closeContract/closeContractSuccess'
import { closeContractFailure } from 'Sagas/Contract/closeContract/closeContractFailure'

export default {
  listContracts,
  listContractsSuccess,
  listContractsFailure,
  //
  getContract,
  getContractSuccess,
  getContractFailure,
  //
  getMyContract,
  getMyContractSuccess,
  getMyContractFailure,
  //
  prefillClient,
  prefillClientSuccess,
  prefillClientFailure,
  //
  saveContract,
  saveContractSuccess,
  saveContractFailure,
  //
  createAccount,
  createAccountSuccess,
  createAccountFailure,
  //
  setStatus,
  setStatusSuccess,
  setStatusFailure,
  //
  sendContract,
  sendContractSuccess,
  sendContractFailure,
  //
  downloadContract,
  downloadContractSuccess,
  downloadContractFailure,
  // 
  changeAddress,
  changeAddressSuccess,
  changeAddressFailure,
  // 
  changeEmail,
  changeEmailSuccess,
  changeEmailFailure,
  //
  deleteContract,
  deleteContractSuccess,
  deleteContractFailure,
  //
  closeContract,
  closeContractSuccess,
  closeContractFailure
}
