import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { Link } from 'react-router-dom'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import format from 'date-fns/format'

import ts from 'Helpers/thousandsSeparator'

const styles = (theme: Theme) => createStyles({
  paper: {
    margin: 'auto',
    marginBottom: 48,
    overflow: 'hidden'
  },
  table: {
    minWidth: 700
  }
})

interface Props extends WithStyles<typeof styles> {
  data: any
}

// @todo
const statusCallback = (value: string): any => {
  switch (value) {
    case 'waiting-for-payment':
      return `Čeká na platbu`

    case 'active':
      return <strong style={{ color: '#baa436' }}>Aktivní</strong>
  }

  return value
}

const ClientsMyContracts = (props: Props) => {
  const { classes, data } = props

  const DetailLink = (linkProps: any) => <Link to={linkProps.href} {...linkProps} />

  return (
    <Paper elevation={2} className={classes.paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Číslo smlouvy</TableCell>
            <TableCell align='center'>Datum sjednání</TableCell>
            <TableCell align='center'>Poslední platba</TableCell>
            <TableCell align='right'>Měsíční platba</TableCell>
            <TableCell align='right'>Délka trvání</TableCell>
            <TableCell align='right'>Objednané množství</TableCell>
            <TableCell align='right'>Stav</TableCell>
            <TableCell align='right'>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item: any) => (
            <TableRow key={item.id} hover={true}>
              <TableCell component='th' scope='row'>
                <strong>{item.contractNumber}</strong>
              </TableCell>
              <TableCell align='center'>{format(new Date(item.signedAt), 'dd.MM.yyyy')}</TableCell>
              <TableCell align='center'>{item.lastPaymentReceivedAt ? format(new Date(item.lastPaymentReceivedAt), 'dd.MM.yyyy') : ` - `}</TableCell>
              <TableCell align='right'>{ts(item.monthlyAmount)}&nbsp;Kč</TableCell>
              <TableCell align='right'>{item.totalYears}&nbsp;let</TableCell>
              <TableCell align='right'>{item.numberOfCoins} ks, {item.qualityOfCoins}</TableCell>
              <TableCell align='right'>{statusCallback(item.status)}</TableCell>
              <TableCell align='right'>
                <Button component={DetailLink} href={`/smlouvy/moje/detail/${item.id}`} variant='outlined' size='small' color='secondary'>
                  Detail
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default withStyles(styles)(ClientsMyContracts)
