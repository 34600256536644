import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import ContractActions from 'Stores/Contract/Actions'

import DefaultLayout from 'Components/_Common/Layout/Default'
import ContractForm from 'Components/Contract/Form'

import * as ROUTES from 'routes'

const Contract = (props: any) => {
  useEffect(() => {
    if (props.data.prefill === false) {
      props.contractFormClean()
    }
  }, [])

  return (
    <DefaultLayout
      title={`Smlouva`}
      tabs={[{
        route: ROUTES.ADMIN_CONTRACTS, title: 'Seznam smluv'
      }, {
        route: '/smlouvy/nova', title: 'Nová smlouva'
      }]}
      activeTab={1}
    >
      <ContractForm />
    </DefaultLayout>
  )
}

const mapStateToProps = (state: any) => ({
  data: state.contract.form
})

const mapDispatchToProps = (dispatch: any) => ({
  contractFormClean: () => dispatch(ContractActions.formClean())
})

export default connect(mapStateToProps, mapDispatchToProps)(Contract)
