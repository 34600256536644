import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import StockActions from 'Stores/Stock/Actions'
import * as StockSelect from 'Stores/Stock/Select'
import InvoiceActions from 'Stores/Invoice/Actions'

import Link from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom'

const StockListColumnCoinDenominationMotif = ({ row, column }: any): any => {
  const modalOpened = useSelector(StockSelect.stocksModalOpened)
  const modalTarget = useSelector(StockSelect.stocksModalTarget)
  const dispatch = useDispatch()

  const stockId = column.getStockId(row)
  const cellValue = column.getCellValue(row)

  if (stockId === null) {
    return <span>{cellValue}</span>
  }

  const style: any = {
    cursor: 'pointer'
  }

  const handleClick = (e: any) => {
    if (modalOpened) {
      e.preventDefault()

      if (modalTarget === 'invoice') {
        const id = row.id
        const text = `${row.emission.name} - ${row.series ? 'sada' : row.coin.denominationMotif}`
        const price = row.priceSelling
        dispatch(InvoiceActions.formAddItemFromStock(id, text, price))
      }

      dispatch(StockActions.listStocksOpenModal(false))
    }
  }

  if (column.primary) {
    style.fontWeight = 'bold'
  }

  return (
    <Link
      to={`/sklad/polozka/${stockId}`}
      onClick={handleClick}
      component={RouterLink}
      color={column.primary === true ? 'primary' : 'inherit'}
    >
      <span style={style}>{cellValue}</span>
    </Link>
  )
}

export default StockListColumnCoinDenominationMotif
