import React from 'react'

import DefaultLayout from 'Components/_Common/Layout/Default'

import * as ROUTES from 'routes'

import PaymentList from 'Components/Payment/List'

const Payments = () => {
  return (
    <DefaultLayout
      title={`Platby`}
      tabs={[{
        route: ROUTES.ADMIN_PAYMENTS, title: 'Seznam plateb'
      }]}
      activeTab={0}
    >
      <PaymentList />
    </DefaultLayout>
  )
}

export default Payments
