import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  startupSuccess: null, // STARTUP_SUCCESS
  startupFailure: ['errorMessage'], // STARTUP_FAILURE
  //
  setDimensions: ['dimensions'], // SET_DIMENSIONS
  setDimensionsFailure: null, // SET_DIMENSIONS_FAILURE
  setDimensionsSuccess: ['dimensions'], // SET_DIMENSIONS_SUCCESS
  //
  toggleDrawerMobile: null, // TOGGLE_DRAWER_MOBILE
  toggleShowDeleted: null // TOGGLE_SHOW_DELETED
}, { prefix: 'APP_' })

export const AppTypes = Types

export default Creators
