import { all, takeLatest } from 'redux-saga/effects'

import { InvoiceTypes } from 'Stores/Invoice/Actions'
import Invoice from 'Sagas/Invoice'

export default function * root() {
  yield all([
    takeLatest(InvoiceTypes.LIST_INVOICES, Invoice.listInvoices),
    takeLatest(InvoiceTypes.LIST_INVOICES_SUCCESS, Invoice.listInvoicesSuccess),
    takeLatest(InvoiceTypes.LIST_INVOICES_FAILURE, Invoice.listInvoicesFailure),
    //
    takeLatest(InvoiceTypes.GET_INVOICE, Invoice.getInvoice),
    takeLatest(InvoiceTypes.GET_INVOICE_SUCCESS, Invoice.getInvoiceSuccess),
    takeLatest(InvoiceTypes.GET_INVOICE_FAILURE, Invoice.getInvoiceFailure),
    //
    takeLatest(InvoiceTypes.PREFILL_CLIENT, Invoice.prefillClient),
    takeLatest(InvoiceTypes.PREFILL_CLIENT_SUCCESS, Invoice.prefillClientSuccess),
    takeLatest(InvoiceTypes.PREFILL_CLIENT_FAILURE, Invoice.prefillClientFailure),
    //
    takeLatest(InvoiceTypes.CREATE_INVOICE, Invoice.createInvoice),
    takeLatest(InvoiceTypes.CREATE_INVOICE_SUCCESS, Invoice.createInvoiceSuccess),
    takeLatest(InvoiceTypes.CREATE_INVOICE_FAILURE, Invoice.createInvoiceFailure),
    //
    takeLatest(InvoiceTypes.DOWNLOAD_INVOICE, Invoice.downloadInvoice),
    takeLatest(InvoiceTypes.DOWNLOAD_INVOICE_SUCCESS, Invoice.downloadInvoiceSuccess),
    takeLatest(InvoiceTypes.DOWNLOAD_INVOICE_FAILURE, Invoice.downloadInvoiceFailure),
    //
    takeLatest(InvoiceTypes.SAVE_INVOICE, Invoice.saveInvoice),
    takeLatest(InvoiceTypes.SAVE_INVOICE_SUCCESS, Invoice.saveInvoiceSuccess),
    takeLatest(InvoiceTypes.SAVE_INVOICE_FAILURE, Invoice.saveInvoiceFailure),
    //
    takeLatest(InvoiceTypes.SEND_INVOICE, Invoice.sendInvoice),
    takeLatest(InvoiceTypes.SEND_INVOICE_SUCCESS, Invoice.sendInvoiceSuccess),
    takeLatest(InvoiceTypes.SEND_INVOICE_FAILURE, Invoice.sendInvoiceFailure)
  ])
}
