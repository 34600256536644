import React from 'react'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import addMonths from 'date-fns/addMonths'
import format from 'date-fns/format'

import Button from '@material-ui/core/Button'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import DraggablePaper from 'Components/_Common/Dialogs/DraggablePaper'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ts from 'Helpers/thousandsSeparator'

const styles = (theme: Theme) => createStyles({
  root: {
    width: `100%`,
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 900,
  },
})

interface Props extends WithStyles<typeof styles> {
  data: any
}

interface State {
  open: boolean
}

class ProgressionTable extends React.Component<Props, State> {
  state = {
    open: false
  }

  handleOpenDialog = () => {
    this.setState({ open: true })
  }

  handleCloseDialog = () => {
    this.setState({ open: false })
  }

  render () {
    const { classes, data } = this.props

    let breakpoint = false
    let highlight = false

    return (
      <React.Fragment>
        <Button size="small" variant="contained" color="secondary" onClick={this.handleOpenDialog}>
          Zobrazit vývoj
        </Button>

        <Dialog
          PaperComponent={DraggablePaper}
          open={this.state.open}
          onClose={this.handleCloseDialog}
          aria-labelledby='form-dialog-title'
          fullWidth={true}
          maxWidth='lg'
        >
          <DialogTitle id='form-dialog-title'>Vývoj v čase</DialogTitle>
          <DialogContent>

            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Měsíc / Rok</TableCell>
                  <TableCell align="right">Vklad</TableCell>
                  <TableCell align="right">Vloženo</TableCell>
                  <TableCell align="center">&nbsp;</TableCell>
                  <TableCell align="center">VCN</TableCell>
                  <TableCell align="right">Naspořeno</TableCell>
                  <TableCell align="right">Mincí</TableCell>
                  <TableCell align="right">Hodnota</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data instanceof Array && data.length > 0 && data.map((row: any) => {
                  const month = `${format(addMonths(new Date(), row.month), 'MM/yyyy')} (${row.month})`

                  if (breakpoint) {
                    highlight = false
                  }

                  if (breakpoint === false && row.saved + row.value > row.total) {
                    breakpoint = true
                    highlight = true
                  }

                  const iconBought = (row: any) => {
                    let result = []
                    if (row.buy) {
                      for (let i = 1; i <= row.boughtCount; i++) {
                        result.push(<FontAwesomeIcon icon={['fal', 'compact-disc']} size='1x' color='#baa436' />)
                      }
                    }

                    return result
                  }

                  return (
                    <React.Fragment key={row.month}>
                      <TableRow style={highlight ? { backgroundColor: '#323232' } : {}}>
                        <TableCell rowSpan={row.buy ? 2 : 1} component="th" scope="row">
                          {highlight ? <strong>{month}</strong> : <span>{month}</span>}
                        </TableCell>
                        <TableCell rowSpan={row.buy ? 2 : 1}  align="right">
                          +{ts(row.monthly)} CZK
                        </TableCell>
                        <TableCell rowSpan={row.buy ? 2 : 1}  align="right">
                          {ts(row.total)} CZK
                        </TableCell>
                        <TableCell rowSpan={row.buy ? 2 : 1} align="center">
                          {iconBought(row)}
                        </TableCell>                      
                        <TableCell rowSpan={row.buy ? 2 : 1} align="center">
                          {row.entryFeePaid ? `A` : `N (${ts(row.entryFeeAmount)} CZK)`}
                        </TableCell>
                        <TableCell align="right">
                          {row.saved > 0 ? `${ts(row.saved)} CZK` : `-`}
                        </TableCell>
                        <TableCell rowSpan={row.buy ? 2 : 1} align="right">
                          {row.coins > 0 ? `${row.coins} ks` : `-`}
                        </TableCell>
                        <TableCell align="right">
                          {row.value > 0 ? `${ts(row.value)} CZK` : `-`}
                        </TableCell>
                      </TableRow>
                      {row.buy && <TableRow>
                        <TableCell align="right">
                          {ts(row.savedDueToBuy)}&nbsp;CZK
                        </TableCell>
                        <TableCell align="right">
                          {ts(row.valueDueToBuy)}&nbsp;CZK
                        </TableCell>
                      </TableRow>}
                    </React.Fragment>
                  )
                })}
              </TableBody>
            </Table>

          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(ProgressionTable)
