export const CLIENT_DATA_STATUS_NEW = 'new'
export const CLIENT_DATA_STATUS_ACTIVE = 'active'

const clientDataStatus = (value: any): any => {
  switch (value) {
    case CLIENT_DATA_STATUS_NEW:
      return 'Nový'

    case CLIENT_DATA_STATUS_ACTIVE:
      return 'Aktivní'
  }

  return value
}

export const getAvailableClientStatuses = () => {
  return {
    CLIENT_DATA_STATUS_NEW: clientDataStatus(CLIENT_DATA_STATUS_NEW),
    CLIENT_DATA_STATUS_ACTIVE: clientDataStatus(CLIENT_DATA_STATUS_ACTIVE)
  }
}

export default clientDataStatus
