import { put } from 'redux-saga/effects'

import DistributionActions from 'Stores/Distribution/Actions'

import { recursivelyLoadAllData } from 'Sagas'
import listDistributionsQuery from 'Graphql/Queries/listDistributionsQuery'

export function * listDistributions ({ filter }: any) {
  try {
    yield put(DistributionActions.listDistributionsLoading())

    const variables = {} as any

    const data = yield recursivelyLoadAllData(listDistributionsQuery, variables, 'listDistributions')
    yield put(DistributionActions.listDistributionsSuccess(data))
  } catch (error) {
    yield put(DistributionActions.listDistributionsFailure(error))
  }
}

export default listDistributions
