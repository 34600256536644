import React from 'react'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'

const FormSection = (props: any) => (
  <Paper variant='outlined' style={{ marginBottom: props.last ? '100px' : '20px' }}>
    <Container style={{ paddingTop: `20px`, paddingBottom: `20px` }}>
      {props.children}
    </Container>
  </Paper>
)

export default FormSection
