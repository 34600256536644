export const CONTRACT_DATA_STATUS_WAITING_FOR_PAYMENT = 'waiting-for-payment'
export const CONTRACT_DATA_STATUS_NOT_ACCEPTED = 'not-accepted'
export const CONTRACT_DATA_STATUS_ACTIVE = 'active'

const contractDataStatus = (value: any): any => {
  switch (value) {
    case CONTRACT_DATA_STATUS_WAITING_FOR_PAYMENT:
      return `Čeká na platbu`

    case CONTRACT_DATA_STATUS_NOT_ACCEPTED:
      return 'Neakceptována'

    case CONTRACT_DATA_STATUS_ACTIVE:
      return 'Akceptována'
  }
}

export const getAvailableContractStatuses = () => {
  return {
    CONTRACT_DATA_STATUS_WAITING_FOR_PAYMENT: contractDataStatus(CONTRACT_DATA_STATUS_WAITING_FOR_PAYMENT),
    CONTRACT_DATA_STATUS_NOT_ACCEPTED: contractDataStatus(CONTRACT_DATA_STATUS_NOT_ACCEPTED),
    CONTRACT_DATA_STATUS_ACTIVE: contractDataStatus(CONTRACT_DATA_STATUS_ACTIVE)
  }
}

export default contractDataStatus
