import React, { useEffect, useState } from 'react'

import ClientsMyPaymentsList from 'Components/_Client/MyPayments/index'
import DefaultLayout from 'Components/_Common/Layout/Default'
import Loading from 'Components/Loading'

import { API, graphqlOperation } from 'aws-amplify'

import * as ROUTES from 'routes'

import gql from 'graphql-tag'
const query = gql`query ($nextToken: String) {
  listContracts (filter: { deleted: { ne: true }, status: { ne: "not-accepted" } signedAt: { ne: null} }, limit: 1000, nextToken: $nextToken) {
    items {
      id
      __typename
      contractNumber
      signedAt
      monthlyAmount
      totalYears
      numberOfCoins
      qualityOfCoins
      entryPriceIncrease
      entryPriceIncreaseOneTimePayment
      lastPaymentReceivedAt
      status
      payments (limit: 10000) {
        items {
          id
          __typename
          createdAt
          createdBy
          updatedAt
          updatedBy
          deleted
          deletedAt
          deletedBy
          statement
          date
          transactionType
          reference
          bankReference
          amount
          currency
          details
          extraDetails
          bankAccountName
          bankAccountNumber
          bankAccountNumberWithBankCode
          bankCode
          variableSymbol
          constantSymbol
          specificSymbol
          message
          notes
          status
          partClient
          partCommission
          partGoldCoins
          commissionExportedAt
          version
        }
        nextToken
      }
    }
    nextToken
  }
}`

const loadPayments = async (nextToken: any = null) => {
  const payments: any[] = []

  const result = (await API.graphql(graphqlOperation(query, { nextToken }))) as any
  console.log(result)

  if (result && 'data' in result && 'listContracts' in result.data && 'items' in result.data.listContracts &&
    result.data.listContracts.items instanceof Array && result.data.listContracts.items.length > 0) {
    result.data.listContracts.items.map((contract: any) => {
      result.data.listContracts.items.map((contract: any) => {
        if (contract.payments && contract.payments.items && contract.payments.items instanceof Array && contract.payments.items.length > 0) {
          contract.payments.items.map((payment: any) => {
            payments.push(payment)
            return payment
          })
        }
        return contract
      })
    })
  }

  if (result && 'data' in result && 'listContracts' in result.data && 'nextToken' in result.data.listContracts && result.data.listContracts.nextToken) {
    const contractsMore = await loadPayments(result.data.listContracts.nextToken)
    payments.push(...contractsMore)
  }

  return payments
} 

const MyPayments = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)

    const payments: any[] = await loadPayments()

    setIsLoading(false)

    payments.sort((a: any, b: any) => {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return b.bankReference - a.bankReference
    })

    setData(payments)
  }

  return (
    <DefaultLayout
      title={`Moje platby`}
      tabs={[{
        route: ROUTES.CLIENT_MY_PAYMENTS, title: 'Příchozí platby'
      }]}
      activeTab={0}
    >
      {isLoading ? <Loading /> : <ClientsMyPaymentsList data={data} />}
    </DefaultLayout>
  )
}

export default MyPayments
