// @todo Refactor & Optimise!
const personalNumberValidator = (pn: string): boolean => {
  try {
    // if (pn.length === 0) {
    //   return true
    // }

    if (pn.length > 10) {
      throw new Error('Personal number cannot be longer than 10 numbers.')
    }

    if (pn.length >= 9) {
      let year = parseInt(pn.substr(0, 2), 10);
      let month = parseInt(pn.substr(2, 2), 10);
      const day = parseInt(pn.substr(4, 2), 10);
      // const ext = parseInt(pn.substr(6, 3), 10);
  
      if (pn.length === 9 && year < 54) {
        return true
      }
  
      if (pn.length === 10) {
        let c = 0
        if (pn.length === 10) {
          c = parseInt(pn.substr(9, 1), 10)
        }
    
        let m = parseInt(pn.substr(0, 9), 10) % 11
        if (m === 10) {
          m = 0
        }
    
        if(m !== c) {
          throw new Error('Personal number division test failed.')
        }
    
        year += (year < 54) ? 2000 : 1900
    
        if (month > 70 && year > 2003) {
          month -= 70
        } else if (month > 50) {
          month -= 50
        } else if (month > 20 && year > 2003) {
          month -= 20
        }
    
        if (month === 0 || month > 12 || day === 0 || day > 31) {
          throw new Error('Personal number has incorrect format.')
        }
      }
    } else {
      return false
    }
  } catch(e) {
    // console.log(e) // tslint:disable-line
    return false
  }

  return true
}

export default personalNumberValidator
