import React from 'react'

import { connect } from 'react-redux'
import AppActions from 'Stores/App/Actions'

import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'

import Hidden from '@material-ui/core/Hidden'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import Navigator from 'Components/Navigator'

import theme from 'theme'

const drawerWidth = 256 // @todo

const styles = createStyles({
  appBar: {
    bottom: 0,
    top: 'auto'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
      width: drawerWidth
    }
  },
  grow: {
    flexGrow: 1
  },
  root: {
    backgroundColor: `#313131`,
    display: `flex`,
    height: `100%`,
    position: `absolute`,
    width: `100%`
  }
})

interface Props extends WithStyles<typeof styles> {
  children: any,
  drawerMobileOpen: boolean,
  toggleDrawerMobile: any,
  user?: any
}

const AppLayout = (props: Props) => {
  const { classes, children } = props

  if (props.user.isAuthenticated === false) {
    return children
  }

  const footer = props.user.group === 'Clients'

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Hidden smUp={true} implementation='js'>
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            variant='temporary'
            open={props.drawerMobileOpen}
            onClose={props.toggleDrawerMobile}
          />
        </Hidden>
        <Hidden xsDown={true} implementation='css'>
          <Navigator PaperProps={{ style: { width: drawerWidth } }} open={props.drawerMobileOpen}
            onClose={props.toggleDrawerMobile} />
        </Hidden>
      </nav>
  
      <div id='layout-content' style={{ width: `100%`, height: `100%`, display: `flex`, flexDirection: `column` }}>
        {children}
        
        {footer && <AppBar color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              &nbsp;
            </Typography>
            <Button color="inherit">Kontakt</Button>
            {/* <Button color="inherit">Obchodní podmínky</Button> */}
            {/* <Button color="inherit">Ceník služeb</Button> */}
          </Toolbar>
        </AppBar>}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  drawerMobileOpen: state.app.drawerMobileOpen,
  user: state.user
})

const mapDispatchToProps = (dispatch: any) => ({
  toggleDrawerMobile: () => dispatch(AppActions.toggleDrawerMobile())
})

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(AppLayout)
)
