import React from 'react'

import Link from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom'

const PaymentListColumnBankReference = ({ row, column }: any): any => {
  const paymentId = column.getPaymentId(row)
  const cellValue = column.getCellValue(row)

  if (paymentId === null) {
    return <span>{cellValue}</span>
  }

  return (
    <Link to={`/platby/detail/${paymentId}`} component={RouterLink}>
      <strong>{cellValue}</strong>
    </Link>
  )
}

export default PaymentListColumnBankReference
