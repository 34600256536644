import { all, takeLatest } from 'redux-saga/effects'

import { CoinTypes } from 'Stores/Coin/Actions'
import Coin from 'Sagas/Coin'

export default function * root() {
  yield all([
    takeLatest(CoinTypes.GET_COIN, Coin.getCoin),
    takeLatest(CoinTypes.GET_COIN_SUCCESS, Coin.getCoinSuccess),
    takeLatest(CoinTypes.GET_COIN_FAILURE, Coin.getCoinFailure)
  ])
}
