import { Table } from '@devexpress/dx-react-grid-material-ui'

import formatDateCallback from 'Components/_Common/List/Callback/formatDate'
import formatMoneyCallback from 'Components/_Common/List/Callback/formatMoney'

import distributionDataStatus from 'Components/Distribution/Data/Status'

export const columns = [
  {
    name: 'distribution.action',
    title: ' ',
    getContractId: (row: any): string => row.id
  },
  //
  {
    name: 'distribution.createdAt',
    title: 'Datum',
    getCellValue: (row: any): string => formatDateCallback(row, 'createdAt')
  },
  //
  {
    name: 'emission.name',
    title: 'Emise',
    primary: false,
    getEmissionId: (row: any): string => row.coin.emission.id,
    getCellValue: (row: any): string => row.coin.emission?.name
  },
  //
  {
    name: 'coin.denominationMotif',
    title: 'Mince',
    primary: false,
    getCoinId: (row: any): string => row.coin.id,
    getCellValue: (row: any): string => row.coin.denominationMotif
  },
  //
  {
    name: 'client.name',
    title: 'Klient',
    primary: false,
    getClientId: (row: any): string => row.client.id,
    getCellValue: (row: any): string => `${row.client.lastname}, ${row.client.firstname}`
  },
  //
  {
    name: 'contract.contractNumber',
    title: 'Smlouva',
    primary: false,
    getContractId: (row: any): string => row.contract.id,
    getCellValue: (row: any): string => row.contract.contractNumber
  },
  //
  {
    name: 'distribution.price',
    title: 'Cena',
    getCellValue: (row: any): any => formatMoneyCallback(row, 'price')
  },
  //
  {
    name: 'invoice.invoiceNumber',
    title: 'Faktura',
    primary: false,
    getInvoiceId: (row: any): string => row.invoice ? row.invoice.id : null,
    getCellValue: (row: any): string => row.invoice ? row.invoice.invoiceNumber : '-'
  },
  //
  {
    name: 'distribution.status',
    title: 'Stav',
    getCellValue: (row: any): string => distributionDataStatus(row.status)
  }
]

export const columnExtensions: Table.ColumnExtension[] = [
  {
    align: 'left',
    columnName: 'distribution.action',
    width: 100
  },
  //
  {
    align: 'left',
    columnName: 'distribution.createdAt',
    width: 150
  },
  //
  {
    align: 'left',
    columnName: 'emission.name',
    width: 150
  },
  //
  {
    align: 'left',
    columnName: 'coin.denominationMotif',
    width: 200
  },
  //
  {
    align: 'left',
    columnName: 'client.name'
  },
  //
  {
    align: 'right',
    columnName: 'contract.contractNumber',
    width: 100
  },
  //
  {
    align: 'right',
    columnName: 'distribution.price',
    width: 125
  },
  //
  {
    align: 'right',
    columnName: 'invoice.invoiceNumber',
    width: 150
  },
  //
  {
    align: 'center',
    columnName: 'distribution.status',
    width: 100
  }
]

export default {
  columns
}
