import gql from 'graphql-tag'

const query = gql`
  query GET_EMAIL_QUERY($id: ID!) {
    getEmail(id: $id) {
      id
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        contracts {
          nextToken
        }
        emails {
          nextToken
        }
        invoices {
          nextToken
        }
        payments {
          nextToken
        }
        distributions {
          nextToken
        }
        version
      }
      contract {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        client {
          id
          benefitId
          isFs
          cognitoUserId
          owner
          createdAt
          createdBy
          updatedAt
          updatedBy
          deleted
          deletedAt
          deletedBy
          titleBefore
          firstname
          lastname
          titleAfter
          personalNumber
          birthDate
          email
          phone
          addressFormatted
          addressStreet
          addressStreetNumber
          addressZip
          addressTown
          hasDifferentDeliveryAddress
          deliveryTitleBefore
          deliveryFirstname
          deliveryLastname
          deliveryTitleAfter
          deliveryCompanyName
          deliveryPhone
          deliveryBirthYear
          deliveryAddressFormatted
          deliveryAddressStreet
          deliveryAddressStreetNumber
          deliveryAddressZip
          deliveryAddressTown
          bankAccountNumber
          bankCode
          status
          version
        }
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        emails {
          nextToken
        }
        invoices {
          nextToken
        }
        payments {
          nextToken
        }
        distributions {
          nextToken
        }
        version
      }
      receivedAt
      from
      to
      cc
      bcc
      recipients
      subject
      body
      bodyText
      bodyHtml
      attachments
      status
      events {
        items {
          id
          receivedAt
          recipient
          type
          details
          version
        }
        nextToken
      }
      version
    }
  }
`

export default query
