import { createReducer } from 'reduxsauce'

import { ContractTypes } from '../Contract/Actions'
import { SearchForClientTypes } from './Actions'
import INITIAL_STATE from './InitialState'

// SEARCH_FOR_CLIENT_LOADING
export const searchForClientLoading = (state: any): any => ({
  ...state,
  clientSearching: true,
  clientFound: false,
  searchingError: false,
  searchingErrorMessage: null
})

// SEARCH_FOR_CLIENT_SUCCESS
export const searchForClientSuccess = (state: any, { result }: any): any => ({
  ...state,
  clientSearching: false,
  clientFound: true,
  result
})

// SEARCH_FOR_CLIENT_FAILURE
export const searchForClientFailure = (state: any, { message }: any): any => ({
  ...state,
  clientSearching: false,
  clientFound: false,
  searchingError: true,
  searchingErrorMessage: message
})

//

// SEARCH_FOR_BI_CLIENT_LOADING
export const searchForBiClientLoading = (state: any): any => ({
  ...state,
  biClientSearching: true,
  biClientFound: null
})

// SEARCH_FOR_BI_CLIENT_SUCCESS
export const searchForBiClientSuccess = (state: any): any => ({
  ...state,
  biClientSearching: false,
  biClientFound: true
})

// SEARCH_FOR_BI_CLIENT_FAILURE
export const searchForBiClientFailure = (state: any, { message }: any): any => ({
  ...state,
  biClientSearching: false,
  biClientFound: false,
  searchingError: true,
  searchingErrorMessage: message
})

//

// SEARCH_FOR_GC_CLIENT_LOADING
export const searchForGcClientLoading = (state: any): any => ({
  ...state,
  gcClientSearching: true,
  gcClientFound: null
})

// SEARCH_FOR_GC_CLIENT_SUCCESS
export const searchForGcClientSuccess = (state: any): any => ({
  ...state,
  gcClientSearching: false,
  gcClientFound: true
})

// SEARCH_FOR_GC_CLIENT_FAILURE
export const searchForGcClientFailure = (state: any, { message }: any): any => ({
  ...state,
  gcClientSearching: false,
  gcClientFound: false,
  searchingError: true,
  searchingErrorMessage: message
})

//

// FORM_CLEAN
export const formClean = (): any => INITIAL_STATE

export const reducer = createReducer(INITIAL_STATE, {
  [SearchForClientTypes.SEARCH_FOR_CLIENT_LOADING]: searchForClientLoading,
  [SearchForClientTypes.SEARCH_FOR_CLIENT_SUCCESS]: searchForClientSuccess,
  [SearchForClientTypes.SEARCH_FOR_CLIENT_FAILURE]: searchForClientFailure,
  //
  [SearchForClientTypes.SEARCH_FOR_BI_CLIENT_LOADING]: searchForBiClientLoading,
  [SearchForClientTypes.SEARCH_FOR_BI_CLIENT_SUCCESS]: searchForBiClientSuccess,
  [SearchForClientTypes.SEARCH_FOR_BI_CLIENT_FAILURE]: searchForBiClientFailure,
  //
  [SearchForClientTypes.SEARCH_FOR_GC_CLIENT_LOADING]: searchForGcClientLoading,
  [SearchForClientTypes.SEARCH_FOR_GC_CLIENT_SUCCESS]: searchForGcClientSuccess,
  [SearchForClientTypes.SEARCH_FOR_GC_CLIENT_FAILURE]: searchForGcClientFailure,
  //
  [ContractTypes.FORM_CLEAN]: formClean
})
