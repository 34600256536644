import { getContext, put } from 'redux-saga/effects'

import InvoiceActions from 'Stores/Invoice/Actions'

import getInvoiceQuery from 'Graphql/Queries/getInvoiceQuery'

export function * getInvoice ({ id }: any) {
  try {
    yield put(InvoiceActions.getInvoiceLoading())

    const graphql = yield getContext('graphql')
    const variables = { id } as any

    const result = yield graphql.query(getInvoiceQuery, variables)

    // @todo
    if (result && result.data && result.data.getInvoice) {
      yield put(InvoiceActions.getInvoiceSuccess(result.data.getInvoice))
    }
  } catch (error) {
    yield put(InvoiceActions.getInvoiceFailure(error))
  }
}

export default getInvoice
