import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import ContractActions from 'Stores/Contract/Actions'
import * as ContractSelect from 'Stores/Contract/Select'

import useReactRouter from 'use-react-router'

import ClientsMyContractDetail from 'Components/_Client/MyContract'
import DefaultLayout from 'Components/_Common/Layout/Default'
import Loading from 'Components/Loading'

import * as ROUTES from 'routes'

const MyContract = (props: any) => {
  const {
    match: {
      params: { id }
    }
  }: any = useReactRouter()

  useEffect(() => {
    props.getMyContract(id)
  }, [id])

  return (
    <DefaultLayout
      title={`Moje smlouva`}
      tabs={[{
        route: ROUTES.CLIENT_MY_CONTRACTS, title: 'Moje smlouvy'
      }, {
        route: `/smlouvy/moje/detail/${id}`, title: 'Detail smlouvy'
      }]}
      activeTab={1}
    >
      {props.myContractLoading && <Loading />}
      {!props.myContractLoading && props.myContractResult && (
        <ClientsMyContractDetail data={props.myContractResult} />
      )}
    </DefaultLayout>
  )
}

const mapStateToProps = (state: any) => ({
  myContractLoading: ContractSelect.contractLoading(state),
  myContractResult: ContractSelect.contractResult(state),
  //
  dimensions: state.app.dimensions
})

const mapDispatchToProps = (dispatch: any) => ({
  getMyContract: (id: any) => dispatch(ContractActions.getMyContract(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyContract)
