import React, { useEffect, useState } from 'react'

import ClientsMyInvoicesList from 'Components/_Client/MyInvoices/index'
import DefaultLayout from 'Components/_Common/Layout/Default'
import Loading from 'Components/Loading'

import { API, graphqlOperation } from 'aws-amplify'

import * as ROUTES from 'routes'

const query = `query {
  listInvoices(filter: { status: { eq: "processed" } }, limit: 1000) {
    items {
      id
      dateOfIssue
      invoiceNumber
      contract {
        contractNumber
      }
      price
      variableSymbol
      status
    }
    nextToken
  }
}`

const MyInvoices = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)

    const invoices: any[] = []

    const result = (await API.graphql(graphqlOperation(query))) as any
    if (result && 'data' in result && 'listInvoices' in result.data && 'items' in result.data.listInvoices &&
      result.data.listInvoices.items instanceof Array && result.data.listInvoices.items.length > 0) {
      result.data.listInvoices.items.map((invoice: any) => {
        invoices.push(invoice)
        return invoice
      })
    }

    setIsLoading(false)

    invoices.sort((a: any, b: any) => {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return b.invoiceNumber - a.invoiceNumber
    })

    setData(invoices)
  }

  return (
    <DefaultLayout
      title={`Moje faktury`}
      tabs={[{
        route: ROUTES.CLIENT_MY_INVOICES, title: 'Vystavené faktury'
      }]}
      activeTab={0}
    >
      {isLoading ? <Loading /> : <ClientsMyInvoicesList data={data} />}
    </DefaultLayout>
  )
}

export default MyInvoices
