import createSagaMiddleware from 'redux-saga'

import AppSyncConfig from 'aws-exports'
import createGraphql from 'Graphql/urql'

const graphql = createGraphql({
  url: AppSyncConfig.aws_appsync_graphqlEndpoint
})

export const sagaMiddleware = createSagaMiddleware({
  context: {
    graphql
  },
  onError: (error: Error) => {
    // @todo Bugsnag
    console.error(error) // tslint:disable-line
  }
})

const middleware = [sagaMiddleware]

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger') // tslint:disable-line no-var-requires
  const invariant = require('redux-immutable-state-invariant').default // tslint:disable-line no-var-requires

  middleware.push(invariant())
  middleware.push(createLogger({ collapsed: true }))
}

export default middleware
