import React from 'react'

import DefaultLayout from 'Components/_Common/Layout/Default'

const Home = () => {
  return (
    <DefaultLayout
      title={`Dashboard`}
      tabs={[]}
      activeTab={0}
    >

    </DefaultLayout>
  )
}

export default Home
