export const EMAIL_DATA_STATUS_QUEUED = 'Queued'
export const EMAIL_DATA_STATUS_SENT = 'Sent'

const emailDataStatus = (value: any): any => {
  switch (value) {
    case EMAIL_DATA_STATUS_QUEUED:
      return `Ve frontě`

    case EMAIL_DATA_STATUS_SENT:
      return 'Odesláno'
  }
}

export const getAvailableEmailStatuses = () => {
  return {
    EMAIL_DATA_STATUS_QUEUED: emailDataStatus(EMAIL_DATA_STATUS_QUEUED),
    EMAIL_DATA_STATUS_SENT: emailDataStatus(EMAIL_DATA_STATUS_SENT)
  }
}

export default emailDataStatus
