import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import DistributionActions from 'Stores/Distribution/Actions'
import * as DistributionSelect from 'Stores/Distribution/Select'

import Loading from 'Components/Loading'
import List from 'Components/Distribution/List/List'

const DistributionList = () => {
  const distributionsLoading = useSelector(DistributionSelect.distributionsLoading)
  const distributionsResult = useSelector(DistributionSelect.distributionsResult)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(DistributionActions.listDistributions())
  }, [])

  return (
    <>
      <List rows={distributionsResult || []} />
      {distributionsLoading && <Loading />}
    </>
  )
}

export default DistributionList
