import React from 'react'

import DefaultLayout from 'Components/_Common/Layout/Default'
import PredictionList from 'Components/Prediction/List'

const Prediction = () => {
  return (
    <DefaultLayout
      title={`Predikce`}
      tabs={[]}
      activeTab={0}
    >
      <PredictionList />
    </DefaultLayout>
  )
}

export default Prediction
