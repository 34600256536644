import { put, select } from 'redux-saga/effects'

import InvoiceActions from 'Stores/Invoice/Actions'

export function * createInvoice ({ id }: any) {
  try {
    yield put(InvoiceActions.createInvoiceLoading())

    const lambdaFunction = 'create-invoice'
    const url = `${process.env.REACT_APP_EXECUTE_API_URL}/${lambdaFunction}`
    const idToken = yield select(state => state.user.idToken)

    // @todo Consolidate!
    const response = yield fetch(url, {
      body: JSON.stringify({ invoiceId: id }),
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Authorization': idToken,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      mode: 'cors'
    })

    if (response.ok && response.status === 200) {
      const json = yield response.json()
      if ('response' in json && 'url' in json.response) {
        const invoicePdfUrl = json.response.url
        yield put(InvoiceActions.createInvoiceSuccess(invoicePdfUrl))
        yield put(InvoiceActions.getInvoice(id))
      } else {
        const error = new Error('Došlo k chybě při získávání Faktury v PDF (003).')
        yield put(InvoiceActions.createInvoiceFailure(error))
      }
    } else {
      const error = new Error('Došlo k chybě při získávání Faktury v PDF (002).')
      yield put(InvoiceActions.createInvoiceFailure(error))
    }
  } catch (e) {
    console.log(e)
    const error = new Error('Došlo k chybě při získávání Faktury v PDF (001).')
    yield put(InvoiceActions.createInvoiceFailure(error))
  }
}

export default createInvoice
