import React from 'react'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import DashboardCard from 'Components/_Client/Dashboard/Card'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Link } from 'react-router-dom'

import * as ROUTES from 'routes'

const styles = (theme: Theme) => createStyles({
  root: {
    alignItems: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden'
  },
  wrapper: {
    margin: 'auto',
    marginBottom: 48,
    maxWidth: 936,
    overflow: 'hidden'
  }
})

interface Props extends WithStyles<typeof styles> {}

const Dashboard = (props: Props) => {
  const { classes } = props

  const MyContracts = (linkProps: any) => <Link to={ROUTES.CLIENT_MY_CONTRACTS} {...linkProps} />
  const MyPayments = (linkProps: any) => <Link to={ROUTES.CLIENT_MY_PAYMENTS} {...linkProps} />
  const MyInvoices = (linkProps: any) => <Link to={ROUTES.CLIENT_MY_INVOICES} {...linkProps} />
  const MyCoins = (linkProps: any) => <Link to={ROUTES.CLIENT_MY_COINS} {...linkProps} />
  const MyProfile = (linkProps: any) => <Link to={ROUTES.CLIENT_MY_PROFILE} {...linkProps} />
  const MySettings = (linkProps: any) => <Link to={ROUTES.CLIENT_MY_SETTINGS} {...linkProps} />

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <DashboardCard
          icon={<FontAwesomeIcon icon={['fal', 'file-contract']} size='2x' fixedWidth={true} />}
          title='Smlouvy'
          subtitle='&nbsp;'
          description='Kompletní informace k Vašim smlouvám.'
          buttonText='Přejít na Moje smlouvy'
          buttonLink={MyContracts}
        />

        <DashboardCard
          icon={<FontAwesomeIcon icon={['fal', 'money-check-alt']} size='2x' fixedWidth={true} />}
          title='Platby'
          subtitle='&nbsp;'
          description='Přehled všech plateb.'
          buttonText='Přejít na Moje platby'
          buttonLink={MyPayments}
        />

        <DashboardCard
          icon={<FontAwesomeIcon icon={['fal', 'file-invoice']} size='2x' fixedWidth={true} />}
          title='Faktury'
          subtitle='&nbsp;'
          description='Přehled Vašich faktur.'
          buttonText='Přejít na Moje faktury'
          buttonLink={MyInvoices}
        />

        <DashboardCard
          icon={<FontAwesomeIcon icon={['fal', 'coins']} size='2x' fixedWidth={true} />}
          title='Mince'
          subtitle='&nbsp;'
          description='Průběh nákupu objednaných mincí.'
          buttonText='Přejít na Moje mince'
          buttonLink={MyCoins}
        />

        <DashboardCard
          icon={<FontAwesomeIcon icon={['fal', 'id-card']} size='2x' fixedWidth={true} />}
          title='Profil'
          subtitle='&nbsp;'
          description='Správa Vašich údajů a zabezpečení.'
          // buttonText='Přejít na Můj profil'
          buttonText='Připravujeme'
          buttonLink={MyProfile}
          buttonDisabled={true}
        />

        <DashboardCard
          icon={<FontAwesomeIcon icon={['fal', 'cogs']} size='2x' fixedWidth={true} />}
          title='Nastavení'
          subtitle='&nbsp;'
          description='Individuální nastavení.'
          // buttonText='Přejít na Moje nastavení'
          buttonText='Připravujeme'
          buttonLink={MySettings}
          buttonDisabled={true}
        />
      </div>
    </div>
  )
}

export default withStyles(styles)(Dashboard)
