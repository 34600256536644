import { put, select } from 'redux-saga/effects'

import ContractActions from 'Stores/Contract/Actions'

import { format } from 'date-fns'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations from 'Graphql/mutations'

import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { history } from '../../../index'

export function * saveContract () {
  try {
    yield put(ContractActions.saveContractLoading())

    const contractForm = yield select(state => state.contract.form)
    const data = Object.assign({}, contractForm)

    const username = yield select(state => state.user.username)

    const fieldsToDelete = [
      'loading', 'prefill', 'dirty',
      'createdAt', 'createdBy', 'updatedAt', 'updatedBy',
      'progressionData',
      //
      'firstEmission','firstCoin'
    ]

    let action = 'CREATE'
    if (data.id) {
      action = 'UPDATE'

      if (data.contractNumber) {
        fieldsToDelete.push('monthlyAmount', 'totalYears', 'defaultPriceOfCoin', 'salesMargin',
          'entryFeePerCoin', 'priceOfCoin', 'indicativeTargetAmount', 'estimatedValue', 
          'entryPriceIncrease', 'numberOfCoins'
        )
      }
    }

    fieldsToDelete.map(field => delete data[field])

    data.clientBirthDate = format(new Date(data.clientBirthDate), 'RRRR-MM-dd')
    if (data.clientBirthDate instanceof Date) {
      data.clientBirthDate = data.clientBirthDate.toISOString().substr(0, 10)
    }

    let mutation
    let selector

    if (action === 'UPDATE') {
      mutation = mutations.updateContract
      selector = `updateContract`

      data.updatedAt = (new Date()).toISOString()
      data.updatedBy = username
  
      data.expectedVersion = data.version
      delete data.version
      delete data.client
    } else {
      mutation = mutations.createContract
      selector = `createContract`

      data.createdAt = (new Date()).toISOString()
      data.createdBy = username
    }

    const phoneNumber = parsePhoneNumberFromString(String(data.clientPhone), 'CZ')
    if (phoneNumber !== undefined) {
      data.clientPhone = phoneNumber.format('E.164')
    }

    if (data.clientHasDifferentDeliveryAddress === false) {
      data.clientDeliveryFirstname = null
      data.clientDeliveryLastname = null
      data.clientDeliveryCompanyName = null
      data.clientDeliveryPhone = null
      data.clientDeliveryBirthYear = null
      data.clientDeliveryAddressFormatted = null
      data.clientDeliveryAddressStreet = null
      data.clientDeliveryAddressStreetNumber = null
      data.clientDeliveryAddressZip = null
      data.clientDeliveryAddressTown = null
    } else {
      const deliveryPhoneNumber = parsePhoneNumberFromString(String(data.clientDeliveryPhone), 'CZ')
      if (deliveryPhoneNumber !== undefined) {
        data.clientDeliveryPhone = deliveryPhoneNumber.format('E.164')
      }
    }

    // Create the Client first
    if (action === 'CREATE') {
      const i = data

      let clientId
      if (i.clientId !== null) {
        clientId = i.clientId
        data.contractClientId = clientId
      } else {
        const input = {} as any
        input.benefitId = `${i.clientBenefitId}`
        input.isFs = i.clientIsFs
        input.createdAt = i.createdAt
        input.createdBy = i.createdBy
        input.firstname = i.clientFirstname
        input.lastname = i.clientLastname
        input.personalNumber = i.clientPersonalNumber
        input.birthDate = i.clientBirthDate
        input.email = i.clientEmail
        input.phone = i.clientPhone
        input.addressStreet = i.clientAddressStreet
        input.addressStreetNumber = i.clientAddressStreetNumber
        input.addressZip = i.clientAddressZip
        input.addressTown = i.clientAddressTown
        input.hasDifferentDeliveryAddress = i.clientHasDifferentDeliveryAddress
        input.deliveryFirstname = i.clientDeliveryFirstname
        input.deliveryLastname = i.clientDeliveryLastname
        input.deliveryCompanyName = i.clientDeliveryCompanyName
        input.deliveryPhone = i.clientDeliveryPhone
        input.deliveryBirthYear = i.clientDeliveryBirthYear
        input.deliveryAddressStreet = i.clientDeliveryAddressStreet
        input.deliveryAddressStreetNumber = i.clientDeliveryAddressStreetNumber
        input.deliveryAddressZip = i.clientDeliveryAddressZip
        input.deliveryAddressTown = i.clientDeliveryAddressTown
        input.bankAccountNumber = i.clientBankAccountNumber
        input.bankCode = i.clientBankCode
        input.status = 'active'

        const clientVariables = { input }
        const clientResult = (yield API.graphql(graphqlOperation(mutations.createClient, clientVariables))) as any
        if (clientResult && 'data' in clientResult) {
          clientId = clientResult.data.createClient.id
        }
      }

      data.contractClientId = clientId
      delete data.clientId
      // delete data.clientBenefitId
      delete data.clientIsFs

      delete data.__typename

      // Create
      const contractResult = (yield API.graphql(graphqlOperation(mutation, { input: data }))) as any
      yield put(ContractActions.saveContractSuccess(contractResult.data[selector]))
      history.replace(`/smlouvy/detail/${contractResult.data[selector].id}`)
    } else {
      delete data.clientId
      delete data.clientBenefitId
      delete data.clientIsFs

      delete data.emails
      delete data.payments
      delete data.invoices
      delete data.distributions

      delete data.__typename

      // Update
      const contractResult = (yield API.graphql(graphqlOperation(mutation, { input: data }))) as any
      yield put(ContractActions.saveContractSuccess(contractResult.data[selector]))
      yield put(ContractActions.getContract(contractResult.data[selector].id))
    }
  } catch (error) {
    console.log(error) // tslint:disable-line
    yield put(ContractActions.saveContractFailure(error))
  }
}

export default saveContract
