import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import UserActions, { UserTypes } from 'Stores/User/Actions'

import { Auth } from 'aws-amplify'

import { history } from '../index'

export function * login ({ username, password }: any) {
  try {
    yield put(UserActions.loginLoading())

    const lowercaseUsername = String(username).toLowerCase()
    const response = yield Auth.signIn(lowercaseUsername, password)
    yield call(processResponse, response)
  } catch (error) {
    yield call(processError, error)
  }
}

export function * logout () {
  try {
    yield put(UserActions.logoutLoading())

    yield Auth.signOut({ global: true })
    yield put(UserActions.logoutSuccess())
  } catch (error) {
    yield put(UserActions.logoutFailure(error))
  }
}

export function * forgotPasswordRequest ({ username }: any) {
  try {
    yield put(UserActions.forgotPasswordLoading())

    const response = yield Auth.forgotPassword(username)
    const { DeliveryMedium = null, Destination = null } = response.CodeDeliveryDetails
    yield put(UserActions.forgotPasswordRequestSuccess(username, DeliveryMedium, Destination))
  } catch (error) {
    yield put(UserActions.forgotPasswordRequestFailure(error))
  }
}

export function * forgotPasswordConfirm ({ code, password, callback }: any) {
  try {
    yield put(UserActions.forgotPasswordLoading())

    const username = yield select(state => state.user.forgotPasswordUsername)

    yield Auth.forgotPasswordSubmit(username, code, password)
    yield put(UserActions.forgotPasswordConfirmSuccess())
    yield call(callback)
    history.push('/prihlaseni')
  } catch (error) {
    yield put(UserActions.forgotPasswordConfirmFailure(error))
  }
}

export function * processResponse (response: any) {
  try {
    // @todo
    //
    // if (response.challengeName) {
    //   if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
    //     yield put({ type: 'CHANGE_LOGIN_STATE', payload: 'NEW_PASSWORD' })
    //   } else if (response.challengeName === 'SMS_MFA' || response.challengeName === 'SOFTWARE_TOKEN_MFA') {
    //     loginMFAState = response.challengeName
    //     yield put({ type: 'CHANGE_LOGIN_STATE', payload: 'MFA' })
    //   }
    // } else
    if (response.signInUserSession) {
      yield put(UserActions.loginSuccess(response))
      history.push('/')
    } else {
      throw new Error('Přihlášení se nezdařilo. Zkuste to prosím znovu ...')
    }
  } catch (error) {
    yield call(processError, error)
  }
}

export function * processError (error: any) {
  if (error.code && error.code === 'PasswordResetRequiredException') {
    const message = 'Je vyžadována změna hesla. Prosím, zvolte "Zapomenuté heslo" a resetujte si svoje heslo.'
    yield put(UserActions.loginFailure(message))
  } else if (error.message) {
    yield put(UserActions.loginFailure(error))
  }

  window.localStorage.clear()
  history.push('/prihlaseni')
}

export default function * root() {
  yield all([
    takeLatest(UserTypes.LOGIN, login),
    takeLatest(UserTypes.LOGOUT, logout),
    takeLatest(UserTypes.FORGOT_PASSWORD_REQUEST, forgotPasswordRequest),
    takeLatest(UserTypes.FORGOT_PASSWORD_CONFIRM, forgotPasswordConfirm)
  ])
}
