import { listClients } from 'Sagas/Client/listClients/listClients'
import { listClientsSuccess } from 'Sagas/Client/listClients/listClientsSuccess'
import { listClientsFailure } from 'Sagas/Client/listClients/listClientsFailure'
//
import { getClient } from 'Sagas/Client/getClient/getClient'
import { getClientSuccess } from 'Sagas/Client/getClient/getClientSuccess'
import { getClientFailure } from 'Sagas/Client/getClient/getClientFailure'

export default {
  listClients,
  listClientsSuccess,
  listClientsFailure,
  //
  getClient,
  getClientSuccess,
  getClientFailure
}
