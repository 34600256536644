import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import EmailActions from 'Stores/Email/Actions'
import * as EmailSelect from 'Stores/Email/Select'

import Loading from 'Components/Loading'
import List from 'Components/Email/List/List'

const EmailList = () => {
  const emailsLoading = useSelector(EmailSelect.emailsLoading)
  const emailsResult = useSelector(EmailSelect.emailsResult)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(EmailActions.listEmails())
  }, [])

  return (
    <>
      <List rows={emailsResult || []} />
      {emailsLoading && <Loading />}
    </>
  )
}

export default EmailList
