const INITIAL_STATE = {
  listEmailsLoading: false,
  listEmailsResult: [],
  listEmailsError: null,
  //
  getEmailLoading: false,
  getEmailResult: null,
  getEmailError: null
}

export default INITIAL_STATE
