import React from 'react'

import { connect } from 'react-redux'
import ToolsActions from 'Stores/Tools/Actions'

import DefaultLayout from 'Components/_Common/Layout/Default'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import WaitDialog from 'Components/_Common/Dialogs/Wait'

const styles = (theme: Theme) => createStyles({
  root: {
    alignItems: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden'
  },
  wrapper: {
    margin: 'auto',
    marginBottom: 48,
    maxWidth: 936,
    overflow: 'hidden'
  },

  card: {
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    paddingBottom: 10,
    width: 445
  },
  pos: {
    marginBottom: 2
  },
  title: {
    fontSize: 14
  }
})

interface Props extends WithStyles<typeof styles> {
  exportDepositsBalanceToDate: any
}

const Tools = (props: Props) => {
  const { classes } = props

  return (
    <DefaultLayout
      title={`Nástroje`}
      tabs={[]}
      activeTab={0}
    >
      <div className={classes.wrapper}>
        <div className={classes.root}>
          <Card className={classes.card}>
            <CardContent>
              <div style={{ display: `flex`, flexDirection: `row` }}>
                <div style={{ width: `55px`, paddingTop: `3px` }}>
                  <FontAwesomeIcon icon={['fal', 'download']} size='2x' fixedWidth={true} />
                </div>
                <div>
                  <Typography variant="h5" component="h2">
                    Stav záloh na účtu
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    Exportuje stav záloh na bankovním účtu<br />ke zvolenému dni.
                  </Typography>
                </div>
              </div>

            </CardContent>
            <CardActions style={{ paddingLeft: `65px` }}>
              <Button size="small" variant="contained" color="secondary" onClick={() => props.exportDepositsBalanceToDate('2019-12-31')}>Stáhnout XLS</Button> 
            </CardActions>
          </Card>
        </div>
      </div>
      <WaitDialog />
    </DefaultLayout>
  )
}

const mapStateToProps = (state: any) => ({
})

const mapDispatchToProps = (dispatch: any) => ({
  exportDepositsBalanceToDate: (date: any) => dispatch(ToolsActions.exportDepositsBalanceToDate(date)),
})

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(Tools)
)
