import React, { useEffect, useState } from 'react'

import { Form } from 'react-final-form'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import { format } from 'date-fns'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import FormGroup from 'Components/_Common/Form/Group'
import FormLayout from 'Components/_Common/Form/Layout'

const styles = (theme: Theme) => createStyles({
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },

  sliderWrapper: {
    // marginTop: `13px`,
    padding: `15px`
  },

  grow: {
    flexGrow: 1,
  },
  appBar: {
    bottom: 0,
    height: 66,
    justifyContent: 'center',
    top: 'auto'
  }
})

interface Props extends WithStyles<typeof styles> {
  data: object | undefined,
  loading?: boolean
}

const EmailForm = (props: Props) => {
  const { classes, data, loading } = props

  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    const _initialValues = Object.assign({ // tslint:disable-line
      
    }, props.data) as any
  
    setInitialValues(_initialValues)
  }, [props.data])

  const onSubmit = (input: any): void => {
    // @todo
  }

  const renderForm = ({ form: { mutators }, handleSubmit, reset, submitting, pristine, invalid, values }: any) => {
    const resizeIframe = (e: any): void => {
      const iframe = e.target
  
      if (iframe) {
        const iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow
        if (iframeWin.document.body) {
          iframe.height = iframeWin.document.documentElement.scrollHeight || iframeWin.document.body.scrollHeight
        }
      }
    }

    const getMessageHtml = (): any => {
      return String(values.bodyHtml).replace('cid:gclogo', '/leh.png')
    }

    return (
      <form onSubmit={handleSubmit}>

        <FormGroup title='Detaily'>
          <Grid container={true} direction='row'>
            <Grid item={true} xs={6}>
              <Typography variant='h6' color='secondary'>
                Základní údaje
              </Typography>

              <List component='nav' style={{ marginLeft: `-15px` }} disablePadding={true}>
                <ListItem>
                  <ListItemText
                    primary={values.from}
                    secondary='Odesílatel'
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={values.receivedAt ? format(new Date(values.receivedAt), 'dd.MM.yyyy') : ` - `}
                    secondary='Odesláno'
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={values.to && values.to instanceof Array ? values.to.join(', ') : ` - `}
                    secondary='Příjemce'
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={values.subject}
                    secondary='Předmět'
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={values.status}
                    secondary='Stav'
                  />
                </ListItem>
              </List>
            </Grid>

            <Grid item={true} xs={6}>
              <Typography variant='h6' color='secondary'>
                Události
              </Typography>

              <List component='nav' style={{ marginLeft: `-15px` }} disablePadding={true}>
                {values.events && values.events.items.map((event: any, i: number) => (
                  <React.Fragment key={i}>
                    <ListItem>
                      <ListItemText
                        primary={`${event.type}${event.receivedAt ? `, ${format(new Date(event.receivedAt), 'dd.MM.yyyy HH:mm')}` : ``}`}
                        secondary={<small>{event.details}</small>}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Grid>
          </Grid>
        </FormGroup>

        <FormGroup title='Zpráva' defaultExpanded={false}>
          <Grid container={true} direction='row'>
            <Grid item={true} xs={12}>
              <iframe title="messageBody" width="100%" height="1000" frameBorder={0} scrolling="no" onLoad={resizeIframe} srcDoc={getMessageHtml()} />
            </Grid>
          </Grid>
        </FormGroup>

        <FormGroup title='Přílohy' defaultExpanded={false}>
          <Grid container={true} direction='row'>
            <Grid item={true} xs={12}>
              &nbsp;
            </Grid>
          </Grid>
        </FormGroup>

        <AppBar color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              &nbsp;
            </Typography>
            &nbsp;
            {<Button type='submit' variant={`outlined`} size="large" color='secondary' disabled={true}>
              Uložit email
            </Button>}
          </Toolbar>
        </AppBar>
      </form>
    )
  }

  return (
    <FormLayout title={loading === false && data !== undefined && 'subject' in data ? (data as any).subject : `Načítám detail emailu`}>
      {loading && <div style={{ position: `absolute`, width: `444px`, display: `flex`, justifyContent: `center`, alignItems: `center`, marginTop: `-44px` }}>
        <CircularProgress />
      </div>}
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={renderForm}
      />
    </FormLayout>
  )
}

export default withStyles(styles)(EmailForm)
