// tslint:disable
// this is an auto generated file. This will be overwritten

export const createClient = `mutation CreateClient($input: CreateClientInput!) {
  createClient(input: $input) {
    id
    benefitId
    isFs
    cognitoUserId
    owner
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    titleBefore
    firstname
    lastname
    titleAfter
    personalNumber
    birthDate
    email
    phone
    addressFormatted
    addressStreet
    addressStreetNumber
    addressZip
    addressTown
    hasDifferentDeliveryAddress
    deliveryTitleBefore
    deliveryFirstname
    deliveryLastname
    deliveryTitleAfter
    deliveryCompanyName
    deliveryPhone
    deliveryBirthYear
    deliveryAddressFormatted
    deliveryAddressStreet
    deliveryAddressStreetNumber
    deliveryAddressZip
    deliveryAddressTown
    bankAccountNumber
    bankCode
    status
    contracts {
      items {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      nextToken
    }
    emails {
      items {
        id
        receivedAt
        from
        to
        cc
        bcc
        recipients
        subject
        body
        bodyText
        bodyHtml
        attachments
        status
        version
      }
      nextToken
    }
    invoices {
      items {
        id
        type
        invoiceNumber
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientCompanyName
        clientCompanyNumber
        clientCompanyNumberVat
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        textAboveItems
        textBelowItems
        price
        dateOfIssue
        dueDate
        variableSymbol
        constantSymbol
        specificSymbol
        status
        version
      }
      nextToken
    }
    payments {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        statement
        date
        transactionType
        reference
        bankReference
        amount
        currency
        details
        extraDetails
        bankAccountName
        bankAccountNumber
        bankAccountNumberWithBankCode
        bankCode
        variableSymbol
        constantSymbol
        specificSymbol
        message
        notes
        status
        partClient
        partCommission
        partGoldCoins
        commissionExportedAt
        version
      }
      nextToken
    }
    distributions {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        price
        status
        version
      }
      nextToken
    }
    version
  }
}
`;
export const updateClient = `mutation UpdateClient($input: UpdateClientInput!) {
  updateClient(input: $input) {
    id
    benefitId
    isFs
    cognitoUserId
    owner
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    titleBefore
    firstname
    lastname
    titleAfter
    personalNumber
    birthDate
    email
    phone
    addressFormatted
    addressStreet
    addressStreetNumber
    addressZip
    addressTown
    hasDifferentDeliveryAddress
    deliveryTitleBefore
    deliveryFirstname
    deliveryLastname
    deliveryTitleAfter
    deliveryCompanyName
    deliveryPhone
    deliveryBirthYear
    deliveryAddressFormatted
    deliveryAddressStreet
    deliveryAddressStreetNumber
    deliveryAddressZip
    deliveryAddressTown
    bankAccountNumber
    bankCode
    status
    contracts {
      items {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      nextToken
    }
    emails {
      items {
        id
        receivedAt
        from
        to
        cc
        bcc
        recipients
        subject
        body
        bodyText
        bodyHtml
        attachments
        status
        version
      }
      nextToken
    }
    invoices {
      items {
        id
        type
        invoiceNumber
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientCompanyName
        clientCompanyNumber
        clientCompanyNumberVat
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        textAboveItems
        textBelowItems
        price
        dateOfIssue
        dueDate
        variableSymbol
        constantSymbol
        specificSymbol
        status
        version
      }
      nextToken
    }
    payments {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        statement
        date
        transactionType
        reference
        bankReference
        amount
        currency
        details
        extraDetails
        bankAccountName
        bankAccountNumber
        bankAccountNumberWithBankCode
        bankCode
        variableSymbol
        constantSymbol
        specificSymbol
        message
        notes
        status
        partClient
        partCommission
        partGoldCoins
        commissionExportedAt
        version
      }
      nextToken
    }
    distributions {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        price
        status
        version
      }
      nextToken
    }
    version
  }
}
`;
export const deleteClient = `mutation DeleteClient($input: DeleteClientInput!) {
  deleteClient(input: $input) {
    id
    benefitId
    isFs
    cognitoUserId
    owner
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    titleBefore
    firstname
    lastname
    titleAfter
    personalNumber
    birthDate
    email
    phone
    addressFormatted
    addressStreet
    addressStreetNumber
    addressZip
    addressTown
    hasDifferentDeliveryAddress
    deliveryTitleBefore
    deliveryFirstname
    deliveryLastname
    deliveryTitleAfter
    deliveryCompanyName
    deliveryPhone
    deliveryBirthYear
    deliveryAddressFormatted
    deliveryAddressStreet
    deliveryAddressStreetNumber
    deliveryAddressZip
    deliveryAddressTown
    bankAccountNumber
    bankCode
    status
    contracts {
      items {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      nextToken
    }
    emails {
      items {
        id
        receivedAt
        from
        to
        cc
        bcc
        recipients
        subject
        body
        bodyText
        bodyHtml
        attachments
        status
        version
      }
      nextToken
    }
    invoices {
      items {
        id
        type
        invoiceNumber
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientCompanyName
        clientCompanyNumber
        clientCompanyNumberVat
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        textAboveItems
        textBelowItems
        price
        dateOfIssue
        dueDate
        variableSymbol
        constantSymbol
        specificSymbol
        status
        version
      }
      nextToken
    }
    payments {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        statement
        date
        transactionType
        reference
        bankReference
        amount
        currency
        details
        extraDetails
        bankAccountName
        bankAccountNumber
        bankAccountNumberWithBankCode
        bankCode
        variableSymbol
        constantSymbol
        specificSymbol
        message
        notes
        status
        partClient
        partCommission
        partGoldCoins
        commissionExportedAt
        version
      }
      nextToken
    }
    distributions {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        price
        status
        version
      }
      nextToken
    }
    version
  }
}
`;
export const createContract = `mutation CreateContract($input: CreateContractInput!) {
  createContract(input: $input) {
    id
    contractNumber
    owner
    createdAt
    createdBy
    updatedAt
    updatedBy
    signedAt
    contractSentAt
    exportedAt
    lastPaymentReceivedAt
    deleted
    deletedAt
    deletedBy
    monthlyAmount
    totalYears
    emission1
    emission2
    firstDeliveryDate
    actualDeliveryEntitlementDate
    firstEmission {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      type
      name
      description
      yearFrom
      yearTo
      coins {
        nextToken
      }
      stock {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    firstCoin {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      emission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      value
      denominationMotif
      description
      fineness
      diameter
      thickness
      weightFine
      weightGross
      decreeNo
      mint
      designer
      dateOfIssue
      mintageLimitTotal
      mintageLimitNormal
      mintageLimitProof
      normalEdge
      normalActualMintage
      normalPriceCalculated
      normalPriceActual
      proofEdge
      proofActualMintage
      proofPriceCalculated
      proofPriceActual
      stock {
        nextToken
      }
      distributions {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    client {
      id
      benefitId
      isFs
      cognitoUserId
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      titleBefore
      firstname
      lastname
      titleAfter
      personalNumber
      birthDate
      email
      phone
      addressFormatted
      addressStreet
      addressStreetNumber
      addressZip
      addressTown
      hasDifferentDeliveryAddress
      deliveryTitleBefore
      deliveryFirstname
      deliveryLastname
      deliveryTitleAfter
      deliveryCompanyName
      deliveryPhone
      deliveryBirthYear
      deliveryAddressFormatted
      deliveryAddressStreet
      deliveryAddressStreetNumber
      deliveryAddressZip
      deliveryAddressTown
      bankAccountNumber
      bankCode
      status
      contracts {
        nextToken
      }
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    clientBenefitId
    clientTitleBefore
    clientFirstname
    clientLastname
    clientTitleAfter
    clientPersonalNumber
    clientBirthDate
    clientEmail
    clientPhone
    clientAddressFormatted
    clientAddressStreet
    clientAddressStreetNumber
    clientAddressZip
    clientAddressTown
    clientHasDifferentDeliveryAddress
    clientDeliveryTitleBefore
    clientDeliveryFirstname
    clientDeliveryLastname
    clientDeliveryTitleAfter
    clientDeliveryCompanyName
    clientDeliveryPhone
    clientDeliveryBirthYear
    clientDeliveryAddressFormatted
    clientDeliveryAddressStreet
    clientDeliveryAddressStreetNumber
    clientDeliveryAddressZip
    clientDeliveryAddressTown
    clientBankAccountNumber
    clientBankCode
    clientBankAccountOwner
    clientBankAccountOwnerRelation
    defaultPriceOfCoin
    salesMargin
    entryFeePerCoin
    priceOfCoin
    estimatedValue
    numberOfCoins
    qualityOfCoins
    entryPriceIncrease
    entryPriceIncreaseOneTimePayment
    entryPriceIncreaseIsPaid
    entryPriceIncreaseWasPaidAt
    entryPriceIncreasePaidAmount
    entryPriceIncreaseInvoiceId
    entryPriceIncreaseInvoiceNumber
    indicativeTargetAmount
    paymentsAmount
    distributionsAmount
    savedAmount
    status
    emails {
      items {
        id
        receivedAt
        from
        to
        cc
        bcc
        recipients
        subject
        body
        bodyText
        bodyHtml
        attachments
        status
        version
      }
      nextToken
    }
    invoices {
      items {
        id
        type
        invoiceNumber
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientCompanyName
        clientCompanyNumber
        clientCompanyNumberVat
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        textAboveItems
        textBelowItems
        price
        dateOfIssue
        dueDate
        variableSymbol
        constantSymbol
        specificSymbol
        status
        version
      }
      nextToken
    }
    payments {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        statement
        date
        transactionType
        reference
        bankReference
        amount
        currency
        details
        extraDetails
        bankAccountName
        bankAccountNumber
        bankAccountNumberWithBankCode
        bankCode
        variableSymbol
        constantSymbol
        specificSymbol
        message
        notes
        status
        partClient
        partCommission
        partGoldCoins
        commissionExportedAt
        version
      }
      nextToken
    }
    distributions {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        price
        status
        version
      }
      nextToken
    }
    version
  }
}
`;
export const updateContract = `mutation UpdateContract($input: UpdateContractInput!) {
  updateContract(input: $input) {
    id
    contractNumber
    owner
    createdAt
    createdBy
    updatedAt
    updatedBy
    signedAt
    contractSentAt
    exportedAt
    lastPaymentReceivedAt
    deleted
    deletedAt
    deletedBy
    monthlyAmount
    totalYears
    emission1
    emission2
    firstDeliveryDate
    actualDeliveryEntitlementDate
    firstEmission {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      type
      name
      description
      yearFrom
      yearTo
      coins {
        nextToken
      }
      stock {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    firstCoin {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      emission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      value
      denominationMotif
      description
      fineness
      diameter
      thickness
      weightFine
      weightGross
      decreeNo
      mint
      designer
      dateOfIssue
      mintageLimitTotal
      mintageLimitNormal
      mintageLimitProof
      normalEdge
      normalActualMintage
      normalPriceCalculated
      normalPriceActual
      proofEdge
      proofActualMintage
      proofPriceCalculated
      proofPriceActual
      stock {
        nextToken
      }
      distributions {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    client {
      id
      benefitId
      isFs
      cognitoUserId
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      titleBefore
      firstname
      lastname
      titleAfter
      personalNumber
      birthDate
      email
      phone
      addressFormatted
      addressStreet
      addressStreetNumber
      addressZip
      addressTown
      hasDifferentDeliveryAddress
      deliveryTitleBefore
      deliveryFirstname
      deliveryLastname
      deliveryTitleAfter
      deliveryCompanyName
      deliveryPhone
      deliveryBirthYear
      deliveryAddressFormatted
      deliveryAddressStreet
      deliveryAddressStreetNumber
      deliveryAddressZip
      deliveryAddressTown
      bankAccountNumber
      bankCode
      status
      contracts {
        nextToken
      }
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    clientBenefitId
    clientTitleBefore
    clientFirstname
    clientLastname
    clientTitleAfter
    clientPersonalNumber
    clientBirthDate
    clientEmail
    clientPhone
    clientAddressFormatted
    clientAddressStreet
    clientAddressStreetNumber
    clientAddressZip
    clientAddressTown
    clientHasDifferentDeliveryAddress
    clientDeliveryTitleBefore
    clientDeliveryFirstname
    clientDeliveryLastname
    clientDeliveryTitleAfter
    clientDeliveryCompanyName
    clientDeliveryPhone
    clientDeliveryBirthYear
    clientDeliveryAddressFormatted
    clientDeliveryAddressStreet
    clientDeliveryAddressStreetNumber
    clientDeliveryAddressZip
    clientDeliveryAddressTown
    clientBankAccountNumber
    clientBankCode
    clientBankAccountOwner
    clientBankAccountOwnerRelation
    defaultPriceOfCoin
    salesMargin
    entryFeePerCoin
    priceOfCoin
    estimatedValue
    numberOfCoins
    qualityOfCoins
    entryPriceIncrease
    entryPriceIncreaseOneTimePayment
    entryPriceIncreaseIsPaid
    entryPriceIncreaseWasPaidAt
    entryPriceIncreasePaidAmount
    entryPriceIncreaseInvoiceId
    entryPriceIncreaseInvoiceNumber
    indicativeTargetAmount
    paymentsAmount
    distributionsAmount
    savedAmount
    status
    emails {
      items {
        id
        receivedAt
        from
        to
        cc
        bcc
        recipients
        subject
        body
        bodyText
        bodyHtml
        attachments
        status
        version
      }
      nextToken
    }
    invoices {
      items {
        id
        type
        invoiceNumber
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientCompanyName
        clientCompanyNumber
        clientCompanyNumberVat
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        textAboveItems
        textBelowItems
        price
        dateOfIssue
        dueDate
        variableSymbol
        constantSymbol
        specificSymbol
        status
        version
      }
      nextToken
    }
    payments {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        statement
        date
        transactionType
        reference
        bankReference
        amount
        currency
        details
        extraDetails
        bankAccountName
        bankAccountNumber
        bankAccountNumberWithBankCode
        bankCode
        variableSymbol
        constantSymbol
        specificSymbol
        message
        notes
        status
        partClient
        partCommission
        partGoldCoins
        commissionExportedAt
        version
      }
      nextToken
    }
    distributions {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        price
        status
        version
      }
      nextToken
    }
    version
  }
}
`;
export const deleteContract = `mutation DeleteContract($input: DeleteContractInput!) {
  deleteContract(input: $input) {
    id
    contractNumber
    owner
    createdAt
    createdBy
    updatedAt
    updatedBy
    signedAt
    contractSentAt
    exportedAt
    lastPaymentReceivedAt
    deleted
    deletedAt
    deletedBy
    monthlyAmount
    totalYears
    emission1
    emission2
    firstDeliveryDate
    actualDeliveryEntitlementDate
    firstEmission {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      type
      name
      description
      yearFrom
      yearTo
      coins {
        nextToken
      }
      stock {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    firstCoin {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      emission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      value
      denominationMotif
      description
      fineness
      diameter
      thickness
      weightFine
      weightGross
      decreeNo
      mint
      designer
      dateOfIssue
      mintageLimitTotal
      mintageLimitNormal
      mintageLimitProof
      normalEdge
      normalActualMintage
      normalPriceCalculated
      normalPriceActual
      proofEdge
      proofActualMintage
      proofPriceCalculated
      proofPriceActual
      stock {
        nextToken
      }
      distributions {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    client {
      id
      benefitId
      isFs
      cognitoUserId
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      titleBefore
      firstname
      lastname
      titleAfter
      personalNumber
      birthDate
      email
      phone
      addressFormatted
      addressStreet
      addressStreetNumber
      addressZip
      addressTown
      hasDifferentDeliveryAddress
      deliveryTitleBefore
      deliveryFirstname
      deliveryLastname
      deliveryTitleAfter
      deliveryCompanyName
      deliveryPhone
      deliveryBirthYear
      deliveryAddressFormatted
      deliveryAddressStreet
      deliveryAddressStreetNumber
      deliveryAddressZip
      deliveryAddressTown
      bankAccountNumber
      bankCode
      status
      contracts {
        nextToken
      }
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    clientBenefitId
    clientTitleBefore
    clientFirstname
    clientLastname
    clientTitleAfter
    clientPersonalNumber
    clientBirthDate
    clientEmail
    clientPhone
    clientAddressFormatted
    clientAddressStreet
    clientAddressStreetNumber
    clientAddressZip
    clientAddressTown
    clientHasDifferentDeliveryAddress
    clientDeliveryTitleBefore
    clientDeliveryFirstname
    clientDeliveryLastname
    clientDeliveryTitleAfter
    clientDeliveryCompanyName
    clientDeliveryPhone
    clientDeliveryBirthYear
    clientDeliveryAddressFormatted
    clientDeliveryAddressStreet
    clientDeliveryAddressStreetNumber
    clientDeliveryAddressZip
    clientDeliveryAddressTown
    clientBankAccountNumber
    clientBankCode
    clientBankAccountOwner
    clientBankAccountOwnerRelation
    defaultPriceOfCoin
    salesMargin
    entryFeePerCoin
    priceOfCoin
    estimatedValue
    numberOfCoins
    qualityOfCoins
    entryPriceIncrease
    entryPriceIncreaseOneTimePayment
    entryPriceIncreaseIsPaid
    entryPriceIncreaseWasPaidAt
    entryPriceIncreasePaidAmount
    entryPriceIncreaseInvoiceId
    entryPriceIncreaseInvoiceNumber
    indicativeTargetAmount
    paymentsAmount
    distributionsAmount
    savedAmount
    status
    emails {
      items {
        id
        receivedAt
        from
        to
        cc
        bcc
        recipients
        subject
        body
        bodyText
        bodyHtml
        attachments
        status
        version
      }
      nextToken
    }
    invoices {
      items {
        id
        type
        invoiceNumber
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientCompanyName
        clientCompanyNumber
        clientCompanyNumberVat
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        textAboveItems
        textBelowItems
        price
        dateOfIssue
        dueDate
        variableSymbol
        constantSymbol
        specificSymbol
        status
        version
      }
      nextToken
    }
    payments {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        statement
        date
        transactionType
        reference
        bankReference
        amount
        currency
        details
        extraDetails
        bankAccountName
        bankAccountNumber
        bankAccountNumberWithBankCode
        bankCode
        variableSymbol
        constantSymbol
        specificSymbol
        message
        notes
        status
        partClient
        partCommission
        partGoldCoins
        commissionExportedAt
        version
      }
      nextToken
    }
    distributions {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        price
        status
        version
      }
      nextToken
    }
    version
  }
}
`;
export const createPayment = `mutation CreatePayment($input: CreatePaymentInput!) {
  createPayment(input: $input) {
    id
    owners
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    statement
    client {
      id
      benefitId
      isFs
      cognitoUserId
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      titleBefore
      firstname
      lastname
      titleAfter
      personalNumber
      birthDate
      email
      phone
      addressFormatted
      addressStreet
      addressStreetNumber
      addressZip
      addressTown
      hasDifferentDeliveryAddress
      deliveryTitleBefore
      deliveryFirstname
      deliveryLastname
      deliveryTitleAfter
      deliveryCompanyName
      deliveryPhone
      deliveryBirthYear
      deliveryAddressFormatted
      deliveryAddressStreet
      deliveryAddressStreetNumber
      deliveryAddressZip
      deliveryAddressTown
      bankAccountNumber
      bankCode
      status
      contracts {
        nextToken
      }
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    contract {
      id
      contractNumber
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      signedAt
      contractSentAt
      exportedAt
      lastPaymentReceivedAt
      deleted
      deletedAt
      deletedBy
      monthlyAmount
      totalYears
      emission1
      emission2
      firstDeliveryDate
      actualDeliveryEntitlementDate
      firstEmission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      firstCoin {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      clientBenefitId
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      clientBankAccountOwner
      clientBankAccountOwnerRelation
      defaultPriceOfCoin
      salesMargin
      entryFeePerCoin
      priceOfCoin
      estimatedValue
      numberOfCoins
      qualityOfCoins
      entryPriceIncrease
      entryPriceIncreaseOneTimePayment
      entryPriceIncreaseIsPaid
      entryPriceIncreaseWasPaidAt
      entryPriceIncreasePaidAmount
      entryPriceIncreaseInvoiceId
      entryPriceIncreaseInvoiceNumber
      indicativeTargetAmount
      paymentsAmount
      distributionsAmount
      savedAmount
      status
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    invoice {
      id
      type
      invoiceNumber
      owners
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      contract {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      stock {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        series
        purchasedAt
        pricePurchase
        pricePurchasePaidWith
        priceSelling
        commission
        status
        sold
        soldAt
        version
      }
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientCompanyName
      clientCompanyNumber
      clientCompanyNumberVat
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      textAboveItems
      items {
        stockId
        text
        price
      }
      textBelowItems
      price
      dateOfIssue
      dueDate
      variableSymbol
      constantSymbol
      specificSymbol
      status
      payments {
        nextToken
      }
      commissionsRelatedPayments {
        nextToken
      }
      version
    }
    commissionsRelatedInvoice {
      id
      type
      invoiceNumber
      owners
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      contract {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      stock {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        series
        purchasedAt
        pricePurchase
        pricePurchasePaidWith
        priceSelling
        commission
        status
        sold
        soldAt
        version
      }
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientCompanyName
      clientCompanyNumber
      clientCompanyNumberVat
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      textAboveItems
      items {
        stockId
        text
        price
      }
      textBelowItems
      price
      dateOfIssue
      dueDate
      variableSymbol
      constantSymbol
      specificSymbol
      status
      payments {
        nextToken
      }
      commissionsRelatedPayments {
        nextToken
      }
      version
    }
    date
    transactionType
    reference
    bankReference
    amount
    currency
    details
    extraDetails
    bankAccountName
    bankAccountNumber
    bankAccountNumberWithBankCode
    bankCode
    variableSymbol
    constantSymbol
    specificSymbol
    message
    notes
    status
    partClient
    partCommission
    partGoldCoins
    commissionExportedAt
    version
  }
}
`;
export const updatePayment = `mutation UpdatePayment($input: UpdatePaymentInput!) {
  updatePayment(input: $input) {
    id
    owners
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    statement
    client {
      id
      benefitId
      isFs
      cognitoUserId
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      titleBefore
      firstname
      lastname
      titleAfter
      personalNumber
      birthDate
      email
      phone
      addressFormatted
      addressStreet
      addressStreetNumber
      addressZip
      addressTown
      hasDifferentDeliveryAddress
      deliveryTitleBefore
      deliveryFirstname
      deliveryLastname
      deliveryTitleAfter
      deliveryCompanyName
      deliveryPhone
      deliveryBirthYear
      deliveryAddressFormatted
      deliveryAddressStreet
      deliveryAddressStreetNumber
      deliveryAddressZip
      deliveryAddressTown
      bankAccountNumber
      bankCode
      status
      contracts {
        nextToken
      }
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    contract {
      id
      contractNumber
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      signedAt
      contractSentAt
      exportedAt
      lastPaymentReceivedAt
      deleted
      deletedAt
      deletedBy
      monthlyAmount
      totalYears
      emission1
      emission2
      firstDeliveryDate
      actualDeliveryEntitlementDate
      firstEmission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      firstCoin {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      clientBenefitId
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      clientBankAccountOwner
      clientBankAccountOwnerRelation
      defaultPriceOfCoin
      salesMargin
      entryFeePerCoin
      priceOfCoin
      estimatedValue
      numberOfCoins
      qualityOfCoins
      entryPriceIncrease
      entryPriceIncreaseOneTimePayment
      entryPriceIncreaseIsPaid
      entryPriceIncreaseWasPaidAt
      entryPriceIncreasePaidAmount
      entryPriceIncreaseInvoiceId
      entryPriceIncreaseInvoiceNumber
      indicativeTargetAmount
      paymentsAmount
      distributionsAmount
      savedAmount
      status
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    invoice {
      id
      type
      invoiceNumber
      owners
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      contract {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      stock {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        series
        purchasedAt
        pricePurchase
        pricePurchasePaidWith
        priceSelling
        commission
        status
        sold
        soldAt
        version
      }
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientCompanyName
      clientCompanyNumber
      clientCompanyNumberVat
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      textAboveItems
      items {
        stockId
        text
        price
      }
      textBelowItems
      price
      dateOfIssue
      dueDate
      variableSymbol
      constantSymbol
      specificSymbol
      status
      payments {
        nextToken
      }
      commissionsRelatedPayments {
        nextToken
      }
      version
    }
    commissionsRelatedInvoice {
      id
      type
      invoiceNumber
      owners
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      contract {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      stock {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        series
        purchasedAt
        pricePurchase
        pricePurchasePaidWith
        priceSelling
        commission
        status
        sold
        soldAt
        version
      }
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientCompanyName
      clientCompanyNumber
      clientCompanyNumberVat
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      textAboveItems
      items {
        stockId
        text
        price
      }
      textBelowItems
      price
      dateOfIssue
      dueDate
      variableSymbol
      constantSymbol
      specificSymbol
      status
      payments {
        nextToken
      }
      commissionsRelatedPayments {
        nextToken
      }
      version
    }
    date
    transactionType
    reference
    bankReference
    amount
    currency
    details
    extraDetails
    bankAccountName
    bankAccountNumber
    bankAccountNumberWithBankCode
    bankCode
    variableSymbol
    constantSymbol
    specificSymbol
    message
    notes
    status
    partClient
    partCommission
    partGoldCoins
    commissionExportedAt
    version
  }
}
`;
export const deletePayment = `mutation DeletePayment($input: DeletePaymentInput!) {
  deletePayment(input: $input) {
    id
    owners
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    statement
    client {
      id
      benefitId
      isFs
      cognitoUserId
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      titleBefore
      firstname
      lastname
      titleAfter
      personalNumber
      birthDate
      email
      phone
      addressFormatted
      addressStreet
      addressStreetNumber
      addressZip
      addressTown
      hasDifferentDeliveryAddress
      deliveryTitleBefore
      deliveryFirstname
      deliveryLastname
      deliveryTitleAfter
      deliveryCompanyName
      deliveryPhone
      deliveryBirthYear
      deliveryAddressFormatted
      deliveryAddressStreet
      deliveryAddressStreetNumber
      deliveryAddressZip
      deliveryAddressTown
      bankAccountNumber
      bankCode
      status
      contracts {
        nextToken
      }
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    contract {
      id
      contractNumber
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      signedAt
      contractSentAt
      exportedAt
      lastPaymentReceivedAt
      deleted
      deletedAt
      deletedBy
      monthlyAmount
      totalYears
      emission1
      emission2
      firstDeliveryDate
      actualDeliveryEntitlementDate
      firstEmission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      firstCoin {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      clientBenefitId
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      clientBankAccountOwner
      clientBankAccountOwnerRelation
      defaultPriceOfCoin
      salesMargin
      entryFeePerCoin
      priceOfCoin
      estimatedValue
      numberOfCoins
      qualityOfCoins
      entryPriceIncrease
      entryPriceIncreaseOneTimePayment
      entryPriceIncreaseIsPaid
      entryPriceIncreaseWasPaidAt
      entryPriceIncreasePaidAmount
      entryPriceIncreaseInvoiceId
      entryPriceIncreaseInvoiceNumber
      indicativeTargetAmount
      paymentsAmount
      distributionsAmount
      savedAmount
      status
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    invoice {
      id
      type
      invoiceNumber
      owners
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      contract {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      stock {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        series
        purchasedAt
        pricePurchase
        pricePurchasePaidWith
        priceSelling
        commission
        status
        sold
        soldAt
        version
      }
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientCompanyName
      clientCompanyNumber
      clientCompanyNumberVat
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      textAboveItems
      items {
        stockId
        text
        price
      }
      textBelowItems
      price
      dateOfIssue
      dueDate
      variableSymbol
      constantSymbol
      specificSymbol
      status
      payments {
        nextToken
      }
      commissionsRelatedPayments {
        nextToken
      }
      version
    }
    commissionsRelatedInvoice {
      id
      type
      invoiceNumber
      owners
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      contract {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      stock {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        series
        purchasedAt
        pricePurchase
        pricePurchasePaidWith
        priceSelling
        commission
        status
        sold
        soldAt
        version
      }
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientCompanyName
      clientCompanyNumber
      clientCompanyNumberVat
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      textAboveItems
      items {
        stockId
        text
        price
      }
      textBelowItems
      price
      dateOfIssue
      dueDate
      variableSymbol
      constantSymbol
      specificSymbol
      status
      payments {
        nextToken
      }
      commissionsRelatedPayments {
        nextToken
      }
      version
    }
    date
    transactionType
    reference
    bankReference
    amount
    currency
    details
    extraDetails
    bankAccountName
    bankAccountNumber
    bankAccountNumberWithBankCode
    bankCode
    variableSymbol
    constantSymbol
    specificSymbol
    message
    notes
    status
    partClient
    partCommission
    partGoldCoins
    commissionExportedAt
    version
  }
}
`;
export const createEmail = `mutation CreateEmail($input: CreateEmailInput!) {
  createEmail(input: $input) {
    id
    client {
      id
      benefitId
      isFs
      cognitoUserId
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      titleBefore
      firstname
      lastname
      titleAfter
      personalNumber
      birthDate
      email
      phone
      addressFormatted
      addressStreet
      addressStreetNumber
      addressZip
      addressTown
      hasDifferentDeliveryAddress
      deliveryTitleBefore
      deliveryFirstname
      deliveryLastname
      deliveryTitleAfter
      deliveryCompanyName
      deliveryPhone
      deliveryBirthYear
      deliveryAddressFormatted
      deliveryAddressStreet
      deliveryAddressStreetNumber
      deliveryAddressZip
      deliveryAddressTown
      bankAccountNumber
      bankCode
      status
      contracts {
        nextToken
      }
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    contract {
      id
      contractNumber
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      signedAt
      contractSentAt
      exportedAt
      lastPaymentReceivedAt
      deleted
      deletedAt
      deletedBy
      monthlyAmount
      totalYears
      emission1
      emission2
      firstDeliveryDate
      actualDeliveryEntitlementDate
      firstEmission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      firstCoin {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      clientBenefitId
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      clientBankAccountOwner
      clientBankAccountOwnerRelation
      defaultPriceOfCoin
      salesMargin
      entryFeePerCoin
      priceOfCoin
      estimatedValue
      numberOfCoins
      qualityOfCoins
      entryPriceIncrease
      entryPriceIncreaseOneTimePayment
      entryPriceIncreaseIsPaid
      entryPriceIncreaseWasPaidAt
      entryPriceIncreasePaidAmount
      entryPriceIncreaseInvoiceId
      entryPriceIncreaseInvoiceNumber
      indicativeTargetAmount
      paymentsAmount
      distributionsAmount
      savedAmount
      status
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    receivedAt
    from
    to
    cc
    bcc
    recipients
    subject
    body
    bodyText
    bodyHtml
    attachments
    status
    events {
      items {
        id
        receivedAt
        recipient
        type
        details
        version
      }
      nextToken
    }
    version
  }
}
`;
export const updateEmail = `mutation UpdateEmail($input: UpdateEmailInput!) {
  updateEmail(input: $input) {
    id
    client {
      id
      benefitId
      isFs
      cognitoUserId
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      titleBefore
      firstname
      lastname
      titleAfter
      personalNumber
      birthDate
      email
      phone
      addressFormatted
      addressStreet
      addressStreetNumber
      addressZip
      addressTown
      hasDifferentDeliveryAddress
      deliveryTitleBefore
      deliveryFirstname
      deliveryLastname
      deliveryTitleAfter
      deliveryCompanyName
      deliveryPhone
      deliveryBirthYear
      deliveryAddressFormatted
      deliveryAddressStreet
      deliveryAddressStreetNumber
      deliveryAddressZip
      deliveryAddressTown
      bankAccountNumber
      bankCode
      status
      contracts {
        nextToken
      }
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    contract {
      id
      contractNumber
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      signedAt
      contractSentAt
      exportedAt
      lastPaymentReceivedAt
      deleted
      deletedAt
      deletedBy
      monthlyAmount
      totalYears
      emission1
      emission2
      firstDeliveryDate
      actualDeliveryEntitlementDate
      firstEmission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      firstCoin {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      clientBenefitId
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      clientBankAccountOwner
      clientBankAccountOwnerRelation
      defaultPriceOfCoin
      salesMargin
      entryFeePerCoin
      priceOfCoin
      estimatedValue
      numberOfCoins
      qualityOfCoins
      entryPriceIncrease
      entryPriceIncreaseOneTimePayment
      entryPriceIncreaseIsPaid
      entryPriceIncreaseWasPaidAt
      entryPriceIncreasePaidAmount
      entryPriceIncreaseInvoiceId
      entryPriceIncreaseInvoiceNumber
      indicativeTargetAmount
      paymentsAmount
      distributionsAmount
      savedAmount
      status
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    receivedAt
    from
    to
    cc
    bcc
    recipients
    subject
    body
    bodyText
    bodyHtml
    attachments
    status
    events {
      items {
        id
        receivedAt
        recipient
        type
        details
        version
      }
      nextToken
    }
    version
  }
}
`;
export const deleteEmail = `mutation DeleteEmail($input: DeleteEmailInput!) {
  deleteEmail(input: $input) {
    id
    client {
      id
      benefitId
      isFs
      cognitoUserId
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      titleBefore
      firstname
      lastname
      titleAfter
      personalNumber
      birthDate
      email
      phone
      addressFormatted
      addressStreet
      addressStreetNumber
      addressZip
      addressTown
      hasDifferentDeliveryAddress
      deliveryTitleBefore
      deliveryFirstname
      deliveryLastname
      deliveryTitleAfter
      deliveryCompanyName
      deliveryPhone
      deliveryBirthYear
      deliveryAddressFormatted
      deliveryAddressStreet
      deliveryAddressStreetNumber
      deliveryAddressZip
      deliveryAddressTown
      bankAccountNumber
      bankCode
      status
      contracts {
        nextToken
      }
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    contract {
      id
      contractNumber
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      signedAt
      contractSentAt
      exportedAt
      lastPaymentReceivedAt
      deleted
      deletedAt
      deletedBy
      monthlyAmount
      totalYears
      emission1
      emission2
      firstDeliveryDate
      actualDeliveryEntitlementDate
      firstEmission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      firstCoin {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      clientBenefitId
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      clientBankAccountOwner
      clientBankAccountOwnerRelation
      defaultPriceOfCoin
      salesMargin
      entryFeePerCoin
      priceOfCoin
      estimatedValue
      numberOfCoins
      qualityOfCoins
      entryPriceIncrease
      entryPriceIncreaseOneTimePayment
      entryPriceIncreaseIsPaid
      entryPriceIncreaseWasPaidAt
      entryPriceIncreasePaidAmount
      entryPriceIncreaseInvoiceId
      entryPriceIncreaseInvoiceNumber
      indicativeTargetAmount
      paymentsAmount
      distributionsAmount
      savedAmount
      status
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    receivedAt
    from
    to
    cc
    bcc
    recipients
    subject
    body
    bodyText
    bodyHtml
    attachments
    status
    events {
      items {
        id
        receivedAt
        recipient
        type
        details
        version
      }
      nextToken
    }
    version
  }
}
`;
export const createEmailEvent = `mutation CreateEmailEvent($input: CreateEmailEventInput!) {
  createEmailEvent(input: $input) {
    id
    receivedAt
    email {
      id
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      contract {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      receivedAt
      from
      to
      cc
      bcc
      recipients
      subject
      body
      bodyText
      bodyHtml
      attachments
      status
      events {
        nextToken
      }
      version
    }
    recipient
    type
    details
    version
  }
}
`;
export const updateEmailEvent = `mutation UpdateEmailEvent($input: UpdateEmailEventInput!) {
  updateEmailEvent(input: $input) {
    id
    receivedAt
    email {
      id
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      contract {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      receivedAt
      from
      to
      cc
      bcc
      recipients
      subject
      body
      bodyText
      bodyHtml
      attachments
      status
      events {
        nextToken
      }
      version
    }
    recipient
    type
    details
    version
  }
}
`;
export const deleteEmailEvent = `mutation DeleteEmailEvent($input: DeleteEmailEventInput!) {
  deleteEmailEvent(input: $input) {
    id
    receivedAt
    email {
      id
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      contract {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      receivedAt
      from
      to
      cc
      bcc
      recipients
      subject
      body
      bodyText
      bodyHtml
      attachments
      status
      events {
        nextToken
      }
      version
    }
    recipient
    type
    details
    version
  }
}
`;
export const createInvoice = `mutation CreateInvoice($input: CreateInvoiceInput!) {
  createInvoice(input: $input) {
    id
    type
    invoiceNumber
    owners
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    client {
      id
      benefitId
      isFs
      cognitoUserId
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      titleBefore
      firstname
      lastname
      titleAfter
      personalNumber
      birthDate
      email
      phone
      addressFormatted
      addressStreet
      addressStreetNumber
      addressZip
      addressTown
      hasDifferentDeliveryAddress
      deliveryTitleBefore
      deliveryFirstname
      deliveryLastname
      deliveryTitleAfter
      deliveryCompanyName
      deliveryPhone
      deliveryBirthYear
      deliveryAddressFormatted
      deliveryAddressStreet
      deliveryAddressStreetNumber
      deliveryAddressZip
      deliveryAddressTown
      bankAccountNumber
      bankCode
      status
      contracts {
        nextToken
      }
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    contract {
      id
      contractNumber
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      signedAt
      contractSentAt
      exportedAt
      lastPaymentReceivedAt
      deleted
      deletedAt
      deletedBy
      monthlyAmount
      totalYears
      emission1
      emission2
      firstDeliveryDate
      actualDeliveryEntitlementDate
      firstEmission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      firstCoin {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      clientBenefitId
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      clientBankAccountOwner
      clientBankAccountOwnerRelation
      defaultPriceOfCoin
      salesMargin
      entryFeePerCoin
      priceOfCoin
      estimatedValue
      numberOfCoins
      qualityOfCoins
      entryPriceIncrease
      entryPriceIncreaseOneTimePayment
      entryPriceIncreaseIsPaid
      entryPriceIncreaseWasPaidAt
      entryPriceIncreasePaidAmount
      entryPriceIncreaseInvoiceId
      entryPriceIncreaseInvoiceNumber
      indicativeTargetAmount
      paymentsAmount
      distributionsAmount
      savedAmount
      status
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    stock {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      emission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      coin {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      series
      purchasedAt
      pricePurchase
      pricePurchasePaidWith
      priceSelling
      commission
      status
      sold
      soldAt
      invoices {
        nextToken
      }
      version
    }
    clientTitleBefore
    clientFirstname
    clientLastname
    clientTitleAfter
    clientPersonalNumber
    clientBirthDate
    clientCompanyName
    clientCompanyNumber
    clientCompanyNumberVat
    clientEmail
    clientPhone
    clientAddressFormatted
    clientAddressStreet
    clientAddressStreetNumber
    clientAddressZip
    clientAddressTown
    clientHasDifferentDeliveryAddress
    clientDeliveryTitleBefore
    clientDeliveryFirstname
    clientDeliveryLastname
    clientDeliveryTitleAfter
    clientDeliveryCompanyName
    clientDeliveryPhone
    clientDeliveryBirthYear
    clientDeliveryAddressFormatted
    clientDeliveryAddressStreet
    clientDeliveryAddressStreetNumber
    clientDeliveryAddressZip
    clientDeliveryAddressTown
    clientBankAccountNumber
    clientBankCode
    textAboveItems
    items {
      stockId
      text
      price
    }
    textBelowItems
    price
    dateOfIssue
    dueDate
    variableSymbol
    constantSymbol
    specificSymbol
    status
    payments {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        statement
        date
        transactionType
        reference
        bankReference
        amount
        currency
        details
        extraDetails
        bankAccountName
        bankAccountNumber
        bankAccountNumberWithBankCode
        bankCode
        variableSymbol
        constantSymbol
        specificSymbol
        message
        notes
        status
        partClient
        partCommission
        partGoldCoins
        commissionExportedAt
        version
      }
      nextToken
    }
    commissionsRelatedPayments {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        statement
        date
        transactionType
        reference
        bankReference
        amount
        currency
        details
        extraDetails
        bankAccountName
        bankAccountNumber
        bankAccountNumberWithBankCode
        bankCode
        variableSymbol
        constantSymbol
        specificSymbol
        message
        notes
        status
        partClient
        partCommission
        partGoldCoins
        commissionExportedAt
        version
      }
      nextToken
    }
    version
  }
}
`;
export const updateInvoice = `mutation UpdateInvoice($input: UpdateInvoiceInput!) {
  updateInvoice(input: $input) {
    id
    type
    invoiceNumber
    owners
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    client {
      id
      benefitId
      isFs
      cognitoUserId
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      titleBefore
      firstname
      lastname
      titleAfter
      personalNumber
      birthDate
      email
      phone
      addressFormatted
      addressStreet
      addressStreetNumber
      addressZip
      addressTown
      hasDifferentDeliveryAddress
      deliveryTitleBefore
      deliveryFirstname
      deliveryLastname
      deliveryTitleAfter
      deliveryCompanyName
      deliveryPhone
      deliveryBirthYear
      deliveryAddressFormatted
      deliveryAddressStreet
      deliveryAddressStreetNumber
      deliveryAddressZip
      deliveryAddressTown
      bankAccountNumber
      bankCode
      status
      contracts {
        nextToken
      }
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    contract {
      id
      contractNumber
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      signedAt
      contractSentAt
      exportedAt
      lastPaymentReceivedAt
      deleted
      deletedAt
      deletedBy
      monthlyAmount
      totalYears
      emission1
      emission2
      firstDeliveryDate
      actualDeliveryEntitlementDate
      firstEmission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      firstCoin {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      clientBenefitId
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      clientBankAccountOwner
      clientBankAccountOwnerRelation
      defaultPriceOfCoin
      salesMargin
      entryFeePerCoin
      priceOfCoin
      estimatedValue
      numberOfCoins
      qualityOfCoins
      entryPriceIncrease
      entryPriceIncreaseOneTimePayment
      entryPriceIncreaseIsPaid
      entryPriceIncreaseWasPaidAt
      entryPriceIncreasePaidAmount
      entryPriceIncreaseInvoiceId
      entryPriceIncreaseInvoiceNumber
      indicativeTargetAmount
      paymentsAmount
      distributionsAmount
      savedAmount
      status
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    stock {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      emission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      coin {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      series
      purchasedAt
      pricePurchase
      pricePurchasePaidWith
      priceSelling
      commission
      status
      sold
      soldAt
      invoices {
        nextToken
      }
      version
    }
    clientTitleBefore
    clientFirstname
    clientLastname
    clientTitleAfter
    clientPersonalNumber
    clientBirthDate
    clientCompanyName
    clientCompanyNumber
    clientCompanyNumberVat
    clientEmail
    clientPhone
    clientAddressFormatted
    clientAddressStreet
    clientAddressStreetNumber
    clientAddressZip
    clientAddressTown
    clientHasDifferentDeliveryAddress
    clientDeliveryTitleBefore
    clientDeliveryFirstname
    clientDeliveryLastname
    clientDeliveryTitleAfter
    clientDeliveryCompanyName
    clientDeliveryPhone
    clientDeliveryBirthYear
    clientDeliveryAddressFormatted
    clientDeliveryAddressStreet
    clientDeliveryAddressStreetNumber
    clientDeliveryAddressZip
    clientDeliveryAddressTown
    clientBankAccountNumber
    clientBankCode
    textAboveItems
    items {
      stockId
      text
      price
    }
    textBelowItems
    price
    dateOfIssue
    dueDate
    variableSymbol
    constantSymbol
    specificSymbol
    status
    payments {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        statement
        date
        transactionType
        reference
        bankReference
        amount
        currency
        details
        extraDetails
        bankAccountName
        bankAccountNumber
        bankAccountNumberWithBankCode
        bankCode
        variableSymbol
        constantSymbol
        specificSymbol
        message
        notes
        status
        partClient
        partCommission
        partGoldCoins
        commissionExportedAt
        version
      }
      nextToken
    }
    commissionsRelatedPayments {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        statement
        date
        transactionType
        reference
        bankReference
        amount
        currency
        details
        extraDetails
        bankAccountName
        bankAccountNumber
        bankAccountNumberWithBankCode
        bankCode
        variableSymbol
        constantSymbol
        specificSymbol
        message
        notes
        status
        partClient
        partCommission
        partGoldCoins
        commissionExportedAt
        version
      }
      nextToken
    }
    version
  }
}
`;
export const deleteInvoice = `mutation DeleteInvoice($input: DeleteInvoiceInput!) {
  deleteInvoice(input: $input) {
    id
    type
    invoiceNumber
    owners
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    client {
      id
      benefitId
      isFs
      cognitoUserId
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      titleBefore
      firstname
      lastname
      titleAfter
      personalNumber
      birthDate
      email
      phone
      addressFormatted
      addressStreet
      addressStreetNumber
      addressZip
      addressTown
      hasDifferentDeliveryAddress
      deliveryTitleBefore
      deliveryFirstname
      deliveryLastname
      deliveryTitleAfter
      deliveryCompanyName
      deliveryPhone
      deliveryBirthYear
      deliveryAddressFormatted
      deliveryAddressStreet
      deliveryAddressStreetNumber
      deliveryAddressZip
      deliveryAddressTown
      bankAccountNumber
      bankCode
      status
      contracts {
        nextToken
      }
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    contract {
      id
      contractNumber
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      signedAt
      contractSentAt
      exportedAt
      lastPaymentReceivedAt
      deleted
      deletedAt
      deletedBy
      monthlyAmount
      totalYears
      emission1
      emission2
      firstDeliveryDate
      actualDeliveryEntitlementDate
      firstEmission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      firstCoin {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      clientBenefitId
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      clientBankAccountOwner
      clientBankAccountOwnerRelation
      defaultPriceOfCoin
      salesMargin
      entryFeePerCoin
      priceOfCoin
      estimatedValue
      numberOfCoins
      qualityOfCoins
      entryPriceIncrease
      entryPriceIncreaseOneTimePayment
      entryPriceIncreaseIsPaid
      entryPriceIncreaseWasPaidAt
      entryPriceIncreasePaidAmount
      entryPriceIncreaseInvoiceId
      entryPriceIncreaseInvoiceNumber
      indicativeTargetAmount
      paymentsAmount
      distributionsAmount
      savedAmount
      status
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    stock {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      emission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      coin {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      series
      purchasedAt
      pricePurchase
      pricePurchasePaidWith
      priceSelling
      commission
      status
      sold
      soldAt
      invoices {
        nextToken
      }
      version
    }
    clientTitleBefore
    clientFirstname
    clientLastname
    clientTitleAfter
    clientPersonalNumber
    clientBirthDate
    clientCompanyName
    clientCompanyNumber
    clientCompanyNumberVat
    clientEmail
    clientPhone
    clientAddressFormatted
    clientAddressStreet
    clientAddressStreetNumber
    clientAddressZip
    clientAddressTown
    clientHasDifferentDeliveryAddress
    clientDeliveryTitleBefore
    clientDeliveryFirstname
    clientDeliveryLastname
    clientDeliveryTitleAfter
    clientDeliveryCompanyName
    clientDeliveryPhone
    clientDeliveryBirthYear
    clientDeliveryAddressFormatted
    clientDeliveryAddressStreet
    clientDeliveryAddressStreetNumber
    clientDeliveryAddressZip
    clientDeliveryAddressTown
    clientBankAccountNumber
    clientBankCode
    textAboveItems
    items {
      stockId
      text
      price
    }
    textBelowItems
    price
    dateOfIssue
    dueDate
    variableSymbol
    constantSymbol
    specificSymbol
    status
    payments {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        statement
        date
        transactionType
        reference
        bankReference
        amount
        currency
        details
        extraDetails
        bankAccountName
        bankAccountNumber
        bankAccountNumberWithBankCode
        bankCode
        variableSymbol
        constantSymbol
        specificSymbol
        message
        notes
        status
        partClient
        partCommission
        partGoldCoins
        commissionExportedAt
        version
      }
      nextToken
    }
    commissionsRelatedPayments {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        statement
        date
        transactionType
        reference
        bankReference
        amount
        currency
        details
        extraDetails
        bankAccountName
        bankAccountNumber
        bankAccountNumberWithBankCode
        bankCode
        variableSymbol
        constantSymbol
        specificSymbol
        message
        notes
        status
        partClient
        partCommission
        partGoldCoins
        commissionExportedAt
        version
      }
      nextToken
    }
    version
  }
}
`;
export const createEmission = `mutation CreateEmission($input: CreateEmissionInput!) {
  createEmission(input: $input) {
    id
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    type
    name
    description
    yearFrom
    yearTo
    coins {
      items {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      nextToken
    }
    stock {
      items {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        series
        purchasedAt
        pricePurchase
        pricePurchasePaidWith
        priceSelling
        commission
        status
        sold
        soldAt
        version
      }
      nextToken
    }
    contractsFirst {
      items {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      nextToken
    }
    version
  }
}
`;
export const updateEmission = `mutation UpdateEmission($input: UpdateEmissionInput!) {
  updateEmission(input: $input) {
    id
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    type
    name
    description
    yearFrom
    yearTo
    coins {
      items {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      nextToken
    }
    stock {
      items {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        series
        purchasedAt
        pricePurchase
        pricePurchasePaidWith
        priceSelling
        commission
        status
        sold
        soldAt
        version
      }
      nextToken
    }
    contractsFirst {
      items {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      nextToken
    }
    version
  }
}
`;
export const deleteEmission = `mutation DeleteEmission($input: DeleteEmissionInput!) {
  deleteEmission(input: $input) {
    id
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    type
    name
    description
    yearFrom
    yearTo
    coins {
      items {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      nextToken
    }
    stock {
      items {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        series
        purchasedAt
        pricePurchase
        pricePurchasePaidWith
        priceSelling
        commission
        status
        sold
        soldAt
        version
      }
      nextToken
    }
    contractsFirst {
      items {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      nextToken
    }
    version
  }
}
`;
export const createCoin = `mutation CreateCoin($input: CreateCoinInput!) {
  createCoin(input: $input) {
    id
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    emission {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      type
      name
      description
      yearFrom
      yearTo
      coins {
        nextToken
      }
      stock {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    value
    denominationMotif
    description
    fineness
    diameter
    thickness
    weightFine
    weightGross
    decreeNo
    mint
    designer
    dateOfIssue
    mintageLimitTotal
    mintageLimitNormal
    mintageLimitProof
    normalEdge
    normalActualMintage
    normalPriceCalculated
    normalPriceActual
    proofEdge
    proofActualMintage
    proofPriceCalculated
    proofPriceActual
    stock {
      items {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        series
        purchasedAt
        pricePurchase
        pricePurchasePaidWith
        priceSelling
        commission
        status
        sold
        soldAt
        version
      }
      nextToken
    }
    distributions {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        price
        status
        version
      }
      nextToken
    }
    contractsFirst {
      items {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      nextToken
    }
    version
  }
}
`;
export const updateCoin = `mutation UpdateCoin($input: UpdateCoinInput!) {
  updateCoin(input: $input) {
    id
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    emission {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      type
      name
      description
      yearFrom
      yearTo
      coins {
        nextToken
      }
      stock {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    value
    denominationMotif
    description
    fineness
    diameter
    thickness
    weightFine
    weightGross
    decreeNo
    mint
    designer
    dateOfIssue
    mintageLimitTotal
    mintageLimitNormal
    mintageLimitProof
    normalEdge
    normalActualMintage
    normalPriceCalculated
    normalPriceActual
    proofEdge
    proofActualMintage
    proofPriceCalculated
    proofPriceActual
    stock {
      items {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        series
        purchasedAt
        pricePurchase
        pricePurchasePaidWith
        priceSelling
        commission
        status
        sold
        soldAt
        version
      }
      nextToken
    }
    distributions {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        price
        status
        version
      }
      nextToken
    }
    contractsFirst {
      items {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      nextToken
    }
    version
  }
}
`;
export const deleteCoin = `mutation DeleteCoin($input: DeleteCoinInput!) {
  deleteCoin(input: $input) {
    id
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    emission {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      type
      name
      description
      yearFrom
      yearTo
      coins {
        nextToken
      }
      stock {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    value
    denominationMotif
    description
    fineness
    diameter
    thickness
    weightFine
    weightGross
    decreeNo
    mint
    designer
    dateOfIssue
    mintageLimitTotal
    mintageLimitNormal
    mintageLimitProof
    normalEdge
    normalActualMintage
    normalPriceCalculated
    normalPriceActual
    proofEdge
    proofActualMintage
    proofPriceCalculated
    proofPriceActual
    stock {
      items {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        series
        purchasedAt
        pricePurchase
        pricePurchasePaidWith
        priceSelling
        commission
        status
        sold
        soldAt
        version
      }
      nextToken
    }
    distributions {
      items {
        id
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        price
        status
        version
      }
      nextToken
    }
    contractsFirst {
      items {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      nextToken
    }
    version
  }
}
`;
export const createStock = `mutation CreateStock($input: CreateStockInput!) {
  createStock(input: $input) {
    id
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    emission {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      type
      name
      description
      yearFrom
      yearTo
      coins {
        nextToken
      }
      stock {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    coin {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      emission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      value
      denominationMotif
      description
      fineness
      diameter
      thickness
      weightFine
      weightGross
      decreeNo
      mint
      designer
      dateOfIssue
      mintageLimitTotal
      mintageLimitNormal
      mintageLimitProof
      normalEdge
      normalActualMintage
      normalPriceCalculated
      normalPriceActual
      proofEdge
      proofActualMintage
      proofPriceCalculated
      proofPriceActual
      stock {
        nextToken
      }
      distributions {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    series
    purchasedAt
    pricePurchase
    pricePurchasePaidWith
    priceSelling
    commission
    status
    sold
    soldAt
    invoices {
      items {
        id
        type
        invoiceNumber
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientCompanyName
        clientCompanyNumber
        clientCompanyNumberVat
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        textAboveItems
        textBelowItems
        price
        dateOfIssue
        dueDate
        variableSymbol
        constantSymbol
        specificSymbol
        status
        version
      }
      nextToken
    }
    version
  }
}
`;
export const updateStock = `mutation UpdateStock($input: UpdateStockInput!) {
  updateStock(input: $input) {
    id
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    emission {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      type
      name
      description
      yearFrom
      yearTo
      coins {
        nextToken
      }
      stock {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    coin {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      emission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      value
      denominationMotif
      description
      fineness
      diameter
      thickness
      weightFine
      weightGross
      decreeNo
      mint
      designer
      dateOfIssue
      mintageLimitTotal
      mintageLimitNormal
      mintageLimitProof
      normalEdge
      normalActualMintage
      normalPriceCalculated
      normalPriceActual
      proofEdge
      proofActualMintage
      proofPriceCalculated
      proofPriceActual
      stock {
        nextToken
      }
      distributions {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    series
    purchasedAt
    pricePurchase
    pricePurchasePaidWith
    priceSelling
    commission
    status
    sold
    soldAt
    invoices {
      items {
        id
        type
        invoiceNumber
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientCompanyName
        clientCompanyNumber
        clientCompanyNumberVat
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        textAboveItems
        textBelowItems
        price
        dateOfIssue
        dueDate
        variableSymbol
        constantSymbol
        specificSymbol
        status
        version
      }
      nextToken
    }
    version
  }
}
`;
export const deleteStock = `mutation DeleteStock($input: DeleteStockInput!) {
  deleteStock(input: $input) {
    id
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    emission {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      type
      name
      description
      yearFrom
      yearTo
      coins {
        nextToken
      }
      stock {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    coin {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      emission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      value
      denominationMotif
      description
      fineness
      diameter
      thickness
      weightFine
      weightGross
      decreeNo
      mint
      designer
      dateOfIssue
      mintageLimitTotal
      mintageLimitNormal
      mintageLimitProof
      normalEdge
      normalActualMintage
      normalPriceCalculated
      normalPriceActual
      proofEdge
      proofActualMintage
      proofPriceCalculated
      proofPriceActual
      stock {
        nextToken
      }
      distributions {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    series
    purchasedAt
    pricePurchase
    pricePurchasePaidWith
    priceSelling
    commission
    status
    sold
    soldAt
    invoices {
      items {
        id
        type
        invoiceNumber
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientCompanyName
        clientCompanyNumber
        clientCompanyNumberVat
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        textAboveItems
        textBelowItems
        price
        dateOfIssue
        dueDate
        variableSymbol
        constantSymbol
        specificSymbol
        status
        version
      }
      nextToken
    }
    version
  }
}
`;
export const createDistribution = `mutation CreateDistribution($input: CreateDistributionInput!) {
  createDistribution(input: $input) {
    id
    owners
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    client {
      id
      benefitId
      isFs
      cognitoUserId
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      titleBefore
      firstname
      lastname
      titleAfter
      personalNumber
      birthDate
      email
      phone
      addressFormatted
      addressStreet
      addressStreetNumber
      addressZip
      addressTown
      hasDifferentDeliveryAddress
      deliveryTitleBefore
      deliveryFirstname
      deliveryLastname
      deliveryTitleAfter
      deliveryCompanyName
      deliveryPhone
      deliveryBirthYear
      deliveryAddressFormatted
      deliveryAddressStreet
      deliveryAddressStreetNumber
      deliveryAddressZip
      deliveryAddressTown
      bankAccountNumber
      bankCode
      status
      contracts {
        nextToken
      }
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    contract {
      id
      contractNumber
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      signedAt
      contractSentAt
      exportedAt
      lastPaymentReceivedAt
      deleted
      deletedAt
      deletedBy
      monthlyAmount
      totalYears
      emission1
      emission2
      firstDeliveryDate
      actualDeliveryEntitlementDate
      firstEmission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      firstCoin {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      clientBenefitId
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      clientBankAccountOwner
      clientBankAccountOwnerRelation
      defaultPriceOfCoin
      salesMargin
      entryFeePerCoin
      priceOfCoin
      estimatedValue
      numberOfCoins
      qualityOfCoins
      entryPriceIncrease
      entryPriceIncreaseOneTimePayment
      entryPriceIncreaseIsPaid
      entryPriceIncreaseWasPaidAt
      entryPriceIncreasePaidAmount
      entryPriceIncreaseInvoiceId
      entryPriceIncreaseInvoiceNumber
      indicativeTargetAmount
      paymentsAmount
      distributionsAmount
      savedAmount
      status
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    coin {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      emission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      value
      denominationMotif
      description
      fineness
      diameter
      thickness
      weightFine
      weightGross
      decreeNo
      mint
      designer
      dateOfIssue
      mintageLimitTotal
      mintageLimitNormal
      mintageLimitProof
      normalEdge
      normalActualMintage
      normalPriceCalculated
      normalPriceActual
      proofEdge
      proofActualMintage
      proofPriceCalculated
      proofPriceActual
      stock {
        nextToken
      }
      distributions {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    invoice {
      id
      type
      invoiceNumber
      owners
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      contract {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      stock {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        series
        purchasedAt
        pricePurchase
        pricePurchasePaidWith
        priceSelling
        commission
        status
        sold
        soldAt
        version
      }
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientCompanyName
      clientCompanyNumber
      clientCompanyNumberVat
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      textAboveItems
      items {
        stockId
        text
        price
      }
      textBelowItems
      price
      dateOfIssue
      dueDate
      variableSymbol
      constantSymbol
      specificSymbol
      status
      payments {
        nextToken
      }
      commissionsRelatedPayments {
        nextToken
      }
      version
    }
    price
    status
    version
  }
}
`;
export const updateDistribution = `mutation UpdateDistribution($input: UpdateDistributionInput!) {
  updateDistribution(input: $input) {
    id
    owners
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    client {
      id
      benefitId
      isFs
      cognitoUserId
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      titleBefore
      firstname
      lastname
      titleAfter
      personalNumber
      birthDate
      email
      phone
      addressFormatted
      addressStreet
      addressStreetNumber
      addressZip
      addressTown
      hasDifferentDeliveryAddress
      deliveryTitleBefore
      deliveryFirstname
      deliveryLastname
      deliveryTitleAfter
      deliveryCompanyName
      deliveryPhone
      deliveryBirthYear
      deliveryAddressFormatted
      deliveryAddressStreet
      deliveryAddressStreetNumber
      deliveryAddressZip
      deliveryAddressTown
      bankAccountNumber
      bankCode
      status
      contracts {
        nextToken
      }
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    contract {
      id
      contractNumber
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      signedAt
      contractSentAt
      exportedAt
      lastPaymentReceivedAt
      deleted
      deletedAt
      deletedBy
      monthlyAmount
      totalYears
      emission1
      emission2
      firstDeliveryDate
      actualDeliveryEntitlementDate
      firstEmission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      firstCoin {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      clientBenefitId
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      clientBankAccountOwner
      clientBankAccountOwnerRelation
      defaultPriceOfCoin
      salesMargin
      entryFeePerCoin
      priceOfCoin
      estimatedValue
      numberOfCoins
      qualityOfCoins
      entryPriceIncrease
      entryPriceIncreaseOneTimePayment
      entryPriceIncreaseIsPaid
      entryPriceIncreaseWasPaidAt
      entryPriceIncreasePaidAmount
      entryPriceIncreaseInvoiceId
      entryPriceIncreaseInvoiceNumber
      indicativeTargetAmount
      paymentsAmount
      distributionsAmount
      savedAmount
      status
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    coin {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      emission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      value
      denominationMotif
      description
      fineness
      diameter
      thickness
      weightFine
      weightGross
      decreeNo
      mint
      designer
      dateOfIssue
      mintageLimitTotal
      mintageLimitNormal
      mintageLimitProof
      normalEdge
      normalActualMintage
      normalPriceCalculated
      normalPriceActual
      proofEdge
      proofActualMintage
      proofPriceCalculated
      proofPriceActual
      stock {
        nextToken
      }
      distributions {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    invoice {
      id
      type
      invoiceNumber
      owners
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      contract {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      stock {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        series
        purchasedAt
        pricePurchase
        pricePurchasePaidWith
        priceSelling
        commission
        status
        sold
        soldAt
        version
      }
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientCompanyName
      clientCompanyNumber
      clientCompanyNumberVat
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      textAboveItems
      items {
        stockId
        text
        price
      }
      textBelowItems
      price
      dateOfIssue
      dueDate
      variableSymbol
      constantSymbol
      specificSymbol
      status
      payments {
        nextToken
      }
      commissionsRelatedPayments {
        nextToken
      }
      version
    }
    price
    status
    version
  }
}
`;
export const deleteDistribution = `mutation DeleteDistribution($input: DeleteDistributionInput!) {
  deleteDistribution(input: $input) {
    id
    owners
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    client {
      id
      benefitId
      isFs
      cognitoUserId
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      titleBefore
      firstname
      lastname
      titleAfter
      personalNumber
      birthDate
      email
      phone
      addressFormatted
      addressStreet
      addressStreetNumber
      addressZip
      addressTown
      hasDifferentDeliveryAddress
      deliveryTitleBefore
      deliveryFirstname
      deliveryLastname
      deliveryTitleAfter
      deliveryCompanyName
      deliveryPhone
      deliveryBirthYear
      deliveryAddressFormatted
      deliveryAddressStreet
      deliveryAddressStreetNumber
      deliveryAddressZip
      deliveryAddressTown
      bankAccountNumber
      bankCode
      status
      contracts {
        nextToken
      }
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    contract {
      id
      contractNumber
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      signedAt
      contractSentAt
      exportedAt
      lastPaymentReceivedAt
      deleted
      deletedAt
      deletedBy
      monthlyAmount
      totalYears
      emission1
      emission2
      firstDeliveryDate
      actualDeliveryEntitlementDate
      firstEmission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      firstCoin {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        version
      }
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      clientBenefitId
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      clientBankAccountOwner
      clientBankAccountOwnerRelation
      defaultPriceOfCoin
      salesMargin
      entryFeePerCoin
      priceOfCoin
      estimatedValue
      numberOfCoins
      qualityOfCoins
      entryPriceIncrease
      entryPriceIncreaseOneTimePayment
      entryPriceIncreaseIsPaid
      entryPriceIncreaseWasPaidAt
      entryPriceIncreasePaidAmount
      entryPriceIncreaseInvoiceId
      entryPriceIncreaseInvoiceNumber
      indicativeTargetAmount
      paymentsAmount
      distributionsAmount
      savedAmount
      status
      emails {
        nextToken
      }
      invoices {
        nextToken
      }
      payments {
        nextToken
      }
      distributions {
        nextToken
      }
      version
    }
    coin {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      emission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        version
      }
      value
      denominationMotif
      description
      fineness
      diameter
      thickness
      weightFine
      weightGross
      decreeNo
      mint
      designer
      dateOfIssue
      mintageLimitTotal
      mintageLimitNormal
      mintageLimitProof
      normalEdge
      normalActualMintage
      normalPriceCalculated
      normalPriceActual
      proofEdge
      proofActualMintage
      proofPriceCalculated
      proofPriceActual
      stock {
        nextToken
      }
      distributions {
        nextToken
      }
      contractsFirst {
        nextToken
      }
      version
    }
    invoice {
      id
      type
      invoiceNumber
      owners
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      contract {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        emission1
        emission2
        firstDeliveryDate
        actualDeliveryEntitlementDate
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        version
      }
      stock {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        series
        purchasedAt
        pricePurchase
        pricePurchasePaidWith
        priceSelling
        commission
        status
        sold
        soldAt
        version
      }
      clientTitleBefore
      clientFirstname
      clientLastname
      clientTitleAfter
      clientPersonalNumber
      clientBirthDate
      clientCompanyName
      clientCompanyNumber
      clientCompanyNumberVat
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryTitleBefore
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryTitleAfter
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      textAboveItems
      items {
        stockId
        text
        price
      }
      textBelowItems
      price
      dateOfIssue
      dueDate
      variableSymbol
      constantSymbol
      specificSymbol
      status
      payments {
        nextToken
      }
      commissionsRelatedPayments {
        nextToken
      }
      version
    }
    price
    status
    version
  }
}
`;
