import { createReducer } from 'reduxsauce'

import { CoinTypes } from './Actions'
import INITIAL_STATE from './InitialState'

// GET_COIN_LOADING
export const getCoinLoading = (state: any): any => ({
  ...state,
  getCoinLoading: true,
  getCoinResult: null,
  getCoinError: null
})

// GET_COIN_SUCCESS
export const getCoinSuccess = (state: any, { data }: any): any => ({
  ...state,
  getCoinLoading: false,
  getCoinResult: data,
  getCoinError: null
})

// GET_COIN_FAILURE
export const getCoinFailure = (state: any, { error }: any): any => ({
  ...state,
  getCoinLoading: false,
  getCoinError: error
})

//

export const reducer = createReducer(INITIAL_STATE, {
  [CoinTypes.GET_COIN_LOADING]: getCoinLoading,
  [CoinTypes.GET_COIN_SUCCESS]: getCoinSuccess,
  [CoinTypes.GET_COIN_FAILURE]: getCoinFailure
})
