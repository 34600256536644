import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import PaymentActions from 'Stores/Payment/Actions'
import * as PaymentSelect from 'Stores/Payment/Select'

import useReactRouter from 'use-react-router'

import DefaultLayout from 'Components/_Common/Layout/Default'
import Loading from 'Components/Loading'
import PaymentForm from 'Routes/Admin/Payments/components/Form'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as ROUTES from 'routes'

import ContractsList from 'Components/Contract/List/List'
import InvoicesList from 'Components/Invoice/List/List'
import PaymentsList from 'Components/Payment/List/List'

const Payment = (props: any) => {
  const {
    match: {
      params: { id }
    }
  }: any = useReactRouter()

  useEffect(() => {
    props.getPayment(id)
  }, [id])

  const [tab, setTab] = useState(0)

  function handleSwitchTab(event: any, newValue: number): void {
    setTab(newValue)
  }

  return (
    <DefaultLayout
      title={`Platba`}
      tabs={[{
        route: ROUTES.ADMIN_PAYMENTS, title: 'Seznam plateb'
      }, {
        route: ROUTES.ADMIN_PAYMENT, title: id ? 'Editace platby' : 'Nová platba'
      }]}
      activeTab={1}
    >
      {props.paymentLoading && <Loading />}
      {!props.paymentLoading && props.paymentResult && (
        <React.Fragment>
          <AppBar position='static' color='default' style={{ width: `${props.dimensions.width - 72}px`, margin: 'auto' }}>
            <Tabs
              value={tab}
              onChange={handleSwitchTab}
              variant='scrollable'
              scrollButtons='off'
              indicatorColor='primary'
              textColor='primary'
            >
              <Tab label='Platba' icon={<FontAwesomeIcon icon={['fal', 'money-check-alt']} size='2x' />} />
              <Tab label='Klient' icon={<FontAwesomeIcon icon={['fal', 'user']} size='2x' />} disabled={props.paymentResult.payment === null} />
              <Tab label='Smlouva' icon={<FontAwesomeIcon icon={['fal', 'file-signature']} size='2x' />} disabled={props.paymentResult.contract === null} />
              <Tab label='Faktura' icon={<FontAwesomeIcon icon={['fal', 'file-invoice']} size='2x' />} disabled={props.paymentResult.invoice === null} />
            </Tabs>
          </AppBar>

          {tab === 0 && <PaymentForm
            loading={props.paymentLoading}
            data={props.paymentResult}
          />}

          {/* @todo SORT */}
          {tab === 1 && props.paymentResult.payment && <PaymentsList
            rows={[props.paymentResult.payment]}
            heightOffset={-72}
          />}

          {/* @todo SORT */}
          {tab === 2 && props.paymentResult.contract && <ContractsList
            rows={[props.paymentResult.contract]}
            heightOffset={-72}
          />}

          {/* @todo SORT */}
          {tab === 3 && props.paymentResult.invoice && <InvoicesList
            rows={[props.paymentResult.invoice]}
            heightOffset={-72}
          />}
        </React.Fragment>
      )}
    </DefaultLayout>
  )
}

const mapStateToProps = (state: any) => ({
  paymentLoading: PaymentSelect.paymentLoading(state),
  paymentResult: PaymentSelect.paymentResult(state),
  //
  dimensions: state.app.dimensions
})

const mapDispatchToProps = (dispatch: any) => ({
  getPayment: (id: any) => dispatch(PaymentActions.getPayment(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Payment)
