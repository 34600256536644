import React from 'react'

import { connect } from 'react-redux'
import EmissionActions from 'Stores/Emission/Actions'
import * as EmissionSelect from 'Stores/Emission/Select'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { parseISO, format } from 'date-fns'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    value: {
      color: theme.palette.primary.main,
    }
  })
)

const SelectedFirstCoin = (props: any) => {
  const classes = useStyles()

  React.useEffect(() => {
    props.listEmissions()
  }, [])

  return (
    <>
      {props.emissionsLoading && <span>Načítám ...</span>}

      {!props.emissionsLoading &&
        (props.contractForm.contractFirstEmissionId === null ||
          props.contractForm.contractFirstCoinId === null) && <>
        <em>– nebyla zvolena –</em>
      </>}

      {!props.emissionsLoading &&
        props.contractForm.contractFirstEmissionId &&
        props.contractForm.contractFirstCoinId && <>
        {props.emissionsResult
          .filter((item: any) => item.id === props.contractForm.contractFirstEmissionId)
          .map((item: any) => (
            <strong className={classes.value}>{item.name}</strong>
        ))}
        &nbsp;&rsaquo;&nbsp;
        {props.emissionsResult.map((item: any) => {
          if (item.id === props.contractForm.contractFirstEmissionId) {
            return item.coins.items
              .filter((coin: any) => coin.id === props.contractForm.contractFirstCoinId)
              .map((coin: any) => (
                <>
                  <strong className={classes.value}>{coin.denominationMotif}</strong>
                  &nbsp;({format(parseISO(coin.dateOfIssue), 'dd.MM.yyyy')})
                </>
              ))
          }
        })}
      </>}
    </>
  )
}

const mapStateToProps = (state: any): any => ({
  emissionsLoading: EmissionSelect.emissionsLoading(state),
  emissionsResult: EmissionSelect.emissionsResult(state),
  //
  contractForm: state.contract.form
})

const mapDispatchToProps = (dispatch: any) => ({
  listEmissions: () => dispatch(EmissionActions.listEmissions())
})

export default connect(mapStateToProps, mapDispatchToProps)(
  SelectedFirstCoin
)
