import { put, select } from 'redux-saga/effects'

import ClientActions from 'Stores/Client/Actions'

import { recursivelyLoadAllData } from 'Sagas'
import listClientsQuery from 'Graphql/Queries/listClientsQuery'

export function * listClients ({ filter }: any) {
  try {
    yield put(ClientActions.listClientsLoading())

    const variables = {} as any
    const user = yield select(state => state.user)
    if (user.group === 'SalesRepresentatives') {
      variables.filter = { owner: { eq: user.username } }
    }

    const data = yield recursivelyLoadAllData(listClientsQuery, variables, 'listClients')

    // @todo
    data.sort((a: any, b: any) => a.lastname.localeCompare(b.lastname))

    yield put(ClientActions.listClientsSuccess(data))
  } catch (error) {
    yield put(ClientActions.listClientsFailure(error))
  }
}

export default listClients
