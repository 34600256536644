import React from 'react'
import { Field } from 'react-final-form'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

export default (props: any) => {
  const { name, label = null, children, ...rest } = props

  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)

  React.useEffect(() => {
    if (inputLabel !== null && inputLabel.current !== null) {
      setLabelWidth((inputLabel.current as any).offsetWidth)
    }
  }, [])

  return (
    <Field
      {...rest}
      name={name}
      fullWidth={true}
    >
      {(field: any): any => {
        const { input, meta }: any = field
        return (
          <FormControl variant='outlined' margin='dense' style={{ width: '100%' }}>
            {label && <InputLabel ref={inputLabel} id={`${name}_label`}>
              {label}
            </InputLabel>}
            <Select
              labelId={`${name}_label`}
              labelWidth={labelWidth}
              {...rest}
              inputProps={input}
            >
              {children}
            </Select>
          </FormControl>
        )
      }}
    </Field>
  )
}
