import isEmpty from 'Helpers/isEmpty'

const concatWs = (value1: any, value2: any, separator: string = '/'): string => {
  let finalValue = ``
  if (!isEmpty(value1) && !isEmpty(value2)) {
    finalValue = `${value1}${separator}${value2}`
  } else if (!isEmpty(value1) && isEmpty(value2)) {
    finalValue = `${value1}`
  } else if (isEmpty(value1) && !isEmpty(value2)) {
    finalValue = `${value2}`
  }

  return finalValue
}

export default concatWs
