import INITIAL_STATE_FORM from './InitialStateForm'

const INITIAL_STATE = {
  listInvoicesLoading: false,
  listInvoicesResult: [],
  listInvoicesError: null,
  //
  getInvoiceLoading: false,
  getInvoiceResult: null,
  getInvoiceError: null,
  //
  form: INITIAL_STATE_FORM,
  //
  createInvoiceLoading: false,
  createInvoiceError: null,
  //
  downloadInvoiceLoading: false,
  downloadInvoiceError: null,
  //
  sendInvoiceLoading: false,
  sendInvoiceError: null
}

export default INITIAL_STATE
