import gql from 'graphql-tag'

const query = gql`
  query LIST_DISTRIBUTIONS_QUERY (
    $filter: ModelDistributionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDistributions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        __typename
        owners
        createdAt
        client {
          id
          __typename
          firstname
          lastname
        }
        contract {
          id
          __typename
          contractNumber
        }
        coin {
          id
          __typename
          denominationMotif
          emission {
            id
            __typename
            name
          }
        }
        invoice {
          id
          __typename
          invoiceNumber
        }
        price
        status
      }
      nextToken
    }
  }
`

export default query
