import React from 'react'
import { Table } from '@devexpress/dx-react-grid-material-ui'

// Client
import ClientListColumnAction from 'Components/Client/List/Column/Action'
import ClientListColumnName from 'Components/Client/List/Column/Name'
// Contract
import ContractListColumnAction from 'Components/Contract/List/Column/Action'
import ContractListColumnContractNumber from 'Components/Contract/List/Column/ContractNumber'
import ContractListColumnEntryPriceIncrease from 'Components/Contract/List/Column/EntryPriceIncrease'
import ContractListColumnStatus from 'Components/Contract/List/Column/Status'
// Distribution
import DistributionListColumnAction from 'Components/Contract/List/Column/Action'
// Email
import EmailListColumnAction from 'Components/Email/List/Column/Action'
// Invoice
import InvoiceListColumnAction from 'Components/Invoice/List/Column/Action'
import InvoiceListColumnInvoiceNumber from 'Components/Invoice/List/Column/InvoiceNumber'
// Payment
import PaymentListColumnAction from 'Components/Payment/List/Column/Action'
import PaymentListColumnBankReference from 'Components/Payment/List/Column/BankReference'
import PaymentListColumnAmount from 'Components/Payment/List/Column/Amount'
// Stock
import StockListColumnAction from 'Components/Stock/List/Column/Action'
import StockListColumnCoinDenominationMotif from 'Components/Stock/List/Column/CoinDenominationMotif'

const ActionCell = (props: any): any => {
  const { column } = props

  switch (column.name) {
    case 'client.action':
      return (
        <Table.Cell {...props}>
          <ClientListColumnAction {...props} />
        </Table.Cell>
      )

    case 'client.name':
      return (
        <Table.Cell {...props}>
          <ClientListColumnName {...props} />
        </Table.Cell>
      )

    case 'contract.action':
      return (
        <Table.Cell {...props}>
          <ContractListColumnAction {...props} />
        </Table.Cell>
      )

    case 'contract.contractNumber':
      return (
        <Table.Cell {...props}>
          <ContractListColumnContractNumber {...props} />
        </Table.Cell>
      )

    case 'contract.entryPriceIncrease':
      return (
        <Table.Cell {...props}>
          <ContractListColumnEntryPriceIncrease {...props} />
        </Table.Cell>
      )

    case 'contract.status':
      return (
        <Table.Cell {...props}>
          <ContractListColumnStatus {...props} />
        </Table.Cell>
      )

    case 'distribution.action':
      return (
        <Table.Cell {...props}>
          <DistributionListColumnAction {...props} />
        </Table.Cell>
      )

    case 'email.action':
      return (
        <Table.Cell {...props}>
          <EmailListColumnAction {...props} />
        </Table.Cell>
      )

    case 'invoice.action':
      return (
        <Table.Cell {...props}>
          <InvoiceListColumnAction {...props} />
        </Table.Cell>
      )

    case 'invoice.invoiceNumber':
      return (
        <Table.Cell {...props}>
          <InvoiceListColumnInvoiceNumber {...props} />
        </Table.Cell>
      )

    case 'payment.action':
      return (
        <Table.Cell {...props}>
          <PaymentListColumnAction {...props} />
        </Table.Cell>
      )

    case 'payment.bankReference':
      return (
        <Table.Cell {...props}>
          <PaymentListColumnBankReference {...props} />
        </Table.Cell>
      )

    case 'payment.amount':
      return (
        <Table.Cell {...props}>
          <PaymentListColumnAmount {...props} />
        </Table.Cell>
      )

    case 'stock.action':
      return (
        <Table.Cell {...props}>
          <StockListColumnAction {...props} />
        </Table.Cell>
      )

    case 'stock.coin.denominationMotif':
      return (
        <Table.Cell {...props}>
          <StockListColumnCoinDenominationMotif {...props} />
        </Table.Cell>
      )

    default:
      return <Table.Cell {...props} />
  }
}

export default ActionCell
