import { all, takeLatest } from 'redux-saga/effects'

import { ClientTypes } from 'Stores/Client/Actions'
import Client from 'Sagas/Client'

export default function * root() {
  yield all([
    takeLatest(ClientTypes.LIST_CLIENTS, Client.listClients),
    takeLatest(ClientTypes.LIST_CLIENTS_SUCCESS, Client.listClientsSuccess),
    takeLatest(ClientTypes.LIST_CLIENTS_FAILURE, Client.listClientsFailure),
    //
    takeLatest(ClientTypes.GET_CLIENT, Client.getClient),
    takeLatest(ClientTypes.GET_CLIENT_SUCCESS, Client.getClientSuccess),
    takeLatest(ClientTypes.GET_CLIENT_FAILURE, Client.getClientFailure)
  ])
}
