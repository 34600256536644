import { Table } from '@devexpress/dx-react-grid-material-ui'

import formatDateCallback from 'Components/_Common/List/Callback/formatDate'
import formatMoneyCallback from 'Components/_Common/List/Callback/formatMoney'

import invoiceDataType from 'Components/Invoice/Data/Type'
import invoiceDataStatus from 'Components/Invoice/Data/Status'

export const columns = [
  {
    name: 'invoice.action',
    title: ' ',
    getInvoiceId: (row: any): string => row.id
  },
  //
  {
    name: 'invoice.invoiceNumber',
    title: 'Číslo faktury *',
    getInvoiceId: (row: any): string => row.id,
    getCellValue: (row: any): string => row.invoiceNumber
  },
  //
  {
    name: 'invoice.type',
    title: 'Typ',
    getCellValue: (row: any): string => invoiceDataType(row.type)
  },
  //
  {
    name: 'invoice.dateOfIssue',
    title: 'Vystaveno',
    getCellValue: (row: any): string => formatDateCallback(row, 'dateOfIssue')
  },
  //
  {
    name: 'client.name',
    title: 'Klient',
    getClientId: (row: any): any => row.clientCompanyName ? null : row.client.id,
    getCellValue: (row: any): string => row.clientCompanyName ? row.clientCompanyName : `${row.clientLastname}, ${row.clientFirstname}`
  },
  //
  {
    name: 'invoice.variableSymbol',
    title: 'VS',
    getCellValue: (row: any): string => row.variableSymbol
  },
  //
  {
    name: 'invoice.price',
    title: 'Částka',
    getCellValue: (row: any): any => formatMoneyCallback(row, 'price')
  },
  //
  {
    name: 'invoice.status',
    title: 'Stav',
    getCellValue: (row: any): any => invoiceDataStatus(row.status)
  }
]

export const columnExtensions: Table.ColumnExtension[] = [
  {
    align: 'center',
    columnName: 'invoice.action',
    width: 75
  },
  //
  {
    align: 'right',
    columnName: 'invoice.invoiceNumber',
    width: 150
  },
  //
  {
    align: 'center',
    columnName: 'invoice.type',
    width: 100
  }, 
  //
  {
    align: 'center',
    columnName: 'invoice.dateOfIssue',
    width: 150
  }, 
  //
  {
    align: 'left',
    columnName: 'client.name'
  },
  //
  {
    align: 'right',
    columnName: 'invoice.variableSymbol',
    width: 100
  },
  //
  {
    align: 'right',
    columnName: 'invoice.price',
    width: 200
  },
  //
  {
    align: 'center',
    columnName: 'invoice.status',
    width: 100
  }
]

export default {
  columns
}
