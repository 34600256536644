import React from 'react'

// import Draggable from 'react-draggable'

import Paper from '@material-ui/core/Paper'

const DraggablePaperComponent = (props: any) => {
  return (
    // <Draggable>
      <Paper elevation={2} {...props} />
    // </Draggable>
  )
}

export default DraggablePaperComponent
