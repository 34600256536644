import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import EmailActions from 'Stores/Email/Actions'
import * as EmailSelect from 'Stores/Email/Select'

import useReactRouter from 'use-react-router'

import DefaultLayout from 'Components/_Common/Layout/Default'
import Loading from 'Components/Loading'
import EmailForm from 'Routes/Admin/Emails/components/Form'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as ROUTES from 'routes'

import ClientsList from 'Components/Client/List/List'
import ContractsList from 'Components/Contract/List/List'

const Email = (props: any) => {
  const {
    match: {
      params: { id }
    }
  }: any = useReactRouter()

  useEffect(() => {
    props.getEmail(id)
  }, [id])

  const [tab, setTab] = React.useState(0)

  function handleSwitchTab(event: any, newValue: number): void {
    setTab(newValue)
  }

  return (
    <DefaultLayout
      title={`Email`}
      tabs={[{
        route: ROUTES.ADMIN_EMAIL, title: 'Detail emailu'
      }]}
      activeTab={0}
    >
      {props.emailLoading && <Loading />}
      {!props.emailLoading && props.emailResult && (
        <React.Fragment>
          <AppBar position='static' color='default' style={{ width: `${props.dimensions.width - 72}px`, margin: 'auto' }}>
            <Tabs
              value={tab}
              onChange={handleSwitchTab}
              variant='scrollable'
              scrollButtons='off'
              indicatorColor='primary'
              textColor='primary'
            >
              <Tab label='Email' icon={<FontAwesomeIcon icon={['fal', 'mail-bulk']} size='2x' />} />
              <Tab label='Klient' icon={<FontAwesomeIcon icon={['fal', 'user']} size='2x' />} disabled={props.emailResult.client === null} />
              <Tab label='Smlouva' icon={<FontAwesomeIcon icon={['fal', 'file-signature']} size='2x' />} disabled={props.emailResult.contract === null} />
            </Tabs>
          </AppBar>

          {tab === 0 && <EmailForm
            loading={props.emailLoading}
            data={props.emailResult}
          />}

          {/* @todo SORT */}
          {tab === 1 && props.emailResult.client && <ClientsList
            rows={[props.emailResult.client]}
            heightOffset={-72}
          />}

          {/* @todo SORT */}
          {tab === 2 && props.emailResult.contract && <ContractsList
            rows={[props.emailResult.contract]}
            heightOffset={-72}
          />}
        </React.Fragment>
      )}
    </DefaultLayout>
  )
}

const mapStateToProps = (state: any) => ({
  emailLoading: EmailSelect.emailLoading(state),
  emailResult: EmailSelect.emailResult(state),
  //
  dimensions: state.app.dimensions
})

const mapDispatchToProps = (dispatch: any) => ({
  getEmail: (id: any) => dispatch(EmailActions.getEmail(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Email)
