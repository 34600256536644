import { listEmails } from 'Sagas/Email/listEmails/listEmails'
import { listEmailsSuccess } from 'Sagas/Email/listEmails/listEmailsSuccess'
import { listEmailsFailure } from 'Sagas/Email/listEmails/listEmailsFailure'
//
import { getEmail } from 'Sagas/Email/getEmail/getEmail'
import { getEmailSuccess } from 'Sagas/Email/getEmail/getEmailSuccess'
import { getEmailFailure } from 'Sagas/Email/getEmail/getEmailFailure'

export default {
  listEmails,
  listEmailsSuccess,
  listEmailsFailure,
  //
  getEmail,
  getEmailSuccess,
  getEmailFailure
}
