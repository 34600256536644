import React from 'react'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import Typography from '@material-ui/core/Typography'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import DefaultLayout from 'Components/_Common/Layout/Default'

import * as ROUTES from 'routes'

const styles = (theme: Theme) => createStyles({
  paper: {
    margin: 'auto',
    marginBottom: 48,
    overflow: 'hidden'
  },
  table: {
    minWidth: 700
  }
})


interface Props extends WithStyles<typeof styles> {
  data: any
}

const Pricelist = (props: Props) => {
  const { classes } = props

  return (
    <DefaultLayout
      title={`Ceník`}
      tabs={[{
        route: ROUTES.CLIENT_PRICELIST, title: 'Aktuální ceník'
      }]}
      activeTab={0}
    >
      <Paper elevation={2} className={classes.paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Mince</TableCell>
              <TableCell align="right">Cena – BK</TableCell>
              <TableCell align="right">Cena – PROOF</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Městské památkové rezervace */}
            <TableRow>
              <TableCell align="left" colSpan={3}>
                <Typography variant='button' color='primary'><strong>Městské památkové rezervace</strong></Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align='left'>Hradec Králové</TableCell>
              <TableCell align='right'><strong>25&nbsp;695&nbsp;Kč</strong></TableCell>
              <TableCell align='right'><strong>26&nbsp;135&nbsp;Kč</strong></TableCell>
            </TableRow>

            <TableRow>
              <TableCell align='left'>Kroměříž</TableCell>
              <TableCell align='right'><strong>25&nbsp;397&nbsp;Kč</strong></TableCell>
              <TableCell align='right'><strong>25&nbsp;837&nbsp;Kč</strong></TableCell>
            </TableRow>

            <TableRow>
              <TableCell align='left'>Litoměřice</TableCell>
              <TableCell align='right'><strong>23&nbsp;875&nbsp;Kč</strong></TableCell>
              <TableCell align='right'><strong>24&nbsp;299&nbsp;Kč</strong></TableCell>
            </TableRow>

            <TableRow>
              <TableCell align='left'>Mikulov</TableCell>
              <TableCell align='right'><strong>24&nbsp;765&nbsp;Kč</strong></TableCell>
              <TableCell align='right'><strong>25&nbsp;189&nbsp;Kč</strong></TableCell>
            </TableRow>

            <TableRow>
              <TableCell align='left'>Cheb</TableCell>
              <TableCell align='right'><strong>21&nbsp;916&nbsp;Kč</strong></TableCell>
              <TableCell align='right'><strong>22&nbsp;340&nbsp;Kč</strong></TableCell>
            </TableRow>

            <TableRow>
              <TableCell align='left'>Jihlava</TableCell>
              <TableCell align='right'><strong>22&nbsp;082&nbsp;Kč</strong></TableCell>
              <TableCell align='right'><strong>22&nbsp;506&nbsp;Kč</strong></TableCell>
            </TableRow>

            {/* Hrady */}
            <TableRow>
              <TableCell align="left" colSpan={3}>
                <Typography variant='button' color='primary'><strong>Hrady</strong></Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align='left'>Buchlov</TableCell>
              <TableCell align='right'><strong>24&nbsp;591&nbsp;Kč</strong></TableCell>
              <TableCell align='right'><strong>25&nbsp;000&nbsp;Kč</strong></TableCell>
            </TableRow>

            <TableRow>
              <TableCell align='left'>Bečov nad Teplou</TableCell>
              <TableCell align='right'><strong>24&nbsp;273&nbsp;Kč</strong></TableCell>
              <TableCell align='right'><strong>24&nbsp;697&nbsp;Kč</strong></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </DefaultLayout>
  )
}

export default withStyles(styles)(Pricelist)
