const INITIAL_STATE = {
  loading: false,
  prefill: false,
  dirty: false,

  clientBenefitId: null,
  clientId: null,

  type: '1-proforma',

  textAboveItems: 'Fakturujeme Vám následující zboží:',

  items: []
}

export default INITIAL_STATE
