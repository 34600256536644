import React from 'react'

import bugsnag, { Bugsnag } from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

const bugsnagClient: Bugsnag.Client = bugsnag((process.env.REACT_APP_BUGSNAG_API_KEY) as string)
bugsnagClient.use(bugsnagReact, React)

const BugsnagContext = React.createContext<Bugsnag.Client | null>(bugsnagClient)

const ErrorBoundary = bugsnagClient.getPlugin('react')

export { BugsnagContext, ErrorBoundary }

export default bugsnagClient
