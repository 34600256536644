import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import AppActions from 'Stores/App/Actions'
import UserActions from 'Stores/User/Actions'

import { Auth } from 'aws-amplify'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import { CognitoUser } from '@aws-amplify/auth'

import { history } from '../index'

const lightColor = 'rgba(255, 255, 255, 0.7)'

const styles = (theme: Theme) => createStyles({
  button: {
    borderColor: lightColor,
    marginLeft: `15px`,
    marginRight: `15px`
  },
  iconButtonAvatar: {
    padding: 4
  },
  link: {
    '&:hover': {
      color: theme.palette.common.white
    },
    color: lightColor,
    textDecoration: 'none'
  },
  menuButton: {
    marginLeft: -theme.spacing(1)
  },
  secondaryBar: {
    zIndex: 0
  }
})

interface Props extends WithStyles<typeof styles> {
  title: string,
  onDrawerToggle?: () => void,
  tabs?: any[],
  activeTab?: any,
  onTabChange?: (event: any, tab: number) => void,
  showDeleted: boolean,
  toggleShowDeleted: any,
  user?: any,
  logout?: any
}

const Header = (props: Props) => {
  const { title, classes, onDrawerToggle } = props
  const [user, setUser]: [CognitoUser | null, any] = useState(null)

  const [anchorEl, setAnchorEl] = React.useState(null)

  useEffect(() => {
    getCurrentUser()
  }, [])

  const onTabChange = (event: any, key: any) => {
    const { tabs } = props
    if (tabs !== undefined) {
      history.push(tabs[key].route)
    }
  }

  const getCurrentUser = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser() as CognitoUser
    // cognitoUser.getUserAttributes((error, attrs) => console.log(attrs))
    setUser(cognitoUser)
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <AppBar color='primary' position='sticky' elevation={0}>
        <Toolbar>
          <Grid container={true} spacing={1} alignItems='center'>
            <Hidden smUp={true}>
              <Grid item={true}>
                <IconButton
                  color='inherit'
                  aria-label='Otevřít menu'
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <FontAwesomeIcon icon={['fal', 'bars']} fixedWidth={true} />
                </IconButton>
              </Grid>
            </Hidden>
            {/* <Grid item={true} xs={true}>
              <Typography color='inherit' variant='h5'>
                {title}
              </Typography>
            </Grid> */}
            <Grid item={true} xs={true} />
            <Grid item={true}>
              {props.user.group === 'Admins' && <Tooltip title='Nastavení'>
                <IconButton color='inherit' onClick={handleClick}>
                  <FontAwesomeIcon icon={['fal', 'cogs']} size='xs' fixedWidth={true} />
                </IconButton>
              </Tooltip>}

              <Button className={classes.button} variant='outlined' color='inherit' size='small'>
                {user !== null ? (user as any).attributes.email : ''}
              </Button>

              <Tooltip title='Odhlásit'>
                <IconButton color='inherit' onClick={props.logout}>
                  <FontAwesomeIcon icon={['fal', 'sign-out']} size='xs' fixedWidth={true} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component='div'
        className={classes.secondaryBar}
        color='primary'
        position='static'
        elevation={0}
      >
        {props.tabs instanceof Array && props.tabs.length > 0 ? <Tabs
          onChange={onTabChange}
          value={props.activeTab}
          textColor='inherit'>
          {props.tabs.map((tab: any, key: any) => (
            <Tab key={key} textColor='inherit' label={tab.title} />
          ))}
        </Tabs> : <Tabs
          value={0}
          textColor='inherit'>
          <Tab textColor='inherit' label={title} />
        </Tabs>}
      </AppBar>

      {props.user.group === 'Admins' && <Menu
        id="global-quick-settings-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          props.toggleShowDeleted()
          handleClose()
        }}>
          <ListItemIcon>
            <FontAwesomeIcon icon={['fal', props.showDeleted ? 'check' : 'times']} fixedWidth={true} />
          </ListItemIcon>
          <ListItemText
            primary={props.showDeleted ? `Zobrazuji smazané záznamy` : `Nezobrazuji smazané záznamy`}
            secondary={props.showDeleted ? `Kliknutím se smazané záznamy nebudou zobrazovat` : `Kliknutím se smazané záznamy budou zobrazovat`}
          />
        </MenuItem>
      </Menu>}
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => ({
  showDeleted: state.app.showDeleted,
  user: state.user
})

const mapDispatchToProps = (dispatch: any) => ({
  toggleShowDeleted: () => dispatch(AppActions.toggleShowDeleted()),
  logout: () => dispatch(UserActions.logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(Header)
)
