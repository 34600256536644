import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import ClientActions from 'Stores/Client/Actions'
import * as ClientSelect from 'Stores/Client/Select'
import ContractActions from 'Stores/Contract/Actions'

import useReactRouter from 'use-react-router'

import ClientForm from 'Components/Client/Form'
import DefaultLayout from 'Components/_Common/Layout/Default'
import Loading from 'Components/Loading'

import Button from '@material-ui/core/Button'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as ROUTES from 'routes'
import { history } from '../../../index'

import sortBy from 'Helpers/sortBy'

import ContractsList from 'Components/Contract/List/List'
import EmailsList from 'Components/Email/List/List'
import InvoicesList from 'Components/Invoice/List/List'
import PaymentsList from 'Components/Payment/List/List'

const ClientsDetail = (props: any) => {
  const {
    match: {
      params: { id }
    }
  }: any = useReactRouter()

  useEffect(() => {
    props.getClient(id)
  }, [id])

  const [tab, setTab] = React.useState(0)

  function handleSwitchTab(event: any, newValue: number): void {
    setTab(newValue)
  }

  const [anchorElement, setAnchorElement] = useState(null)

  const handleMenuClick = (event: any): void => {
    setAnchorElement(event.currentTarget)
  }

  const handleCloseMenu = (): void => {
    setAnchorElement(null)
  }

  const handleCreateNewContract = () => {
    props.prefillClient(id)
    history.push(`/smlouvy/nova`)
  }

  const actionMenu = (
    <React.Fragment>
      <Button
        aria-owns={anchorElement ? 'simple-menu' : undefined}
        aria-haspopup='true'
        onClick={handleMenuClick}
        variant='outlined'
        color='secondary'
        size='large'
      >
        Akce
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleCreateNewContract}>
          <ListItemText primary='Nová smlouva' secondary='Uzavřít klientovi novou smlouvu' />
        </MenuItem>
      </Menu>
    </React.Fragment>
  )

  return (
    <DefaultLayout
      title={`Klient`}
      tabs={[{
        route: ROUTES.ADMIN_CLIENTS, title: 'Seznam klientů'
      }, {
        route: ROUTES.ADMIN_CLIENT, title: id ? 'Editace klienta' : 'Nový klient'
      }]}
      activeTab={1}
    >
      {props.clientLoading && <Loading />}
      {!props.clientLoading && props.clientResult && (
        <React.Fragment>
          <AppBar position='static' color='default' style={{ width: `${props.dimensions.width - 72}px`, margin: 'auto' }}>
            <Tabs
              value={tab}
              onChange={handleSwitchTab}
              variant='scrollable'
              scrollButtons='off'
              indicatorColor='primary'
              textColor='primary'
            >
              <Tab label='Klient' icon={<FontAwesomeIcon icon={['fal', 'user']} size='2x' />} />
              <Tab label='Smlouvy' icon={<FontAwesomeIcon icon={['fal', 'file-signature']} size='2x' />} />
              <Tab label='Platby' icon={<FontAwesomeIcon icon={['fal', 'money-check-alt']} size='2x' />} />
              <Tab label='Faktury' icon={<FontAwesomeIcon icon={['fal', 'file-invoice']} size='2x' />} />
              <Tab label='Komunikace' icon={<FontAwesomeIcon icon={['fal', 'mail-bulk']} size='2x' />} />
            </Tabs>
          </AppBar>

          {tab === 0 && <ClientForm
            edit={!!id}
            data={props.clientResult}
            onSubmit={async () => {}}
            actionMenu={!!id ? actionMenu : null}
          />}

          {/* @todo SORT */}
          {tab === 1 && props.clientResult.contracts.items && <ContractsList
            rows={sortBy(props.clientResult.contracts.items.slice(), 'contractNumber', 'ASC')}
            heightOffset={-72}
          />}

          {/* @todo SORT */}
          {tab === 2 && props.clientResult.payments.items && <PaymentsList
            rows={sortBy(props.clientResult.payments.items.slice(), 'bankReference', 'DESC')}
            heightOffset={-72}
          />}

          {/* @todo SORT */}
          {tab === 3 && props.clientResult.invoices.items && <InvoicesList
            rows={sortBy(props.clientResult.invoices.items.slice(), 'invoiceNumber', 'ASC')}
            heightOffset={-72}
          />}

          {/* @todo SORT */}
          {tab === 4 && props.clientResult.emails.items && <EmailsList
            rows={sortBy(props.clientResult.emails.items.slice(), 'receivedAt', 'DESC', (v: any): any => new Date(v))}
            heightOffset={-72}
          />}
        </React.Fragment>
      )}
    </DefaultLayout>
  )
}

const mapStateToProps = (state: any) => ({
  clientLoading: ClientSelect.clientLoading(state),
  clientResult: ClientSelect.clientResult(state),
  //
  dimensions: state.app.dimensions
})

const mapDispatchToProps = (dispatch: any) => ({
  getClient: (id: any) => dispatch(ClientActions.getClient(id)),
  prefillClient: (id: any) => dispatch(ContractActions.prefillClient(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientsDetail)
