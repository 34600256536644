import { createReducer } from 'reduxsauce'

import { PaymentTypes } from './Actions'
import INITIAL_STATE from './InitialState'

// LIST_PAYMENTS_LOADING
export const listPaymentsLoading = (state: any): any => ({
  ...state,
  listPaymentsLoading: true,
  listPaymentsResult: [],
  listPaymentsError: null
})

// LIST_PAYMENTS_SUCCESS
export const listPaymentsSuccess = (state: any, { data }: any): any => ({
  ...state,
  listPaymentsLoading: false,
  listPaymentsResult: data,
  listPaymentsError: null
})

// LIST_PAYMENTS_FAILURE
export const listPaymentsFailure = (state: any, { error }: any): any => ({
  ...state,
  listPaymentsLoading: false,
  listPaymentsError: error
})

//

// GET_PAYMENT_LOADING
export const getPaymentLoading = (state: any): any => ({
  ...state,
  getPaymentLoading: true,
  getPaymentResult: null,
  getPaymentError: null
})

// GET_PAYMENT_SUCCESS
export const getPaymentSuccess = (state: any, { data }: any): any => ({
  ...state,
  getPaymentLoading: false,
  getPaymentResult: data,
  getPaymentError: null
})

// GET_PAYMENT_FAILURE
export const getPaymentFailure = (state: any, { error }: any): any => ({
  ...state,
  getPaymentLoading: false,
  getPaymentError: error
})

//

export const reducer = createReducer(INITIAL_STATE, {
  [PaymentTypes.LIST_PAYMENTS_LOADING]: listPaymentsLoading,
  [PaymentTypes.LIST_PAYMENTS_SUCCESS]: listPaymentsSuccess,
  [PaymentTypes.LIST_PAYMENTS_FAILURE]: listPaymentsFailure,
  //
  [PaymentTypes.GET_PAYMENT_LOADING]: getPaymentLoading,
  [PaymentTypes.GET_PAYMENT_SUCCESS]: getPaymentSuccess,
  [PaymentTypes.GET_PAYMENT_FAILURE]: getPaymentFailure
})
