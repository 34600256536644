import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import StockActions from 'Stores/Stock/Actions'
import * as StockSelect from 'Stores/Stock/Select'

import useReactRouter from 'use-react-router'

import DefaultLayout from 'Components/_Common/Layout/Default'
import Loading from 'Components/Loading'
import StockForm from 'Components/Stock/Form'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import sortBy from 'Helpers/sortBy'

import ClientsList from 'Components/Client/List/List'
import InvoicesList from 'Components/Invoice/List/List'
import PaymentsList from 'Components/Payment/List/List'

import * as ROUTES from 'routes'

const Stock = (props: any) => {
  const {
    match: {
      params: { id }
    }
  }: any = useReactRouter()

  useEffect(() => {
    props.getStock(id)
  }, [id])

  const [tab, setTab] = React.useState(0)

  function handleSwitchTab(event: any, newValue: number): void {
    setTab(newValue)
  }

  return (
    <DefaultLayout
      title={`Skladová položka`}
      tabs={[{
        route: ROUTES.ADMIN_STOCK, title: 'Seznam položek'
      }, {
        route: id ? `/sklad/polozka/${id}` : `/sklad/polozka`, title: id ? 'Editace skladové položky' : 'Nová skladová položka'
      }]}
      activeTab={1}
    >
      {props.stockLoading && <Loading />}
      {!props.stockLoading && props.stockResult && (
        <React.Fragment>
          <AppBar position='static' color='default' style={{ width: `${props.dimensions.width - 72}px`, margin: 'auto' }}>
            <Tabs
              value={tab}
              onChange={handleSwitchTab}
              variant='scrollable'
              scrollButtons='off'
              indicatorColor='primary'
              textColor='primary'
            >
              <Tab label='Položka' icon={<FontAwesomeIcon icon={['fal', 'warehouse-alt']} size='2x' />} />
              <Tab label='Klient' icon={<FontAwesomeIcon icon={['fal', 'user']} size='2x' />} />
              <Tab label='Faktury' icon={<FontAwesomeIcon icon={['fal', 'file-invoice']} size='2x' />} />
            </Tabs>
          </AppBar>

          {tab === 0 && <StockForm />}
          {/* @todo SORT */}
          {tab === 2 && <InvoicesList
            rows={sortBy(props.stockResult.invoices.items.slice(), 'invoiceNumber', 'ASC')}
            heightOffset={-72}
          />}
        </React.Fragment>
      )
      }
    </DefaultLayout>
  )
}

const mapStateToProps = (state: any) => ({
  stockLoading: StockSelect.stockLoading(state),
  stockResult: StockSelect.stockResult(state),
  //
  dimensions: state.app.dimensions
})

const mapDispatchToProps = (dispatch: any) => ({
  getStock: (id: any) => dispatch(StockActions.getStock(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Stock)
