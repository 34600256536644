import { Table } from '@devexpress/dx-react-grid-material-ui'

import formatDateCallback from 'Components/_Common/List/Callback/formatDate'
import formatMoneyCallback from 'Components/_Common/List/Callback/formatMoney'
import formatBankAccountCallback from 'Components/Payment/List/Callback/formatBankAccount'
import formatPaymentSymbolCallback from 'Components/Payment/List/Callback/formatPaymentSymbol'

import paymentDataStatus from 'Components/Payment/Data/Status'

export const columns = [
  {
    name: 'payment.action',
    title: ' ',
    getPaymentId: (row: any): string => row.id
  },
  //
  {
    name: 'payment.bankReference',
    title: 'Transakce *',
    getPaymentId: (row: any): string => row.id,
    getCellValue: (row: any): string => row.bankReference
  },
  //
  {
    name: 'payment.date',
    title: 'Datum',
    getCellValue: (row: any): any => formatDateCallback(row, 'date')
  },
  //
  {
    name: 'payment.amount',
    title: 'Částka',
    getCellValue: (row: any): any => row.amount
  },
  //
  {
    name: 'payment.variableSymbol',
    title: 'VS',
    getCellValue: (row: any): any => formatPaymentSymbolCallback(row, 'variableSymbol')
  },
  //
  {
    name: 'payment.constantSymbol',
    title: 'KS',
    getCellValue: (row: any): any => formatPaymentSymbolCallback(row, 'constantSymbol')
  },
  //
  {
    name: 'payment.specificSymbol',
    title: 'SS',
    getCellValue: (row: any): any => formatPaymentSymbolCallback(row, 'specificSymbol')
  },
  //
  {
    name: 'payment.bankAccountNumberWithBankCode',
    title: 'Účet',
    getCellValue: (row: any): any => formatBankAccountCallback(row, 'bankAccountNumberWithBankCode')
  },
  //
  {
    name: 'payment.bankAccountName',
    title: 'Název účtu',
    getCellValue: (row: any): any => row.bankAccountName
  },
  //
  {
    name: 'payment.message',
    title: 'Zpráva',
    getCellValue: (row: any): any => row.message
  },
  //
  {
    name: 'payment.partClient',
    title: 'Spoření',
    getCellValue: (row: any): string => formatMoneyCallback(row, 'partClient')
  },
  //
  {
    name: 'payment.partCommission',
    title: 'Provize',
    getCellValue: (row: any): string => formatMoneyCallback(row, 'partCommission')
  },
  //
  {
    name: 'payment.partGoldCoins',
    title: 'Gold Coins',
    getCellValue: (row: any): string => formatMoneyCallback(row, 'partGoldCoins')
  },
  //
  {
    name: 'payment.commissionExportedAt',
    title: 'Exportováno do BI',
    getCellValue: (row: any): any => formatDateCallback(row, 'commissionExportedAt')
  },
  //
  {
    name: 'invoice.invoiceNumber',
    title: 'Faktura do BI',
    getInvoiceId: (row: any): any => row.commissionsRelatedInvoice ? row.commissionsRelatedInvoice.id : null,
    getCellValue: (row: any): any => row.commissionsRelatedInvoice ? row.commissionsRelatedInvoice.invoiceNumber : ` - `
  },
  //
  {
    name: 'payment.status',
    title: 'Stav',
    getCellValue: (row: any): any => paymentDataStatus(row.status)
  }
]

export const columnExtensions: Table.ColumnExtension[] = [
  {
    align: 'center',
    columnName: 'payment.action',
    width: 75
  },
  //
  {
    align: 'left',
    columnName: 'payment.bankReference',
    width: 150
  },
  //
  {
    align: 'center',
    columnName: 'payment.date'
  },
  //
  {
    align: 'right',
    columnName: 'payment.amount'
  },
  //
  {
    align: 'right',
    columnName: 'payment.variableSymbol'
  },
  //
  {
    align: 'right',
    columnName: 'payment.constantSymbol'
  },
  //
  {
    align: 'right',
    columnName: 'payment.specificSymbol'
  },
  //
  {
    align: 'right',
    columnName: 'payment.bankAccountNumberWithBankCode',
    width: 200
  },
  //
  {
    align: 'left',
    columnName: 'payment.bankAccountName',
    width: 150
  },
  //
  {
    align: 'left',
    columnName: 'payment.message',
    width: 150
  },
  //
  {
    align: 'center',
    columnName: 'payment.partClient'
  },
  //
  {
    align: 'center',
    columnName: 'payment.partCommission'
  },
  //
  {
    align: 'center',
    columnName: 'payment.partGoldCoins'
  },
  //
  {
    align: 'center',
    columnName: 'payment.commissionExportedAt'
  },
  //
  {
    align: 'center',
    columnName: 'invoice.invoiceNumber'
  },
  //
  {
    align: 'center',
    columnName: 'payment.status',
    width: 100
  }
]

export default {
  columnExtensions,
  columns
}
