import { getContext, put, select } from 'redux-saga/effects'

import ContractActions from 'Stores/Contract/Actions'

import gql from 'graphql-tag'

export function * createAccount ({ id }: any) {
  try {
    yield put(ContractActions.createAccountLoading())

    const graphql = yield getContext('graphql')

    const query = gql(`query GetContract($id: ID!) {
      getContract(id: $id) {
        id
        client {
          id
          cognitoUserId
        }
      }
    }
    `)
    const variables = { id }

    const result = yield graphql.query(query, variables)
    const cognitoUserId = result.data.getContract.client.cognitoUserId

    if (cognitoUserId === null) {
      const lambdaFunction = `create-account`
      const url = `${process.env.REACT_APP_EXECUTE_API_URL}/${lambdaFunction}`
      const idToken = yield select(state => state.user.idToken)

      const response = yield fetch(url, {
        body: JSON.stringify({ contractId: id }),
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Authorization': idToken,
          'Content-Type': 'application/json'
        },
        method: 'POST',
        mode: 'cors'
      })

      if (response.ok && response.status === 200) {
        const json = yield response.json()
        if ('response' in json && 'cognitoUserId' in json.response && 'postmarkMessageId' in json.response) {
          yield put(ContractActions.createAccountSuccess())
        } else {
          yield put(ContractActions.createAccountFailure())
        }
      } else {
        yield put(ContractActions.createAccountFailure())
      }
    } else {
      yield put(ContractActions.createAccountSuccess())
    }
  } catch (error) {
    yield put(ContractActions.createAccountFailure(error))
  }
}

export default createAccount
