import { all, put, takeLatest } from 'redux-saga/effects'

import { StartupTypes } from 'Stores/Startup/Actions'
import AppActions from 'Stores/App/Actions'
import UserActions from 'Stores/User/Actions'

import Auth from '@aws-amplify/auth'
import Amplify from 'aws-amplify'
import awsConfig from 'aws-exports'
import jwtDecode from 'jwt-decode'

export function * startup () {
  try {
    yield Amplify.configure(awsConfig)

    const user = yield Auth.currentAuthenticatedUser()
    if (user !== undefined) {
      const session = user.getSignInUserSession()

      if (session !== null && session.isValid()) {
        const idTokenObj = session.getIdToken()
        const idToken = idTokenObj.getJwtToken()
        const accessTokenObj = session.getAccessToken()
        const accessToken = accessTokenObj.getJwtToken()
        const tokenDecoded = jwtDecode(idToken) as any
        const cognitoGroups = tokenDecoded['cognito:groups']
        const username = tokenDecoded['cognito:username']
        const benefitId = tokenDecoded['custom:bi_number']

        if (cognitoGroups instanceof Array && cognitoGroups.length === 1) {
          const group = cognitoGroups[0]

          yield put(UserActions.fetchUserSuccess(username, group, benefitId, idToken, accessToken))
        } else {
          yield put(UserActions.fetchUserFailure('Problem with user\'s group(s) membership.'))
        }
      } else {
        yield put(UserActions.fetchUserFailure('Session is not valid.'))
      }
    } else {
      yield put(UserActions.fetchUserFailure('User is not authenticated.'))
    }

    yield put(AppActions.startupSuccess())
  } catch (e) {
    yield put(UserActions.fetchUserFailure('User is not authenticated.'))
    yield put(AppActions.startupSuccess())
  }
}

export default function * root() {
  yield all([
    takeLatest(StartupTypes.STARTUP, startup)
  ])
}
