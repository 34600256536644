import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  searchForClient: ['personalNumber'], // SEARCH_FOR_CLIENT
  searchForClientLoading: null, // SEARCH_FOR_CLIENT_LOADING
  searchForClientSuccess: null, // SEARCH_FOR_CLIENT_SUCCESS
  searchForClientFailure: ['message'], // SEARCH_FOR_CLIENT_FAILURE
  //
  searchForBiClient: ['personalNumber'], // SEARCH_FOR_BI_CLIENT
  searchForBiClientLoading: null, // SEARCH_FOR_BI_CLIENT_LOADING
  searchForBiClientSuccess: null, // SEARCH_FOR_BI_CLIENT_SUCCESS
  searchForBiClientFailure: ['message'], // SEARCH_FOR_BI_CLIENT_FAILURE
  //
  searchForGcClient: ['personalNumber'], // SEARCH_FOR_GC_CLIENT
  searchForGcClientLoading: null, // SEARCH_FOR_GC_CLIENT_LOADING
  searchForGcClientSuccess: null, // SEARCH_FOR_GC_CLIENT_SUCCESS
  searchForGcClientFailure: ['message'] // SEARCH_FOR_GC_CLIENT_FAILURE
})

export const SearchForClientTypes = Types

export default Creators
