import { listPayments } from 'Sagas/Payment/listPayments/listPayments'
import { listPaymentsSuccess } from 'Sagas/Payment/listPayments/listPaymentsSuccess'
import { listPaymentsFailure } from 'Sagas/Payment/listPayments/listPaymentsFailure'
//
import { getPayment } from 'Sagas/Payment/getPayment/getPayment'
import { getPaymentSuccess } from 'Sagas/Payment/getPayment/getPaymentSuccess'
import { getPaymentFailure } from 'Sagas/Payment/getPayment/getPaymentFailure'

export default {
  listPayments,
  listPaymentsSuccess,
  listPaymentsFailure,
  //
  getPayment,
  getPaymentSuccess,
  getPaymentFailure
}
