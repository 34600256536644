import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import GenerateContractPdfDialog from 'Components/_Common/Dialogs/GenerateContractPdf'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import format from 'date-fns/format'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import sortBy from 'Helpers/sortBy'
import ts from 'Helpers/thousandsSeparator'

// @todo
const paymentSymbolCallback = (value: string): string => {
  const result = parseInt(value, 10)
  return isNaN(result) || result === 0 ? ` - ` : `${result}`
}

export const paymentStatusCallback = (value: string): any => {
  switch (value) {
    case 'new':
    case 'retry':
      return `Čeká na zpracování`

    case 'mismatch':
    case 'mismatch-confirmed':
      return `Neodpovídá účet`
    
    case 'processed':
      return <strong style={{ color: '#baa436' }}>Zpracována</strong>

    default:
        return ` - `
  }
}

export const invoiceStatusCallback = (value: string): any => {
  switch (value) {
    case 'waiting-for-payment':
      return `Čeká na platbu`

    case 'paid':
      return <strong style={{ color: '#baa436' }}>Zaplacena</strong>
  }

  return value
}

export const distributionStatusCallback = (value: string): any => {
  switch (value) {
    case 'done':
      return <strong style={{ color: '#baa436' }}>Hotovo</strong>
  }

  return value
}

const styles = (theme: Theme) => createStyles({
  paper: {
    margin: 'auto',
    marginBottom: 48,
    overflow: 'hidden'
  },
  table: {
    minWidth: 700
  },
  toolbar: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    width: '100%'
  },

  paymentDetailList: {},
  paymentDetailPaper: {
    maxHeight: 435
  },

  emailPreviewList: {},
  emailPreviewPaper: {
    maxHeight: 800
  },

  dialogTitleRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogTitleCloseButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props: any) => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography={true} className={classes.dialogTitleRoot}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Zavřít" className={classes.dialogTitleCloseButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

interface Props extends WithStyles<typeof styles> {
  data: any,
  dimensions: any,
  user?: any
}

const ClientsMyContracts = (props: Props) => {
  const { classes, data } = props

  const [generateContractPdfDialog, setGenerateContractPdfDialogVisible] = useState(false)
  const [downloadInvoicePdfDialog, setDownloadInvoicePdfDialogVisible] = useState(false)
  const [emailPreviewDialog, setEmailPreviewDialogVisible] = useState(false)
  const [paymentDetailDialog, setPaymentDetailDialogVisible] = useState(false)

  const [contractIsLoading, setContractIsLoading] = useState<boolean>(false)
  const [contractUrl, setContractUrl] = useState<null | string>(null)
  const [contractError, setContractError] = useState<null | string>(null)

  const [invoiceIsLoading, setInvoiceIsLoading] = useState<boolean>(false)
  const [invoiceUrl, setInvoiceUrl] = useState<null | string>(null)
  const [invoiceError, setInvoiceError] = useState<null | string>(null)

  const [tab, setTab] = React.useState(0)
  const [history, setHistory] = React.useState([])

  const [email, setEmail] = useState<any>({})
  const [payment, setPayment] = useState<any>({})

  const [events, setEvents] = React.useState({
    contract: true,
    payment: true,
    invoice: true,
    distribution: true,
    email: true
  })

  const handleEventsChange = (name: string) => (event: any) => {
    setEvents({ ...events, [name]: event.target.checked })
  }

  const resizeIframe = (e: any): void => {
    const iframe = e.target

    if (iframe) {
      const iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow
      if (iframeWin.document.body) {
        iframe.height = iframeWin.document.documentElement.scrollHeight || iframeWin.document.body.scrollHeight
      }
    }
  }

  const getMessageHtml = (): any => {
    return String(email.bodyHtml).replace('cid:gclogo', '/leh.png')
  }

  const handleSwitchTab = (event: any, newValue: number): void => {
    setTab(newValue)
  }

  const emailPreviewShow = (item: any): void => {
    setEmail(item)
    setEmailPreviewDialogVisible(true)
  }

  const emailPreviewHide = (): void => {
    setEmailPreviewDialogVisible(false)
    setEmail({})
  }

  const paymentDetailShow = (item: any): void => {
    setPayment(item)
    setPaymentDetailDialogVisible(true)
  }

  const paymentDetailHide = (): void => {
    setPaymentDetailDialogVisible(false)
    setPayment({})
  }

  const prepareContractsHistory = useCallback(() => {
    const h = []

    // Platby
    if (data.payments) {
      data.payments.items.map((item: any) => {
        const date = new Date(item.date)
        h.push({
          date: date.setSeconds(date.getSeconds() + 1),
          dateFormatted: format(new Date(date), 'dd.MM.yyyy'),
          title: `Přijata platba ve výši ${ts(item.amount)} Kč.`,
          type: `Platba`,
          eventName: 'payment',

          action: true,
          actionOnClick: () => { paymentDetailShow(item) },
          actionTitle: `Detail`
        })
        return item
      })
    }

    // Úhrada VCN
    if (data.entryPriceIncreaseIsPaid) {
      const date = new Date(data.entryPriceIncreaseWasPaidAt)
      h.push({
        date: date.setSeconds(date.getSeconds() + 2),
        dateFormatted: format(new Date(date), 'dd.MM.yyyy'),
        title: `Vstupní cenové navýšení bylo uhrazeno!`,
        type: `Smlouva`,
        eventName: 'contract',

        action: false
      })
    }

    // Faktury
    if (data.invoices) {
      data.invoices.items.map((item: any) => {
        const date = new Date(item.dateOfIssue)
        h.push({
          date: date.setSeconds(date.getSeconds() + 3),
          dateFormatted: format(new Date(date), 'dd.MM.yyyy'),
          title: `Vystavena faktura č. ${item.invoiceNumber}.`,
          type: `Faktura`,
          eventName: 'invoice',

          action: true,
          actionOnClick: () => { handleGetPdfInvoice(item.id) },
          actionTitle: `Stáhnout PDF`
        })
        return item
      })
    }

    // Distribuce
    if (data.distributions) {
      data.distributions.items.map((item: any) => {
        const date = new Date(item.createdAt)
        h.push({
          date: new Date('2019-12-12'), // @todo
          dateFormatted: format(new Date(date), 'dd.MM.yyyy'),
          title: `Distribuce mince "${item.coin.denominationMotif}" za ${ts(item.price)} Kč.`,
          type: `Distibuce`,
          eventName: 'distribution',

          action: false
        })
        return item
      })
    }

    // Datum uzavření smlouvy
    h.push({
      date: new Date(data.createdAt),
      dateFormatted: format(new Date(data.createdAt), 'dd.MM.yyyy HH:mm'),
      title: `Smlouva č. ${data.contractNumber} byla uzavřena.`,
      type: `Smlouva`,
      eventName: 'contract',

      action: true,
      actionOnClick: () => { handleGetPdfContract(data.id) },
      actionTitle: `Stáhnout PDF`
    })

    // Emaily
    if (data.emails) {
      data.emails.items.map((item: any) => {
        h.push({
          date: new Date(item.receivedAt),
          dateFormatted: format(new Date(item.receivedAt), 'dd.MM.yyyy HH:mm'),
          title: `Odeslán email "${item.subject}".`,
          type: `Email`,
          eventName: 'email',

          action: true,
          actionOnClick: () => { emailPreviewShow(item) },
          actionTitle: `Náhled`
        })
        return item
      })
    }

    setHistory(sortBy(h, 'date', 'DESC', (v: any): any => new Date(v)))
  }, [data])

  useEffect(() => {
    prepareContractsHistory()
  }, [data, prepareContractsHistory])

  const openGenerateContractPdfDialog = (): void => { setGenerateContractPdfDialogVisible(true) }
  const closeGenerateContractPdfDialog = (): void => { setGenerateContractPdfDialogVisible(false) }

  const openDownloadInvoicePdfDialog = (): void => { setDownloadInvoicePdfDialogVisible(true) }
  const closeDownloadInvoicePdfDialog = (): void => { setDownloadInvoicePdfDialogVisible(false) }

  const handleGetPdfContract = async (id: string): Promise<void> => {
    openGenerateContractPdfDialog()
    
    setContractIsLoading(true)
    setContractError(null)

    try {
      const lambdaFunction = `get-contract`
      const url = `${process.env.REACT_APP_EXECUTE_API_URL}/${lambdaFunction}`

      const response = await fetch(url, {
        body: JSON.stringify({ contractId: id }),
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Authorization': props.user.idToken,
          'Content-Type': 'application/json'
        },
        method: 'POST',
        mode: 'cors'
      })

      if (response.ok && response.status === 200) {
        const json = await response.json()
        if ('response' in json && 'url' in json.response) {
          setContractUrl(json.response.url)
        } else {
          setContractError(`Došlo k chybě při získávání Smlouvy v PDF (003).`)
        }
      } else {
        setContractError(`Došlo k chybě při získávání Smlouvy v PDF (002).`)
      }
    } catch (e) {
      setContractError(`Došlo k chybě při získávání Smlouvy v PDF (001).`)
    }

    setContractIsLoading(false)
  }

  const handleGetPdfInvoice = async (id: string): Promise<void> => {
    openDownloadInvoicePdfDialog()
    
    setInvoiceIsLoading(true)
    setInvoiceError(null)

    try {
      const lambdaFunction = `get-invoice`
      const url = `${process.env.REACT_APP_EXECUTE_API_URL}/${lambdaFunction}`

      const response = await fetch(url, {
        body: JSON.stringify({ invoiceId: id }),
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Authorization': props.user.idToken,
          'Content-Type': 'application/json'
        },
        method: 'POST',
        mode: 'cors'
      })

      if (response.ok && response.status === 200) {
        const json = await response.json()
        if ('response' in json && 'url' in json.response) {
          setInvoiceUrl(json.response.url)
        } else {
          setInvoiceError(`Došlo k chybě při získávání Faktury v PDF (003).`)
        }
      } else {
        setInvoiceError(`Došlo k chybě při získávání Faktury v PDF (002).`)
      }
    } catch (e) {
      setInvoiceError(`Došlo k chybě při získávání Faktury v PDF (001).`)
    }

    setInvoiceIsLoading(false)
  }

  const numberOfDistributedCoins = data.distributions && 'items' in data.distributions && data.distributions.items instanceof Array
    ? data.distributions.items.length
    : 0

  const paymentsAmount = data.payments && 'items' in data.payments && data.payments.items instanceof Array
    ? data.payments.items.reduce((accumulator: any, item: any) => accumulator + parseFloat(item.amount), 0.0)
    : 0
  // console.log("->data.distributions", data.distributions);
  const distributionsAmount = data.distributions && 'items' in data.distributions && data.distributions.items instanceof Array
    ? data.distributions.items.reduce((accumulator: any, item: any) => accumulator + parseFloat(item.price), 0.0)
    : 0
  const savedAmount = paymentsAmount - data.entryPriceIncrease - distributionsAmount

  return (
    <Paper elevation={2} className={classes.paper}>
      <AppBar position="static" color="default" style={{ width: `${props.dimensions.width - 72}px`, margin: 'auto' }}>
        <Tabs
          value={tab}
          onChange={handleSwitchTab}
          variant="scrollable"
          scrollButtons="off"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Smlouva" icon={<FontAwesomeIcon icon={['fal', 'file-signature']} size='2x' />} />
          <Tab label="Klient" icon={<FontAwesomeIcon icon={['fal', 'user']} size='2x' />} />
          <Tab label="Platby" icon={<FontAwesomeIcon icon={['fal', 'money-check-alt']} size='2x' />} />
          <Tab label="Faktury" icon={<FontAwesomeIcon icon={['fal', 'file-invoice']} size='2x' />} />
          <Tab label="Distribuce" icon={<FontAwesomeIcon icon={['fal', 'truck-container']} size='2x' />} />
          <Tab label="Komunikace" icon={<FontAwesomeIcon icon={['fal', 'mail-bulk']} size='2x' />} />
          <Tab label="Průběh" icon={<FontAwesomeIcon icon={['fal', 'history']} size='2x' />} />
        </Tabs>
      </AppBar>

      <Toolbar className={classes.toolbar}>
        <Typography variant='h5' gutterBottom={true}>
          Smlouva „Vyražená jistota“ č. {data.contractNumber}
        </Typography>

        <div style={{ marginLeft: `25px`, marginBottom: `11px` }}>
          <Button variant="outlined" color="primary" onClick={() => { handleGetPdfContract(data.id) }}>
            Stáhnout smlouvu v PDF
          </Button>
        </div>
      </Toolbar>

      {tab === 0 && <Grid container={true} direction='row' spacing={1} style={{ padding: `10px` }}>
        <Grid item={true} xs={6}>
          <Typography variant='h6' style={{ marginLeft: `15px` }} color='secondary'>
            Informace o smlouvě
          </Typography>

          <List component='nav' >
            <ListItem>
              <ListItemText
                primary={data.contractNumber}
                secondary='Číslo smlouvy (variabilní symbol)'
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={format(new Date(data.createdAt), 'dd.MM.yyyy')}
                secondary='Datum sjednání návrhu Kupní smlouvy'
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={`${ts(data.monthlyAmount)} Kč`}
                secondary='Měsíční částka'
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={`${data.totalYears} let`}
                secondary='Délka trvání'
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={`${numberOfDistributedCoins} ks / ${data.numberOfCoins} ks (${data.qualityOfCoins})`}
                secondary='Počet expedovaných / objednaných kusů (kvalita)'
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item={true} xs={6}>
          <Typography variant='h6' style={{ marginLeft: `15px` }} color='secondary'>
            Placení smlouvy
          </Typography>

          <List component='nav'>
            <ListItem>
              <ListItemText
                primary={`${ts(data.entryPriceIncrease)} Kč ${data.entryPriceIncreaseIsPaid
                  ? `(zaplaceno ${format(new Date(data.entryPriceIncreaseWasPaidAt), 'dd.MM.yyyy')})`
                  : `(zatím uhrazeno ${data.entryPriceIncreasePaidAmount ? `${ts(data.entryPriceIncreasePaidAmount)} Kč` : `0 Kč`}`})`}
                secondary='Vstupní cenové navýšení'
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={`${ts(paymentsAmount)} Kč (${data.lastPaymentReceivedAt ? `poslední ${format(new Date(data.lastPaymentReceivedAt), 'dd.MM.yyyy')}` : `zatím žádná platba`})`}
                secondary='Platby celkem'
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={`${ts(distributionsAmount)} Kč`}
                secondary='Cena za distribuované mince'
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={`${savedAmount > 0 ? ts(savedAmount) : 0} Kč`}
                primaryTypographyProps={{ color: 'primary' }}
                secondary='Naspořeno na mince (zůstatek)'
              />
            </ListItem>
            <Divider />
          </List>
        </Grid>
      </Grid>}

      {tab === 1 && <Grid container={true} direction='row' spacing={1} style={{ padding: `10px` }}>
        <Grid item={true} xs={6}>
          <Typography variant='h6' style={{ marginLeft: `15px` }} color='secondary'>
            Základní údaje
          </Typography>

          <List component='nav' >
            <ListItem>
              <ListItemText
                primary={`${data.clientFirstname} ${data.clientLastname}`}
                secondary='Jméno a příjmení'
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={`${data.clientAddressStreet} ${data.clientAddressStreetNumber}`}
                secondary='Ulice a číslo'
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={`${data.clientAddressZip} ${data.clientAddressTown}`}
                secondary='PSČ a Město'
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={data.clientHasDifferentDeliveryAddress === true ? `Jiná` : `Stejná`}
                secondary='Adresa pro doručení'
              />
            </ListItem>
          </List>

          {data.clientHasDifferentDeliveryAddress === true && <React.Fragment>
            <Typography variant='h6' style={{ marginLeft: `15px` }} color='secondary'>
              Adresa pro doručení
            </Typography>

            <List component='nav' >
              <ListItem>
                <ListItemText
                  primary={`${data.clientDeliveryFirstname} ${data.clientDeliveryLastname} (${data.clientDeliveryBirthYear})`}
                  secondary='Jméno a příjmení (rok narození)'
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={`${data.clientDeliveryAddressStreet} ${data.clientDeliveryAddressStreetNumber}`}
                  secondary='Ulice a číslo'
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={`${data.clientDeliveryAddressZip} ${data.clientDeliveryAddressTown}`}
                  secondary='PSČ a Město'
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={data.clientDeliveryPhone}
                  secondary='Telefonní číslo'
                />
              </ListItem>
            </List>
          </React.Fragment>}
        </Grid>

        <Grid item={true} xs={6}>
          <Typography variant='h6' style={{ marginLeft: `15px` }} color='secondary'>
            Kontaktní údaje
          </Typography>

          <List component='nav'>
            <ListItem>
              <ListItemText
                primary={data.clientPhone}
                secondary='Telefonní číslo'
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={data.clientEmail}
                secondary='Email'
              />
            </ListItem>
          </List>

          <Typography variant='h6' style={{ marginLeft: `15px` }} color='secondary'>
            Bankovní účet
          </Typography>

          <List component='nav'>
            <ListItem>
              <ListItemText
                primary={`${data.clientBankAccountNumber}/${data.clientBankCode}`}
                secondary='Číslo účtu'
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>}

      {tab === 2 && <Grid container={true} direction='row' spacing={1} style={{ padding: `10px` }}>
        <Grid item={true} xs={12}>

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Datum</TableCell>
                <TableCell align="right">Částka</TableCell>
                <TableCell align="right">VS</TableCell>
                <TableCell align="right">KS</TableCell>
                <TableCell align="right">SS</TableCell>
                <TableCell align="right">Stav</TableCell>
                <TableCell align='right' style={{ width: `100px` }}>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.payments && data.payments.items.map((item: any) => (
                <TableRow key={item.id} hover={true}>
                  <TableCell align='left'>{format(new Date(item.date), 'dd.MM.yyyy')}</TableCell>
                  <TableCell align='right'><strong>{ts(item.amount)}&nbsp;Kč</strong></TableCell>
                  <TableCell align='right'>{paymentSymbolCallback(item.variableSymbol)}</TableCell>
                  <TableCell align='right'>{paymentSymbolCallback(item.constantSymbol)}</TableCell>
                  <TableCell align='right'>{paymentSymbolCallback(item.specificSymbol)}</TableCell>
                  <TableCell align="right">{paymentStatusCallback(item.status)}</TableCell>
                  <TableCell align='right' style={{ width: `100px` }}>
                    <Button variant='outlined' size='small' color='secondary'
                      onClick={() => { paymentDetailShow(item) }}>
                      Detail
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </Grid>
      </Grid>}

      {tab === 3 && <Grid container={true} direction='row' spacing={1} style={{ padding: `10px` }}>
        <Grid item={true} xs={12}>

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Číslo faktury</TableCell>
                <TableCell align="left">Datum</TableCell>
                <TableCell align="right">Částka</TableCell>
                <TableCell align="right">VS</TableCell>
                <TableCell align="right">Stav</TableCell>
                <TableCell align='right' style={{ width: `150px` }}>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.invoices && data.invoices.items.map((item: any) => (
                <TableRow key={item.id} hover={true}>
                  <TableCell align='left'><strong>{item.invoiceNumber}</strong></TableCell>
                  <TableCell align='left'>{format(new Date(item.dateOfIssue), 'dd.MM.yyyy')}</TableCell>
                  <TableCell align='right'><strong>{ts(item.price)}&nbsp;Kč</strong></TableCell>
                  <TableCell align='right'>{paymentSymbolCallback(item.variableSymbol)}</TableCell>
                  <TableCell align='right'>{invoiceStatusCallback(item.status)}</TableCell>
                  <TableCell align='right' style={{ width: `150px` }}>
                    <Button variant='outlined' size='small' color='secondary'
                      onClick={() => { handleGetPdfInvoice(item.id) }}>
                      Stáhnout PDF
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </Grid>
      </Grid>}

      {tab === 4 && <Grid container={true} direction='row' spacing={1} style={{ padding: `10px` }}>
        <Grid item={true} xs={12}>

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Datum</TableCell>
                <TableCell align="left">Mince</TableCell>
                <TableCell align="right">Cena</TableCell>
                <TableCell align="right">Stav</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.distributions && data.distributions.items.map((item: any) => (
                <TableRow key={item.id} hover={true}>
                  <TableCell align='left'>{format(new Date(item.createdAt), 'dd.MM.yyyy')}</TableCell>
                  <TableCell align='left'>{item.coin.denominationMotif}</TableCell>
                  <TableCell align='right'><strong>{ts(item.price)}&nbsp;Kč</strong></TableCell>
                  <TableCell align='right'>{distributionStatusCallback(item.status)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </Grid>
      </Grid>}

      {tab === 5 && <Grid container={true} direction='row' spacing={1} style={{ padding: `10px` }}>
        <Grid item={true} xs={12}>

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Datum</TableCell>
                <TableCell align="left">Příjemce</TableCell>
                <TableCell align="left">Předmět</TableCell>
                <TableCell align="right">Stav</TableCell>
                <TableCell align='right' style={{ width: `100px` }}>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.emails && sortBy(data.emails.items.slice(), 'receivedAt', 'DESC', (v: any): any => new Date(v)).map((item: any) => (
                <TableRow key={item.id} hover={true}>
                  <TableCell align='left'>{format(new Date(item.receivedAt), 'dd.MM.yyyy HH:mm')}</TableCell>
                  <TableCell align='left'>{item.to[0]}</TableCell>
                  <TableCell align='left'>{item.subject}</TableCell>
                  <TableCell align='right'><strong style={{ color: '#baa436' }}>Odesláno</strong></TableCell>
                  <TableCell align='right' style={{ width: `100px` }}>
                    <Button variant='outlined' size='small' color='secondary'
                      onClick={() => { emailPreviewShow(item) }}>
                      Náhled
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </Grid>
      </Grid>}

      {tab === 6 && <Grid container={true} direction='row' spacing={1} style={{ padding: `10px` }}>
        <Grid item={true} xs={12}>

          <FormControl component='fieldset' style={{ marginLeft: `17px` }}>
            <FormLabel component='legend'>Zobrazit události týkající se</FormLabel>
            <FormGroup row={true}>
              <FormControlLabel
                control={
                  <Checkbox
                  checked={events.contract}
                    onChange={handleEventsChange('contract')}
                    value='contract'
                    color='primary'
                  />
                }
                label='Smlouvy'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={events.payment}
                    onChange={handleEventsChange('payment')}
                    value='payment'
                    color='primary'
                  />
                }
                label='Plateb'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={events.invoice}
                    onChange={handleEventsChange('invoice')}
                    value='invoice'
                    color='primary'
                  />
                }
                label='Faktur'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={events.distribution}
                    onChange={handleEventsChange('distribution')}
                    value='distribution'
                    color='primary'
                  />
                }
                label='Distribuce'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={events.email}
                    onChange={handleEventsChange('email')}
                    value='email'
                    color='primary'
                  />
                }
                label='Komunikace'
              />
            </FormGroup>
          </FormControl>

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ width: `175px` }}>Datum</TableCell>
                <TableCell align="center" style={{ width: `130px` }}>&nbsp;</TableCell>
                <TableCell align="left">Událost</TableCell>
                <TableCell align='right' style={{ width: `150px` }}>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((item: any) => {
                if ((events as any)[item.eventName] !== true) {
                  return null
                }

                return (
                  <TableRow key={item.id} hover={true}>
                    <TableCell align='left' style={{ width: `175px` }}>{item.dateFormatted}</TableCell>
                    <TableCell align="center" style={{ width: `130px` }}><small>{item.type}</small></TableCell>
                    <TableCell align='left'>{item.title}</TableCell>
                    <TableCell align='right' style={{ width: `150px` }}>
                      {item.action === true
                        ? <Button variant='outlined' size='small' color='secondary'
                            onClick={item.actionOnClick}>
                            {item.actionTitle}
                          </Button> 
                        : ``}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>

        </Grid>
      </Grid>}

      <GenerateContractPdfDialog
        title={`Smlouva`}
        open={generateContractPdfDialog}
        handleClose={closeGenerateContractPdfDialog}
        loading={contractIsLoading}
        loadingText={`Smlouva se připravuje ...`}
        doneText={`Smlouva je připravena`}
        buttonText={`Stáhnout smlouvu v PDF`}
        url={contractUrl}
        error={contractError}
      />

      <GenerateContractPdfDialog
        title={`Faktura`}
        open={downloadInvoicePdfDialog}
        handleClose={closeDownloadInvoicePdfDialog}
        loading={invoiceIsLoading}
        loadingText={`Faktura se připravuje ...`}
        doneText={`Faktura je připravena`}
        buttonText={`Stáhnout fakturu v PDF`}
        url={invoiceUrl}
        error={invoiceError}
      />

      {/* Payment Detail */}

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={paymentDetailDialog}
        classes={{ paper: classes.paymentDetailPaper }}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
      >
        <DialogTitle onClose={paymentDetailHide}>Detail platby</DialogTitle>
        <DialogContent dividers={true}>
          <List className={classes.paymentDetailList}>
           <ListItem alignItems="flex-start">
              <ListItemText
                primary={payment !== null ? (payment.date ? format(new Date(payment.date), 'dd.MM.yyyy') : ` - `) : ` - `}
                secondary={`Datum`}
              />
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={payment !== null ? payment.bankAccountNumberWithBankCode : ` - `}
                secondary={`Číslo účtu`}
              />
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={payment !== null ? payment.bankAccountName : ` - `}
                secondary={`Název účtu`}
              />
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={payment !== null ? `${ts(payment.amount)} ${payment.currency}` : ` - `}
                secondary={`Částka`}
              />
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={payment !== null ? paymentSymbolCallback(payment.variableSymbol) : ` - `}
                secondary={`Variabilní symbol`}
              />
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={payment !== null ? (payment.message ? payment.message : ` - `) : ` - `}
                secondary={`Zpráva`}
              />
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={payment !== null ? (paymentStatusCallback(payment.status)) : ` - `}
                secondary={`Stav`}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={paymentDetailHide} color="primary">
            Zavřít
          </Button>
        </DialogActions>
      </Dialog>

      {/* Email Preview */}

      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={emailPreviewDialog}
        classes={{ paper: classes.emailPreviewPaper }}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
      >
        <DialogTitle onClose={emailPreviewHide}>Náhled emailu</DialogTitle>
        <DialogContent dividers={true}>
          <List className={classes.emailPreviewList}>
           <ListItem alignItems="flex-start">
            <ListItemText
              primary={email.from}
              secondary='Odesílatel'
            />
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={email.receivedAt ? format(new Date(email.receivedAt), 'dd.MM.yyyy HH:mm') : ` - `}
                secondary='Odesláno'
              />
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={email.to && email.to instanceof Array ? email.to.join(', ') : ` - `}
                secondary='Příjemce'
              />
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={email.subject}
                secondary='Předmět'
              />
            </ListItem>
            <Divider />
          </List>

          <iframe title="messageBody" width="100%" height="800" frameBorder={0} scrolling="no" onLoad={resizeIframe} srcDoc={getMessageHtml()} />

          <List className={classes.emailPreviewList}>
            <Divider />
            <ListItem alignItems="flex-start">
             <ListItemText
                primary={<strong style={{ color: '#baa436' }}>Odesláno</strong>}
                secondary='Stav'
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={emailPreviewHide} color="primary">
            Zavřít
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

const mapStateToProps = (state: any) => ({
  dimensions: state.app.dimensions,
  user: state.user
})

export default connect(mapStateToProps)(
  withStyles(styles)(ClientsMyContracts)
)
