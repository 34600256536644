import gql from 'graphql-tag'

const mutation = gql`
  mutation DELETE_CONTRACT_MUTATION (
    $contractId: ID!,
    $deletedAt: AWSDateTime,
    $deletedBy: String,
    $expectedVersion: Int!
  ) {
    updateContract(input: {
      id: $contractId,
      deleted: true,
      deletedAt: $deletedAt,
      deletedBy: $deletedBy,
      expectedVersion: $expectedVersion
    }) {
      id
      __typename
      contractNumber
      deleted
      deletedAt
      deletedBy
    }
  }
`

export default mutation
