import React from 'react'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { Link as RouterLink } from 'react-router-dom'

const PaymentListColumnAction = ({ row, column }: any): any => {
  const paymentId = column.getPaymentId(row)

  if (paymentId === null) {
    return <span>-</span>
  }

  return (
    <Link to={`/platby/detail/${paymentId}`} component={RouterLink}>
      <Typography variant='caption'>Detail</Typography>
    </Link>
  )
}

export default PaymentListColumnAction
