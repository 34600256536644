import React from 'react'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import CircularProgress from '@material-ui/core/CircularProgress'

const styles = (theme: Theme) => createStyles({
  loadingContainer: {
    height: `100%`,
    width: `100%`,

    alignItems: `center`,
    display: `flex`,
    justifyContent: `center`,

    zIndex: theme.zIndex.drawer + 1,
  },
  progress: {
    margin: theme.spacing(2)
  },
  absolute: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(35, 31, 32, 0.50)'
  }
})

interface Props extends WithStyles<typeof styles> {
  fullscreen?: boolean
}

const Loading = (props: Props) => {
  const { classes } = props

  return (
    <div className={clsx(classes.loadingContainer, classes.absolute)}>
      <CircularProgress className={classes.progress} color='secondary' />
    </div>
  )
}

export default withStyles(styles)(Loading)
