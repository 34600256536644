import gql from 'graphql-tag'

const query = gql`
  query GET_STOCK_QUERY($id: ID!) {
    getStock(id: $id) {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      emission {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        type
        name
        description
        yearFrom
        yearTo
        coins {
          nextToken
        }
        stock {
          nextToken
        }
        version
      }
      coin {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        emission {
          id
          createdAt
          createdBy
          updatedAt
          updatedBy
          deleted
          deletedAt
          deletedBy
          type
          name
          description
          yearFrom
          yearTo
          version
        }
        value
        denominationMotif
        description
        fineness
        diameter
        thickness
        weightFine
        weightGross
        decreeNo
        mint
        designer
        dateOfIssue
        mintageLimitTotal
        mintageLimitNormal
        mintageLimitProof
        normalEdge
        normalActualMintage
        normalPriceCalculated
        normalPriceActual
        proofEdge
        proofActualMintage
        proofPriceCalculated
        proofPriceActual
        stock {
          nextToken
        }
        distributions {
          nextToken
        }
        version
      }
      series
      purchasedAt
      pricePurchase
      priceSelling
      status
      sold
      soldAt
      invoices (limit: 1000) {
        items {
          id
          __typename
          invoiceNumber
          owners
          createdAt
          createdBy
          updatedAt
          updatedBy
          deleted
          deletedAt
          deletedBy
          client {
            id
            __typename
            benefitId
            isFs
            cognitoUserId
            owner
            createdAt
            createdBy
            updatedAt
            updatedBy
            deleted
            deletedAt
            deletedBy
            titleBefore
            firstname
            lastname
            titleAfter
            personalNumber
            birthDate
            email
            phone
            addressFormatted
            addressStreet
            addressStreetNumber
            addressZip
            addressTown
            hasDifferentDeliveryAddress
            deliveryTitleBefore
            deliveryFirstname
            deliveryLastname
            deliveryTitleAfter
            deliveryCompanyName
            deliveryPhone
            deliveryBirthYear
            deliveryAddressFormatted
            deliveryAddressStreet
            deliveryAddressStreetNumber
            deliveryAddressZip
            deliveryAddressTown
            bankAccountNumber
            bankCode
            status
            version
          }
          clientTitleBefore
          clientFirstname
          clientLastname
          clientTitleAfter
          clientPersonalNumber
          clientBirthDate
          clientEmail
          clientPhone
          clientAddressFormatted
          clientAddressStreet
          clientAddressStreetNumber
          clientAddressZip
          clientAddressTown
          clientHasDifferentDeliveryAddress
          clientDeliveryTitleBefore
          clientDeliveryFirstname
          clientDeliveryLastname
          clientDeliveryTitleAfter
          clientDeliveryCompanyName
          clientDeliveryPhone
          clientDeliveryBirthYear
          clientDeliveryAddressFormatted
          clientDeliveryAddressStreet
          clientDeliveryAddressStreetNumber
          clientDeliveryAddressZip
          clientDeliveryAddressTown
          clientBankAccountNumber
          clientBankCode
          textAboveItems
          textBelowItems
          price
          dateOfIssue
          dueDate
          variableSymbol
          constantSymbol
          specificSymbol
          status
          version
        }
        nextToken
      }
      version
    }
  }
`

export default query
