import { all, takeLatest } from 'redux-saga/effects'

import { PredictionTypes } from 'Stores/Prediction/Actions'
import Prediction from 'Sagas/Prediction'

export default function * root() {
  yield all([
    takeLatest(PredictionTypes.GET_DATA, Prediction.getData),
    takeLatest(PredictionTypes.GET_DATA_SUCCESS, Prediction.getDataSuccess),
    takeLatest(PredictionTypes.GET_DATA_FAILURE, Prediction.getDataFailure)
  ])
}
