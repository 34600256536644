import { put } from 'redux-saga/effects'

import StockActions from 'Stores/Stock/Actions'

import { recursivelyLoadAllData } from 'Sagas'
import listStocksQuery from 'Graphql/Queries/listStocksQuery'

export function * listStocks ({ filter }: any) {
  try {
    yield put(StockActions.listStocksLoading())

    const variables = {} as any

    const data = yield recursivelyLoadAllData(listStocksQuery, variables, 'listStocks')
    // @todo
    data.sort((a: any, b: any) => {
      return (new Date(b.purchasedAt) as any) - (new Date(a.purchasedAt) as any)
    })

    yield put(StockActions.listStocksSuccess(data))
  } catch (error) {
    yield put(StockActions.listStocksFailure(error))
  }
}

export default listStocks
