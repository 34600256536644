import React from 'react'

import { connect } from 'react-redux'
import WaitDialogActions from 'Stores/WaitDialog/Actions'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import DraggablePaper from 'Components/_Common/Dialogs/DraggablePaper'

import Loading from 'Components/Loading'

interface Props {
  closeContractOpen?: boolean,
  data?: any,
  close?: any
}

const WaitDialog = (props: Props) => {
  const { open, loading, title, text, error, actionText, actionUrl } = props.data

  return (
    <Dialog
      PaperComponent={DraggablePaper}
      open={open && !props.closeContractOpen}
      onClose={props.close}
      aria-labelledby='form-dialog-title'
      maxWidth='sm'
      fullWidth={true}
    >
      <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        {loading && <Loading />}
        {error !== null &&  <DialogContentText style={{ color: `red` }}>{error}</DialogContentText>}
        {text !== null &&  <DialogContentText>{text}</DialogContentText>}
        {!loading && actionUrl !== null && <div style={{ display: `flex`, width: `100%`, marginTop: `30px`, justifyContent: `center`, alignItems: `center` }}>
          <Button href={actionUrl} variant="outlined" size="large" color="secondary">
            {actionText}
          </Button>
        </div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close}>
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state: any) => ({
  closeContractOpen: state.closeContractDialog.open,
  data: state.waitDialog
})

const mapDispatchToProps = (dispatch: any) => ({
  close: () => dispatch(WaitDialogActions.close())
})

export default connect(mapStateToProps, mapDispatchToProps)(
  WaitDialog
)
