import { getContext, put } from 'redux-saga/effects'

import ClientActions from 'Stores/Client/Actions'

import getClientQuery from 'Graphql/Queries/getClientQuery'

export function * getClient ({ id }: any) {
  try {
    yield put(ClientActions.getClientLoading())

    const graphql = yield getContext('graphql')
    const variables = { id } as any

    const result = yield graphql.query(getClientQuery, variables)

    // @todo
    if (result && result.data && result.data.getClient) {
      yield put(ClientActions.getClientSuccess(result.data.getClient))
    }
  } catch (error) {
    yield put(ClientActions.getClientFailure(error))
  }
}

export default getClient
