import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getData: ['id'], // GET_DATA
  getDataLoading: null, // GET_DATA_LOADING
  getDataSuccess: ['data'], // GET_DATA_SUCCESS
  getDataFailure: ['error'] // GET_DATA_FAILURE
}, { prefix: 'PREDICTION_' })

export const PredictionTypes = Types

export default Creators
