import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import StockActions from 'Stores/Stock/Actions'
import * as StockSelect from 'Stores/Stock/Select'
import InvoiceActions from 'Stores/Invoice/Actions'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { Link as RouterLink } from 'react-router-dom'

const StockListColumnAction = ({ row, column }: any): any => {
  const modalOpened = useSelector(StockSelect.stocksModalOpened)
  const modalTarget = useSelector(StockSelect.stocksModalTarget)
  const dispatch = useDispatch()

  const stockId = column.getStockId(row)

  if (stockId === null) {
    return <span>-</span>
  }

  const handleClick = (e: any) => {
    if (modalOpened) {
      e.preventDefault()

      if (modalTarget === 'invoice') {
        const id = row.id
        const text = `${row.emission.name} - ${row.series ? 'sada' : row.coin.denominationMotif}`
        const price = row.priceSelling
        dispatch(InvoiceActions.formAddItemFromStock(id, text, price))
      }

      dispatch(StockActions.listStocksOpenModal(false))
    }
  }

  return (
    <Link
      to={`/sklad/polozka/${stockId}`}
      onClick={handleClick}
      component={RouterLink}
    >
      <Typography variant='caption'>{modalOpened ? 'Vybrat' : 'Detail'}</Typography>
    </Link>
  )
}

export default StockListColumnAction
