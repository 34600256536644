import React, { ReactNode } from 'react'

import { Field, Form } from 'react-final-form'

import { Alert, AlertTitle } from '@material-ui/lab'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import DatePicker from 'Components/_Common/Form/Inputs/DatePicker'
import Switch from 'Components/_Common/Form/Inputs/Switch'
import TextField from 'Components/_Common/Form/Inputs/TextField'

import validate from './validate'

import FormSection from 'Components/_Common/Form/Section'
import InputRow from 'Components/_Common/Form/InputRow'

const styles = (theme: Theme) => createStyles({
  appBar: {
    bottom: 0,
    height: 66,
    justifyContent: 'center',
    top: 'auto'
  },
  grow: {
    flexGrow: 1
  }
})

interface Props extends WithStyles<typeof styles> {
  edit: boolean,
  data: object | undefined,
  onSubmit: (input: {}) => Promise<void>,
  actionMenu?: ReactNode,
  loading?: boolean
}

const ClientForm = (props: Props) => {
  const { actionMenu, classes } = props

  const onSubmit = (input: any): void => {
    // @todo
    delete input.createdAt
    delete input.createdBy
    delete input.updatedAt
    delete input.updatedBy
  
    props.onSubmit({ input })
  }

  const renderForm = ({ form, handleSubmit, pristine, invalid, values }: any) => (
    <>
      <form onSubmit={handleSubmit}>
        <FormSection>
          <InputRow>
            <Grid item={true} xs={12}>
              <Alert severity='warning'>
                <AlertTitle>Informace!</AlertTitle>
                Změny prosím provádějte přímo na Smlouvě. Změny se pak automaticky přenesou i na Klienta.
              </Alert>
            </Grid>
          </InputRow>
          <br />

          <InputRow>
            <Grid item={true} xs={6}>
              <TextField
                name='personalNumber'
                label='Rodné číslo *'
                disabled={true}
              />
            </Grid>

            <Grid item={true} xs={6}>
              <DatePicker
                name='birthDate'
                label='Datum narození'
                disabled={true}
              />
            </Grid>
          </InputRow>

          <InputRow>
            <Grid item={true} xs={2}>
              <TextField
                name='titleBefore'
                label='Titul'
                disabled={true}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            <Grid item={true} xs={4}>
              <TextField
                name='firstname'
                label='Jméno *'
                disabled={true}
              />
            </Grid>

            <Grid item={true} xs={4}>
              <TextField
                name='lastname'
                label='Příjmení *'
                disabled={true}
              />
            </Grid>

            <Grid item={true} xs={2}>
              <TextField
                name='titleAfter'
                label='Titul'
                disabled={true}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </InputRow>

          <InputRow>
            <Grid item={true} xs={6}>
              <TextField
                name='phone'
                label='Telefon *'
                disabled={true}
              />
            </Grid>

            <Grid item={true} xs={6}>
              <TextField
                name='email'
                label='Email *'
                disabled={true}
              />
            </Grid>
          </InputRow>

          <InputRow>
            <Grid item={true} xs={6}>
              <TextField
                name='addressStreet'
                label='Ulice *'
                disabled={true}
              />
            </Grid>

            <Grid item={true} xs={6}>
              <TextField
                name='addressStreetNumber'
                label='Číslo popisné/orientační *'
                disabled={true}
              />
            </Grid>
          </InputRow>

          <InputRow>
            <Grid item={true} xs={6}>
              <TextField
                name='addressZip'
                label='PSČ *'
                disabled={true}
              />
            </Grid>

            <Grid item={true} xs={6}>
              <TextField
                name='addressTown'
                label='Město *'
                disabled={true}
              />
            </Grid>
          </InputRow>
        </FormSection>

        <FormSection>
          <InputRow>
            <Grid item={true} xs={6}>
              <FormControlLabel
                control={
                  <Field name='hasDifferentDeliveryAddress' component={Switch} type='checkbox' />
                }
                label={`Odlišná adresa pro zasílání`}
                disabled={true}
              />
            </Grid>
          </InputRow>

          {values.hasDifferentDeliveryAddress && <InputRow>
            <Grid item={true} xs={2}>
              <TextField
                name='deliveryTitleBefore'
                label='Titul'
                disabled={true}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            <Grid item={true} xs={4}>
              <TextField
                name='deliveryFirstname'
                label='Jméno *'
                disabled={true}
              />
            </Grid>

            <Grid item={true} xs={4}>
              <TextField
                name='deliveryLastname'
                label='Příjmení *'
                disabled={true}
              />
            </Grid>

            <Grid item={true} xs={2}>
              <TextField
                name='deliveryTitleAfter'
                label='Titul'
                disabled={true}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </InputRow>}

          {values.hasDifferentDeliveryAddress && <InputRow>
            <Grid item={true} xs={12}>
              <TextField
                name='deliveryCompanyName'
                label='Název firmy *'
                disabled={true}
              />
            </Grid>
          </InputRow>}

          {values.hasDifferentDeliveryAddress && <InputRow>
            <Grid item={true} xs={6}>
              <TextField
                name='deliveryAddressStreet'
                label='Ulice *'
                disabled={true}
              />
            </Grid>

            <Grid item={true} xs={6}>
              <TextField
                name='deliveryAddressStreetNumber'
                label='Číslo popisné/orientační *'
                disabled={true}
              />
            </Grid>
          </InputRow>}

          {values.hasDifferentDeliveryAddress && <InputRow>
            <Grid item={true} xs={6}>
              <TextField
                name='deliveryAddressZip'
                label='PSČ *'
                disabled={true}
              />
            </Grid>

            <Grid item={true} xs={6}>
              <TextField
                name='deliveryAddressTown'
                label='Město *'
                disabled={true}
              />
            </Grid>
          </InputRow>}

          {values.hasDifferentDeliveryAddress && <InputRow>
            <Grid item={true} xs={6}>
              <TextField name='deliveryPhone' label='Telefon *' disabled={true} />
            </Grid>

            <Grid item={true} xs={6}>
              <TextField name='deliveryBirthYear' label='Rok narození *' disabled={true} />
            </Grid>
          </InputRow>}
        </FormSection>

        <FormSection last>
          <InputRow>
            <Grid item={true} xs={6}>
              <TextField
                name='bankAccountNumber'
                label='Číslo bankovního účtu *'
                disabled={true}
              />
            </Grid>

            <Grid item={true} xs={6}>
              <TextField
                name='bankCode'
                label='Kód banky *'
                disabled={true}
              />
            </Grid>
          </InputRow>
        </FormSection>

        <AppBar color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              &nbsp;
            </Typography>
            {actionMenu}&nbsp;
            <Button type='submit' variant="contained" size="large" color='secondary'>
              Uložit klienta
            </Button>
            <Button size='large'>Zrušit bez uložení</Button>
          </Toolbar>
        </AppBar>

        {/* <pre>
          {JSON.stringify(values, null, 2)}
        </pre> */}

      </form>
    </>
  )

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={props.data}
      render={renderForm}
    />
  )
}

export default withStyles(styles)(ClientForm)
