import { Table } from '@devexpress/dx-react-grid-material-ui'

import formatDateCallback from 'Components/_Common/List/Callback/formatDate'
import formatMoneyCallback from 'Components/_Common/List/Callback/formatMoney'

import stockDataStatus from 'Components/Stock/Data/Status'

export const columns = [
  {
    name: 'stock.action',
    title: ' ',
    getStockId: (row: any): string => row.id
  },
  //
  {
    name: 'stock.purchasedAt',
    title: 'Koupeno',
    getCellValue: (row: any): string => formatDateCallback(row, 'purchasedAt')
  },
  //
  {
    name: 'emission.name',
    title: 'Emise',
    primary: false,
    getEmissionId: (row: any): string => row.emission.id,
    getCellValue: (row: any): string => row.emission.name
  },
  //
  {
    name: 'stock.series',
    title: 'Sada',
    getCellValue: (row: any): string => row.series ? 'A' : ''
  }, 
  //
  {
    name: 'stock.coin.denominationMotif',
    title: 'Mince *',
    primary: true,
    getStockId: (row: any): string => row.id,
    getCellValue: (row: any): any => row.coin ? row.coin.denominationMotif : 'celá sada'
  },
  //
  {
    name: 'stock.pricePurchase',
    title: 'Nákupní cena',
    getCellValue: (row: any): any => formatMoneyCallback(row, 'pricePurchase')
  },
  //
  {
    name: 'stock.priceSelling',
    title: 'Prodejní cena',
    getCellValue: (row: any): any => formatMoneyCallback(row, 'priceSelling')
  }, 
  //
  {
    name: 'stock.commission',
    title: 'Provize',
    getCellValue: (row: any): any => row.commission ? 'A' : ''
  },
  //
  {
    name: 'stock.invoices',
    title: 'Fakturováno',
    getCellValue: (row: any): any => row.invoices.items.length > 0 ? 'A' : ''
  }, 
  //
  {
    name: 'stock.status',
    title: 'Stav',
    getCellValue: (row: any): any => stockDataStatus(row.status)
  }
]

export const columnExtensions: Table.ColumnExtension[] = [
  {
    align: 'center',
    columnName: 'stock.action',
    width: 75
  },
  //
  {
    align: 'left',
    columnName: 'stock.purchasedAt',
    width: 150
  },
  //
  {
    align: 'left',
    columnName: 'emission.name',
    width: 200,
  },
  //
  {
    align: 'center',
    columnName: 'stock.series',
    width: 66,
  },
  //
  {
    align: 'left',
    columnName: 'stock.coin.denominationMotif'
  },
  //
  {
    align: 'right',
    columnName: 'stock.pricePurchase',
    width: 150
  },
  //
  {
    align: 'right',
    columnName: 'stock.priceSelling',
    width: 150
  },
  //
  {
    align: 'center',
    columnName: 'stock.commission',
    width: 66,
  },
  //
  {
    align: 'center',
    columnName: 'stock.invoices',
    width: 100,
  },
  //
  {
    align: 'center',
    columnName: 'stock.status',
    width: 100
  }
]

export default {
  columnExtensions,
  columns
}
