import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ClientActions from 'Stores/Client/Actions'
import * as ClientSelect from 'Stores/Client/Select'
import ContractActions from 'Stores/Contract/Actions'
import InvoiceActions from 'Stores/Invoice/Actions'

import Link from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom'

const ClientListColumnName = ({ row, column }: any): any => {
  const modalOpened = useSelector(ClientSelect.clientsModalOpened)
  const modalTarget = useSelector(ClientSelect.clientsModalTarget)
  const dispatch = useDispatch()

  const clientId = column.getClientId(row)
  const cellValue = column.getCellValue(row)

  if (clientId === null) {
    return <span>{cellValue}</span>
  }

  const style: any = {}

  const handleClick = (e: any) => {
    if (modalOpened) {
      e.preventDefault()

      if (modalTarget === 'contract') {
        dispatch(ContractActions.prefillClient(clientId))
      } else if (modalTarget === 'invoice') {
        dispatch(InvoiceActions.prefillClient(clientId))
      }

      dispatch(ClientActions.listClientsOpenModal(false))
    }
  }

  if (column.primary) {
    style.fontWeight = 'bold'
  }

  return (
    <Link
      to={`/klienti/detail/${clientId}`}
      onClick={handleClick}
      component={RouterLink}
      color={column.primary === true ? 'primary' : 'inherit'}
    >
      <span style={style}>{cellValue}</span>
    </Link>
  )
}

export default ClientListColumnName
