const INITIAL_STATE = {
  loginIsLoading: false,
  loginErrorMessage: null,
  //
  logoutIsLoading: false,
  logoutErrorMessage: null,
  //
  forgotPasswordIsLoading: false,
  forgotPasswordErrorMessage: null,
  forgotPasswordState: 'REQUEST',
  forgotPasswordUsername: null,
  forgotPasswordDeliveryMedium: null,
  forgotPasswordDestination: null,
  //
  accessToken: null,
  benefitId: null,
  group: null,
  idToken: null,
  isAuthenticated: false,
  username: null
}

export default INITIAL_STATE
