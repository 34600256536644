import { put, select } from 'redux-saga/effects'

import ContractActions from 'Stores/Contract/Actions'

import { recursivelyLoadAllData } from 'Sagas'
import listContractsQuery from 'Graphql/Queries/listContractsQuery'

export function * listContracts ({ filter }: any) {
  try {
    yield put(ContractActions.listContractsLoading())

    const variables = { limit: 1000 } as any

    const user = yield select(state => state.user)
    const showDeleted = yield select(state => state.app.showDeleted)
  
    if (user.group === 'Admins') {
      if (!showDeleted) {
        variables.filter = { deleted: { ne: true } }
      }
    }
  
    if (user.group === 'SalesRepresentatives') {
      variables.filter = { deleted: { ne: true }, owner: { eq: user.username } }
    }

    const data = yield recursivelyLoadAllData(listContractsQuery, variables, 'listContracts')

    // @todo
    data.sort((a: any, b: any) => {
      if (a.contractNumber && b.contractNumber) {
        return parseInt(b.contractNumber, 10) - parseInt(a.contractNumber, 10)
      } else if (!a.contractNumber && b.contractNumber) {
        return -1
      } else if (a.contractNumber && !b.contractNumber) {
        return 1
      }

      return 0
    })

    yield put(ContractActions.listContractsSuccess(data))
  } catch (error) {
    yield put(ContractActions.listContractsFailure(error))
  }
}

export default listContracts
