import { put, select } from 'redux-saga/effects'

import InvoiceActions from 'Stores/Invoice/Actions'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations from 'Graphql/mutations'

import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { history } from '../../../index'

export function * saveInvoice () {
  try {
    yield put(InvoiceActions.saveInvoiceLoading())

    const invoiceForm = yield select(state => state.invoice.form)
    const data = Object.assign({}, invoiceForm)

    const username = yield select(state => state.user.username)

    const fieldsToDelete = [
      'loading', 'prefill', 'dirty',
      'createdAt', 'createdBy', 'updatedAt', 'updatedBy'
    ]

    let action = 'CREATE'
    if (data.id) {
      action = 'UPDATE'
      fieldsToDelete.push('invoiceNumber')
    }

    fieldsToDelete.map(field => delete data[field])

    let mutation
    let selector

    if (action === 'UPDATE') {
      mutation = mutations.updateInvoice
      selector = `updateInvoice`

      data.updatedAt = (new Date()).toISOString()
      data.updatedBy = username
  
      data.expectedVersion = data.version
      delete data.version

      delete data.client
      delete data.contract
      delete data.stock
    } else {
      mutation = mutations.createInvoice
      selector = `createInvoice`

      data.createdAt = (new Date()).toISOString()
      data.createdBy = username
    }

    const phoneNumber = parsePhoneNumberFromString(String(data.clientPhone), 'CZ')
    if (phoneNumber !== undefined) {
      data.clientPhone = phoneNumber.format('E.164')
    }

    if (data.clientHasDifferentDeliveryAddress === false) {
      data.clientDeliveryFirstname = null
      data.clientDeliveryLastname = null
      data.clientDeliveryCompanyName = null
      data.clientDeliveryPhone = null
      data.clientDeliveryBirthYear = null
      data.clientDeliveryAddressFormatted = null
      data.clientDeliveryAddressStreet = null
      data.clientDeliveryAddressStreetNumber = null
      data.clientDeliveryAddressZip = null
      data.clientDeliveryAddressTown = null
    } else {
      const deliveryPhoneNumber = parsePhoneNumberFromString(String(data.clientDeliveryPhone), 'CZ')
      if (deliveryPhoneNumber !== undefined) {
        data.clientDeliveryPhone = deliveryPhoneNumber.format('E.164')
      }
    }

    // Create the Client first
    if (action === 'CREATE') {
      const i = data

      let clientId
      if (i.clientId !== null) {
        clientId = i.clientId
        data.invoiceClientId = clientId
      } else {
        const input = {} as any
        input.benefitId = `${i.clientBenefitId}`
        input.isFs = i.clientIsFs
        input.createdAt = i.createdAt
        input.createdBy = i.createdBy
        input.firstname = i.clientFirstname
        input.lastname = i.clientLastname
        input.email = i.clientEmail
        input.phone = i.clientPhone
        input.addressStreet = i.clientAddressStreet
        input.addressStreetNumber = i.clientAddressStreetNumber
        input.addressZip = i.clientAddressZip
        input.addressTown = i.clientAddressTown
        input.hasDifferentDeliveryAddress = i.clientHasDifferentDeliveryAddress
        input.deliveryFirstname = i.clientDeliveryFirstname
        input.deliveryLastname = i.clientDeliveryLastname
        input.deliveryCompanyName = i.clientDeliveryCompanyName
        input.deliveryPhone = i.clientDeliveryPhone
        input.deliveryAddressStreet = i.clientDeliveryAddressStreet
        input.deliveryAddressStreetNumber = i.clientDeliveryAddressStreetNumber
        input.deliveryAddressZip = i.clientDeliveryAddressZip
        input.deliveryAddressTown = i.clientDeliveryAddressTown
        input.status = 'active'

        const clientVariables = { input }
        const clientResult = (yield API.graphql(graphqlOperation(mutations.createClient, clientVariables))) as any
        if (clientResult && 'data' in clientResult) {
          clientId = clientResult.data.createClient.id
        }
      }

      data.invoiceClientId = clientId
      delete data.clientId
      delete data.clientBenefitId
      delete data.clientIsFs

      delete data.__typename

      // Create
      const invoiceResult = (yield API.graphql(graphqlOperation(mutation, { input: data }))) as any
      yield put(InvoiceActions.saveInvoiceSuccess(invoiceResult.data[selector]))

      const invoiceId = invoiceResult.data[selector].id

      // @todo
      // const actions: any = []
      // data.items.map((item: any) => {
      //   if(item.stockId) {
      //     actions.push(call(assignStockItemToInvoice, item.stockId, invoiceId))
      //   }
      //   return 
      // })

      // yield all(actions)

      history.replace(`/faktury/detail/${invoiceId}`)
    } else {
      delete data.clientId
      delete data.clientBenefitId
      delete data.clientIsFs

      delete data.payments
      delete data.commissionsRelatedPayments

      delete data.__typename

      data.items = data.items.map((item: any) => {
        return {
          text: item.text,
          price: item.price
        }
      })

      console.log(data)

      // Update
      const invoiceResult = (yield API.graphql(graphqlOperation(mutation, { input: data }))) as any
      yield put(InvoiceActions.saveInvoiceSuccess(invoiceResult.data[selector]))
    }
  } catch (error) {
    console.log(error) // tslint:disable-line
    yield put(InvoiceActions.saveInvoiceFailure(error))
  }
}

export default saveInvoice

// function * assignStockItemToInvoice (stockId: string, invoiceId: string) {
//   const stock = yield API.graphql(graphqlOperation(getStockQuery, { id: stockId }))
//   const expectedVersion = stock.data.getStock.version

//   const input = {
//     id: stockId,
//     stockInvoiceId: invoiceId,
//     expectedVersion
//   }

//   yield API.graphql(graphqlOperation(mutations.updateStock, { input }))
// }
