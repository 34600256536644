import { put } from 'redux-saga/effects'

import InvoiceActions from 'Stores/Invoice/Actions'

import { recursivelyLoadAllData } from 'Sagas'
import listInvoicesQuery from 'Graphql/Queries/listInvoicesQuery'

export function * listInvoices ({ filter }: any) {
  try {
    yield put(InvoiceActions.listInvoicesLoading())

    const variables = {} as any

    const data = yield recursivelyLoadAllData(listInvoicesQuery, variables, 'listInvoices')

    // @todo
    data.sort((a: any, b: any) => {
      return (new Date(b.dateOfIssue) as any) - (new Date(a.dateOfIssue) as any)
    })

    yield put(InvoiceActions.listInvoicesSuccess(data))
  } catch (error) {
    yield put(InvoiceActions.listInvoicesFailure(error))
  }
}

export default listInvoices
