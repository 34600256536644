import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  exportDepositsBalanceToDate: ['date'], // EXPORT_DEPOSITS_BALANCE_TO_DATE
  exportDepositsBalanceToDateLoading: null, // EXPORT_DEPOSITS_BALANCE_TO_DATE_LOADING
  exportDepositsBalanceToDateSuccess: ['url'], // EXPORT_DEPOSITS_BALANCE_TO_DATE_SUCCESS
  exportDepositsBalanceToDateFailure: ['error'] // EXPORT_DEPOSITS_BALANCE_TO_DATE_FAILURE
}, { prefix: 'TOOLS_' })

export const ToolsTypes = Types

export default Creators
