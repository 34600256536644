import { createReducer } from 'reduxsauce'

import { AppTypes } from './Actions'
import INITIAL_STATE from './InitialState'

export const startupSuccess = (state: any): any => ({
  ...state,
  startedUp: true
})

export const setDimensionsSuccess = (state: any, { dimensions }: any): any => ({
  ...state,
  dimensions
})

export const toggleShowDeleted = (state: any): any => ({
  ...state,
  showDeleted: !state.showDeleted
})

export const toggleDrawerMobile = (state: any): any => ({
  ...state,
  drawerMobileOpen: !state.drawerMobileOpen
})

export const reducer = createReducer(INITIAL_STATE, {
  [AppTypes.STARTUP_SUCCESS]: startupSuccess,
  //
  [AppTypes.SET_DIMENSIONS_SUCCESS]: setDimensionsSuccess,
  //
  [AppTypes.TOGGLE_DRAWER_MOBILE]: toggleDrawerMobile,
  [AppTypes.TOGGLE_SHOW_DELETED]: toggleShowDeleted,
})
