import React from 'react'

import { connect } from 'react-redux'
import ContractActions from 'Stores/Contract/Actions'

import { Alert } from '@material-ui/lab'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import DraggablePaper from 'Components/_Common/Dialogs/DraggablePaper'

import Loading from 'Components/Loading'

import Grid from '@material-ui/core/Grid'

import { Form } from 'react-final-form'
import TextField from 'Components/_Common/Form/Inputs/TextField'

import FormSection from 'Components/_Common/Form/Section'

import ucfirst from 'Helpers/ucfirst'

const InputRow = (props: any) => (
  <Grid container={true} xs={12} md={12} lg={12} direction='row'>
    <Grid container={true} alignContent='center' style={{ minHeight: `66px` }} spacing={1}>
      {props.children}
    </Grid>
  </Grid>
)

interface Props {
  contract: any,
  opened: boolean,
  changeEmail: any,
  loading?: boolean,
  error?: any,
  close: any
}

const ChangeEmailDialog = (props: Props) => {
  const { contract, opened, changeEmail, loading, error, close} = props

  const renderForm = (formProps: any) => (
    <form onSubmit={formProps.handleSubmit}>
      {/* <pre style={{ color: 'white' }}>
        {JSON.stringify(formProps, null, 2)}
      </pre> */}

      <FormSection>
        <Alert severity='warning' style={{ marginBottom: '20px' }}>
          Email bude změněn u Klienta, na Smlouvě č. {contract ? contract.contractNumber : ''} a případně i na dalších Smlouvách stejného klienta! <br />
          Klientovi budou na nový Email odeslány nové přihlašovací údaje.
        </Alert>

        <InputRow>
          <Grid item={true} xs={12} md={12} style={{ paddingLeft: '20px', marginBottom: '15px' }}>
            <small style={{ marginBottom: '2px' }}>Stávající email:</small> <br />
            <strong style={{ fontSize: '16px', color: '#baa436' }}>{contract ? contract.clientEmail : ''}</strong>
          </Grid>
        </InputRow>

        <InputRow>
          <Grid item={true} xs={12} md={12}>
            <TextField
              name='clientEmail'
              label='Nový email *'
            />
          </Grid>
        </InputRow>
      </FormSection>

      <Button type='submit' variant='contained' size="large" color='secondary'>
        Odeslat
      </Button>
      &nbsp;
      <Button variant='outlined' size="large" color='secondary' onClick={close}>
        Zrušit
      </Button>
    </form>
  )

  const handleChangeEmail = (values: any): void => {
    const transferKeys = [
      'email'
    ]
  
    const data: any = {}
    transferKeys.map(key => {
      data[key] = values[`client${ucfirst(key)}`]
      return key
    })

    changeEmail(contract.id, data)
  }

  return (
    <Dialog
      PaperComponent={DraggablePaper}
      open={opened}
      onClose={close}
      aria-labelledby='form-dialog-title'
      maxWidth='md'
      fullWidth={true}
    >
      <DialogTitle id='form-dialog-title'>Změnit email na Smlouvě č. {contract ? contract.contractNumber : ''}</DialogTitle>
      <DialogContent style={{ paddingBottom: '20px' }}>
        {loading && <Loading />}
        {error !== null &&  <DialogContentText style={{ color: `red` }}>{error.message}</DialogContentText>}

        <Form
          onSubmit={handleChangeEmail}
          initialValues={contract}
          render={renderForm}
        />
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = (state: any) => ({
  opened: state.contract.changeEmailDialogOpened,
  loading: state.contract.changeEmailLoading,
  error: state.contract.changeEmailError
})

const mapDispatchToProps = (dispatch: any) => ({
  changeEmail: (id: any, data: any) => dispatch(ContractActions.changeEmail(id, data)),
  close: () => dispatch(ContractActions.closeChangeEmailDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(
  ChangeEmailDialog
)
