import { listStocks } from 'Sagas/Stock/listStocks/listStocks'
import { listStocksSuccess } from 'Sagas/Stock/listStocks/listStocksSuccess'
import { listStocksFailure } from 'Sagas/Stock/listStocks/listStocksFailure'
//
import { getStock } from 'Sagas/Stock/getStock/getStock'
import { getStockSuccess } from 'Sagas/Stock/getStock/getStockSuccess'
import { getStockFailure } from 'Sagas/Stock/getStock/getStockFailure'
//
import { saveStock } from 'Sagas/Stock/saveStock/saveStock'
import { saveStockSuccess } from 'Sagas/Stock/saveStock/saveStockSuccess'
import { saveStockFailure } from 'Sagas/Stock/saveStock/saveStockFailure'

export default {
  listStocks,
  listStocksSuccess,
  listStocksFailure,
  //
  getStock,
  getStockSuccess,
  getStockFailure,
  //
  saveStock,
  saveStockSuccess,
  saveStockFailure
}
