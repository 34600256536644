import React from 'react'

import DefaultLayout from 'Components/_Common/Layout/Default'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import Button from '@material-ui/core/Button'
// import Card from '@material-ui/core/Card'
// import CardActions from '@material-ui/core/CardActions'
// import CardContent from '@material-ui/core/CardContent'
// import Typography from '@material-ui/core/Typography'

const styles = (theme: Theme) => createStyles({
  root: {
    alignItems: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden'
  },
  wrapper: {
    margin: 'auto',
    marginBottom: 48,
    maxWidth: 936,
    overflow: 'hidden'
  },

  card: {
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    width: 445
  },
  pos: {
    marginBottom: 2
  },
  title: {
    fontSize: 14
  }
})

interface Props extends WithStyles<typeof styles> {}

const Settings = (props: Props) => {
  const { classes } = props

  return (
    <DefaultLayout
      title={`Nastavení`}
      tabs={[]}
      activeTab={0}
    >
      <div className={classes.wrapper}>
        <div className={classes.root}>
          {/* <Card className={classes.card}>
            <CardContent>
              <div style={{ display: `flex`, flexDirection: `row` }}>
                <div style={{ width: `55px`, paddingTop: `3px` }}>
                  <FontAwesomeIcon icon={['fal', 'unlock-alt']} size='2x' fixedWidth={true} />
                </div>
                <div>
                  <Typography variant="h5" component="h2">
                    Změnit heslo
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    Nastavte si vlastní přístupové heslo.
                  </Typography>
                </div>
              </div>

            </CardContent>
            <CardActions style={{ paddingLeft: `60px` }}>
              <Button size="small" color="secondary" onClick={() => alert('Připravuje se ...')}>Pro změnu hesla klikněte zde</Button> 
            </CardActions>
          </Card> */}
        </div>
      </div>
    </DefaultLayout>
  )
}

export default withStyles(styles)(Settings)
