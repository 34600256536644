import { Table } from '@devexpress/dx-react-grid-material-ui'

import clientDataStatus from 'Components/Client/Data/Status'
import formatDateCallback from 'Components/_Common/List/Callback/formatDate'

export const columns = [
  {
    name: 'client.action',
    title: ' ',
    getClientId: (row: any): string => row.id
  },
  //
  {
    name: 'client.name',
    title: 'Příjmení a jméno klienta *',
    primary: true,
    getCellValue: (row: any): any => `${row.lastname}, ${row.firstname}`,
    getClientId: (row: any): string => row.id
  },
  //
  {
    name: 'client.birthDate',
    title: 'Datum narození',
    getCellValue: (row: any): string => formatDateCallback(row, 'birthDate')
  },
  //
  {
    name: 'client.phone',
    title: 'Telefon',
    getCellValue: (row: any): any => row.phone,
  },
  //
  {
    name: 'client.email',
    title: 'Email',
    getCellValue: (row: any): any => row.email,
  },
  //
  {
    name: 'client.status',
    title: 'Stav',
    getCellValue: (row: any): any => clientDataStatus(row.status)
  }]

export const columnExtensions: Table.ColumnExtension[] = [
  {
    align: 'center',
    columnName: 'client.action',
    width: 75
  },
  //
  {
    align: 'left',
    columnName: 'client.name'
  },
  //
  {
    align: 'center',
    columnName: 'client.birthDate'
  },
  //
  {
    align: 'left',
    columnName: 'client.phone'
  },
  //
  {
    align: 'left',
    columnName: 'client.email'
  },
  //
  {
    align: 'center',
    columnName: 'client.status',
    width: 100
  }
]

export default {
  columnExtensions,
  columns
}
