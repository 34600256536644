import { put } from 'redux-saga/effects'

import EmailActions from 'Stores/Email/Actions'

import { recursivelyLoadAllData } from 'Sagas'
import listEmailsQuery from 'Graphql/Queries/listEmailsQuery'

export function * listEmails ({ filter }: any) {
  try {
    yield put(EmailActions.listEmailsLoading())

    const variables = {} as any

    const data = yield recursivelyLoadAllData(listEmailsQuery, variables, 'listEmails')

    // @todo
    data.sort((a: any, b: any) => {
      return (new Date(b.receivedAt) as any) - (new Date(a.receivedAt) as any)
    })

    yield put(EmailActions.listEmailsSuccess(data))
  } catch (error) {
    yield put(EmailActions.listEmailsFailure(error))
  }
}

export default listEmails
