import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'

import { SnackbarProvider } from 'notistack'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'

import { persistor, store } from 'Stores/index'

import fontawesome from 'Config/fontawesome'

import App from 'App'
import * as serviceWorker from 'serviceWorker'

import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'

import { Route, Router } from 'react-router-dom'

import withGa from 'Hoc/withGa'

import bugsnagClient, { BugsnagContext, ErrorBoundary } from 'Components/Bugsnag'

import { createBrowserHistory } from 'history'
export const history =  createBrowserHistory()

// wrap your entire app tree in the ErrorBoundary provided

fontawesome()

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HelmetProvider>
          <BugsnagContext.Provider value={bugsnagClient}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <SnackbarProvider maxSnack={3}>
                <Router history={history}>
                  <Route component={withGa(App)} />
                </Router>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </BugsnagContext.Provider>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
  , document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
