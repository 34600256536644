import { put } from 'redux-saga/effects'

import EmissionActions from 'Stores/Emission/Actions'

import { recursivelyLoadAllData } from 'Sagas'
import listEmissionsQuery from 'Graphql/Queries/listEmissionsQuery'

export function * listEmissions ({ filter }: any) {
  try {
    yield put(EmissionActions.listEmissionsLoading())

    const variables = {} as any

    const data = yield recursivelyLoadAllData(listEmissionsQuery, variables, 'listEmissions')

    // @todo
    data.sort((a: any, b: any) => {
      return parseInt(b.yearFrom, 10) - parseInt(a.yearFrom, 10)
    })

    
    data.map((emission: any) => {
      return emission.coins.items.sort((a: any, b: any) => {
        const aDateOfIssue = new Date(a.dateOfIssue).getTime()
        const bDateOfIssue = new Date(b.dateOfIssue).getTime()
  
        if (aDateOfIssue !== bDateOfIssue) {
          return bDateOfIssue - aDateOfIssue
        } else {
          return b.value - a.value
        }
      })
    })  

    yield put(EmissionActions.listEmissionsSuccess(data))
  } catch (error) {
    yield put(EmissionActions.listEmissionsFailure(error))
  }
}

export default listEmissions
