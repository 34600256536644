import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import StockActions from 'Stores/Stock/Actions'

import DefaultLayout from 'Components/_Common/Layout/Default'
import StockForm from 'Components/Stock/Form'

import * as ROUTES from 'routes'

const Stock = (props: any) => {
  useEffect(() => {
    if (props.data.prefill === false) {
      props.stockFormClean()
    }
  }, [])

  return (
    <DefaultLayout
      title={`Sklad`}
      tabs={[{
        route: ROUTES.ADMIN_STOCK, title: 'Seznam položek'
      }, {
        route: ROUTES.ADMIN_STOCK_ITEM_NEW, title: 'Nová položka'
      }]}
      activeTab={1}
    >
      <StockForm />
    </DefaultLayout>
  )
}

const mapStateToProps = (state: any) => ({
  data: state.contract.form
})

const mapDispatchToProps = (dispatch: any) => ({
  stockFormClean: () => dispatch(StockActions.formClean())
})

export default connect(mapStateToProps, mapDispatchToProps)(Stock)
