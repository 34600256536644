export const LOGIN = '/prihlaseni'
export const FORGOT_PASSWORD = '/zapomenute-heslo'

export const ADMIN_HOME = '/'
export const ADMIN_CLIENTS = '/klienti'
export const ADMIN_CLIENT = '/klienti/detail/:id?'
export const ADMIN_CONTRACTS = '/smlouvy'
export const ADMIN_CONTRACT_NEW = '/smlouvy/nova'
export const ADMIN_CONTRACT_DETAIL = '/smlouvy/detail/:id'
export const ADMIN_INVOICES = '/faktury'
export const ADMIN_INVOICES_HELP = '/faktury/napoveda'
export const ADMIN_INVOICE_NEW = '/faktury/nova'
export const ADMIN_INVOICE_DETAIL = '/faktury/detail/:id'
export const ADMIN_MY_CONTRACTS = '/smlouvy/moje'
export const ADMIN_MY_CONTRACT = '/smlouvy/moje/detail/:id'
export const ADMIN_PAYMENTS = '/platby'
export const ADMIN_PAYMENT = '/platby/detail/:id?'
export const ADMIN_EMAILS = '/emaily'
export const ADMIN_EMAIL = '/emaily/detail/:id?'
export const ADMIN_SETTINGS = '/nastaveni'
export const ADMIN_TOOLS = '/nastroje'
export const ADMIN_EMISSIONS = '/emise'
export const ADMIN_EMISSION_COINS = '/emise/:id/mince'
export const ADMIN_COIN = '/emise/:eid/mince/:id'
export const ADMIN_ORDERS = '/objednavky'
export const ADMIN_STOCK = '/sklad'
export const ADMIN_STOCK_ITEM_NEW = '/sklad/polozka/nova'
export const ADMIN_STOCK_ITEM_DETAIL = '/sklad/polozka/:id'
export const ADMIN_DISTRIBUTIONS = '/distribuce'
export const ADMIN_PREDICTION = '/predikce'
export const ADMIN_PRICELIST = '/cenik'

export const CLIENT_HOME = '/'
export const CLIENT_MY_CONTRACTS = '/smlouvy/moje'
export const CLIENT_MY_CONTRACT = '/smlouvy/moje/detail/:id'
export const CLIENT_MY_PAYMENTS = '/platby'
export const CLIENT_MY_PAYMENT = '/platby/detail/:id'
export const CLIENT_MY_INVOICES = '/faktury'
export const CLIENT_MY_INVOICE = '/faktury/detail/:id'
export const CLIENT_MY_COINS = '/mince'
export const CLIENT_MY_COIN = '/mince/detail/:id'
export const CLIENT_MY_PROFILE = '/profil'
export const CLIENT_MY_SETTINGS = '/nastaveni'
export const CLIENT_EMISSIONS = '/emise'
export const CLIENT_COIN = '/emise/:eid/mince/:id'
export const CLIENT_PRICELIST = '/cenik'
