import React from 'react'

// import ClientsMyProfileForm from 'Components/_Client/MyProfile/Form/index'
import DefaultLayout from 'Components/_Common/Layout/Default'

import Typography from '@material-ui/core/Typography'

import * as ROUTES from 'routes'

const Home = () => {
  return (
    <DefaultLayout
      title={`Můj profil`}
      tabs={[{
        route: ROUTES.CLIENT_MY_PROFILE, title: 'Základní údaje'
      }]}
      activeTab={0}
    >
      <Typography variant='h6'>Připravujeme ...</Typography>

      {/* <ClientsMyProfileForm
        data={{
          firstname: 'Igor',
          lastname: 'Lamoš',
          personalNumber: '8611179346',
          birthDate: '1986-11-17',
          phone: '+420774222822',
          email: 'igor@beplus.ee',
          addressStreet: 'Kumpoštova',
          addressStreetNumber: '124/7',
          addressZip: '61200',
          addressTown: 'Brno',
          hasDifferentDeliveryAddress: true,
          deliveryFirstname: 'Igor',
          deliveryLastname: 'Lamoš',
          deliveryCompanyName: 'Benefit investment a.s.',
          deliveryAddressStreet: 'Pražákova',
          deliveryAddressStreetNumber: '1008/69',
          deliveryAddressZip: '63900',
          deliveryAddressTown: 'Brno',
          deliveryPhone: '+420774222822',
          deliveryBirthYear: '1986',
          bankAccountNumber: '2946062033',
          bankCode: '0800'
        }}
        onSubmit={async () => {}}
      /> */}
    </DefaultLayout>
  )
}

export default Home
