import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import EmissionActions from 'Stores/Emission/Actions'
import StockActions from 'Stores/Stock/Actions'
import * as EmissionSelect from 'Stores/Emission/Select'
import * as StockSelect from 'Stores/Stock/Select'

import { Field, Form, FormSpy } from 'react-final-form'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import { createStyles, Theme, withStyles } from '@material-ui/core/styles'

import DatePicker from 'Components/_Common/Form/Inputs/DatePicker'
import Checkbox from 'Components/_Common/Form/Inputs/Checkbox'
import Select from 'Components/_Common/Form/Inputs/Select'
import { InputComponent as TextFieldComponent } from 'Components/_Common/Form/Inputs/TextField'

import FormSection from 'Components/_Common/Form/Section'
import InputRow from 'Components/_Common/Form/InputRow'

import { history } from '../../../index'

const styles = (theme: Theme) => createStyles({
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },

  sliderWrapper: {
    // marginTop: `13px`,
    padding: `15px`
  },

  grow: {
    flexGrow: 1,
  },
  appBar: {
    bottom: 0,
    height: 66,
    justifyContent: 'center',
    top: 'auto'
  }
})

const StockForm = (props: any) => {
  useEffect(() => {
    props.listEmissions()
  }, [])

  const { actionMenu, classes } = props

  const isEditing = props.stockForm && 'id' in props.stockForm && props.stockForm.id !== undefined
  const disableEdit = isEditing && 'sold' in props.stockForm && props.stockForm.sold === true

  const updateFormState = (state: any): any => {
    Object.keys(state.modified).map((key: any): void => {
      if (state.modified[key] === true) {
        if (key.substr(0, 6) === 'items[') {
          props.stockFormUpdate({ items: state.values.items })
        } else {
          props.stockFormUpdate({ [key]: state.values[key] })
        }
      }

      return key
    })
  }

  const renderForm = ({ handleSubmit }: any) => (
    <form onSubmit={handleSubmit}>
      <FormSpy onChange={updateFormState} />

      <FormSection>
        <InputRow>
          <Grid item={true} xs={12}>
            <Select
              name='stockEmissionId'
              label='Emise'
              disabled={isEditing || disableEdit || props.emissionsLoading}>
              {props.emissionsResult.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item={true} style={{ paddingLeft: `10px` }}>
            <FormControlLabel
              control={
                <Field name='series' component={Checkbox} type='checkbox' />
              }
              label={`Celá sada`}
              disabled={isEditing || disableEdit}
            />
          </Grid>

          {props.stockForm.series !== true && <Grid item={true} xs={12}>
            <Select
              name='stockCoinId'
              label='Mince'
              disabled={isEditing || disableEdit || props.emissionsLoading}>
              {props.stockForm.stockEmissionId 
                ? (
                  props.emissionsResult.map((item: any) => {
                    if (item.id === props.stockForm.stockEmissionId) {
                      return item.coins.items.map((coin: any) => (
                        <MenuItem key={coin.id} value={coin.id}>{coin.denominationMotif}</MenuItem>
                      ))
                    }
                  })
                ) : <MenuItem value=''>Vyberte emisi</MenuItem>
              }
            </Select>
          </Grid>}
        </InputRow>
      </FormSection>

      <FormSection>
        <InputRow>
          <Grid item={true} xs={6}>
            <DatePicker
              name='purchasedAt'
              label='Datum koupě'
              disabled={disableEdit}
            />
          </Grid>

          <Grid item={true} xs={6}>
          </Grid>
        </InputRow>

        <InputRow>
          <Grid item={true} xs={6}>
            <Field
              name='pricePurchase'
              label='Nákupní cena *'
              component={TextFieldComponent as React.SFC<any>}
              type='number'
              margin='dense'
              fullWidth={true}
              variant='outlined'
              InputProps={{
                endAdornment: <InputAdornment position='end'>CZK</InputAdornment>
              }}
            />
          </Grid>

          <Grid item={true} xs={6}>
            <Select
              name='pricePurchasePaidWith'
              label='Způsob úhrady'
              disabled={disableEdit}
            >
              <MenuItem value='cash'>Hotovost</MenuItem>
              <Divider />
              <MenuItem value='bank'>Banka</MenuItem>
            </Select>
          </Grid>
        </InputRow>

        <InputRow>
          <Grid item={true} xs={6}>
            <Field
              name='priceSelling'
              label='Prodejní cena'
              component={TextFieldComponent as React.SFC<any>}
              type='number'
              margin='dense'
              fullWidth={true}
              variant='outlined'
              InputProps={{
                endAdornment: <InputAdornment position='end'>CZK</InputAdornment>
              }}
            />
          </Grid>

          <Grid item={true} xs={6} style={{ display: 'flex', alignItems: 'center', paddingLeft: '20px', paddingTop: '5px' }}>
            <FormControlLabel
              control={
                <Field name='commission' component={Checkbox} type='checkbox' />
              }
              label={`Provize`}
            />
          </Grid>
        </InputRow>

        <InputRow>
          <Grid item={true} xs={6}>
            <Select
              name='status'
              label='Stav'
              disabled={disableEdit}
            >
              <MenuItem value='free'>K prodeji</MenuItem>
              <MenuItem value='ordered'>Objednáno</MenuItem>
              <MenuItem value='blocked'>Blokováno</MenuItem>
              <MenuItem value='sold'>Prodáno</MenuItem>
            </Select>
          </Grid>
        </InputRow>
      </FormSection>

      <AppBar color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            &nbsp;
          </Typography>
          {actionMenu}&nbsp;
          {props.stockForm.dirty === true && <Button type='submit' variant={isEditing ? 'outlined' : 'contained'} size="large" color='secondary' disabled={disableEdit}>
            Uložit položku
          </Button>}
          &nbsp;
          {!isEditing && <Button size='large' disabled={disableEdit} onClick={() => {
            props.stockFormClean()
          }}>
            Vyčistit formulář
          </Button>}
          &nbsp;
          <Button size='large' disabled={disableEdit} onClick={() => {
            props.stockFormClean()
            history.push('/sklad')
          }}>
            Zrušit bez uložení
          </Button>
        </Toolbar>
      </AppBar>

      {/* <pre style={{ color: 'white' }}>
        {JSON.stringify(props.stockForm, null, 2)}
      </pre> */}
    </form>
  )

  return (
    <Form
      onSubmit={props.saveStock}
      initialValues={props.stockForm}
      // validate={validate}
      render={renderForm}
    />
  )
}

const mapStateToProps = (state: any): any => ({
  emissionsLoading: EmissionSelect.emissionsLoading(state),
  emissionsResult: EmissionSelect.emissionsResult(state),
  //
  getStockLoading: StockSelect.stockLoading(state),
  stockForm: state.stock.form,
  searchForClient: state.searchForClient,
  //
  user: state.user
})

const mapDispatchToProps = (dispatch: any) => ({
  listEmissions: () => dispatch(EmissionActions.listEmissions()),
  //
  stockFormUpdate: (data: any) => dispatch(StockActions.formUpdate(data)),
  stockFormClean: () => dispatch(StockActions.formClean()),
  //
  saveStock: () => dispatch(StockActions.saveStock())
})

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(StockForm)
)
