const INITIAL_STATE = {
  open: false,
  loading: false,
  title: '',
  text: '',
  error: null,
  actionText: null,
  actionUrl: null
}

export default INITIAL_STATE
