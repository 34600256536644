import gql from 'graphql-tag'

const query = gql`
  query LIST_CONTRACTS_QUERY (
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        __typename
        contractNumber
        owner
        deleted
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        monthlyAmount
        totalYears
        client {
          id
          __typename
        }
        clientFirstname
        clientLastname
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        savedAmount
        status
      }
      nextToken
    }
  }
`

export default query
