import React from 'react'

import DefaultLayout from 'Components/_Common/Layout/Default'

import Dashboard from 'Components/_Client/Dashboard'

const Home = (props: any) => {
  return (
    <DefaultLayout
      title={`Dashboard`}
      tabs={[]}
      activeTab={0}
    >
      <Dashboard />
    </DefaultLayout>
  )
}

export default Home
