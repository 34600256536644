import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import EmissionActions from 'Stores/Emission/Actions'
import * as EmissionSelect from 'Stores/Emission/Select'

import DefaultLayout from 'Components/_Common/Layout/Default'
import Loading from 'Components/Loading'

import { Link as RouterLink } from 'react-router-dom'
import * as ROUTES from 'routes'

import Link from '@material-ui/core/Link'
import { fade, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import AppBar from '@material-ui/core/AppBar'
import InputBase from '@material-ui/core/InputBase'
import Toolbar from '@material-ui/core/Toolbar'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'

// import Card from '@material-ui/core/Card'
// import CardHeader from '@material-ui/core/CardHeader'
// import CardMedia from '@material-ui/core/CardMedia'
// import CardContent from '@material-ui/core/CardContent'
// import CardActions from '@material-ui/core/CardActions'
// import IconButton from '@material-ui/core/IconButton'

// import GridList from '@material-ui/core/GridList'
// import GridListTile from '@material-ui/core/GridListTile'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Typography from '@material-ui/core/Typography'

import ts from 'Helpers/thousandsSeparator'

import format from 'date-fns/format'

const useStyles = makeStyles(theme => ({
  table: {
    width: '100%'
  },

  root: {
    width: '100%',
  },

  heading: {
    flexBasis: '66.66%',
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  headingTiles: {
    flexBasis: '66.66%',
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(15),
  },

  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: '100%'
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  // sectionDesktop: {
  //   display: 'none',
  //   [theme.breakpoints.up('md')]: {
  //     display: 'flex',
  //   },
  // },
  // sectionMobile: {
  //   display: 'flex',
  //   [theme.breakpoints.up('md')]: {
  //     display: 'none',
  //   },
  // },
  card: {
    maxWidth: 250,
    marginRight: 20,
    marginBottom: 20
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
}))

const Emissions = (props: any) => {
  useEffect(() => {
    props.listEmissions()
  }, [])

  const classes = useStyles()

  return (
    <DefaultLayout
      title={`Emise`}
      tabs={[{
        route: ROUTES.ADMIN_EMISSIONS, title: 'Přehled emisí'
      }]}
      activeTab={0}
    >
      {props.emissionsLoading
        ? <Loading />
        : (
          <div className={classes.root}>

            {false && <div className={classes.grow}>
              <AppBar position="static">
                <Toolbar>
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <FontAwesomeIcon icon={['fal', 'search']} />
                    </div>
                    <InputBase
                      placeholder="Vyhledat…"
                      classes={{
                        input: classes.inputInput,
                        root: classes.inputRoot,
                      }}
                      inputProps={{ 'aria-label': 'Vyhledat' }}
                    />
                  </div>
                </Toolbar>
              </AppBar>
            </div>}

            {props.emissionsResult.map((item: any) => {
              return (
                <ExpansionPanel key={item.id}>
                  <ExpansionPanelSummary expandIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} size='xs' />}>
                    <Typography className={classes.heading}>{item.name}</Typography>
                    <Typography className={classes.secondaryHeading}>{item.yearFrom !== 9999 ? item.yearFrom : ``}{item.yearTo ? ` - ${item.yearTo}` : ``}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Motiv<br />
                            Hodnota
                          </TableCell>
                          <TableCell align="left" style={{ width: `200px` }}>
                            Autor
                          </TableCell>
                          <TableCell align="right" style={{ width: `120px` }}>
                            BK náklad<br />
                            hrana
                          </TableCell>
                          <TableCell align="right" style={{ width: `140px` }}>
                            PROOF náklad<br />
                            hrana
                          </TableCell>
                          <TableCell align="right" style={{ width: `130px` }}>
                            Datum emise<br />
                            Vyhláška
                          </TableCell>
                          <TableCell align="right" style={{ width: `130px` }}>
                            Hmotnost<br />
                            (ryzí / hrubá)
                          </TableCell>
                          <TableCell align="right" style={{ width: `90px` }}>
                            Průměr<br />
                            Síla
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item.coins.items.slice().map((coin: any) => {
                          return (
                            <TableRow key={coin.id}>
                              <TableCell component="th" scope="row">
                                <Link component={RouterLink} to={`/emise/${item.id}/mince/${coin.id}`}>
                                  {coin.denominationMotif}
                                </Link><br />
                                <small>{ts(coin.value)} Kč</small>
                              </TableCell>
                              <TableCell align="left" style={{ width: `200px` }}>
                                <small>{coin.designer}</small>
                              </TableCell>
                              <TableCell align="right" style={{ width: `120px` }}>
                                {coin.mintageLimitNormal ? ts(coin.mintageLimitNormal) : ts(coin.normalActualMintage)}<br />
                                <small>{coin.normalEdge === 'milled' ? 'vroubkovaná' : ``}</small>
                              </TableCell>
                              <TableCell align="right" style={{ width: `140px` }}>
                                {coin.mintageLimitProof ? ts(coin.mintageLimitProof) : ts(coin.proofActualMintage)}<br />
                                <small>{coin.proofEdge === 'plain' ? 'hladká' : ``}</small>
                              </TableCell>
                              <TableCell align="right" style={{ width: `130px` }}>
                                {format(new Date(coin.dateOfIssue), 'dd.MM.yyyy')}<br />
                                <small>{coin.decreeNo}</small>
                              </TableCell>
                              <TableCell align="right" style={{ width: `130px` }}>
                                {coin.weightFine} oz<br />
                                <small>{coin.weightGross} g</small>
                              </TableCell>
                              <TableCell align="right" style={{ width: `90px` }}>
                                {coin.diameter} mm<br />
                                {coin.thickness ? <small>{coin.thickness} mm</small> : '-'}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )
            })}


            {/* CARD VIEW */}
            {/*
            {props.emissionsResult.map((item: any) => {
              item.coins.items.sort((a: any, b: any) => {
                const aDateOfIssue = new Date(a.dateOfIssue).getTime()
                const bDateOfIssue = new Date(b.dateOfIssue).getTime()

                if (aDateOfIssue !== bDateOfIssue) {
                  return bDateOfIssue - aDateOfIssue
                } else {
                  return b.value - a.value
                }
              })

              return (
                <React.Fragment key={item.id}>
                  <Typography className={classes.headingTiles} color="primary">{item.name}</Typography>
                  <Typography className={classes.secondaryHeading}>{item.yearFrom !== 9999 ? item.yearFrom : ``}{item.yearTo ? ` - ${item.yearTo}` : ``}</Typography>

                  <GridList className={classes.gridList} style={{ width: `${props.dimensions.width - 72}px` }}>
                    {item.coins.items.map((coin: any) => {
                      return (
                        <GridListTile key={coin.id} style={{ width: 'auto', height: `420px`, marginTop: `15px` }}>
                          <Card className={classes.card} style={{ width: '250px' }}>
                            <CardHeader
                              title={coin.denominationMotif}
                              titleTypographyProps={{ style: { fontSize: `14px` }, noWrap: true }}
                              subheaderTypographyProps={{ style: { fontSize: `12px` }, noWrap: true }}
                              subheader={format(new Date(coin.dateOfIssue), 'dd.MM.yyyy')}
                            />
                            <CardMedia
                              className={classes.media}
                              style={{ width: '250px', height: '250px' }}
                              image="https://via.placeholder.com/250x250"
                              title={coin.denominationMotif}
                            />
                            <CardActions disableSpacing={true}>
                              <IconButton aria-label="Add to basket">
                                <FontAwesomeIcon icon={['fal', 'cart-plus']} size='xs' fixedWidth={true} />
                              </IconButton>
                            </CardActions>
                          </Card>
                        </GridListTile>
                      )
                    })}
                  </GridList>
                  <br /><br />
                </React.Fragment>
              )
            })}
            */}
          </div>
        )
      }
    </DefaultLayout>
  )
}

const mapStateToProps = (state: any) => ({
  dimensions: state.app.dimensions,
  //
  emissionsLoading: EmissionSelect.emissionsLoading(state),
  emissionsResult: EmissionSelect.emissionsResult(state)
})

const mapDispatchToProps = (dispatch: any) => ({
  listEmissions: () => dispatch(EmissionActions.listEmissions())
})

export default connect(mapStateToProps, mapDispatchToProps)(Emissions)
