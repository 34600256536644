import { getContext, put } from 'redux-saga/effects'

import ContractActions from 'Stores/Contract/Actions'

import prefillClientQuery from 'Graphql/Queries/prefillClientQuery'

export function * prefillClient ({ id }: any) {
  try {
    yield put(ContractActions.prefillClientLoading())

    const graphql = yield getContext('graphql')
    const variables = { id }
    const result = yield graphql.query(prefillClientQuery, variables)
    const client = result.data.getClient
    yield put(ContractActions.prefillClientSuccess(client))
  } catch (e) {
    yield put(ContractActions.prefillClientFailure())
  }
}

export default prefillClient
