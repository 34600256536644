import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootSaga from 'Sagas/index'

import middleware, { sagaMiddleware } from './middleware'

import { reducer as AppReducer } from 'Stores/App/Reducers'
import { reducer as ClientReducer } from 'Stores/Client/Reducers'
import { reducer as CoinReducer } from 'Stores/Coin/Reducers'
import { reducer as CloseContractDialogReducer } from 'Stores/CloseContractDialog/Reducers'
import { reducer as ContractReducer } from 'Stores/Contract/Reducers'
import { reducer as DistributionReducer } from 'Stores/Distribution/Reducers'
import { reducer as EmailReducer } from 'Stores/Email/Reducers'
import { reducer as EmissionReducer } from 'Stores/Emission/Reducers'
import { reducer as InvoiceReducer } from 'Stores/Invoice/Reducers'
import { reducer as PaymentReducer } from 'Stores/Payment/Reducers'
import { reducer as PredictionReducer } from 'Stores/Prediction/Reducers'
import { reducer as SearchForClientReducer } from 'Stores/SearchForClient/Reducers'
import { reducer as StockReducer } from 'Stores/Stock/Reducers'
import { reducer as UserReducer } from 'Stores/User/Reducers'
import { reducer as WaitDialogReducer } from 'Stores/WaitDialog/Reducers'

const rootReducer = {
  app: AppReducer,
  client: ClientReducer,
  coin: CoinReducer,
  closeContractDialog: CloseContractDialogReducer,
  contract: ContractReducer,
  distribution: DistributionReducer,
  email: EmailReducer,
  emission: EmissionReducer,
  invoice: InvoiceReducer,
  payment: PaymentReducer,
  prediction: PredictionReducer,
  searchForClient: SearchForClientReducer,
  stock: StockReducer,
  user: UserReducer,
  waitDialog: WaitDialogReducer
}

const reducer = persistReducer({
  key: 'goldcoins',
  storage,
  blacklist: ['app']
}, combineReducers({ ...rootReducer }))

const composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const configStore = (initialState: any = {}): any => {
  const reduxStore: any = createStore(reducer, initialState, composeEnhancer(applyMiddleware(...middleware)))

  sagaMiddleware.run(rootSaga)

  // if (module.hot) {
  //   module.hot.accept('reducers', () => {
  //     reduxStore.replaceReducer(require('reducers/index').default)
  //   })
  // }

  return {
    persistor: persistStore(reduxStore),
    store: reduxStore
  }
}

const { store, persistor } = configStore()

const globalAny: any = global
globalAny.store = store

export { store, persistor }
