import { getContext, put } from 'redux-saga/effects'

import ContractActions from 'Stores/Contract/Actions'

import getContractQuery from 'Graphql/Queries/getContractQuery'

export function * getContract ({ id }: any): any {
  try {
    yield put(ContractActions.getContractLoading())

    const graphql = yield getContext('graphql')
    const variables = { id } as any

    const result = yield graphql.query(getContractQuery, variables)
    // console.log("->result", result)

    // @todo
    if (result && result.data && result.data.getContract) {
      yield put(ContractActions.getContractSuccess(result.data.getContract))
    }
  } catch (error) {
    yield put(ContractActions.getContractFailure(error))
  }
}

export default getContract
