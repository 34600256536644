import { Table } from '@devexpress/dx-react-grid-material-ui'

import formatDateCallback from 'Components/_Common/List/Callback/formatDate'

import emailDataStatus from 'Components/Email/Data/Status'

export const columns = [
  {
    name: 'email.action',
    title: ' ',
    getEmailId: (row: any): any => row.id
  },
  //
  {
    name: 'email.receivedAt',
    title: 'Datum',
    getCellValue: (row: any): any => formatDateCallback(row, 'receivedAt', 'dd.MM.yyyy HH:mm')
  },
  //
  {
    name: 'email.to',
    title: 'Příjemce',
    getCellValue: (row: any): any => row.to.join(', ')
  },
  //
  {
    name: 'email.subject',
    title: 'Předmět',
    getCellValue: (row: any): any => row.subject
  },
  //
  {
    name: 'email.status',
    title: 'Stav',
    getCellValue: (row: any): any => emailDataStatus(row.status)
  }
]

export const columnExtensions: Table.ColumnExtension[] = [
  {
    align: 'center',
    columnName: 'email.action',
    width: 75
  },
  //
  {
    align: 'center',
    columnName: 'email.receivedAt',
    width: 180
  },
  //
  {
    columnName: 'email.to',
    width: 300
  },
  //
  {
    columnName: 'email.subject'
  },
  //
  {
    align: 'center',
    columnName: 'email.status',
    width: 100
  }
]

export default {
  columnExtensions,
  columns
}
