export const DISTRIBUTION_DATA_STATUS_DONE = 'done'

const distributionDataStatus = (value: any): any => {
  switch (value) {
    case DISTRIBUTION_DATA_STATUS_DONE:
      return `Hotovo`
  }
}

export const getAvailableDistributionStatuses = () => {
  return {
    DISTRIBUTION_DATA_STATUS_DONE: distributionDataStatus(DISTRIBUTION_DATA_STATUS_DONE)
  }
}

export default distributionDataStatus
