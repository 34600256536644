import { createReducer } from 'reduxsauce'

import { ContractTypes } from 'Stores/Contract/Actions'
import { InvoiceTypes } from 'Stores/Invoice/Actions'
import { ToolsTypes } from 'Stores/Tools/Actions'
import { WaitDialogTypes } from 'Stores/WaitDialog/Actions'

import INITIAL_STATE from './InitialState'

export const reducer = createReducer(INITIAL_STATE, {
  [WaitDialogTypes.CLOSE]: (): any => INITIAL_STATE,

  //

  [ContractTypes.SEND_CONTRACT_LOADING]: (state: any): any => ({
    ...INITIAL_STATE,
    open: true,
    loading: true,
    title: 'Odesílám email s Návrhem smlouvy ...'
  }),
  [ContractTypes.SEND_CONTRACT_SUCCESS]: (state: any): any => ({
    ...state,
    loading: false,
    title: 'Odeslání Návrhu smlouvy',
    text: 'Email byl úspěšně odeslán!'
  }),
  [ContractTypes.SEND_CONTRACT_FAILURE]: (state: any, { error }: any): any => ({
    ...state,
    loading: false,
    title: 'Odeslání Návrhu smlouvy',
    text: null,
    error: error.message
  }),

  //

  [ContractTypes.DOWNLOAD_CONTRACT_LOADING]: (state: any): any => ({
    ...INITIAL_STATE,
    open: true,
    loading: true,
    title: 'Připravuji Návrh smlouvy ...'
  }),
  [ContractTypes.DOWNLOAD_CONTRACT_SUCCESS]: (state: any, { url }: any): any => ({
    ...state,
    loading: false,
    title: 'Příprava Návrhu smlouvy',
    text: 'Návrh smlouvy byl úspěšně připraven.',
    actionText: 'Stáhnout PDF',
    actionUrl: url
  }),
  [ContractTypes.DOWNLOAD_CONTRACT_FAILURE]: (state: any, { error }: any): any => ({
    ...state,
    loading: false,
    title: 'Příprava Návrhu smlouvy',
    text: null,
    error: error.message
  }),

  //

  [InvoiceTypes.CREATE_INVOICE_LOADING]: (state: any): any => ({
    ...INITIAL_STATE,
    open: true,
    loading: true,
    title: 'Připravuji PDF faktury ...'
  }),
  [InvoiceTypes.CREATE_INVOICE_SUCCESS]: (state: any, { url }: any): any => ({
    ...state,
    loading: false,
    title: 'Příprava PDF faktury',
    text: 'Faktura byla úspěšně vygenerována.',
    actionText: 'Stáhnout PDF',
    actionUrl: url
  }),
  [InvoiceTypes.CREATE_INVOICE_FAILURE]: (state: any, { error }: any): any => ({
    ...state,
    loading: false,
    title: 'Příprava PDF faktury',
    text: null,
    error: error.message
  }),

  //

  [InvoiceTypes.DOWNLOAD_INVOICE_LOADING]: (state: any): any => ({
    ...INITIAL_STATE,
    open: true,
    loading: true,
    title: 'Připravuji Fakturu ...'
  }),
  [InvoiceTypes.DOWNLOAD_INVOICE_SUCCESS]: (state: any, { url }: any): any => ({
    ...state,
    loading: false,
    title: 'Příprava Faktury',
    text: 'Faktura byla úspěšně připravena.',
    actionText: 'Stáhnout PDF',
    actionUrl: url
  }),
  [InvoiceTypes.DOWNLOAD_INVOICE_FAILURE]: (state: any, { error }: any): any => ({
    ...state,
    loading: false,
    title: 'Příprava Faktury',
    text: null,
    error: error.message
  }),

  //

  [InvoiceTypes.SEND_INVOICE_LOADING]: (state: any): any => ({
    ...INITIAL_STATE,
    open: true,
    loading: true,
    title: 'Odesílám fakturu ...'
  }),
  [InvoiceTypes.SEND_INVOICE_SUCCESS]: (state: any, { messageId }: any): any => ({
    ...state,
    loading: false,
    title: 'Odeslání faktury',
    text: 'Faktura byla úspěšně odeslána.',
    actionText: 'Stáhnout PDF',
    actionUrl: '#' // messageId
  }),
  [InvoiceTypes.SEND_INVOICE_FAILURE]: (state: any, { error }: any): any => ({
    ...state,
    loading: false,
    title: 'Odeslání faktury',
    text: null,
    error: error.message
  }),

  //

  [ToolsTypes.EXPORT_DEPOSITS_BALANCE_TO_DATE_LOADING]: (state: any): any => ({
    ...INITIAL_STATE,
    open: true,
    loading: true,
    title: 'Připravuji export ...'
  }),
  [ToolsTypes.EXPORT_DEPOSITS_BALANCE_TO_DATE_SUCCESS]: (state: any, { url }: any): any => ({
    ...state,
    loading: false,
    title: 'Příprava exportu',
    text: 'Export stavu záloh na účtu byl úspěšně připraven.',
    actionText: 'Stáhnout XLS',
    actionUrl: url
  }),
  [ToolsTypes.EXPORT_DEPOSITS_BALANCE_TO_DATE_FAILURE]: (state: any, { error }: any): any => ({
    ...state,
    loading: false,
    title: 'Příprava exportu',
    text: null,
    error: error.message
  })
})
