import React from 'react'

import * as Status from 'Components/Contract/Data/Status'

const ContractListColumnStatus = ({ row, column }: any): any => {
  const rawValue = row.status
  const cellValue = column.getCellValue(row)

  const style: any = {}

  if (row.deleted === true) {
    return <span style={{ color: '#ee0000' }}>Smazáno</span>   
  }

  switch (rawValue) {
    case Status.CONTRACT_DATA_STATUS_WAITING_FOR_PAYMENT:
      style.color = '#fff'
      break

    case Status.CONTRACT_DATA_STATUS_NOT_ACCEPTED:
      style.color = '#ffaa00'
      break

    case Status.CONTRACT_DATA_STATUS_ACTIVE:
      style.fontWeight = 'bold'
      style.color = '#baa436'
      break
  }

  return (
    <span style={style}>{cellValue}</span>
  )
}

export default ContractListColumnStatus
