import { all, takeLatest } from 'redux-saga/effects'

import { AppTypes } from 'Stores/App/Actions'
import App from 'Sagas/App'

export default function * root () {
  yield all([
    takeLatest(AppTypes.SET_DIMENSIONS, App.setDimensions)
  ])
}
