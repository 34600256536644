import { put, select } from 'redux-saga/effects'

import ContractActions from 'Stores/Contract/Actions'

export function * downloadContract ({ id, generate = true }: any) {
  try {
    yield put(ContractActions.downloadContractLoading())

    const lambdaFunction = generate === false ? `get-contract` : `create-contract`
    const url = `${process.env.REACT_APP_EXECUTE_API_URL}/${lambdaFunction}`
    const idToken = yield select(state => state.user.idToken)

    // @todo Consolidate!
    const response = yield fetch(url, {
      body: JSON.stringify({ contractId: id }),
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Authorization': idToken,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      mode: 'cors'
    })

    if (response.ok && response.status === 200) {
      const json = yield response.json()
      if ('response' in json && 'url' in json.response) {
        const contractPdfUrl = json.response.url
        yield put(ContractActions.downloadContractSuccess(contractPdfUrl))
      } else {
        const error = new Error('Došlo k chybě při získávání Smlouvy v PDF (003).')
        yield put(ContractActions.downloadContractFailure(error))
      }
    } else {
      const error = new Error('Došlo k chybě při získávání Smlouvy v PDF (002).')
      yield put(ContractActions.downloadContractFailure(error))
    }
  } catch (e) {
    const error = new Error('Došlo k chybě při získávání Smlouvy v PDF (001).')
    yield put(ContractActions.downloadContractFailure(error))
  }
}

export default downloadContract
