import React, { useEffect, useState } from 'react'

import ClientsMyContractsList from 'Components/_Client/MyContracts/index'
import DefaultLayout from 'Components/_Common/Layout/Default'
import Loading from 'Components/Loading'

import { API, graphqlOperation } from 'aws-amplify'

import * as ROUTES from 'routes'

import gql from 'graphql-tag'
const query = gql`query ($nextToken: String) {
  listContracts (filter: { deleted: { ne: true }, status: { ne: "not-accepted" } signedAt: { ne: null} }, limit: 1000, nextToken: $nextToken) {
    items {
      id
      __typename
      contractNumber
      signedAt
      monthlyAmount
      totalYears
      numberOfCoins
      qualityOfCoins
      entryPriceIncrease
      entryPriceIncreaseOneTimePayment
      lastPaymentReceivedAt
      status
    }
    nextToken
  }
}`

const loadContracts = async (nextToken: any = null) => {
  const contracts: any[] = []

  const result = (await API.graphql(graphqlOperation(query, { nextToken }))) as any

  if (result && 'data' in result && 'listContracts' in result.data && 'items' in result.data.listContracts &&
    result.data.listContracts.items instanceof Array && result.data.listContracts.items.length > 0) {
    result.data.listContracts.items.map((contract: any) => {
      contracts.push(contract)
      return contract
    })
  }

  if (result && 'data' in result && 'listContracts' in result.data && 'nextToken' in result.data.listContracts && result.data.listContracts.nextToken) {
    const contractsMore = await loadContracts(result.data.listContracts.nextToken)
    contracts.push(...contractsMore)
  }

  return contracts
} 

const Home = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)

    const contracts: any[] = await loadContracts()

    setIsLoading(false)
    setData(contracts)
  }

  return (
    <DefaultLayout
      title={`Moje smlouvy`}
      tabs={[{
        route: ROUTES.CLIENT_MY_CONTRACTS, title: 'Moje smlouvy'
      }]}
      activeTab={0}
    >
      {isLoading ? <Loading /> : <ClientsMyContractsList data={data} />}
    </DefaultLayout>
  )
}

export default Home
