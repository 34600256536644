const INITIAL_STATE = {
  clientSearching: false,
  clientFound: null,
  //
  gcClientSearching: false,
  gcClientFound: null,
  //
  biClientSearching: false,
  biClientFound: null,
  //
  searchingError: false,
  searchingErrorMessage: null,
  //
  result: null
}

export default INITIAL_STATE
