import { listInvoices } from 'Sagas/Invoice/listInvoices/listInvoices'
import { listInvoicesSuccess } from 'Sagas/Invoice/listInvoices/listInvoicesSuccess'
import { listInvoicesFailure } from 'Sagas/Invoice/listInvoices/listInvoicesFailure'
//
import { getInvoice } from 'Sagas/Invoice/getInvoice/getInvoice'
import { getInvoiceSuccess } from 'Sagas/Invoice/getInvoice/getInvoiceSuccess'
import { getInvoiceFailure } from 'Sagas/Invoice/getInvoice/getInvoiceFailure'
//
import { prefillClient } from 'Sagas/Invoice/prefillClient/prefillClient'
import { prefillClientSuccess } from 'Sagas/Invoice/prefillClient/prefillClientSuccess'
import { prefillClientFailure } from 'Sagas/Invoice/prefillClient/prefillClientFailure'
//
import { createInvoice } from 'Sagas/Invoice/createInvoice/createInvoice'
import { createInvoiceSuccess } from 'Sagas/Invoice/createInvoice/createInvoiceSuccess'
import { createInvoiceFailure } from 'Sagas/Invoice/createInvoice/createInvoiceFailure'
//
import { downloadInvoice } from 'Sagas/Invoice/downloadInvoice/downloadInvoice'
import { downloadInvoiceSuccess } from 'Sagas/Invoice/downloadInvoice/downloadInvoiceSuccess'
import { downloadInvoiceFailure } from 'Sagas/Invoice/downloadInvoice/downloadInvoiceFailure'
//
import { saveInvoice } from 'Sagas/Invoice/saveInvoice/saveInvoice'
import { saveInvoiceSuccess } from 'Sagas/Invoice/saveInvoice/saveInvoiceSuccess'
import { saveInvoiceFailure } from 'Sagas/Invoice/saveInvoice/saveInvoiceFailure'
//
import { sendInvoice } from 'Sagas/Invoice/sendInvoice/sendInvoice'
import { sendInvoiceSuccess } from 'Sagas/Invoice/sendInvoice/sendInvoiceSuccess'
import { sendInvoiceFailure } from 'Sagas/Invoice/sendInvoice/sendInvoiceFailure'

export default {
  listInvoices,
  listInvoicesSuccess,
  listInvoicesFailure,
  //
  getInvoice,
  getInvoiceSuccess,
  getInvoiceFailure,
  //
  prefillClient,
  prefillClientSuccess,
  prefillClientFailure,
  //
  createInvoice,
  createInvoiceSuccess,
  createInvoiceFailure,
  //
  downloadInvoice,
  downloadInvoiceSuccess,
  downloadInvoiceFailure,
  //
  saveInvoice,
  saveInvoiceSuccess,
  saveInvoiceFailure,
  //
  sendInvoice,
  sendInvoiceSuccess,
  sendInvoiceFailure
}
