import gql from 'graphql-tag'

const query = gql`
  query PREFILL_CLIENT_QUERY($id: ID!) {
    getClient(id: $id) {
      id
      benefitId
      isFs
      cognitoUserId
      titleBefore
      firstname
      lastname
      titleAfter
      personalNumber
      birthDate
      email
      phone
      addressFormatted
      addressStreet
      addressStreetNumber
      addressZip
      addressTown
      hasDifferentDeliveryAddress
      deliveryTitleBefore
      deliveryFirstname
      deliveryLastname
      deliveryTitleAfter
      deliveryCompanyName
      deliveryPhone
      deliveryBirthYear
      deliveryAddressFormatted
      deliveryAddressStreet
      deliveryAddressStreetNumber
      deliveryAddressZip
      deliveryAddressTown
      bankAccountNumber
      bankCode
      version
    }
  }
`

export default query
