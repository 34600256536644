import INITIAL_STATE_FORM from './InitialStateForm'

const INITIAL_STATE: any = {
  listStocksLoading: false,
  listStocksResult: [],
  listStocksError: null,
  //
  listStocksModalOpened: false,
  listStocksModalTarget: null, 
  //
  getStockLoading: false,
  getStockResult: null,
  getStockError: null,
  //
  form: INITIAL_STATE_FORM
}

export default INITIAL_STATE
