import { createReducer } from 'reduxsauce'

import { ClientTypes } from './Actions'
import INITIAL_STATE from './InitialState'

// LIST_CLIENTS_LOADING
export const listClientsLoading = (state: any): any => ({
  ...state,
  listClientsLoading: true,
  listClientsResult: [],
  listClientsError: null
})

// LIST_CLIENTS_SUCCESS
export const listClientsSuccess = (state: any, { data }: any): any => ({
  ...state,
  listClientsLoading: false,
  listClientsResult: data,
  listClientsError: null
})

// LIST_CLIENTS_FAILURE
export const listClientsFailure = (state: any, { error }: any): any => ({
  ...state,
  listClientsLoading: false,
  listClientsError: error
})

//

// LIST_CLIENTS_OPEN_MODAL
export const listClientsOpenModal = (state: any, { flag, target }: any): any => ({
  ...state,
  listClientsModalOpened: flag,
  listClientsModalTarget: flag ? target : null
})

//

// GET_CLIENT_LOADING
export const getClientLoading = (state: any): any => ({
  ...state,
  getClientLoading: true,
  getClientResult: null,
  getClientError: null
})

// GET_CLIENT_SUCCESS
export const getClientSuccess = (state: any, { data }: any): any => ({
  ...state,
  getClientLoading: false,
  getClientResult: data,
  getClientError: null
})

// GET_CLIENT_FAILURE
export const getClientFailure = (state: any, { error }: any): any => ({
  ...state,
  getClientLoading: false,
  getClientError: error
})

//

export const reducer = createReducer(INITIAL_STATE, {
  [ClientTypes.LIST_CLIENTS_LOADING]: listClientsLoading,
  [ClientTypes.LIST_CLIENTS_SUCCESS]: listClientsSuccess,
  [ClientTypes.LIST_CLIENTS_FAILURE]: listClientsFailure,
  //
  [ClientTypes.LIST_CLIENTS_OPEN_MODAL]: listClientsOpenModal,
  //
  [ClientTypes.GET_CLIENT_LOADING]: getClientLoading,
  [ClientTypes.GET_CLIENT_SUCCESS]: getClientSuccess,
  [ClientTypes.GET_CLIENT_FAILURE]: getClientFailure
})
