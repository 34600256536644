import { getContext, put } from 'redux-saga/effects'

import ContractActions from 'Stores/Contract/Actions'
import setStatusOnContractMutation from 'Graphql/Mutations/setStatusOnContractMutation'

import gql from 'graphql-tag'

export function * setStatus ({ id, status }: any) {
  try {
    yield put(ContractActions.setStatusLoading())

    const graphql = yield getContext('graphql')
    const query = gql(`query GetContract($id: ID!) {
      getContract(id: $id) {
        id
        version
      }
    }
    `)
    const queryVariables = { id }
    const contract = yield graphql.query(query, queryVariables)

    const expectedVersion = contract.data.getContract.version

    const mutationVariables = { id, status, expectedVersion }
    
    const result = yield graphql.mutation(setStatusOnContractMutation, mutationVariables)

    yield put(ContractActions.setStatusSuccess(result.data.updateContract))
  } catch (error) {
    yield put(ContractActions.setStatusFailure(error))
  }
}

export default setStatus
