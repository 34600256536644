import React from 'react'

import { connect } from 'react-redux'
import StockActions from 'Stores/Stock/Actions'
import * as StockSelect from 'Stores/Stock/Select'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import DraggablePaper from 'Components/_Common/Dialogs/DraggablePaper'

import StockList from 'Components/Stock/List'

interface Props {
  open?: boolean,
  handleOpenDialog?: any,
  handleCloseDialog?: any
}

class StockList_Modal extends React.Component<Props> {
  render () {
    return (
      <>
        <Dialog
          PaperComponent={DraggablePaper}
          open={(this.props.open) as boolean}
          onClose={this.props.handleCloseDialog}
          aria-labelledby='stock-search-modal-title'
          fullWidth={true}
          maxWidth='lg'
        >
          <DialogTitle id='stock-search-modal-title'>Vyhledání skladové položky</DialogTitle>
          <DialogContent style={{ padding: 0 }}>
            <StockList />
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  open: StockSelect.stocksModalOpened(state)
})

const mapDispatchToProps = (dispatch: any) => ({
  handleOpenDialog: () => dispatch(StockActions.listStocksOpenModal(true)),
  handleCloseDialog: () => dispatch(StockActions.listStocksOpenModal(false))
})

export default connect(mapStateToProps, mapDispatchToProps)(StockList_Modal)
