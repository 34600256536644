import gql from 'graphql-tag'

const query = gql`
query LIST_STOCKS_QUERY (
  $filter: ModelStockFilterInput
  $limit: Int
  $nextToken: String
) {
  listStocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      __typename
      emission {
        name
      }
      coin {
        denominationMotif
      }
      series
      purchasedAt
      pricePurchase
      priceSelling
      commission
      status
      sold
      soldAt
      invoices (limit: 1000) {
        items {
          id
          __typename
        }
      }
    }
    nextToken
  }
}
`

export default query
