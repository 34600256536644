import React from 'react'

import Alert from '@material-ui/lab/Alert'

import DefaultLayout from 'Components/_Common/Layout/Default'

const Http404NotFound = (props: any) => {
  return (
    <DefaultLayout
      title={`Stránka nenalezena`}
      tabs={[]}
      activeTab={0}
    >
      <Alert severity='error'>Požadovaná stránka nebyla nalezena!</Alert>
    </DefaultLayout>
  )
}

export default Http404NotFound
