import { createReducer } from 'reduxsauce'

import { ContractTypes } from '../Contract/Actions'
import { CloseContractDialogTypes } from './Actions'

import INITIAL_STATE from './InitialState'

export const reducer = createReducer(INITIAL_STATE, {
  [CloseContractDialogTypes.CLOSE]: (): any => INITIAL_STATE,
  [CloseContractDialogTypes.SET_STEP]: (state: any, { step }: any): any => ({
    ...state,
    activeStep: step
  }),
  //
  [ContractTypes.CLOSE_CONTRACT_LOADING]: (): any => ({
    ...INITIAL_STATE,
    open: true,
    loading: true
  }),
  [ContractTypes.CLOSE_CONTRACT_SUCCESS]: (state: any): any => ({
    ...state,
    loading: false,
    activeStep: 3
  }),
  [ContractTypes.CLOSE_CONTRACT_FAILURE]: (state: any, { error }: any): any => ({
    ...state,
    loading: false,
    error: error.message
  })
})
