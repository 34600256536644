import React, { useEffect, useState } from 'react'

import { Form } from 'react-final-form'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import FormGroup from 'Components/_Common/Form/Group'
import FormLayout from 'Components/_Common/Form/Layout'

import ts from 'Helpers/thousandsSeparator'

import { format } from 'date-fns'

const styles = (theme: Theme) => createStyles({
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },

  sliderWrapper: {
    // marginTop: `13px`,
    padding: `15px`
  },

  grow: {
    flexGrow: 1,
  },
  appBar: {
    bottom: 0,
    height: 66,
    justifyContent: 'center',
    top: 'auto'
  }
})

interface Props extends WithStyles<typeof styles> {
  data: object | undefined,
  loading?: boolean
}

const PaymentForm = (props: Props) => {
  const { classes, loading } = props

  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    const _initialValues = Object.assign({ // tslint:disable-line
      
    }, props.data) as any
  
    setInitialValues(_initialValues)
  }, [props.data])

  const onSubmit = (input: any): void => {
    // @todo
  }

  const renderForm = ({ form: { mutators }, handleSubmit, reset, submitting, pristine, invalid, values }: any) => (
    <form onSubmit={handleSubmit}>

      {<FormGroup title='Detaily platby'>
        <Grid container={true} direction='row'>
          <Grid item={true} xs={6}>
            <Typography variant='h6' color='secondary'>
              Základní údaje
            </Typography>

            <List component='nav' style={{ marginLeft: `-15px` }} disablePadding={true}>
              <ListItem>
                <ListItemText
                  primary={values.bankReference}
                  secondary='Číslo transakce'
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={values.date ? format(new Date(values.date), 'dd.MM.yyyy') : ` - `}
                  secondary='Datum transakce'
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={values.bankAccountNumberWithBankCode}
                  secondary='Číslo účtu protistrany'
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={values.bankAccountName ? values.bankAccountName : ` - `}
                  secondary='Název účtu protistrany'
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={values.message ? values.message : ` - `}
                  secondary='Zpráva pro příjemce'
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item={true} xs={6}>
            <Typography variant='h6' color='secondary'>
              Detaily
            </Typography>

            <List component='nav' style={{ marginLeft: `-15px` }} disablePadding={true}>
              <ListItem>
                <ListItemText
                  primary={`${ts(values.amount)} ${values.currency}`}
                  secondary='Částka'
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={values.variableSymbol}
                  secondary='Variabilní symbol'
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={values.constantSymbol}
                  secondary='Konstantní symbol'
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={values.specificSymbol}
                  secondary='Specifický symbol'
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </FormGroup>}

      <AppBar color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            &nbsp;
          </Typography>
          &nbsp;
          {<Button type='submit' variant={`outlined`} size="large" color='secondary' disabled={true}>
            Uložit platbu
          </Button>}
        </Toolbar>
      </AppBar>
    </form>
  )

  return (
    <FormLayout title={`Náhled platby`}>
      {loading && <div style={{ position: `absolute`, width: `444px`, display: `flex`, justifyContent: `center`, alignItems: `center`, marginTop: `-44px` }}>
        <CircularProgress />
      </div>}
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={renderForm}
      />
    </FormLayout>
  )
}

export default withStyles(styles)(PaymentForm)
