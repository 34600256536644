import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  listDistributions: ['filter'], // LIST_DISTRIBUTIONS
  listDistributionsLoading: null, // LIST_DISTRIBUTIONS_LOADING
  listDistributionsSuccess: ['data'], // LIST_DISTRIBUTIONS_SUCCESS
  listDistributionsFailure: ['error'] // LIST_DISTRIBUTIONS_FAILURE
}, { prefix: 'DISTRIBUTION_' })

export const DistributionTypes = Types

export default Creators
