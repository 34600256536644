import { Table } from '@devexpress/dx-react-grid-material-ui'

import formatDateCallback from 'Components/_Common/List/Callback/formatDate'
import formatMoneyCallback from 'Components/_Common/List/Callback/formatMoney'

import contractDataStatus from 'Components/Contract/Data/Status'

export const columns = [
  {
    name: 'contract.action',
    title: ' ',
    getContractId: (row: any): string => row.id
  },
  //
  {
    name: 'contract.contractNumber',
    title: 'Číslo smlouvy *',
    getCellValue: (row: any): string => row.contractNumber,
    getContractId: (row: any): string => row.id
  },
  //
  {
    name: 'contract.signedAt',
    title: 'Uzavřeno',
    getCellValue: (row: any): string => formatDateCallback(row, 'signedAt')
  },
  //
  {
    name: 'client.name',
    title: 'Klient',
    primary: false,
    getCellValue: (row: any): string => `${row.clientLastname}, ${row.clientFirstname}`,
    getClientId: (row: any): string => row.client.id
  },
  //
  {
    name: 'contract.entryPriceIncrease',
    title: 'VCN',
    getCellValue: (row: any): any => row.entryPriceIncrease
  },
  //
  {
    name: 'contract.monthlyAmount',
    title: 'Měsíčně',
    getCellValue: (row: any): any => formatMoneyCallback(row, 'monthlyAmount')
  },
  //
  {
    name: 'contract.totalYears',
    title: 'Trvání',
    getCellValue: (row: any): string => `${row.totalYears} let`
  },
  //
  {
    name: 'contract.numberOfCoins',
    title: 'Mincí',
    getCellValue: (row: any): string => `${row.numberOfCoins} ks`
  },
  //
  {
    name: 'contract.qualityOfCoins',
    title: 'Kvalita',
    getCellValue: (row: any): string => row.qualityOfCoins
  },
  //
  {
    name: 'contract.exportedAt',
    title: 'Exportováno do BI',
    getCellValue: (row: any): string => formatDateCallback(row, 'exportedAt')
  },
  //
  {
    name: 'contract.status',
    title: 'Stav',
    getCellValue: (row: any): string => contractDataStatus(row.status)
  }
]

export const columnExtensions: Table.ColumnExtension[] = [
  {
    align: 'center',
    columnName: 'contract.action',
    width: 75
  },
  //
  {
    align: 'right',
    columnName: 'contract.contractNumber',
    width: 120
  },
  //
  {
    align: 'center',
    columnName: 'contract.signedAt'
  },
  //
  {
    align: 'left',
    columnName: 'client.name',
    width: 250
  },
  //
  {
    align: 'right',
    columnName: 'contract.entryPriceIncrease',
    width: 200
  },
  //
  {
    align: 'right',
    columnName: 'contract.monthlyAmount'
  },
  //
  {
    align: 'right',
    columnName: 'contract.totalYears'
  },
  //
  {
    align: 'right',
    columnName: 'contract.numberOfCoins'
  },
  //
  {
    align: 'center',
    columnName: 'contract.qualityOfCoins'
  },
  //
  {
    align: 'center',
    columnName: 'contract.exportedAt'
  },
  //
  {
    align: 'center',
    columnName: 'contract.status'
  }
]
