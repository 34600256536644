import { Table } from '@devexpress/dx-react-grid-material-ui'

// import contractDataStatus from 'Components/Contract/Data/Status'
import formatDateCallback from 'Components/_Common/List/Callback/formatDate'

export const columns = [
  {
    name: 'contract.contractNumber',
    title: 'Číslo smlouvy *',
    getCellValue: (row: any): string => row.contractNumber,
    getContractId: (row: any): string => row.id
  },
  //
  {
    name: 'contract.signedAt',
    title: 'Uzavřeno',
    getCellValue: (row: any): string => formatDateCallback(row, 'signedAt')
  },
  //
  {
    name: 'client.name',
    title: 'Klient',
    primary: false,
    getCellValue: (row: any): string => row.clientFullName,
    getClientId: (row: any): string => row.clientId
  },
  //
  {
    name: 'client.type',
    title: 'Typ',
    getCellValue: (row: any): string => row.clientType
  },
  //
  {
    name: 'contract.deleted',
    title: 'Smazáno',
    getCellValue: (row: any): any => row.contractDeleted
  },
  //
  {
    name: 'contract.active',
    title: 'Aktivní',
    getCellValue: (row: any): any => row.contractActive
  },
  //
  // {
  //   name: 'contract.status',
  //   title: 'Stav',
  //   getCellValue: (row: any): string => contractDataStatus(row.status)
  // },
  //
  {
    name: 'contract.monthlyAmount',
    title: 'Měsíčně',
    getCellValue: (row: any): any => row.monthlyAmount
  },
  //
  {
    name: 'contract.totalYears',
    title: 'Trvání',
    getCellValue: (row: any): string => row.totalYears
  },
  //
  {
    name: 'contract.qualityOfCoins',
    title: 'Kvalita',
    getCellValue: (row: any): string => row.qualityOfCoins
  },
  //
  {
    name: 'contract.entryPriceIncrease',
    title: 'Celkem',
    getCellValue: (row: any): any => row.entryPriceIncrease
  },
  //
  {
    name: 'contract.entryPriceIncreaseOneTime',
    title: 'Jednorázově',
    getCellValue: (row: any): any => row.entryPriceIncreaseOneTime
  },
  //
  {
    name: 'contract.entryPriceIncreasePaidAmount',
    title: 'Již zaplaceno',
    getCellValue: (row: any): any => row.entryPriceIncreasePaidAmount
  },
  //
  {
    name: 'contract.entryPriceIncreaseIsPaid',
    title: 'Uhrazeno',
    getCellValue: (row: any): any => row.entryPriceIncreaseIsPaid
  },
  //
  {
    name: 'contract.entryPriceIncreaseWasPaidAt',
    title: 'Kdy',
    getCellValue: (row: any): any => formatDateCallback(row, 'entryPriceIncreaseWasPaidAt')
  },
  //
  {
    name: 'prediction.paymentsTotal',
    title: 'Celkem',
    getCellValue: (row: any): any => row.paymentsTotal
  },
  //
  {
    name: 'prediction.savedAmountNow',
    title: 'Spoření',
    getCellValue: (row: any): any => row.savedAmountNow
  },
  //
  {
    name: 'prediction.distributionsForContractSum',
    title: 'Cena celkem',
    getCellValue: (row: any): any => row.distributionsForContractSum
  },
  //
  {
    name: 'prediction.savedAmountAfterDistributions',
    title: 'Zůstatek',
    getCellValue: (row: any): any => row.savedAmountAfterDistributions
  },

  // 
  {
    name: 'prediction.firstCoinDenominationMotif',
    title: 'Motiv',
    getCellValue: (row: any): any => row.firstCoinDenominationMotif
  },
  //
  {
    name: 'prediction.firstCoinDateOfIssue',
    title: 'Emise',
    getCellValue: (row: any): any => formatDateCallback(row, 'firstCoinDateOfIssue')
  },

  // 
  {
    name: 'prediction.emission1',
    title: '1',
    getCellValue: (row: any): any => row.emission1
  },
  //
  {
    name: 'prediction.emission2',
    title: '2',
    getCellValue: (row: any): any => row.emission2
  },

  //
  {
    name: 'prediction.shipped201911',
    title: 'Expedováno',
    getCellValue: (row: any): any => row.shipped201911
  },
  //
  {
    name: 'prediction.shipped201911price',
    title: 'Cena',
    getCellValue: (row: any): any => row.shipped201911price
  },
  //
  {
    name: 'prediction.shipped202005',
    title: 'Expedováno',
    getCellValue: (row: any): any => row.shipped202005
  },
  //
  {
    name: 'prediction.shipped202005price',
    title: 'Cena',
    getCellValue: (row: any): any => row.shipped202005price
  },
  //
  {
    name: 'prediction.shipped202010',
    title: 'Expedováno',
    getCellValue: (row: any): any => row.shipped202010
  },
  //
  {
    name: 'prediction.shipped202010price',
    title: 'Cena',
    getCellValue: (row: any): any => row.shipped202010price
  },

  //
  // 05/2021

  //
  {
    name: 'prediction.entryPriceIncrease202105',
    title: 'Stav VCN',
    getCellValue: (row: any): any => row.entryPriceIncrease202105
  },
  //
  {
    name: 'prediction.claim202105',
    title: 'Nárok',
    getCellValue: (row: any): any => row.claim202105
  },
  //
  {
    name: 'prediction.shipped202105',
    title: 'Expedováno',
    getCellValue: (row: any): any => row.shipped202105
  },
  //
  {
    name: 'prediction.shipped202105price',
    title: 'Cena',
    getCellValue: (row: any): any => row.shipped202105price
  }, 
  //
  {
    name: 'prediction.savedAmount202105',
    title: 'Naspořeno',
    getCellValue: (row: any): any => row.shipped202105 === 'Ano' ? null : row.savedAmount202105
  },
  //
  {
    name: 'prediction.leftToPayForCoin202105',
    title: 'Doplatit',
    getCellValue: (row: any): any => row.claim202105 === 'Ne' || row.shipped202105 === 'Ano' ? null : row.leftToPayForCoin202105
  },
  //
  {
    name: 'prediction.coinsCount202105',
    title: 'Mince',
    getCellValue: (row: any): string => row.shipped202105 === 'Ano' ? null : row.coinsCount202105
  },
  //
  {
    name: 'prediction.balance202105',
    title: 'Zůstatek',
    getCellValue: (row: any): any => row.balance202105
  },

  //
  // 10/2021

  //
  {
    name: 'prediction.entryPriceIncrease202110',
    title: 'Stav VCN',
    getCellValue: (row: any): any => row.entryPriceIncrease202110
  },
  //
  {
    name: 'prediction.claim202110',
    title: 'Nárok',
    getCellValue: (row: any): any => row.claim202110
  },
  //
  {
    name: 'prediction.shipped202110',
    title: 'Expedováno',
    getCellValue: (row: any): any => row.shipped202110
  },
  //
  {
    name: 'prediction.shipped202110price',
    title: 'Cena',
    getCellValue: (row: any): any => row.shipped202110price
  }, 
  //
  {
    name: 'prediction.savedAmount202110',
    title: 'Naspořeno',
    getCellValue: (row: any): any => row.shipped202110 === 'Ano' ? null : row.savedAmount202110
  },
  //
  {
    name: 'prediction.leftToPayForCoin202110',
    title: 'Doplatit',
    getCellValue: (row: any): any => row.claim202110 === 'Ne' || row.shipped202110 === 'Ano' ? null : row.leftToPayForCoin202110
  },
  //
  {
    name: 'prediction.coinsCount202110',
    title: 'Mince',
    getCellValue: (row: any): string => row.shipped202110 === 'Ano' ? null : row.coinsCount202110
  },
  //
  {
    name: 'prediction.balance202110',
    title: 'Zůstatek',
    getCellValue: (row: any): any => row.balance202110
  },

  //
  // 05/2022

  //
  {
    name: 'prediction.entryPriceIncrease202205',
    title: 'Stav VCN',
    getCellValue: (row: any): any => row.entryPriceIncrease202205
  },
  //
  {
    name: 'prediction.claim202205',
    title: 'Nárok',
    getCellValue: (row: any): any => row.claim202205
  },
  //
  {
    name: 'prediction.shipped202205',
    title: 'Expedováno',
    getCellValue: (row: any): any => row.shipped202205
  },
  //
  {
    name: 'prediction.shipped202205price',
    title: 'Cena',
    getCellValue: (row: any): any => row.shipped202205price
  }, 
  //
  {
    name: 'prediction.savedAmount202205',
    title: 'Naspořeno',
    getCellValue: (row: any): any => row.shipped202205 === 'Ano' ? null : row.savedAmount202205
  },
  //
  {
    name: 'prediction.leftToPayForCoin202205',
    title: 'Doplatit',
    getCellValue: (row: any): any => row.claim202205 === 'Ne' || row.shipped202205 === 'Ano' ? null : row.leftToPayForCoin202205
  },
  //
  {
    name: 'prediction.coinsCount202205',
    title: 'Mince',
    getCellValue: (row: any): string => row.shipped202205 === 'Ano' ? null : row.coinsCount202205
  },
  //
  {
    name: 'prediction.balance202205',
    title: 'Zůstatek',
    getCellValue: (row: any): any => row.balance202205
  },

  //
  // 10/2022

  //
  {
    name: 'prediction.entryPriceIncrease202210',
    title: 'Stav VCN',
    getCellValue: (row: any): any => row.entryPriceIncrease202210
  },
  //
  {
    name: 'prediction.claim202210',
    title: 'Nárok',
    getCellValue: (row: any): any => row.claim202210
  },
  //
  {
    name: 'prediction.shipped202210',
    title: 'Expedováno',
    getCellValue: (row: any): any => row.shipped202210
  },
  //
  {
    name: 'prediction.shipped202210price',
    title: 'Cena',
    getCellValue: (row: any): any => row.shipped202210price
  }, 
  //
  {
    name: 'prediction.savedAmount202210',
    title: 'Naspořeno',
    getCellValue: (row: any): any => row.shipped202210 === 'Ano' ? null : row.savedAmount202210
  },
  //
  {
    name: 'prediction.leftToPayForCoin202210',
    title: 'Doplatit',
    getCellValue: (row: any): any => row.claim202210 === 'Ne' || row.shipped202210 === 'Ano' ? null : row.leftToPayForCoin202210
  },
  //
  {
    name: 'prediction.coinsCount202210',
    title: 'Mince',
    getCellValue: (row: any): string => row.shipped202210 === 'Ano' ? null : row.coinsCount202210
  },
  //
  {
    name: 'prediction.balance202210',
    title: 'Zůstatek',
    getCellValue: (row: any): any => row.balance202210
  },

  //
  // 05/2023

  //
  {
    name: 'prediction.entryPriceIncrease202305',
    title: 'Stav VCN',
    getCellValue: (row: any): any => row.entryPriceIncrease202305
  },
  //
  {
    name: 'prediction.claim202305',
    title: 'Nárok',
    getCellValue: (row: any): any => row.claim202305
  },
  //
  {
    name: 'prediction.shipped202305',
    title: 'Expedováno',
    getCellValue: (row: any): any => row.shipped202305
  },
  //
  {
    name: 'prediction.shipped202305price',
    title: 'Cena',
    getCellValue: (row: any): any => row.shipped202305price
  }, 
  //
  {
    name: 'prediction.savedAmount202305',
    title: 'Naspořeno',
    getCellValue: (row: any): any => row.shipped202305 === 'Ano' ? null : row.savedAmount202305
  },
  //
  {
    name: 'prediction.leftToPayForCoin202305',
    title: 'Doplatit',
    getCellValue: (row: any): any => row.claim202305 === 'Ne' || row.shipped202305 === 'Ano' ? null : row.leftToPayForCoin202305
  },
  //
  {
    name: 'prediction.coinsCount202305',
    title: 'Mince',
    getCellValue: (row: any): string => row.shipped202305 === 'Ano' ? null : row.coinsCount202305
  },
  //
  {
    name: 'prediction.balance202305',
    title: 'Zůstatek',
    getCellValue: (row: any): any => row.balance202305
  },

  //
  // 10/2023

  //
  {
    name: 'prediction.entryPriceIncrease202310',
    title: 'Stav VCN',
    getCellValue: (row: any): any => row.entryPriceIncrease202310
  },
  //
  {
    name: 'prediction.claim202310',
    title: 'Nárok',
    getCellValue: (row: any): any => row.claim202310
  },
  //
  {
    name: 'prediction.shipped202310',
    title: 'Expedováno',
    getCellValue: (row: any): any => row.shipped202310
  },
  //
  {
    name: 'prediction.shipped202310price',
    title: 'Cena',
    getCellValue: (row: any): any => row.shipped202310price
  }, 
  //
  {
    name: 'prediction.savedAmount202310',
    title: 'Naspořeno',
    getCellValue: (row: any): any => row.shipped202310 === 'Ano' ? null : row.savedAmount202310
  },
  //
  {
    name: 'prediction.leftToPayForCoin202310',
    title: 'Doplatit',
    getCellValue: (row: any): any => row.claim202310 === 'Ne' || row.shipped202310 === 'Ano' ? null : row.leftToPayForCoin202310
  },
  //
  {
    name: 'prediction.coinsCount202310',
    title: 'Mince',
    getCellValue: (row: any): string => row.shipped202310 === 'Ano' ? null : row.coinsCount202310
  },
  //
  {
    name: 'prediction.balance202310',
    title: 'Zůstatek',
    getCellValue: (row: any): any => row.balance202310
  }
]

export const columnExtensions: Table.ColumnExtension[] = [
  {
    align: 'right',
    columnName: 'contract.contractNumber',
    width: 135
  },
  //
  {
    align: 'center',
    columnName: 'contract.signedAt'
  },
  //
  {
    align: 'left',
    columnName: 'client.name',
    width: 250
  },
  //
  {
    align: 'center',
    columnName: 'client.type',
    width: 100
  },
  //
  {
    align: 'center',
    columnName: 'contract.deleted'
  },
  //
  {
    align: 'center',
    columnName: 'contract.active'
  },
  //
  // {
  //   align: 'center',
  //   columnName: 'contract.status'
  // },
  //
  {
    align: 'right',
    columnName: 'contract.monthlyAmount'
  },
  //
  {
    align: 'right',
    columnName: 'contract.totalYears'
  },
  //
  {
    align: 'center',
    columnName: 'contract.qualityOfCoins'
  },
  //
  {
    align: 'right',
    columnName: 'contract.entryPriceIncrease',
    width: 200
  },
  //
  {
    align: 'center',
    columnName: 'contract.entryPriceIncreaseOneTime'
  },
  //
  {
    align: 'right',
    columnName: 'contract.entryPriceIncreasePaidAmount'
  },
  //
  {
    align: 'center',
    columnName: 'contract.entryPriceIncreaseIsPaid'
  },
  //
  {
    align: 'center',
    columnName: 'contract.entryPriceIncreaseWasPaidAt'
  },
  //
  {
    align: 'right',
    columnName: 'prediction.paymentsTotal'
  },
  //
  {
    align: 'right',
    columnName: 'prediction.savedAmountNow'
  },
  //
  {
    align: 'right',
    columnName: 'prediction.distributionsForContractSum'
  },
  //
  {
    align: 'right',
    columnName: 'prediction.savedAmountAfterDistributions'
  },

  //
  {
    align: 'left',
    columnName: 'prediction.firstCoinDenominationMotif'
  },
  {
    align: 'left',
    columnName: 'prediction.firstCoinDateOfIssue'
  },

  //
  {
    align: 'center',
    columnName: 'prediction.emission1',
    width: 66
  },
  {
    align: 'center',
    columnName: 'prediction.emission2',
    width: 66
  },

  //
  {
    align: 'center',
    columnName: 'prediction.shipped201911'
  },
  //
  {
    align: 'right',
    columnName: 'prediction.shipped201911price'
  },
  //
  {
    align: 'center',
    columnName: 'prediction.shipped202005'
  },
  //
  {
    align: 'right',
    columnName: 'prediction.shipped202005price'
  },
  //
  {
    align: 'center',
    columnName: 'prediction.shipped202010'
  },
  //
  {
    align: 'right',
    columnName: 'prediction.shipped202010price'
  },

  /**********/

  //
  {
    align: 'right',
    columnName: 'prediction.entryPriceIncrease202105'
  },
  //
  {
    align: 'center',
    columnName: 'prediction.claim202105'
  },
  //
  {
    align: 'center',
    columnName: 'prediction.shipped202105'
  },
  //
  {
    align: 'right',
    columnName: 'prediction.shipped202105price'
  },
  //
  {
    align: 'right',
    columnName: 'prediction.savedAmount202105'
  },
  //
  {
    align: 'right',
    columnName: 'prediction.leftToPayForCoin202105'
  },
  //
  {
    align: 'center',
    columnName: 'prediction.coinsCount202105'
  },
  //
  {
    align: 'right',
    columnName: 'prediction.balance202105'
  },
  //
  {
    align: 'right',
    columnName: 'prediction.entryPriceIncrease202110'
  },
  //
  {
    align: 'center',
    columnName: 'prediction.claim202110'
  },
  //
  {
    align: 'center',
    columnName: 'prediction.shipped202110'
  },
  //
  {
    align: 'right',
    columnName: 'prediction.shipped202110price'
  },
  //
  {
    align: 'right',
    columnName: 'prediction.savedAmount202110'
  },
  //
  {
    align: 'right',
    columnName: 'prediction.leftToPayForCoin202110'
  },
  //
  {
    align: 'center',
    columnName: 'prediction.coinsCount202110'
  },
  //
  {
    align: 'right',
    columnName: 'prediction.balance202110'
  }
]
