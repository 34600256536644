import React from 'react'

import DefaultLayout from 'Components/_Common/Layout/Default'

import * as ROUTES from 'routes'

import DistributionList from 'Components/Distribution/List'

const Distribution = () => {
  return (
    <DefaultLayout
      title={`Distribuce`}
      tabs={[{
        route: ROUTES.ADMIN_DISTRIBUTIONS, title: 'Přehled distribucí'
      }]}
      activeTab={0}
    >
      <DistributionList />
    </DefaultLayout>
  )
}

export default Distribution
