import React, { useEffect, useState } from 'react'
import { gsap, Power3 } from 'gsap'

import { isMobile, isFirefox, isEdge } from 'react-device-detect'

import LoginForm from 'Components/Auth/LoginForm'
import ForgotPasswordForm from 'Components/Auth/ForgotPasswordForm'
import Copyright from 'Components/Auth/Copyright'

import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  '::-webkit-scrollbar': {
    display: 'none',
    width: '0px',
    background: 'transparent'
  },
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: `#000000`,
    WebkitOverflowScrolling: 'touch'
  },
  formContainer: {
    position: 'absolute',
    width: '100%',
    marginBottom: '100px'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: theme.spacing(3)
  },
  link: {
    color: theme.palette.primary.light,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  switchFormLink: {
    cursor: 'pointer',
    opacity: 0
  }
}))

const circlesW = isMobile ? 150 : 400
const circlesH = isMobile ? 150 : 400

const logoW = isMobile ? 307 : 818
const logoH = isMobile ? 307 : 818

const logoToW = isMobile ? 300 : 400
const logoToH = isMobile ? 300 : 400

const styles: any = {
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000000',
    overflow: 'hidden'
  },
  circles: {
    position: 'absolute',
    marginTop: isFirefox || isEdge ? '-275px' : '-150px',
    width: '1300px',
    height: '1300px'
  },
  circle: {
    borderRadius: '50%',
    borderWidth: 1,
    borderColor: 'rgba(235, 192, 78, 0.3)',
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circle3: {
    width: '100%', // '400px',
    height: '100%' // '400px'
  },
  circle2: {
    width: '75%', // '300px',
    height: '75%' // '300px'
  },
  circle1: {
    width: '66.666%', // '200px',
    height: '66.666%' // '200px'
  },
  group1: {
    position: 'absolute',
    marginLeft: '-20%' // '-80px'
  },
  group2: {
    position: 'absolute',
    marginLeft: '20%'// '80px'
  },
  group3: {
    position: 'absolute',
    marginTop: '33%', // '135px'
  },
  logo: {
    position: 'absolute',
    marginTop: isMobile ? '-28px' : '-76px', // ?
    left: isMobile ? '-80px' : '-214px', // ?
    width: `${logoW}px`,
    display: 'flex',
    justifyContent: 'center'
  },
  form: {
    width: isMobile ? '90%' : '500px',
    position: 'absolute',
    opacity: 0,
    marginTop: '100px'
  }
}

const Login = () => {
  const circles = React.createRef()
  const circle1 = React.createRef()
  const circle2 = React.createRef()
  const circle3 = React.createRef()

  const theSVG = React.createRef()
  const theGradient = React.createRef()
  const masker = React.createRef()
  const guy = React.createRef()

  const formContainer = React.createRef()
  const switchFormLink = React.createRef()

  const classes = useStyles()

  const [form, setForm] = useState('')

  useEffect(() => {
    gsap.to((circles.current as any), { width: `${circlesW}px`, height: `${circlesH}px`, duration: 4, ease: Power3.easeOut })
      
    gsap.set((theGradient.current as any), { attr: { x1: logoW, x2: logoW * 2 + 1 } })
    gsap.to((theGradient.current as any), 5, { attr: { x1: -logoW, x2: 1 }, delay: 1, ease: Power3.easeInOut })
  
    gsap.to((theSVG.current as any), { width: `${logoToW}px`, height: `${logoToH}px`, translateY: isMobile ? '-80px' : '0px', delay: 5, duration: 2, ease: Power3.easeInOut })

    gsap.to((circle1.current as any), { opacity: 0, delay: 3, duration: 1.5, ease: Power3.easeInOut })
    gsap.to((circle2.current as any), { opacity: 0, delay: 3, duration: 1.5, ease: Power3.easeInOut })
    gsap.to((circle3.current as any), { opacity: 0, delay: 3, duration: 1.5, ease: Power3.easeInOut })
    
    gsap.to((formContainer.current as any), { opacity: 1, marginTop: '0px', duration: 3, delay: 7, ease: Power3.easeOut })

    setTimeout(() => {
      setForm('LoginForm')
    }, 7000)
  }, [])

  useEffect(() => {
    gsap.set((switchFormLink.current as any), { opacity: 0 })
    gsap.to((switchFormLink.current as any), { opacity: 1, duration: 2, delay: 2, ease: Power3.easeOut })
  }, [form, setForm])

  return (
    <div style={styles.container}>

      <div style={styles.circles} ref={(circles as any)}>
        <div style={styles.logo}>

          <svg
            version='1.1'
            id='theSVG'
            ref={(theSVG as any)}
            viewBox={`0 0 ${logoW} ${logoH}`}
            width={`${logoW}`}
            height={`${logoH}`}
            xmlSpace='preserve'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
          >
            <defs>
              <mask id='masker' ref={(masker as any)}>
                <rect className='gradientBox' fill='url(#theGradient)' x='0' y='0' width={`${logoW}`} height={`${logoH}`} />
              </mask>
              <pattern id='guy' ref={(guy as any)} patternUnits='userSpaceOnUse' width={`${logoW}`} height={`${logoH}`}>
                <image
                  xlinkHref='/logo.png'
                  x='0'
                  y='0'
                  width={`${logoW}`}
                  height={`${logoH}`}
                />
              </pattern>
              <linearGradient id='theGradient' ref={(theGradient as any)} gradientUnits='userSpaceOnUse' x1='0' y1={`${logoH / 2}`} x2={`${logoW}`} y2={`${logoH / 2}`}>
                <stop offset='0' style={{stopColor: '#000000'}} />
                <stop offset='1' style={{stopColor: '#ffffff'}} />
              </linearGradient>
            </defs>
            <g mask='url(#masker)'>
              <rect id='theImage' width={`${logoW}`} height={`${logoH}`} x='0' y='0' fill='url(#guy)' />
            </g>
          </svg>

        </div>

        <div ref={(circle3 as any)} style={{ ...styles.group1, ...styles.circle, ...styles.circle3 }}>
          <div style={{ ...styles.circle, ...styles.circle2 }}>
            <div style={{ ...styles.circle, ...styles.circle1 }}>
            </div>
          </div>
        </div>

        <div ref={(circle2 as any)} style={{ ...styles.group2, ...styles.circle, ...styles.circle3 }}>
          <div style={{ ...styles.circle, ...styles.circle2 }}>
            <div style={{ ...styles.circle, ...styles.circle1 }}>
            </div>
          </div>
        </div>

        <div ref={(circle1 as any)} style={{ ...styles.group3, ...styles.circle, ...styles.circle3 }}>
          <div style={{ ...styles.circle, ...styles.circle2 }}>
            <div style={{ ...styles.circle, ...styles.circle1 }}>
            </div>
          </div>
        </div>
      </div>

      <div style={styles.form} ref={(formContainer as any)}>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <div className={classes.paper}>
            {form === 'LoginForm' && <LoginForm />}
            {form === 'ForgotPasswordForm' && <ForgotPasswordForm onSuccess={() => { setForm('LoginForm') }} />}
            <Grid container={true}>
              <Grid item={true} xs={true} ref={(switchFormLink as any)} className={classes.switchFormLink}>
                <Link component='a' className={classes.link} onClick={(e: any) => {
                  e.preventDefault()
                  setForm(form => form === 'LoginForm' ? 'ForgotPasswordForm' : 'LoginForm')
                }}>
                  {form === 'LoginForm' ? 'Zapomněli jste heslo?' : 'Zpět na přihlášení'}
                </Link>
              </Grid>
            </Grid>
          </div>
        </Container>

        <Copyright />
      </div>

    </div>
  )
}

export default Login
