import React from 'react'

import { connect } from 'react-redux'
import ContractActions from 'Stores/Contract/Actions'
import EmissionActions from 'Stores/Emission/Actions'
import * as EmissionSelect from 'Stores/Emission/Select'

import { Form, FormSpy } from 'react-final-form'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'

import Select from 'Components/_Common/Form/Inputs/Select'

import { isAfter, parseISO, getYear, format } from 'date-fns'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    row: {
      width: 350
    }
  })
)

const SelectFirstCoin = (props: any) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    props.listEmissions()
  }, [])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getCoinEmissionDateByCoinId = (coinId: string): any => {
    let dateOfIssue: any = null

    props.emissionsResult.map((emssion: any) => {
      emssion.coins.items
        .map((coin: any) => {
          if (coin.id === coinId) {
            dateOfIssue = parseISO(coin.dateOfIssue)
          }
        })
    })

    return format(dateOfIssue, 'yyyy-MM-dd')
  }

  const updateFormState = (state: any): any => {
    const emissionId = state.values['contractFirstEmissionId']

    if (props.contractForm.contractFirstEmissionId !== emissionId) {
      props.emissionsResult.map((item: any) => {
        if (item.id === emissionId) {
          const coinId = item.coins.items
            .filter((coin: any) => isAfter(parseISO(coin.dateOfIssue), new Date()))
            .reverse()[0].id

          if (props.contractForm.contractFirstCoinId !== coinId) {
            const dateOfIssue = getCoinEmissionDateByCoinId(coinId)
            props.contractFormSelectFirstCoin(emissionId, coinId, dateOfIssue)
          }
        }
      })
    } else {
      const coinId = state.values['contractFirstCoinId']
      if (props.contractForm.contractFirstCoinId !== coinId) {
        const dateOfIssue = getCoinEmissionDateByCoinId(coinId)
        props.contractFormSelectFirstCoin(emissionId, coinId, dateOfIssue)
      }
    }
  }

  const renderForm = ({ handleSubmit }: any) => (
    <form>
      <FormSpy onChange={updateFormState} />

      <Button size="small" variant="contained" color="secondary" onClick={handleClickOpen}>Změnit</Button>
      <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Vyberte první minci</DialogTitle>
        <DialogContent> 
          <Grid item={true} xs={12} className={classes.row}>
            <Select
              name='contractFirstEmissionId'
              label='Emise'
              disabled={props.emissionsLoading}>
              {props.emissionsResult
                .slice(0) // duplicate the array to avoid state mutation
                .filter((item: any) => parseInt(item.yearTo) >= getYear(new Date()))
                .reverse()
                .map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item={true} xs={12} className={classes.row}>
            <Select
              name='contractFirstCoinId'
              label='Mince'
              disabled={props.emissionsLoading}>
              {props.contractForm.contractFirstEmissionId 
                ? (
                  props.emissionsResult.map((item: any) => {
                    if (item.id === props.contractForm.contractFirstEmissionId) {
                      return item.coins.items
                        .slice(0) // duplicate the array to avoid state mutation
                        .reverse()
                        .map((coin: any, i: any) => {
                          if (isAfter(parseISO(coin.dateOfIssue), parseISO('2020-05-01'))) {
                            return (
                              <MenuItem key={coin.id} value={coin.id}>
                                {i + 1}. {coin.denominationMotif} – {format(parseISO(coin.dateOfIssue), 'dd.MM.yyyy')}
                              </MenuItem>
                            )
                          }
                        })
                    }
                  })
                ) : <MenuItem value=''>Vyberte emisi</MenuItem>
              }
            </Select>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Hotovo
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )

  return (
    <Form
      onSubmit={() => {}}
      initialValues={props.contractForm}
      render={renderForm}
    />
  )
}

const mapStateToProps = (state: any): any => ({
  emissionsLoading: EmissionSelect.emissionsLoading(state),
  emissionsResult: EmissionSelect.emissionsResult(state),
  //
  contractForm: state.contract.form,
})

const mapDispatchToProps = (dispatch: any) => ({
  listEmissions: () => dispatch(EmissionActions.listEmissions()),
  //
  contractFormSelectFirstCoin: (emissionId: string, coinId: string, dateOfIssue: string) => dispatch(ContractActions.formSelectFirstCoin(emissionId, coinId, dateOfIssue)),
})

export default connect(mapStateToProps, mapDispatchToProps)(
  SelectFirstCoin
)
