import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import PredictionActions from 'Stores/Prediction/Actions'
import * as PredictionSelect from 'Stores/Prediction/Select'

import Loading from 'Components/Loading'
import List from 'Components/Prediction/List/List'

const PredictionList = () => {
  const loading = useSelector(PredictionSelect.predictionLoading)
  const data = useSelector(PredictionSelect.predictionResult)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(PredictionActions.getData())
  }, [])

  return (
    <>
      <List rows={data || []} />
      {loading && <Loading />}
    </>
  )
}

export default PredictionList
