import React, { useState } from 'react'

import { connect } from 'react-redux'

import { DataTypeProvider, FilteringState, IntegratedFiltering, IntegratedSummary, SummaryState } from '@devexpress/dx-react-grid';
import { Grid, TableBandHeader, TableFilterRow, TableFixedColumns, TableHeaderRow, TableSummaryRow, VirtualTable } from '@devexpress/dx-react-grid-material-ui'
import Paper from '@material-ui/core/Paper'

import { columnExtensions, columns } from 'Components/Prediction/List/Settings'

import Cell from 'Components/_Common/List/Cell'
import FilterCell from 'Components/_Common/List/FilterCell'

import { filterRowMessages, summaryRowMessages, tableMessages } from 'Components/_Common/List/I18n/messages'
import thousandsSeparator from 'Helpers/thousandsSeparator'

const getRowId = (row: any): string => row.id

interface Props {
  rows: any[],
  dimensions?: any,
  heightOffset?: number
}

const CurrencyFormatter = ({ value }: any): any => (
  <>
    {value ? `${thousandsSeparator(value)} Kč` : ``}
  </>
)

const CurrencyTypeProvider = (props: any) => (
  <DataTypeProvider
    formatterComponent={CurrencyFormatter}
    {...props}
  />
)

const columnBands = [
  {
    title: 'VCN',
    children: [
      { columnName: 'contract.entryPriceIncrease' },
      { columnName: 'contract.entryPriceIncreaseOneTime' },
      { columnName: 'contract.entryPriceIncreasePaidAmount' },
      { columnName: 'contract.entryPriceIncreaseIsPaid' },
      { columnName: 'contract.entryPriceIncreaseWasPaidAt' }
    ]
  },
  {
    title: 'Platby',
    children: [
      { columnName: 'prediction.paymentsTotal' },
      { columnName: 'prediction.savedAmountNow' }
    ]
  },
  {
    title: 'První mince',
    children: [
      { columnName: 'prediction.firstCoinDenominationMotif' },
      { columnName: 'prediction.firstCoinDateOfIssue' }
    ]
  },
  {
    title: 'Emise',
    children: [
      { columnName: 'prediction.emission1' },
      { columnName: 'prediction.emission2' }
    ]
  },
  {
    title: 'Distribuce – všechny',
    children: [
      { columnName: 'prediction.distributionsForContractSum' },
      { columnName: 'prediction.savedAmountAfterDistributions' }
    ]
  },
  {
    title: 'Distribuce – 11/2019',
    children: [
      { columnName: 'prediction.shipped201911' },
      { columnName: 'prediction.shipped201911price' }
    ]
  },
  {
    title: 'Distribuce – 05/2020',
    children: [
      { columnName: 'prediction.shipped202005' },
      { columnName: 'prediction.shipped202005price' }
    ]
  },
  {
    title: 'Distribuce – 10/2020',
    children: [
      { columnName: 'prediction.shipped202010' },
      { columnName: 'prediction.shipped202010price' }
    ]
  },
  {
    title: 'Distribuce – Emise 1 – 05/2021 (CHEB)',
    children: [
      { columnName: 'prediction.entryPriceIncrease202105' },
      { columnName: 'prediction.claim202105' },
      { columnName: 'prediction.shipped202105' },
      { columnName: 'prediction.shipped202105price' },
      { columnName: 'prediction.savedAmount202105' },
      { columnName: 'prediction.leftToPayForCoin202105' },
      { columnName: 'prediction.coinsCount202105' },
      { columnName: 'prediction.balance202105' }
    ]
  },
  {
    title: 'Distribuce – Emise 2 – 10/2021 (JIHLAVA)',
    children: [
      { columnName: 'prediction.entryPriceIncrease202110' },
      { columnName: 'prediction.claim202110' },
      { columnName: 'prediction.shipped202110' },
      { columnName: 'prediction.shipped202110price' },
      { columnName: 'prediction.savedAmount202110' },
      { columnName: 'prediction.leftToPayForCoin202110' },
      { columnName: 'prediction.coinsCount202110' },
      { columnName: 'prediction.balance202110' }
    ]
  },
  {
    title: 'Distribuce – Emise 1 – 05/2022 (MIKULOV)',
    children: [
      { columnName: 'prediction.entryPriceIncrease202205' },
      { columnName: 'prediction.claim202205' },
      { columnName: 'prediction.shipped202205' },
      { columnName: 'prediction.shipped202205price' },
      { columnName: 'prediction.savedAmount202205' },
      { columnName: 'prediction.leftToPayForCoin202205' },
      { columnName: 'prediction.coinsCount202205' },
      { columnName: 'prediction.balance202205' }
    ]
  },
  {
    title: 'Distribuce – Emise 2 – 10/2022 (LITOMĚŘICE)',
    children: [
      { columnName: 'prediction.entryPriceIncrease202210' },
      { columnName: 'prediction.claim202210' },
      { columnName: 'prediction.shipped202210' },
      { columnName: 'prediction.shipped202210price' },
      { columnName: 'prediction.savedAmount202210' },
      { columnName: 'prediction.leftToPayForCoin202210' },
      { columnName: 'prediction.coinsCount202210' },
      { columnName: 'prediction.balance202210' }
    ]
  },
  {
    title: 'Distribuce – Emise 1 – 05/2023 (KROMĚŘÍŽ)',
    children: [
      { columnName: 'prediction.entryPriceIncrease202305' },
      { columnName: 'prediction.claim202305' },
      { columnName: 'prediction.shipped202305' },
      { columnName: 'prediction.shipped202305price' },
      { columnName: 'prediction.savedAmount202305' },
      { columnName: 'prediction.leftToPayForCoin202305' },
      { columnName: 'prediction.coinsCount202305' },
      { columnName: 'prediction.balance202305' }
    ]
  },
  {
    title: 'Distribuce – Emise 2 – 10/2023 (HRADEC KRÁLOVÉ)',
    children: [
      { columnName: 'prediction.entryPriceIncrease202310' },
      { columnName: 'prediction.claim202310' },
      { columnName: 'prediction.shipped202310' },
      { columnName: 'prediction.shipped202310price' },
      { columnName: 'prediction.savedAmount202310' },
      { columnName: 'prediction.leftToPayForCoin202310' },
      { columnName: 'prediction.coinsCount202310' },
      { columnName: 'prediction.balance202310' }
    ]
  },
]

const PredictionsList = ({ rows, dimensions, heightOffset = 0 }: Props) => {
  const [numberColumns] = useState([
    'contract.contractNumber',
    'contract.totalYears',
    'prediction.coinsCount202105',
    'prediction.coinsCount202110',
    'prediction.coinsCount202205',
    'prediction.coinsCount202210',
    'prediction.coinsCount202305',
    'prediction.coinsCount202310'
  ])

  const [numberFilterOperations] = useState([
    'equal',
    'notEqual',
    'greaterThan',
    'greaterThanOrEqual',
    'lessThan',
    'lessThanOrEqual'
  ])

  const [currencyColumns] = useState([
    'contract.monthlyAmount',
    'contract.entryPriceIncrease',
    'contract.entryPriceIncreasePaidAmount',
    'prediction.paymentsTotal',
    'prediction.savedAmountNow',
    'prediction.distributionsForContractSum',
    'prediction.savedAmountAfterDistributions',
    'prediction.shipped201911price',
    'prediction.shipped202005price',
    'prediction.shipped202010price',
    'prediction.shipped202105price',
    'prediction.shipped202110price',
    'prediction.shipped202205price',
    'prediction.shipped202210price',
    'prediction.shipped202305price',
    'prediction.shipped202310price',
    // 05/2021
    'prediction.entryPriceIncrease202105',
    'prediction.savedAmount202105',
    'prediction.leftToPayForCoin202105',
    'prediction.balance202105',
    // 10/2021
    'prediction.entryPriceIncrease202110',
    'prediction.savedAmount202110',
    'prediction.leftToPayForCoin202110',
    'prediction.balance202110',
    // 05/2022
    'prediction.entryPriceIncrease202205',
    'prediction.savedAmount202205',
    'prediction.leftToPayForCoin202205',
    'prediction.balance202205',
    // 10/2022
    'prediction.entryPriceIncrease202210',
    'prediction.savedAmount202210',
    'prediction.leftToPayForCoin202210',
    'prediction.balance202210',
    // 05/2023
    'prediction.entryPriceIncrease202305',
    'prediction.savedAmount202305',
    'prediction.leftToPayForCoin202305',
    'prediction.balance202305',
    // 10/2023
    'prediction.entryPriceIncrease202310',
    'prediction.savedAmount202310',
    'prediction.leftToPayForCoin202310',
    'prediction.balance202310'
  ])

  const [currencyFilterOperations] = useState([
    'equal',
    'notEqual',
    'greaterThan',
    'greaterThanOrEqual',
    'lessThan',
    'lessThanOrEqual'
  ])

  return (
    <Paper elevation={2} style={{ width: `${dimensions.width - 72}px`, overflow: `hidden` }}>
      <Grid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
      >
        <CurrencyTypeProvider
          for={currencyColumns}
        />

        <DataTypeProvider
          for={numberColumns}
          availableFilterOperations={numberFilterOperations}
        />
        <DataTypeProvider
          for={currencyColumns}
          availableFilterOperations={currencyFilterOperations}
        />

        <FilteringState defaultFilters={[
            { columnName: 'contract.deleted', value: 'Ne' },
            { columnName: 'contract.active', value: 'Ano' }
          ]}
        />
        <IntegratedFiltering />

        <SummaryState
          totalItems={[
            { columnName: 'contract.contractNumber', type: 'count' },
            // { columnName: 'contract.monthlyAmount', type: 'avg' },
            { columnName: 'prediction.paymentsTotal', type: 'sum' },
            { columnName: 'prediction.savedAmountNow', type: 'sum' },
            { columnName: 'prediction.savedAmountAfterDistributions', type: 'sum' },
            { columnName: 'prediction.leftToPayForCoin202105', type: 'sum' },
            { columnName: 'prediction.leftToPayForCoin202110', type: 'sum' },
            { columnName: 'prediction.leftToPayForCoin202205', type: 'sum' },
            { columnName: 'prediction.leftToPayForCoin202210', type: 'sum' },
            { columnName: 'prediction.leftToPayForCoin202305', type: 'sum' },
            { columnName: 'prediction.leftToPayForCoin202310', type: 'sum' }
          ]}
        />
        <IntegratedSummary />

        <VirtualTable
          cellComponent={Cell}
          columnExtensions={columnExtensions}
          height={dimensions.height - 96 + heightOffset}
          estimatedRowHeight={50}
          messages={tableMessages}
        />

        <TableHeaderRow />
        <TableFilterRow
          showFilterSelector
          cellComponent={FilterCell}
          messages={filterRowMessages}
        />
        <TableSummaryRow
          messages={summaryRowMessages}
        />

        <TableBandHeader
          columnBands={columnBands}
        />
        <TableFixedColumns
          leftColumns={['contract.contractNumber']}
        />
      </Grid>
    </Paper>
  )
}

const mapStateToProps = (state: any) => ({
  dimensions: state.app.dimensions
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(
  PredictionsList
)
