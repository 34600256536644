import { createReducer } from 'reduxsauce'

import { InvoiceTypes } from './Actions'
import INITIAL_STATE from './InitialState'
import INITIAL_STATE_FORM from './InitialStateForm'

import personalNumberValidator from 'Components/_Common/Form/validators/personalNumber'

import getBirthDateFromPersonalNumber from 'Helpers/getBirthDateFromPersonalNumber'
import isEmpty from 'Helpers/isEmpty'
import ucfirst from 'Helpers/ucfirst'

import { addDays } from 'date-fns'

// LIST_INVOICES_LOADING
export const listInvoicesLoading = (state: any): any => ({
  ...state,
  listInvoicesLoading: true,
  listInvoicesResult: [],
  listInvoicesError: null
})

// LIST_INVOICES_SUCCESS
export const listInvoicesSuccess = (state: any, { data }: any): any => ({
  ...state,
  listInvoicesLoading: false,
  listInvoicesResult: data,
  listInvoicesError: null
})

// LIST_INVOICES_FAILURE
export const listInvoicesFailure = (state: any, { error }: any): any => ({
  ...state,
  listInvoicesLoading: false,
  listInvoicesError: error
})

//

// GET_INVOICE_LOADING
export const getInvoiceLoading = (state: any): any => ({
  ...state,
  getInvoiceLoading: true,
  getInvoiceResult: null,
  getInvoiceError: null,
  //
  form: {
    ...INITIAL_STATE_FORM,
    loading: true    
  }
})

// GET_INVOICE_SUCCESS
export const getInvoiceSuccess = (state: any, { data }: any): any => {
  const invoice: any = Object.assign({}, data)
  
  invoice.clientBenefitId = data.client.benefitId
  invoice.clientIsFs = data.client.isFs

  let price = 0
  invoice.items.map((item: any) => {
    if (parseInt(item.price, 10)) {
      price += parseInt(item.price, 10)
    }
    return item
  })
  
  return {
    ...state,
    getInvoiceLoading: false,
    getInvoiceResult: data,
    getInvoiceError: null,
    //
    form: {
      ...INITIAL_STATE_FORM,
      loading: false,
      ...invoice,
      price
    }
  }
}

// GET_INVOICE_FAILURE
export const getInvoiceFailure = (state: any, { error }: any): any => ({
  ...state,
  getInvoiceLoading: false,
  getInvoiceError: error,
  //
  form: INITIAL_STATE_FORM
})

//

// FORM_UPDATE
export const formUpdate = (state: any, { data }: any): any => {
  const newFormState = { ...state.form, ...data, dirty: true }

  if (!data || !(data instanceof Object) || Object.keys(data).length === 0) {
    return state
  }

  Object.keys(data).map((key: string) => {
    switch(key) {
      case 'dateOfIssue':
        if (data[key] && (!('dueDate' in newFormState) || isEmpty(newFormState.dueDate))) {
          const dueDate = addDays(new Date(data[key]), 7)
          newFormState.dueDate = dueDate.toISOString()
        }
        break

      case 'clientEmail':
        const clientEmail = data[key]
        if (!isEmpty(clientEmail)) {
          newFormState[key] = String(clientEmail).toLowerCase()
        }
        break

      case 'clientPersonalNumber':
        const pn = String(data[key])
        if ((pn.length === 10 || (pn.length === 9 && parseInt(pn.substr(0, 2), 10) < 54)) && personalNumberValidator(pn)) {
          newFormState.clientBirthDate = getBirthDateFromPersonalNumber(pn)
        }
        break

      case 'clientFirstname':
      case 'clientLastname':
      case 'clientAddressStreet':
      case 'clientAddressTown':
      case 'clientDeliveryFirstname':
      case 'clientDeliveryLastname':
      case 'clientDeliveryAddressStreet':
      case 'clientDeliveryAddressTown':
        const value = data[key]
        if (!isEmpty(value)) {
          newFormState[key] = String(value).charAt(0).toUpperCase() + String(value).slice(1)
        }
        break

      case 'clientHasDifferentDeliveryAddress':
        if (data[key] === false) {
          const whatToClear = {
            clientDeliveryTitleBefore: null,
            clientDeliveryFirstname: null,
            clientDeliveryLastname: null,
            clientDeliveryTitleAfter: null,
            clientDeliveryCompanyName: null,
            clientDeliveryAddressStreet: null,
            clientDeliveryAddressStreetNumber: null,
            clientDeliveryAddressZip: null,
            clientDeliveryAddressTown: null,
            clientDeliveryPhone: null,
            clientDeliveryBirthYear: null,
          } as any
        
          for (const property in whatToClear) {
            if (whatToClear.hasOwnProperty(property)) {
              newFormState[property] = whatToClear[property]
            }
          }
        }
        break
    }

    return data[key]
  })

  let price = 0
  newFormState.items.map((item: any) => {
    if (parseInt(item.price, 10)) {
      price += parseInt(item.price, 10)
    }
    return item
  })

  newFormState.price = price

  return {
    ...state,
    form: newFormState
  }
}

// FORM_ADD_ITEM
export const formAddItem = (state: any): any => {
  const newItems = state.form.items.slice()
  newItems.push({ text: '', price: '' })

  return {
    ...state,
    form: {
      ...state.form,
      dirty: true,
      items: newItems
    }
  }
}

// FORM_ADD_ITEM
export const formAddItemFromStock = (state: any, { id, text, price }: any): any => {
  let newItems = state.form.items.slice()

  if (newItems.length === 1 && newItems[0].text === '' && newItems[0].price === '') {
    newItems = []
  }

  newItems.push({ stockId: id, text, price })

  let priceTotal = 0
  newItems.map((item: any) => {
    if (parseInt(item.price, 10)) {
      priceTotal += parseInt(item.price, 10)
    }
    return item
  })

  return {
    ...state,
    form: {
      ...state.form,
      dirty: true,
      items: newItems,
      price: priceTotal
    }
  }
}

// FORM_REMOVE_ITEM
export const formRemoveItem = (state: any, { index }: any): any => {
  const newItems = state.form.items.slice()
  newItems.splice(index, 1)

  let priceTotal = 0
  newItems.map((item: any) => {
    if (parseInt(item.price, 10)) {
      priceTotal += parseInt(item.price, 10)
    }
    return item
  })

  return {
    ...state,
    form: {
      ...state.form,
      dirty: true,
      items: newItems,
      price: priceTotal
    }
  }
}

// FORM_CLEAN
export const formClean = (state: any): any => ({
  ...state,
  form: INITIAL_STATE_FORM
})

// FORM_CLEAN_CLIENT
export const formCleanClient = (state: any): any => {
  const newFormState = { ...state.form }

  const whatToClear = {
    clientId: null,
    clientBenefitId: null,
    clientFirstname: null,
    clientLastname: null,
    // clientPersonalNumber: null,
    clientBirthDate: null,
    clientEmail: null,
    clientPhone: null,

    clientAddressStreet: null,
    clientAddressStreetNumber: null,
    clientAddressZip: null,
    clientAddressTown: null,

    clientHasDifferentDeliveryAddress: false,
     
    clientDeliveryFirstname: null,
    clientDeliveryLastname: null,
    clientDeliveryCompanyName: null,
    clientDeliveryAddressStreet: null,
    clientDeliveryAddressStreetNumber: null,
    clientDeliveryAddressZip: null,
    clientDeliveryAddressTown: null,
    clientDeliveryPhone: null,
    clientDeliveryBirthYear: null,

    clientBankAccountNumber: null,
    clientBankCode: null,

    clientIsFs: null
  } as any

  for (const property in whatToClear) {
    if (whatToClear.hasOwnProperty(property)) {
      newFormState[property] = whatToClear[property]
    }
  }

  return {
    ...state,
    form: newFormState
  }
}

//

// PREFILL_CLIENT_LOADING
export const prefillClientLoading = (state: any): any => ({
  ...state,
  form: {
    ...state.form,
    loading: true,
    prefill: true
  }
})

// PREFILL_CLIENT_SUCCESS
export const prefillClientSuccess = (state: any, { data }: any): any => {
  const client: any = {}

  client.clientId = data.id
  client.clientBenefitId = data.benefitId
  client.clientIsFs = data.isFs

  const transferKeys = [
    'titleBefore', 'firstname', 'lastname', 'titleAfter',
    'phone', 'email',
    'addressStreet', 'addressStreetNumber', 'addressZip', 'addressTown',
    'hasDifferentDeliveryAddress',
    'deliveryAddressStreet', 'deliveryAddressStreetNumber', 'deliveryAddressZip', 'deliveryAddressTown'
  ]

  transferKeys.map(key => {
    client[`client${ucfirst(key)}`] = data[key]
    return key
  })

  return {
    ...state,
    form: {
      ...state.form,
      prefill: false,
      dirty: true,
      ...client
    }
  }
}

// PREFILL_CLIENT_FAILURE
export const prefillClientFailure = (state: any): any => ({
  ...state,
  form: INITIAL_STATE_FORM
})

//

// CREATE_INVOICE_LOADING
export const createInvoiceLoading = (state: any): any => ({
  ...state,
  createInvoiceLoading: true,
  createInvoiceError: null
})

// CREATE_INVOICE_SUCCESS
export const createInvoiceSuccess = (state: any): any => ({
  ...state,
  createInvoiceLoading: false,
  createInvoiceError: null
})

// CREATE_INVOICE_FAILURE
export const createInvoiceFailure = (state: any, { error }: any): any => ({
  ...state,
  createInvoiceLoading: false,
  createInvoiceError: error
})

//

// // DOWNLOAD_INVOICE_LOADING
// export const downloadInvoiceLoading = (state: any): any => ({
//   ...state,
//   downloadInvoiceLoading: true,
//   downloadInvoiceError: null
// })

// // DOWNLOAD_INVOICE_SUCCESS
// export const downloadInvoiceSuccess = (state: any): any => ({
//   ...state,
//   downloadInvoiceLoading: false,
//   downloadInvoiceError: null
// })

// // DOWNLOAD_INVOICE_FAILURE
// export const downloadInvoiceFailure = (state: any, { error }: any): any => ({
//   ...state,
//   downloadInvoiceLoading: false,
//   downloadInvoiceError: error
// })

//

// CREATE_INVOICE_LOADING
export const sendInvoiceLoading = (state: any): any => ({
  ...state,
  sendInvoiceLoading: true,
  sendInvoiceError: null
})

// CREATE_INVOICE_SUCCESS
export const sendInvoiceSuccess = (state: any): any => ({
  ...state,
  sendInvoiceLoading: false,
  sendInvoiceError: null
})

// CREATE_INVOICE_FAILURE
export const sendInvoiceFailure = (state: any, { error }: any): any => ({
  ...state,
  sendInvoiceLoading: false,
  sendInvoiceError: error
})

//

export const reducer = createReducer(INITIAL_STATE, {
  [InvoiceTypes.LIST_INVOICES_LOADING]: listInvoicesLoading,
  [InvoiceTypes.LIST_INVOICES_SUCCESS]: listInvoicesSuccess,
  [InvoiceTypes.LIST_INVOICES_FAILURE]: listInvoicesFailure,
  //
  [InvoiceTypes.GET_INVOICE_LOADING]: getInvoiceLoading,
  [InvoiceTypes.GET_INVOICE_SUCCESS]: getInvoiceSuccess,
  [InvoiceTypes.GET_INVOICE_FAILURE]: getInvoiceFailure,
  //
  [InvoiceTypes.FORM_UPDATE]: formUpdate,
  [InvoiceTypes.FORM_ADD_ITEM]: formAddItem,
  [InvoiceTypes.FORM_ADD_ITEM_FROM_STOCK]: formAddItemFromStock,
  [InvoiceTypes.FORM_REMOVE_ITEM]: formRemoveItem,
  [InvoiceTypes.FORM_CLEAN]: formClean,
  [InvoiceTypes.FORM_CLEAN_CLIENT]: formCleanClient,
  //
  [InvoiceTypes.PREFILL_CLIENT_LOADING]: prefillClientLoading,
  [InvoiceTypes.PREFILL_CLIENT_SUCCESS]: prefillClientSuccess,
  [InvoiceTypes.PREFILL_CLIENT_FAILURE]: prefillClientFailure,
  //
  [InvoiceTypes.CREATE_INVOICE_LOADING]: createInvoiceLoading,
  [InvoiceTypes.CREATE_INVOICE_SUCCESS]: createInvoiceSuccess,
  [InvoiceTypes.CREATE_INVOICE_FAILURE]: createInvoiceFailure,
  //
  // [InvoiceTypes.DOWNLOAD_INVOICE_LOADING]: downloadInvoiceLoading,
  // [InvoiceTypes.DOWNLOAD_INVOICE_SUCCESS]: downloadInvoiceSuccess,
  // [InvoiceTypes.DOWNLOAD_INVOICE_FAILURE]: downloadInvoiceFailure,
  //
  [InvoiceTypes.SEND_INVOICE_LOADING]: sendInvoiceLoading,
  [InvoiceTypes.SEND_INVOICE_SUCCESS]: sendInvoiceSuccess,
  [InvoiceTypes.SEND_INVOICE_FAILURE]: sendInvoiceFailure
})
