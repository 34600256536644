import { put, select } from 'redux-saga/effects'

import ContractActions from 'Stores/Contract/Actions'

export function * changeAddress ({ id, data }: any): any {
  try {
    yield put(ContractActions.changeAddressLoading())

    console.log('Contract ID:', id)
    console.log('Address Data:', data)

    const lambdaFunction = 'change-address'
    const url = `${process.env.REACT_APP_EXECUTE_API_URL}/${lambdaFunction}`
    const idToken: any = yield select(state => state.user.idToken)

    const response = yield fetch(url, {
      body: JSON.stringify({ contractId: id, addressData: data }),
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Authorization': idToken,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      mode: 'cors'
    })

    if (response.ok && response.status === 200) {
      yield put(ContractActions.changeAddressSuccess())
      yield put(ContractActions.getContract(id))
    } else {
      const error = new Error('Došlo k chybě při změně adresy – (002)!')
      yield put(ContractActions.changeAddressFailure(error))
    }
  } catch (e) {
    const error = new Error(`Došlo k chybě při změně adresy – (001) [${e.message}]!`)
    yield put(ContractActions.changeAddressFailure(error))
  }
}

export default changeAddress
