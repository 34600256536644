import { all, takeLatest } from 'redux-saga/effects'

import { PaymentTypes } from 'Stores/Payment/Actions'
import Payment from 'Sagas/Payment'

export default function * root() {
  yield all([
    takeLatest(PaymentTypes.LIST_PAYMENTS, Payment.listPayments),
    takeLatest(PaymentTypes.LIST_PAYMENTS_SUCCESS, Payment.listPaymentsSuccess),
    takeLatest(PaymentTypes.LIST_PAYMENTS_FAILURE, Payment.listPaymentsFailure),
    //
    takeLatest(PaymentTypes.GET_PAYMENT, Payment.getPayment),
    takeLatest(PaymentTypes.GET_PAYMENT_SUCCESS, Payment.getPaymentSuccess),
    takeLatest(PaymentTypes.GET_PAYMENT_FAILURE, Payment.getPaymentFailure)
  ])
}
