import React from 'react'

import { connect } from 'react-redux'

import { FilteringState, IntegratedFiltering, IntegratedSummary, SummaryState } from '@devexpress/dx-react-grid';
import { Grid, TableFilterRow, TableFixedColumns, TableHeaderRow, TableSummaryRow, VirtualTable } from '@devexpress/dx-react-grid-material-ui'
import Paper from '@material-ui/core/Paper'

import { columnExtensions, columns } from 'Components/Payment/List/Settings'

import Cell from 'Components/_Common/List/Cell'
import FilterCell from 'Components/_Common/List/FilterCell'

import { filterRowMessages, summaryRowMessages, tableMessages } from 'Components/_Common/List/I18n/messages'

const getRowId = (row: any): string => row.id

interface Props {
  rows: any[],
  dimensions?: any,
  heightOffset?: number,
  user?: any
}

const PaymentsList = ({ rows, dimensions, heightOffset = 0, user }: Props) => {
  let listColumns

  if (user.group !== 'Admins') {
    const disabledColumns = [`partClient`, `partCommission`, `partGoldCoins`, `commissionExportedAt`]
    listColumns = columns.filter((column: any) => disabledColumns.indexOf(column.name) === -1)
  } else {
    listColumns = columns
  }

  return (
    <Paper elevation={2} style={{ width: `${dimensions.width - 72}px`, overflow: `hidden` }}>
      <Grid
        rows={rows}
        columns={listColumns}
        getRowId={getRowId}
      >
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />

        <SummaryState
          totalItems={[
            { columnName: 'payment.bankReference', type: 'count' }
          ]}
        />
        <IntegratedSummary />

        <VirtualTable
          cellComponent={Cell}
          columnExtensions={columnExtensions}
          height={dimensions.height - 96 + heightOffset}
          estimatedRowHeight={50}
          messages={tableMessages}
        />
        <TableHeaderRow />
        <TableFilterRow
          showFilterSelector
          cellComponent={FilterCell}
          messages={filterRowMessages}
        />
        <TableSummaryRow
          messages={summaryRowMessages}
        />
        <TableFixedColumns
          leftColumns={['payment.action', 'payment.bankReference']}
          rightColumns={['payment.status']}
        />
      </Grid>
    </Paper>
  )
}

const mapStateToProps = (state: any): any => ({
  dimensions: state.app.dimensions,
  user: state.user
})

export default connect(mapStateToProps)(PaymentsList)
