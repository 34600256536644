import { put, select } from 'redux-saga/effects'

import ContractActions from 'Stores/Contract/Actions'

export function * sendContract ({ id }: any) {
  try {
    yield put(ContractActions.sendContractLoading())

    const lambdaFunction = `send-contract`
    const url = `${process.env.REACT_APP_EXECUTE_API_URL}/${lambdaFunction}`
    const idToken = yield select(state => state.user.idToken)

    const response = yield fetch(url, {
      body: JSON.stringify({ contractId: id }),
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Authorization': idToken,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      mode: 'cors'
    })

    if (response.ok && response.status === 200) {
      const json = yield response.json()
      if ('response' in json && 'postmarkMessageId' in json.response) {
        yield put(ContractActions.sendContractSuccess())
      } else {
        const error = new Error('Došlo k chybě při odesílání emailu!')
        yield put(ContractActions.sendContractFailure(error))
      }
    } else {
      const { response: resp } = yield response.json()
      const error = new Error(`Došlo k chybě při odesílání emailu (${resp.code}: ${resp.message})!`)
      yield put(ContractActions.sendContractFailure(error))
    }
  } catch (e) {
    const error = new Error(`Došlo k chybě při odesílání emailu.`)
    yield put(ContractActions.sendContractFailure(error))
  }
}

export default sendContract
