import { all, takeLatest } from 'redux-saga/effects'

import { DistributionTypes } from 'Stores/Distribution/Actions'
import Distribution from 'Sagas/Distribution'

export default function * root() {
  yield all([
    takeLatest(DistributionTypes.LIST_DISTRIBUTIONS, Distribution.listDistributions),
    takeLatest(DistributionTypes.LIST_DISTRIBUTIONS_SUCCESS, Distribution.listDistributionsSuccess),
    takeLatest(DistributionTypes.LIST_DISTRIBUTIONS_FAILURE, Distribution.listDistributionsFailure)
  ])
}
