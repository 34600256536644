import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getCoin: ['id'], // GET_COIN
  getCoinLoading: null, // GET_COIN_LOADING
  getCoinSuccess: ['data'], // GET_COIN_SUCCESS
  getCoinFailure: ['error'] // GET_COIN_FAILURE
}, { prefix: 'COIN_' })

export const CoinTypes = Types

export default Creators
