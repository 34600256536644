import React from 'react'

import { connect } from 'react-redux'
import ContractActions from 'Stores/Contract/Actions'

import { Alert } from '@material-ui/lab'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import DraggablePaper from 'Components/_Common/Dialogs/DraggablePaper'

import Loading from 'Components/Loading'

import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { Field, Form } from 'react-final-form'
import Switch from 'Components/_Common/Form/Inputs/Switch'
import TextField from 'Components/_Common/Form/Inputs/TextField'

import FormSection from 'Components/_Common/Form/Section'

import ucfirst from 'Helpers/ucfirst'

const InputRow = (props: any) => (
  <Grid container={true} xs={12} md={12} lg={12} direction='row'>
    <Grid container={true} alignContent='center' style={{ minHeight: `66px` }} spacing={1}>
      {props.children}
    </Grid>
  </Grid>
)

interface Props {
  contract: any,
  opened: boolean,
  changeAddress: any,
  loading?: boolean,
  error?: any,
  close: any
}

const ChangeAddressDialog = (props: Props) => {
  const { contract, opened, changeAddress, loading, error, close} = props

  const renderForm = (formProps: any) => (
    <form onSubmit={formProps.handleSubmit}>
      {/* <pre style={{ color: 'white' }}>
        {JSON.stringify(formProps, null, 2)}
      </pre> */}

      <FormSection>
        <Alert severity='warning' style={{ marginBottom: '20px' }}>
          Adresa bude změněna na Smlouvě č. {contract ? contract.contractNumber : ''} a u Klienta. <br />
          <strong>Jiné Smlouvy Klienta nebudou ovlivněny!</strong>
        </Alert>

        <InputRow>
          <Grid item={true} xs={4} md={2}>
            <TextField
              name='clientTitleBefore'
              label='Titul'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item={true} xs={8} md={4}>
            <TextField
              name='clientFirstname'
              label='Jméno *'
            />
          </Grid>

          <Grid item={true} xs={8} md={4}>
            <TextField
              name='clientLastname'
              label='Příjmení *'
            />
          </Grid>

          <Grid item={true} xs={4} md={2}>
            <TextField
              name='clientTitleAfter'
              label='Titul'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </InputRow>

        <InputRow>
          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientAddressStreet'
              label='Ulice *'
            />
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientAddressStreetNumber'
              label='Číslo popisné/orientační *'
            />
          </Grid>
        </InputRow>

        <InputRow>
          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientAddressZip'
              label='PSČ *'
            />
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientAddressTown'
              label='Město *'
            />
          </Grid>
        </InputRow>
      </FormSection>

      <FormSection>
        <InputRow>
          <Grid item={true} xs={12} md={6}>
            <FormControlLabel
              control={
                <Field name='clientHasDifferentDeliveryAddress' component={Switch} type='checkbox' />
              }
              label={`Odlišná adresa pro zasílání`}
            />
          </Grid>
        </InputRow>

        {formProps && formProps.values && formProps.values.clientHasDifferentDeliveryAddress && <InputRow>
          <Grid item={true} xs={4} md={2}>
            <TextField
              name='clientDeliveryTitleBefore'
              label='Titul'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item={true} xs={8} md={4}>
            <TextField
              name='clientDeliveryFirstname'
              label='Jméno *'
            />
          </Grid>

          <Grid item={true} xs={8} md={4}>
            <TextField
              name='clientDeliveryLastname'
              label='Příjmení *'
            />
          </Grid>

          <Grid item={true} xs={4} md={2}>
            <TextField
              name='clientDeliveryTitleAfter'
              label='Titul'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </InputRow>}

        {formProps && formProps.values && formProps.values.clientHasDifferentDeliveryAddress && <InputRow>
          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientDeliveryCompanyName'
              label='Název firmy'
            />
          </Grid>
        </InputRow>}

        {formProps && formProps.values && formProps.values.clientHasDifferentDeliveryAddress && <InputRow>
          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientDeliveryAddressStreet'
              label='Ulice *'
            />
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientDeliveryAddressStreetNumber'
              label='Číslo popisné/orientační *'
            />
          </Grid>
        </InputRow>}

        {formProps && formProps.values && formProps.values.clientHasDifferentDeliveryAddress && <InputRow>
          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientDeliveryAddressZip'
              label='PSČ *'
            />
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientDeliveryAddressTown'
              label='Město *'
            />
          </Grid>
        </InputRow>}

        {formProps && formProps.values && formProps.values.clientHasDifferentDeliveryAddress && <InputRow>
          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientDeliveryPhone'
              label='Telefon *'
            />
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <TextField
              name='clientDeliveryBirthYear'
              label='Rok narození *'
            />
          </Grid>
        </InputRow>}
      </FormSection>

      <Button type='submit' variant='contained' size="large" color='secondary'>
        Odeslat
      </Button>
      &nbsp;
      <Button variant='outlined' size="large" color='secondary' onClick={close}>
        Zrušit
      </Button>
    </form>
  )

  const handleChangeAddress = (values: any): void => {
    const transferKeys = [
      'titleBefore', 'firstname', 'lastname', 'titleAfter',
      'addressStreet', 'addressStreetNumber', 'addressZip', 'addressTown',
      'hasDifferentDeliveryAddress',
      'deliveryTitleBefore', 'deliveryFirstname', 'deliveryLastname', 'deliveryTitleAfter',
      'deliveryAddressStreet', 'deliveryAddressStreetNumber', 'deliveryAddressZip', 'deliveryAddressTown',
      'deliveryPhone', 'deliveryBirthYear'
    ]
  
    const data: any = {}
    transferKeys.map(key => {
      if (String(key).includes('delivery') && values['clientHasDifferentDeliveryAddress'] !== true) {
        data[key] = null  
      } else {
        const v = values[`client${ucfirst(key)}`]
        data[key] = v ? v : null
      }

      return key
    })

    changeAddress(contract.id, data)
  }

  return (
    <Dialog
      PaperComponent={DraggablePaper}
      open={opened}
      onClose={close}
      aria-labelledby='form-dialog-title'
      maxWidth='md'
      fullWidth={true}
    >
      <DialogTitle id='form-dialog-title'>Změnit adresu na Smlouvě č. {contract ? contract.contractNumber : ''}</DialogTitle>
      <DialogContent style={{ paddingBottom: '20px' }}>
        {loading && <Loading />}
        {error !== null &&  <DialogContentText style={{ color: `red` }}>{error.message}</DialogContentText>}

        <Form
          onSubmit={handleChangeAddress}
          initialValues={contract}
          render={renderForm}
        />
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = (state: any) => ({
  opened: state.contract.changeAddressDialogOpened,
  loading: state.contract.changeAddressLoading,
  error: state.contract.changeAddressError
})

const mapDispatchToProps = (dispatch: any) => ({
  changeAddress: (id: any, data: any) => dispatch(ContractActions.changeAddress(id, data)),
  close: () => dispatch(ContractActions.closeChangeAddressDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(
  ChangeAddressDialog
)
