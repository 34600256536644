import React from 'react'
import { Field } from 'react-final-form'

import { InlineDatePicker } from 'material-ui-pickers'

import { format } from 'date-fns'

const formatDate = (v: string): string => v ? format(new Date(v), 'dd.MM.yyyy') : ''

export const InputComponent = ({
  input: { name, onChange, ...restInput },
  InputProps,
  meta,
  value,
  ...rest
}: any): any => (
  <InlineDatePicker
    {...rest}
    value={value}
    name={name}
    helperText={meta.touched ? meta.error : undefined}
    error={meta.error && meta.touched}
    InputProps={{
      ...InputProps,
      ...restInput
    }}
    onChange={onChange}
  />
)

export default ({ name, label, ...rest }: any) => (
  <Field
    keyboard={true}
    name={name}
    component={InputComponent as React.SFC<any>}
    type='text'
    label={label}
    margin='dense'
    fullWidth={true}
    variant='outlined'
    disableFuture={false}
    openToYearSelection={false}
    format={formatDate}
    mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
    {...rest}
  />
)
