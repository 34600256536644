import { call, put, select } from 'redux-saga/effects'

import CloseContractDialogActions from 'Stores/CloseContractDialog/Actions'
import ContractActions from 'Stores/Contract/Actions'
import Contract from 'Sagas/Contract'

export function * closeContract () {
  const id = yield select(state => state.contract.form.id)

  if (id) {
    yield put(ContractActions.closeContractLoading())

    yield put(CloseContractDialogActions.setStep(0))
    yield call(Contract.createAccount, { id })
    
    yield put(CloseContractDialogActions.setStep(1))
    yield call(Contract.downloadContract, { id, generate: true })
    
    yield put(CloseContractDialogActions.setStep(2))
    yield call(Contract.sendContract, { id })

    yield call(Contract.getContract, { id })

    yield put(ContractActions.closeContractSuccess())
  } else {
    yield put(ContractActions.closeContractFailure())
  }
}

export default closeContract
