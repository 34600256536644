import { createReducer } from 'reduxsauce'

import { EmailTypes } from './Actions'
import INITIAL_STATE from './InitialState'

// LIST_EMAILS_LOADING
export const listEmailsLoading = (state: any): any => ({
  ...state,
  listEmailsLoading: true,
  listEmailsResult: [],
  listEmailsError: null
})

// LIST_EMAILS_SUCCESS
export const listEmailsSuccess = (state: any, { data }: any): any => ({
  ...state,
  listEmailsLoading: false,
  listEmailsResult: data,
  listEmailsError: null
})

// LIST_EMAILS_FAILURE
export const listEmailsFailure = (state: any, { error }: any): any => ({
  ...state,
  listEmailsLoading: false,
  listEmailsError: error
})

//

// GET_EMAIL_LOADING
export const getEmailLoading = (state: any): any => ({
  ...state,
  getEmailLoading: true,
  getEmailResult: null,
  getEmailError: null
})

// GET_EMAIL_SUCCESS
export const getEmailSuccess = (state: any, { data }: any): any => ({
  ...state,
  getEmailLoading: false,
  getEmailResult: data,
  getEmailError: null
})

// GET_EMAIL_FAILURE
export const getEmailFailure = (state: any, { error }: any): any => ({
  ...state,
  getEmailLoading: false,
  getEmailError: error
})

//

export const reducer = createReducer(INITIAL_STATE, {
  [EmailTypes.LIST_EMAILS_LOADING]: listEmailsLoading,
  [EmailTypes.LIST_EMAILS_SUCCESS]: listEmailsSuccess,
  [EmailTypes.LIST_EMAILS_FAILURE]: listEmailsFailure,
  //
  [EmailTypes.GET_EMAIL_LOADING]: getEmailLoading,
  [EmailTypes.GET_EMAIL_SUCCESS]: getEmailSuccess,
  [EmailTypes.GET_EMAIL_FAILURE]: getEmailFailure
})
