import { getContext, put } from 'redux-saga/effects'

import EmailActions from 'Stores/Email/Actions'

import getEmailQuery from 'Graphql/Queries/getEmailQuery'

export function * getEmail ({ id }: any) {
  try {
    yield put(EmailActions.getEmailLoading())

    const graphql = yield getContext('graphql')
    const variables = { id } as any

    const result = yield graphql.query(getEmailQuery, variables)

    // @todo
    if (result && result.data && result.data.getEmail) {
      yield put(EmailActions.getEmailSuccess(result.data.getEmail))
    }
  } catch (error) {
    yield put(EmailActions.getEmailFailure(error))
  }
}

export default getEmail
