import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  listEmails: ['filter'], // LIST_EMAILS
  listEmailsLoading: null, // LIST_EMAILS_LOADING
  listEmailsSuccess: ['data'], // LIST_EMAILS_SUCCESS
  listEmailsFailure: ['error'], // LIST_EMAILS_FAILURE
  //
  getEmail: ['id'], // GET_EMAIL
  getEmailLoading: null, // GET_EMAIL_LOADING
  getEmailSuccess: ['data'], // GET_EMAIL_SUCCESS
  getEmailFailure: ['error'] // GET_EMAIL_FAILURE
}, { prefix: 'EMAIL_' })

export const EmailTypes = Types

export default Creators
