import React from 'react'

import DefaultLayout from 'Components/_Common/Layout/Default'

import * as ROUTES from 'routes'

import ClientList from 'Components/Client/List'

const Clients = () => {
  return (
    <DefaultLayout
      title={`Klienti`}
      tabs={[{
        route: ROUTES.ADMIN_CLIENTS, title: 'Seznam klientů'
      }, {
        route: '/klient/novy', title: 'Nový klient'
      }]}
      activeTab={0}
    >
      <ClientList />
    </DefaultLayout>
  )
}

export default Clients
