import { createReducer } from 'reduxsauce'

import { StockTypes } from './Actions'
import INITIAL_STATE from './InitialState'
import INITIAL_STATE_FORM from './InitialStateForm'

// LIST_STOCKS_LOADING
export const listStocksLoading = (state: any): any => ({
  ...state,
  listStocksLoading: true,
  listStocksResult: [],
  listStocksError: null
})

// LIST_STOCKS_SUCCESS
export const listStocksSuccess = (state: any, { data }: any): any => ({
  ...state,
  listStocksLoading: false,
  listStocksResult: data,
  listStocksError: null
})

// LIST_STOCKS_FAILURE
export const listStocksFailure = (state: any, { error }: any): any => ({
  ...state,
  listStocksLoading: false,
  listStocksError: error
})

//

// LIST_STOCKS_OPEN_MODAL
export const listStocksOpenModal = (state: any, { flag, target }: any): any => ({
  ...state,
  listStocksModalOpened: flag,
  listStocksModalTarget: flag ? target : null
})

//

// GET_STOCK_LOADING
export const getStockLoading = (state: any): any => ({
  ...state,
  getStockLoading: true,
  getStockResult: null,
  getStockError: null,
  //
  form: {
    ...INITIAL_STATE_FORM,
    loading: true    
  }
})

// GET_STOCK_SUCCESS
export const getStockSuccess = (state: any, { data }: any): any => {
  const stock: any = Object.assign({}, data)
  
  stock.stockEmissionId = data.emission.id
  stock.stockCoinId = data.coin !== null ? data.coin.id : null
  
  return {
    ...state,
    getStockLoading: false,
    getStockResult: data,
    getStockError: null,
    //
    form: {
      ...INITIAL_STATE_FORM,
      loading: false,
      ...stock
    }
  }
}

// GET_STOCK_FAILURE
export const getStockFailure = (state: any, { error }: any): any => ({
  ...state,
  getStockLoading: false,
  getStockError: error,
  //
  form: INITIAL_STATE_FORM
})

//

// FORM_UPDATE
export const formUpdate = (state: any, { data }: any): any => {
  const newFormState = { ...state.form, ...data, dirty: true }

  Object.keys(data).map((key: string) => {
    switch(key) {
      case 'series':
        if (data[key] === true) {
          newFormState.stockCoinId = null
        }
        break
    }
    return data[key]
  })

  return {
    ...state,
    form: newFormState
  }
}

// FORM_CLEAN
export const formClean = (state: any): any => ({
  ...state,
  form: INITIAL_STATE_FORM
})

//

export const reducer = createReducer(INITIAL_STATE, {
  [StockTypes.LIST_STOCKS_LOADING]: listStocksLoading,
  [StockTypes.LIST_STOCKS_SUCCESS]: listStocksSuccess,
  [StockTypes.LIST_STOCKS_FAILURE]: listStocksFailure,
  //
  [StockTypes.LIST_STOCKS_OPEN_MODAL]: listStocksOpenModal,
  //
  [StockTypes.GET_STOCK_LOADING]: getStockLoading,
  [StockTypes.GET_STOCK_SUCCESS]: getStockSuccess,
  [StockTypes.GET_STOCK_FAILURE]: getStockFailure,
  //
  [StockTypes.FORM_UPDATE]: formUpdate,
  [StockTypes.FORM_CLEAN]: formClean
})
