import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import InvoiceActions from 'Stores/Invoice/Actions'

import DefaultLayout from 'Components/_Common/Layout/Default'
import InvoiceForm from 'Components/Invoice/Form'

import * as ROUTES from 'routes'

const Invoice = (props: any) => {
  useEffect(() => {
    props.invoiceFormClean()
  }, [])

  return (
    <DefaultLayout
      title={`Faktury`}
      tabs={[{
        route: ROUTES.ADMIN_INVOICES, title: 'Seznam faktur'
      }, {
        route: '/faktury/nova', title: 'Nová faktura'
      }, {
        route: '/faktury/napoveda', title: 'Nápověda'
      }]}
      activeTab={1}
    >
      <InvoiceForm />
    </DefaultLayout>
  )
}

const mapStateToProps = (state: any) => ({
  data: state.invoice.form
})

const mapDispatchToProps = (dispatch: any) => ({
  invoiceFormClean: () => dispatch(InvoiceActions.formClean())
})

export default connect(mapStateToProps, mapDispatchToProps)(Invoice)
