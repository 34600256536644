import gql from 'graphql-tag'

const query = gql`
  query GET_MY_CONTRACT_QUERY ($id: ID!) {
    getContract(id: $id) {
      id
      contractNumber
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      signedAt
      contractSentAt
      exportedAt
      lastPaymentReceivedAt
      deleted
      deletedAt
      deletedBy
      monthlyAmount
      totalYears
      client {
        id
        __typename
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        version
      }
      clientBenefitId
      clientFirstname
      clientLastname
      clientPersonalNumber
      clientBirthDate
      clientEmail
      clientPhone
      clientAddressFormatted
      clientAddressStreet
      clientAddressStreetNumber
      clientAddressZip
      clientAddressTown
      clientHasDifferentDeliveryAddress
      clientDeliveryFirstname
      clientDeliveryLastname
      clientDeliveryCompanyName
      clientDeliveryPhone
      clientDeliveryBirthYear
      clientDeliveryAddressFormatted
      clientDeliveryAddressStreet
      clientDeliveryAddressStreetNumber
      clientDeliveryAddressZip
      clientDeliveryAddressTown
      clientBankAccountNumber
      clientBankCode
      clientBankAccountOwner
      clientBankAccountOwnerRelation
      defaultPriceOfCoin
      salesMargin
      entryFeePerCoin
      priceOfCoin
      estimatedValue
      numberOfCoins
      qualityOfCoins
      entryPriceIncrease
      entryPriceIncreaseOneTimePayment
      entryPriceIncreaseIsPaid
      entryPriceIncreaseWasPaidAt
      entryPriceIncreasePaidAmount
      indicativeTargetAmount
      savedAmount
      status
      emails (limit: 10000) {
        items {
          id
          receivedAt
          from
          to
          cc
          bcc
          recipients
          subject
          body
          bodyText
          bodyHtml
          attachments
          status
          version
        }
        nextToken
      }
      invoices (limit: 10000) {
        items {
          id
          invoiceNumber
          createdAt
          createdBy
          updatedAt
          updatedBy
          deleted
          deletedAt
          deletedBy
          clientFirstname
          clientLastname
          clientPersonalNumber
          clientBirthDate
          clientEmail
          clientPhone
          clientAddressFormatted
          clientAddressStreet
          clientAddressStreetNumber
          clientAddressZip
          clientAddressTown
          clientHasDifferentDeliveryAddress
          clientDeliveryFirstname
          clientDeliveryLastname
          clientDeliveryCompanyName
          clientDeliveryPhone
          clientDeliveryBirthYear
          clientDeliveryAddressFormatted
          clientDeliveryAddressStreet
          clientDeliveryAddressStreetNumber
          clientDeliveryAddressZip
          clientDeliveryAddressTown
          clientBankAccountNumber
          clientBankCode
          textAboveItems
          textBelowItems
          price
          dateOfIssue
          dueDate
          variableSymbol
          constantSymbol
          specificSymbol
          status
          version
        }
        nextToken
      }
      payments (limit: 10000) {
        items {
          id
          createdAt
          createdBy
          updatedAt
          updatedBy
          deleted
          deletedAt
          deletedBy
          statement
          date
          transactionType
          reference
          bankReference
          amount
          currency
          details
          extraDetails
          bankAccountName
          bankAccountNumber
          bankAccountNumberWithBankCode
          bankCode
          variableSymbol
          constantSymbol
          specificSymbol
          message
          notes
          status
          partClient
          partCommission
          partGoldCoins
          commissionExportedAt
          version
        }
        nextToken
      }
      distributions (limit: 10000)  {
        items {
          id
          __typename
          owners
          createdAt
          createdBy
          updatedAt
          updatedBy
          deleted
          deletedAt
          deletedBy
          owners
          client {
            id
            __typename
            firstname
            lastname
          }
          contract {
            id
            __typename
            contractNumber
          }
          coin {
            id
            __typename
            denominationMotif
            emission {
              id
              __typename
              name
            }
          }
          invoice {
            id
            __typename
            invoiceNumber
          }
          price
          status
          version
        }
        nextToken
      }
      version
    }
  }
`

export default query
