const INITIAL_STATE = {
  listClientsLoading: false,
  listClientsResult: [],
  listClientsError: null,
  //
  listClientsModalOpened: false, 
  listClientsModalTarget: null,
  //
  getClientLoading: false,
  getClientResult: null,
  getClientError: null
}

export default INITIAL_STATE
