import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import StockActions from 'Stores/Stock/Actions'
import * as StockSelect from 'Stores/Stock/Select'

import Loading from 'Components/Loading'
import List from 'Components/Stock/List/List'

const StockList = () => {
  const stocksLoading = useSelector(StockSelect.stocksLoading)
  const stocksResult = useSelector(StockSelect.stocksResult)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(StockActions.listStocks())
  }, [])

  return (
    <>
      <List rows={stocksResult || []} />
      {stocksLoading && <Loading />}
    </>
  )
}

export default StockList
