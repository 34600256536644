import React, { ReactNode } from 'react'

import { connect } from 'react-redux'
import AppActions from 'Stores/App/Actions'

import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import Header from 'Components/Header'

import Measure from 'react-measure'

const styles = createStyles({
  appContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  mainContent: {
    background: '#313131',
    flex: 1,
    padding: '48px 36px 0',
    position: 'relative'
  }
})

interface Props extends WithStyles<typeof styles> {
  title: string,
  tabs: any[],
  activeTab: number,
  children: ReactNode,
  setDimensions: any,
  toggleDrawerMobile: any
}

const DefaultLayout = (props: Props) => {
  const { classes, title, tabs, activeTab, children } = props

  const onResize = (contentRect: any): void => {
    props.setDimensions({ ...contentRect.bounds })
  }

  return (
    <div className={classes.appContent}>
      <Header
        title={title}
        tabs={tabs}
        activeTab={activeTab}
        onDrawerToggle={props.toggleDrawerMobile}
      />

      <Measure bounds={true} onResize={onResize}>
        {({ measureRef }: any): any => (
          <main ref={measureRef} className={classes.mainContent}>
            {children}
          </main>
        )}
      </Measure>
    </div>
  )
}

const mapStateToProps = (state: any) => ({

})

const mapDispatchToProps = (dispatch: any) => ({
  setDimensions: (dimensions: any) => dispatch(AppActions.setDimensions(dimensions)),
  toggleDrawerMobile: () => dispatch(AppActions.toggleDrawerMobile())
})

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(DefaultLayout)
)
