import { all, put, takeLatest } from 'redux-saga/effects'

import { ShutdownTypes } from 'Stores/Shutdown/Actions'
import UserActions from 'Stores/User/Actions'

export function * shutdown () {
  try {
    // @todo Shutdown handlers
    yield put(UserActions.logoutSuccess())
  } catch (e) {
    yield put(UserActions.logoutFailure())
  }
}

export default function * root() {
  yield all([
    takeLatest(ShutdownTypes.SHUTDOWN, shutdown)
  ])
}
