import { createReducer } from 'reduxsauce'

import { PredictionTypes } from './Actions'
import INITIAL_STATE from './InitialState'

// GET_DATA_LOADING
export const getDataLoading = (state: any): any => ({
  ...state,
  getDataLoading: true,
  getDataResult: null,
  getDataError: null
})

// GET_DATA_SUCCESS
export const getDataSuccess = (state: any, { data }: any): any => ({
  ...state,
  getDataLoading: false,
  getDataResult: data,
  getDataError: null
})

// GET_DATA_FAILURE
export const getDataFailure = (state: any, { error }: any): any => ({
  ...state,
  getDataLoading: false,
  getDataError: error
})

//

export const reducer = createReducer(INITIAL_STATE, {
  [PredictionTypes.GET_DATA_LOADING]: getDataLoading,
  [PredictionTypes.GET_DATA_SUCCESS]: getDataSuccess,
  [PredictionTypes.GET_DATA_FAILURE]: getDataFailure
})
