import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import ContractActions from 'Stores/Contract/Actions'
import * as ContractSelect from 'Stores/Contract/Select'

import Loading from 'Components/Loading'
import List from 'Components/Contract/List/List'

const ContractList = () => {
  const contractsLoading = useSelector(ContractSelect.contractsLoading)
  const contractsResult = useSelector(ContractSelect.contractsResult)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(ContractActions.listContracts())
  }, [])

  return (
    <>
      <List rows={contractsResult || []} />
      {contractsLoading && <Loading />}
    </>
  )
}

export default ContractList
