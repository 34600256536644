import gql from 'graphql-tag'

const query = gql`
  query GET_PAYMENT_QUERY($id: ID!) {
    getPayment(id: $id) {
      id
      owners
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      statement
      client {
        id
        benefitId
        isFs
        cognitoUserId
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        titleBefore
        firstname
        lastname
        titleAfter
        personalNumber
        birthDate
        email
        phone
        addressFormatted
        addressStreet
        addressStreetNumber
        addressZip
        addressTown
        hasDifferentDeliveryAddress
        deliveryTitleBefore
        deliveryFirstname
        deliveryLastname
        deliveryTitleAfter
        deliveryCompanyName
        deliveryPhone
        deliveryBirthYear
        deliveryAddressFormatted
        deliveryAddressStreet
        deliveryAddressStreetNumber
        deliveryAddressZip
        deliveryAddressTown
        bankAccountNumber
        bankCode
        status
        contracts {
          nextToken
        }
        emails {
          nextToken
        }
        invoices {
          nextToken
        }
        payments {
          nextToken
        }
        distributions {
          nextToken
        }
        version
      }
      contract {
        id
        contractNumber
        owner
        createdAt
        createdBy
        updatedAt
        updatedBy
        signedAt
        contractSentAt
        exportedAt
        lastPaymentReceivedAt
        deleted
        deletedAt
        deletedBy
        monthlyAmount
        totalYears
        client {
          id
          benefitId
          isFs
          cognitoUserId
          owner
          createdAt
          createdBy
          updatedAt
          updatedBy
          deleted
          deletedAt
          deletedBy
          titleBefore
          firstname
          lastname
          titleAfter
          personalNumber
          birthDate
          email
          phone
          addressFormatted
          addressStreet
          addressStreetNumber
          addressZip
          addressTown
          hasDifferentDeliveryAddress
          deliveryTitleBefore
          deliveryFirstname
          deliveryLastname
          deliveryTitleAfter
          deliveryCompanyName
          deliveryPhone
          deliveryBirthYear
          deliveryAddressFormatted
          deliveryAddressStreet
          deliveryAddressStreetNumber
          deliveryAddressZip
          deliveryAddressTown
          bankAccountNumber
          bankCode
          status
          version
        }
        clientBenefitId
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        clientBankAccountOwner
        clientBankAccountOwnerRelation
        defaultPriceOfCoin
        salesMargin
        entryFeePerCoin
        priceOfCoin
        estimatedValue
        numberOfCoins
        qualityOfCoins
        entryPriceIncrease
        entryPriceIncreaseOneTimePayment
        entryPriceIncreaseIsPaid
        entryPriceIncreaseWasPaidAt
        entryPriceIncreasePaidAmount
        entryPriceIncreaseInvoiceId
        entryPriceIncreaseInvoiceNumber
        indicativeTargetAmount
        paymentsAmount
        distributionsAmount
        savedAmount
        status
        emails {
          nextToken
        }
        invoices {
          nextToken
        }
        payments {
          nextToken
        }
        distributions {
          nextToken
        }
        version
      }
      invoice {
        id
        invoiceNumber
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        client {
          id
          benefitId
          isFs
          cognitoUserId
          owner
          createdAt
          createdBy
          updatedAt
          updatedBy
          deleted
          deletedAt
          deletedBy
          titleBefore
          firstname
          lastname
          titleAfter
          personalNumber
          birthDate
          email
          phone
          addressFormatted
          addressStreet
          addressStreetNumber
          addressZip
          addressTown
          hasDifferentDeliveryAddress
          deliveryTitleBefore
          deliveryFirstname
          deliveryLastname
          deliveryTitleAfter
          deliveryCompanyName
          deliveryPhone
          deliveryBirthYear
          deliveryAddressFormatted
          deliveryAddressStreet
          deliveryAddressStreetNumber
          deliveryAddressZip
          deliveryAddressTown
          bankAccountNumber
          bankCode
          status
          version
        }
        contract {
          id
          contractNumber
          owner
          createdAt
          createdBy
          updatedAt
          updatedBy
          signedAt
          contractSentAt
          exportedAt
          lastPaymentReceivedAt
          deleted
          deletedAt
          deletedBy
          monthlyAmount
          totalYears
          clientBenefitId
          clientTitleBefore
          clientFirstname
          clientLastname
          clientTitleAfter
          clientPersonalNumber
          clientBirthDate
          clientEmail
          clientPhone
          clientAddressFormatted
          clientAddressStreet
          clientAddressStreetNumber
          clientAddressZip
          clientAddressTown
          clientHasDifferentDeliveryAddress
          clientDeliveryTitleBefore
          clientDeliveryFirstname
          clientDeliveryLastname
          clientDeliveryTitleAfter
          clientDeliveryCompanyName
          clientDeliveryPhone
          clientDeliveryBirthYear
          clientDeliveryAddressFormatted
          clientDeliveryAddressStreet
          clientDeliveryAddressStreetNumber
          clientDeliveryAddressZip
          clientDeliveryAddressTown
          clientBankAccountNumber
          clientBankCode
          clientBankAccountOwner
          clientBankAccountOwnerRelation
          defaultPriceOfCoin
          salesMargin
          entryFeePerCoin
          priceOfCoin
          estimatedValue
          numberOfCoins
          qualityOfCoins
          entryPriceIncrease
          entryPriceIncreaseOneTimePayment
          entryPriceIncreaseIsPaid
          entryPriceIncreaseWasPaidAt
          entryPriceIncreasePaidAmount
          entryPriceIncreaseInvoiceId
          entryPriceIncreaseInvoiceNumber
          indicativeTargetAmount
          paymentsAmount
          distributionsAmount
          savedAmount
          status
          version
        }
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientCompanyName
        clientCompanyNumber
        clientCompanyNumberVat
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        textAboveItems
        items {
          text
          price
        }
        textBelowItems
        price
        dateOfIssue
        dueDate
        variableSymbol
        constantSymbol
        specificSymbol
        status
        payments {
          nextToken
        }
        commissionsRelatedPayments {
          nextToken
        }
        version
      }
      commissionsRelatedInvoice {
        id
        invoiceNumber
        owners
        createdAt
        createdBy
        updatedAt
        updatedBy
        deleted
        deletedAt
        deletedBy
        client {
          id
          benefitId
          isFs
          cognitoUserId
          owner
          createdAt
          createdBy
          updatedAt
          updatedBy
          deleted
          deletedAt
          deletedBy
          titleBefore
          firstname
          lastname
          titleAfter
          personalNumber
          birthDate
          email
          phone
          addressFormatted
          addressStreet
          addressStreetNumber
          addressZip
          addressTown
          hasDifferentDeliveryAddress
          deliveryTitleBefore
          deliveryFirstname
          deliveryLastname
          deliveryTitleAfter
          deliveryCompanyName
          deliveryPhone
          deliveryBirthYear
          deliveryAddressFormatted
          deliveryAddressStreet
          deliveryAddressStreetNumber
          deliveryAddressZip
          deliveryAddressTown
          bankAccountNumber
          bankCode
          status
          version
        }
        contract {
          id
          contractNumber
          owner
          createdAt
          createdBy
          updatedAt
          updatedBy
          signedAt
          contractSentAt
          exportedAt
          lastPaymentReceivedAt
          deleted
          deletedAt
          deletedBy
          monthlyAmount
          totalYears
          clientBenefitId
          clientTitleBefore
          clientFirstname
          clientLastname
          clientTitleAfter
          clientPersonalNumber
          clientBirthDate
          clientEmail
          clientPhone
          clientAddressFormatted
          clientAddressStreet
          clientAddressStreetNumber
          clientAddressZip
          clientAddressTown
          clientHasDifferentDeliveryAddress
          clientDeliveryTitleBefore
          clientDeliveryFirstname
          clientDeliveryLastname
          clientDeliveryTitleAfter
          clientDeliveryCompanyName
          clientDeliveryPhone
          clientDeliveryBirthYear
          clientDeliveryAddressFormatted
          clientDeliveryAddressStreet
          clientDeliveryAddressStreetNumber
          clientDeliveryAddressZip
          clientDeliveryAddressTown
          clientBankAccountNumber
          clientBankCode
          clientBankAccountOwner
          clientBankAccountOwnerRelation
          defaultPriceOfCoin
          salesMargin
          entryFeePerCoin
          priceOfCoin
          estimatedValue
          numberOfCoins
          qualityOfCoins
          entryPriceIncrease
          entryPriceIncreaseOneTimePayment
          entryPriceIncreaseIsPaid
          entryPriceIncreaseWasPaidAt
          entryPriceIncreasePaidAmount
          entryPriceIncreaseInvoiceId
          entryPriceIncreaseInvoiceNumber
          indicativeTargetAmount
          paymentsAmount
          distributionsAmount
          savedAmount
          status
          version
        }
        clientTitleBefore
        clientFirstname
        clientLastname
        clientTitleAfter
        clientPersonalNumber
        clientBirthDate
        clientCompanyName
        clientCompanyNumber
        clientCompanyNumberVat
        clientEmail
        clientPhone
        clientAddressFormatted
        clientAddressStreet
        clientAddressStreetNumber
        clientAddressZip
        clientAddressTown
        clientHasDifferentDeliveryAddress
        clientDeliveryTitleBefore
        clientDeliveryFirstname
        clientDeliveryLastname
        clientDeliveryTitleAfter
        clientDeliveryCompanyName
        clientDeliveryPhone
        clientDeliveryBirthYear
        clientDeliveryAddressFormatted
        clientDeliveryAddressStreet
        clientDeliveryAddressStreetNumber
        clientDeliveryAddressZip
        clientDeliveryAddressTown
        clientBankAccountNumber
        clientBankCode
        textAboveItems
        items {
          text
          price
        }
        textBelowItems
        price
        dateOfIssue
        dueDate
        variableSymbol
        constantSymbol
        specificSymbol
        status
        payments {
          nextToken
        }
        commissionsRelatedPayments {
          nextToken
        }
        version
      }
      date
      transactionType
      reference
      bankReference
      amount
      currency
      details
      extraDetails
      bankAccountName
      bankAccountNumber
      bankAccountNumberWithBankCode
      bankCode
      variableSymbol
      constantSymbol
      specificSymbol
      message
      notes
      status
      partClient
      partCommission
      partGoldCoins
      commissionExportedAt
      version
    }
  }
`

export default query
