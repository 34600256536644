export const invoicesLoading = (state: any) => state.invoice.listInvoicesLoading
export const invoicesResult = (state: any) => state.invoice.listInvoicesResult
//
export const invoiceLoading = (state: any) => state.invoice.getInvoiceLoading
export const invoiceResult = (state: any) => state.invoice.getInvoiceResult
//
export const createInvoiceLoading = (state: any) => state.invoice.createInvoiceLoading
//
export const downloadInvoiceLoading = (state: any) => state.invoice.downloadInvoiceLoading
//
export const sendInvoiceLoading = (state: any) => state.invoice.sendInvoiceLoading
