import React from 'react'
import { Field } from 'react-final-form'

import TextField from '@material-ui/core/TextField'

export const InputComponent = ({
  input: { name, onChange, value, ...restInput },
  InputProps,
  meta,
  ...rest
}: { input: any, InputProps: any, meta: any }): any => (
  <TextField
    {...rest}
    name={name}
    helperText={meta.touched ? meta.error : ((rest as any).helperText ? (rest as any).helperText : undefined)}
    error={meta.error && meta.touched}
    InputProps={{...InputProps, ...restInput}}
    onChange={onChange}
    value={value}
  />
)

export default ({ name, label, ...rest }: any) => (
  <Field
    {...rest}
    name={name}
    component={InputComponent as React.SFC<any>}
    type='text'
    label={label}
    margin='dense'
    fullWidth={true}
    variant='outlined'
  />
)
