import { getContext, put } from 'redux-saga/effects'

import StockActions from 'Stores/Stock/Actions'

import getStockQuery from 'Graphql/Queries/getStockQuery'

export function * getStock ({ id }: any) {
  try {
    yield put(StockActions.getStockLoading())

    const graphql = yield getContext('graphql')
    const variables = { id } as any

    const result = yield graphql.query(getStockQuery, variables)

    // @todo
    if (result && result.data && result.data.getStock) {
      yield put(StockActions.getStockSuccess(result.data.getStock))
    }
  } catch (error) {
    yield put(StockActions.getStockFailure(error))
  }
}

export default getStock
