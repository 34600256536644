import { createReducer } from 'reduxsauce'
import { parseISO } from 'date-fns'

import { ContractTypes } from './Actions'
import INITIAL_STATE from './InitialState'
import INITIAL_STATE_FORM from './InitialStateForm'

import personalNumberValidator from 'Components/_Common/Form/validators/personalNumber'
import calculateEstimatedValue from 'Components/Contract/Form/calculateEstimatedValue'

import getBirthDateFromPersonalNumber from 'Helpers/getBirthDateFromPersonalNumber'
import isEmpty from 'Helpers/isEmpty'
import ucfirst from 'Helpers/ucfirst'

// LIST_CONTRACTS_LOADING
export const listContractsLoading = (state: any): any => ({
  ...state,
  listContractsLoading: true,
  listContractsResult: [],
  listContractsError: null
})

// LIST_CONTRACTS_SUCCESS
export const listContractsSuccess = (state: any, { data }: any): any => ({
  ...state,
  listContractsLoading: false,
  listContractsResult: data,
  listContractsError: null
})

// LIST_CONTRACTS_FAILURE
export const listContractsFailure = (state: any, { error }: any): any => ({
  ...state,
  listContractsLoading: false,
  listContractsError: error
})

//

// GET_CONTRACT_LOADING
export const getContractLoading = (state: any): any => ({
  ...state,
  getContractLoading: true,
  getContractResult: null,
  getContractError: null,
  //
  form: {
    ...INITIAL_STATE_FORM,
    loading: true    
  }
})

// GET_CONTRACT_SUCCESS
export const getContractSuccess = (state: any, { data }: any): any => {
  const contract: any = Object.assign({}, data)

  contract.clientBenefitId = data.client.benefitId
  contract.clientIsFs = data.client.isFs

  return {
    ...state,
    getContractLoading: false,
    getContractResult: contract,
    getContractError: null,
    //
    form: {
      ...INITIAL_STATE_FORM,
      loading: false,
      ...contract,
      // @todo
      monthlyAmount: `${data.monthlyAmount}`,
      contractFirstEmissionId: data.firstEmission ? data.firstEmission.id : null,
      contractFirstCoinId: data.firstCoin ? data.firstCoin.id : null
    }
  }
}

// GET_CONTRACT_FAILURE
export const getContractFailure = (state: any, { error }: any): any => ({
  ...state,
  getContractLoading: false,
  getContractResult: null,
  getContractError: error,
  //
  form: INITIAL_STATE_FORM
})

//

// GET_MY_CONTRACT_LOADING
export const getMyContractLoading = (state: any): any => ({
  ...state,
  getContractLoading: true,
  getContractResult: null,
  getContractError: null,
  //
  form: INITIAL_STATE_FORM
})

// GET_MY_CONTRACT_SUCCESS
export const getMyContractSuccess = (state: any, { data }: any): any => {
  const contract: any = Object.assign({}, data)
  
  contract.clientBenefitId = data.client.benefitId
  contract.clientIsFs = data.client.isFs

  return {
    ...state,
    getContractLoading: false,
    getContractResult: contract,
    getContractError: null,
    //
    form: INITIAL_STATE_FORM
  }
}

// GET_MY_CONTRACT_FAILURE
export const getMyContractFailure = (state: any, { error }: any): any => ({
  ...state,
  getContractLoading: false,
  getContractResult: null,
  getContractError: error,
  //
  form: INITIAL_STATE_FORM
})

//

// FORM_UPDATE
export const formUpdate = (state: any, { data }: any): any => {
  let newFormState = {
    ...state.form,
    ...data,
    dirty: true
  }

  let modified = false

  Object.keys(data).map((key: string) => {
    if (data[key] !== state.form[key]) {
      modified = true

      switch(key) {
        // Calculator
        case 'monthlyAmount':
        case 'totalYears':
        case 'qualityOfCoins':
        case 'clientIsFs':
        case 'entryPriceIncreaseOneTimePayment':
          const result = _calculate(newFormState)
          newFormState = {
            ...newFormState,
            ...result
          }
          break

        case 'clientEmail':
          const clientEmail = data[key]
          if (!isEmpty(clientEmail)) {
            newFormState[key] = String(clientEmail).toLowerCase()
          }
          break

        case 'clientPersonalNumber':
          const pn = String(data[key])
          if ((pn.length === 10 || (pn.length === 9 && parseInt(pn.substr(0, 2), 10) < 54)) && personalNumberValidator(pn)) {
            newFormState.clientBirthDate = getBirthDateFromPersonalNumber(pn)
          }
          break

        case 'clientFirstname':
        case 'clientLastname':
        case 'clientAddressStreet':
        case 'clientAddressTown':
        case 'clientDeliveryFirstname':
        case 'clientDeliveryLastname':
        case 'clientDeliveryAddressStreet':
        case 'clientDeliveryAddressTown':
          const value = data[key]
          if (!isEmpty(value)) {
            newFormState[key] = String(value).charAt(0).toUpperCase() + String(value).slice(1)
          }
          break

        case 'clientAddressZip':  
        case 'clientDeliveryAddressZip':
          if (!isEmpty(data[key])) {
            newFormState[key] = String(data[key]).replace(/\s/g, '')
          }
          break

        case 'clientHasDifferentDeliveryAddress':
          if (data[key] === false) {
            const whatToClear = {
              clientDeliveryTitleBefore: null,
              clientDeliveryFirstname: null,
              clientDeliveryLastname: null,
              clientDeliveryTitleAfter: null,
              clientDeliveryCompanyName: null,
              clientDeliveryAddressStreet: null,
              clientDeliveryAddressStreetNumber: null,
              clientDeliveryAddressZip: null,
              clientDeliveryAddressTown: null,
              clientDeliveryPhone: null,
              clientDeliveryBirthYear: null,
            } as any
          
            for (const property in whatToClear) {
              if (whatToClear.hasOwnProperty(property)) {
                newFormState[property] = whatToClear[property]
              }
            }
          }
          break
      }
    }

    return key
  })

  if (modified) {
    return {
      ...state,
      form: newFormState
    }
  }

  return state
}

// FORM_SELECT_FIRST_COIN
export const formSelectFirstCoin = (state: any, { emissionId, coinId, dateOfIssue }: any): any => {
  let newFormState = {
    ...state.form,
    contractFirstEmissionId: emissionId,
    contractFirstCoinId: coinId,
    firstDeliveryDate: dateOfIssue,
    dirty: true
  }

  const result = _calculate(newFormState)
  newFormState = {
    ...newFormState,
    ...result
  }

  return {
    ...state,
    form: newFormState
  }
}

// FORM_CLEAN
export const formClean = (state: any): any => ({
  ...state,
  form: INITIAL_STATE_FORM
})

// FORM_CLEAN_CLIENT
export const formCleanClient = (state: any): any => {
  const newFormState = { ...state.form }

  const whatToClear = {
    clientId: null,
    clientBenefitId: null,
    clientFirstname: null,
    clientLastname: null,
    // clientPersonalNumber: null,
    clientBirthDate: null,
    clientEmail: null,
    clientPhone: null,

    clientAddressStreet: null,
    clientAddressStreetNumber: null,
    clientAddressZip: null,
    clientAddressTown: null,

    clientHasDifferentDeliveryAddress: false,
     
    clientDeliveryFirstname: null,
    clientDeliveryLastname: null,
    clientDeliveryCompanyName: null,
    clientDeliveryAddressStreet: null,
    clientDeliveryAddressStreetNumber: null,
    clientDeliveryAddressZip: null,
    clientDeliveryAddressTown: null,
    clientDeliveryPhone: null,
    clientDeliveryBirthYear: null,

    clientBankAccountNumber: null,
    clientBankCode: null,

    clientIsFs: null
  } as any

  for (const property in whatToClear) {
    if (whatToClear.hasOwnProperty(property)) {
      newFormState[property] = whatToClear[property]
    }
  }

  return {
    ...state,
    form: newFormState
  }
}

//

// PREFILL_CLIENT_LOADING
export const prefillClientLoading = (state: any): any => ({
  ...state,
  form: {
    ...state.form,
    loading: true,
    prefill: true
  }
})

// PREFILL_CLIENT_SUCCESS
export const prefillClientSuccess = (state: any, { data }: any): any => {
  const client: any = {}

  client.clientId = data.id
  client.clientBenefitId = data.benefitId
  client.clientIsFs = data.isFs

  const transferKeys = [
    'titleBefore', 'firstname', 'lastname', 'titleAfter',
    'personalNumber', 'birthDate',
    'phone', 'email',
    'addressStreet', 'addressStreetNumber', 'addressZip', 'addressTown',
    'hasDifferentDeliveryAddress',
    'deliveryAddressStreet', 'deliveryAddressStreetNumber', 'deliveryAddressZip', 'deliveryAddressTown',
    'bankAccountNumber', 'bankCode'
  ]

  transferKeys.map(key => {
    client[`client${ucfirst(key)}`] = data[key]
    return key
  })

  return {
    ...state,
    form: {
      ...state.form,
      prefill: false,
      dirty: true,
      ...client
    }
  }
}

// PREFILL_CLIENT_FAILURE
export const prefillClientFailure = (state: any): any => ({
  ...state,
  form: INITIAL_STATE_FORM // @todo
})

//

// SAVE_CONTRACT_LOADING
export const saveContractLoading = (state: any): any => ({
  ...state,
  form: {
    ...state.form,
    loading: true
  }
})

// SAVE_CONTRACT_SUCCESS
export const saveContractSuccess = (state: any, { data }: any): any => {
  const contract: any = Object.assign({}, data)
  
  contract.clientBenefitId = data.client.benefitId
  contract.clientIsFs = data.client.isFs

  return {
    ...INITIAL_STATE,
    //
    getContractLoading: false,
    getContractResult: contract,
    getContractError: null,
    //
    form: {
      ...INITIAL_STATE_FORM,
      dirty: false,
      ...contract,
      // @todo
      monthlyAmount: `${data.monthlyAmount}`,
      contractFirstEmissionId: data.firstEmission ? data.firstEmission.id : null,
      contractFirstCoinId: data.firstCoin ? data.firstCoin.id : null
    }
  }
}

// SAVE_CONTRACT_FAILURE
export const saveContractFailure = (state: any): any => state

//

// SET_STATUS_LOADING
export const setStatusLoading = (state: any): any => ({
  ...state,
  setStatusLoading: true
})

// SET_STATUS_SUCCESS
export const setStatusSuccess = (state: any, { data }: any): any => {
  const contract: any = Object.assign({}, data)
  
  contract.clientBenefitId = data.client.benefitId
  contract.clientIsFs = data.client.isFs

  return {
    ...state,
    form: {
      ...INITIAL_STATE_FORM,
      ...contract,
      // @todo
      monthlyAmount: `${data.monthlyAmount}`,
      contractFirstEmissionId: data.firstEmission ? data.firstEmission.id : null,
      contractFirstCoinId: data.firstCoin ? data.firstCoin.id : null
    }, 
    setStatusLoading: false
  }
}

// SET_STATUS_FAILURE
export const setStatusFailure = (state: any): any => ({
  ...state,
  setStatusLoading: false
})

//

// SEND_CONTRACT_LOADING
export const sendContractLoading = (state: any): any => ({
  ...state,
  sendContractLoading: true
})

// SEND_CONTRACT_SUCCESS
export const sendContractSuccess = (state: any, { data }: any): any => ({
  ...state,
  sendContractLoading: false
})

// SEND_CONTRACT_FAILURE
export const sendContractFailure = (state: any): any => ({
  ...state,
  sendContractLoading: false
})

// OPEN_CHANGE_ADDRESS_DIALOG
export const openChangeAddressDialog = (state: any): any => ({
  ...state,
  changeAddressDialogOpened: true
})

// CLOSE_CHANGE_ADDRESS_DIALOG
export const closeChangeAddressDialog = (state: any): any => ({
  ...state,
  changeAddressDialogOpened: false
})

// OPEN_CHANGE_EMAIL_DIALOG
export const openChangeEmailDialog = (state: any): any => ({
  ...state,
  changeEmailDialogOpened: true
})

// CLOSE_CHANGE_EMAIL_DIALOG
export const closeChangeEmailDialog = (state: any): any => ({
  ...state,
  changeEmailDialogOpened: false
})

//

// CHANGE_ADDRESS_LOADING
export const changeAddressLoading = (state: any): any => ({
  ...state,
  changeAddressLoading: true,
  changeAddressError: null
})

// CHANGE_ADDRESS_SUCCESS
export const changeAddressSuccess = (state: any): any => ({
  ...state,
  changeAddressDialogOpened: false,
  changeAddressLoading: false,
  changeAddressError: null
})

// CHANGE_ADDRESS_FAILURE
export const changeAddressFailure = (state: any, { error }: any): any => ({
  ...state,
  changeAddressDialogOpened: true,
  changeAddressLoading: false,
  changeAddressError: error
})

// CHANGE_EMAIL_LOADING
export const changeEmailLoading = (state: any): any => ({
  ...state,
  changeEmailLoading: true,
  changeEmailError: null
})

// CHANGE_EMAIL_SUCCESS
export const changeEmailSuccess = (state: any): any => ({
  ...state,
  changeEmailDialogOpened: false,
  changeEmailLoading: false,
  changeEmailError: null
})

// CHANGE_EMAIL_FAILURE
export const changeEmailFailure = (state: any, { error }: any): any => ({
  ...state,
  changeEmailDialogOpened: true,
  changeEmailLoading: false,
  changeEmailError: error
})

export const reducer = createReducer(INITIAL_STATE, {
  [ContractTypes.LIST_CONTRACTS_LOADING]: listContractsLoading,
  [ContractTypes.LIST_CONTRACTS_SUCCESS]: listContractsSuccess,
  [ContractTypes.LIST_CONTRACTS_FAILURE]: listContractsFailure,
  //
  [ContractTypes.GET_CONTRACT_LOADING]: getContractLoading,
  [ContractTypes.GET_CONTRACT_SUCCESS]: getContractSuccess,
  [ContractTypes.GET_CONTRACT_FAILURE]: getContractFailure,
  //
  [ContractTypes.GET_MY_CONTRACT_LOADING]: getMyContractLoading,
  [ContractTypes.GET_MY_CONTRACT_SUCCESS]: getMyContractSuccess,
  [ContractTypes.GET_MY_CONTRACT_FAILURE]: getMyContractFailure,
  //
  [ContractTypes.FORM_UPDATE]: formUpdate,
  [ContractTypes.FORM_SELECT_FIRST_COIN]: formSelectFirstCoin,
  [ContractTypes.FORM_CLEAN]: formClean,
  [ContractTypes.FORM_CLEAN_CLIENT]: formCleanClient,
  //
  [ContractTypes.PREFILL_CLIENT_LOADING]: prefillClientLoading,
  [ContractTypes.PREFILL_CLIENT_SUCCESS]: prefillClientSuccess,
  [ContractTypes.PREFILL_CLIENT_FAILURE]: prefillClientFailure,
  //
  [ContractTypes.SAVE_CONTRACT_LOADING]: saveContractLoading,
  [ContractTypes.SAVE_CONTRACT_SUCCESS]: saveContractSuccess,
  [ContractTypes.SAVE_CONTRACT_FAILURE]: saveContractFailure,
  //
  // @todo CREATE_ACCOUNT
  //
  [ContractTypes.SET_STATUS_LOADING]: setStatusLoading,
  [ContractTypes.SET_STATUS_SUCCESS]: setStatusSuccess,
  [ContractTypes.SET_STATUS_FAILURE]: setStatusFailure,
  //
  [ContractTypes.SEND_CONTRACT_LOADING]: sendContractLoading,
  [ContractTypes.SEND_CONTRACT_SUCCESS]: sendContractSuccess,
  [ContractTypes.SEND_CONTRACT_FAILURE]: sendContractFailure,
  // @todo DOWNLOAD_CONTRACT
  // @todo DELETE_CONTRACT
  // @todo CLOSE_CONTRACT

  [ContractTypes.OPEN_CHANGE_EMAIL_DIALOG]: openChangeEmailDialog,
  [ContractTypes.CLOSE_CHANGE_EMAIL_DIALOG]: closeChangeEmailDialog,
  [ContractTypes.CHANGE_EMAIL_LOADING]: changeEmailLoading,
  [ContractTypes.CHANGE_EMAIL_SUCCESS]: changeEmailSuccess,
  [ContractTypes.CHANGE_EMAIL_FAILURE]: changeEmailFailure,
  // 

  // 

  [ContractTypes.OPEN_CHANGE_ADDRESS_DIALOG]: openChangeAddressDialog,
  [ContractTypes.CLOSE_CHANGE_ADDRESS_DIALOG]: closeChangeAddressDialog,
  // 
  [ContractTypes.CHANGE_ADDRESS_LOADING]: changeAddressLoading,
  [ContractTypes.CHANGE_ADDRESS_SUCCESS]: changeAddressSuccess,
  [ContractTypes.CHANGE_ADDRESS_FAILURE]: changeAddressFailure
})

const _calculate = (newFormState: any): any => {
  const {
    monthlyAmount,
    totalYears,
    qualityOfCoins,
    // defaultPriceOfCoin,
    salesMargin,
    entryFeePerCoin,
    entryPriceIncreaseOneTimePayment,
    //
    clientIsFs
  } = newFormState

  const defaultPriceOfCoin = 21000 / salesMargin // (qualityOfCoins === 'BK' ? 24500 : 24500) / salesMargin

  if (clientIsFs) {
    newFormState.entryPriceIncreaseOneTimePayment = false
  }

  const monthlyAmountAsNumber = parseInt(monthlyAmount, 10)
  const totalYearsAsNumber = parseInt(totalYears, 10)

  const priceOfCoin = Math.round(defaultPriceOfCoin * salesMargin)
  
  const targetAmount = monthlyAmountAsNumber * 12 * totalYearsAsNumber
  const numberOfCoins = Math.ceil(targetAmount / priceOfCoin)
  const indicativeTargetAmount = numberOfCoins * priceOfCoin
  const entryPriceIncrease = clientIsFs ? 0 : numberOfCoins * entryFeePerCoin

  const startFrom = new Date()
  const firstDelivery = parseISO(newFormState.firstDeliveryDate)
  const { data: progressionData, estimatedValue, actualDeliveryEntitlementDate, emission1, emission2 } = calculateEstimatedValue(monthlyAmountAsNumber, totalYearsAsNumber, entryPriceIncrease, entryPriceIncreaseOneTimePayment, numberOfCoins, priceOfCoin, startFrom, firstDelivery)
  
  newFormState.monthlyAmount = `${monthlyAmountAsNumber}`
  newFormState.defaultPriceOfCoin = Math.round(defaultPriceOfCoin)
  newFormState.priceOfCoin = priceOfCoin
  newFormState.numberOfCoins = numberOfCoins
  newFormState.indicativeTargetAmount = indicativeTargetAmount
  newFormState.entryPriceIncrease = entryPriceIncrease
  newFormState.estimatedValue = estimatedValue

  newFormState.emission1 = emission1
  newFormState.emission2 = emission2

  newFormState.actualDeliveryEntitlementDate = actualDeliveryEntitlementDate

  newFormState.progressionData = progressionData

  return newFormState
}