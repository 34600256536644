import { all, takeLatest } from 'redux-saga/effects'

import { StockTypes } from 'Stores/Stock/Actions'
import Stock from 'Sagas/Stock'

export default function * root() {
  yield all([
    takeLatest(StockTypes.LIST_STOCKS, Stock.listStocks),
    takeLatest(StockTypes.LIST_STOCKS_SUCCESS, Stock.listStocksSuccess),
    takeLatest(StockTypes.LIST_STOCKS_FAILURE, Stock.listStocksFailure),
    //
    takeLatest(StockTypes.GET_STOCK, Stock.getStock),
    takeLatest(StockTypes.GET_STOCK_SUCCESS, Stock.getStockSuccess),
    takeLatest(StockTypes.GET_STOCK_FAILURE, Stock.getStockFailure),
    //
    takeLatest(StockTypes.SAVE_STOCK, Stock.saveStock),
    takeLatest(StockTypes.SAVE_STOCK_SUCCESS, Stock.saveStockSuccess),
    takeLatest(StockTypes.SAVE_STOCK_FAILURE, Stock.saveStockFailure)
  ])
}
