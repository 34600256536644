import React from 'react'

import thousandsSeparator from 'Helpers/thousandsSeparator'

const PaymentListColumnAmount = ({ row, column }: any): any => {
  const value = column.getCellValue(row)
  const valueFormatted = `${thousandsSeparator(value)} Kč`
  return <span style={{ color: 0 > parseInt(value, 10) ? '#ffcccc' : '#ccffcc' }}>{valueFormatted}</span>
}

export default PaymentListColumnAmount
