import personalNumberValidator from 'Components/_Common/Form/validators/personalNumber'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import isEmpty from 'Helpers/isEmpty'

const validate = (values: any): object => {
  const errors = {} as any

  if (!values.clientPersonalNumber) {
    errors.clientPersonalNumber = 'Rodné číslo je povinné.'
  } else {
    if (!personalNumberValidator(String(values.clientPersonalNumber))) {
      errors.clientPersonalNumber = 'Rodné číslo není validní.'
    }
  }

  if (isEmpty(values.clientFirstname)) {
    errors.clientFirstname = 'Jméno je povinné.'
  }

  if (isEmpty(values.clientLastname)) {
    errors.clientLastname = 'Příjmení je povinné.'
  }

  if (isEmpty(values.clientPhone)) {
    errors.clientPhone = 'Telefon je povinný.'
  } else {
    const phoneNumber = parsePhoneNumberFromString(String(values.clientPhone), 'CZ')
    if (phoneNumber === undefined || !phoneNumber.isValid()) {
      errors.clientPhone = 'Telefon není validní.'
    }
  }

  if (isEmpty(values.clientEmail)) {
    errors.clientEmail = 'Email je povinný.'
  }

  if (isEmpty(values.clientAddressStreet)) {
    errors.clientAddressStreet = 'Ulice je povinná.'
  }

  if (isEmpty(values.clientAddressStreetNumber)) {
    errors.clientAddressStreetNumber = 'Číslo popisné/orientační je povinné.'
  }

  if (isEmpty(values.clientAddressZip)) {
    errors.clientAddressZip = 'PSČ je povinné.'
  } else if (String(values.clientAddressZip).includes(' ')) {
    errors.clientAddressZip = 'PSČ nesmí obsahovat mezeru.'
  } else if (String(values.clientAddressZip).length !== 5) {
    errors.clientAddressZip = 'PSČ nemá správnou délku (5 čísel).'
  }

  if (isEmpty(values.clientAddressTown)) {
    errors.clientAddressTown = 'Město je povinné.'
  }

  if (values.clientHasDifferentDeliveryAddress === true) {
    if (isEmpty(values.clientDeliveryFirstname)) {
      errors.clientDeliveryFirstname = 'Jméno je povinné.'
    }
  
    if (isEmpty(values.clientDeliveryLastname)) {
      errors.clientDeliveryLastname = 'Příjmení je povinné.'
    }

    if (isEmpty(values.clientDeliveryAddressStreet)) {
      errors.clientDeliveryAddressStreet = 'Ulice je povinná.'
    }
  
    if (isEmpty(values.clientDeliveryAddressStreetNumber)) {
      errors.clientDeliveryAddressStreetNumber = 'Číslo popisné/orientační je povinné.'
    }

    if (isEmpty(values.clientDeliveryAddressZip)) {
      errors.clientDeliveryAddressZip = 'PSČ je povinné.'
    } else if (String(values.clientDeliveryAddressZip).includes(' ')) {
      errors.clientDeliveryAddressZip = 'PSČ nesmí obsahovat mezeru.'
    } else if (String(values.clientDeliveryAddressZip).length !== 5) {
      errors.clientDeliveryAddressZip = 'PSČ nemá správnou délku (5 čísel).'
    }
  
    if (isEmpty(values.clientDeliveryAddressTown)) {
      errors.clientDeliveryAddressTown = 'Město je povinné.'
    }

    if (isEmpty(values.clientDeliveryPhone)) {
      errors.clientDeliveryPhone = 'Telefon je povinný.'
    } else {
      const phoneNumber = parsePhoneNumberFromString(String(values.clientDeliveryPhone), 'CZ')
      if (phoneNumber === undefined || !phoneNumber.isValid()) {
        errors.clientDeliveryPhone = 'Telefon není validní.'
      }
    }

    if (isEmpty(values.clientDeliveryBirthYear)) {
      errors.clientDeliveryBirthYear = 'Rok narození osoby je povinný.'
    }
  }

  if (isEmpty(values.clientBankAccountNumber)) {
    errors.clientBankAccountNumber = 'Číslo účtu je povinné.'
  } else {
    if (!isValidBankAccount(values.clientBankAccountNumber)) {
      errors.clientBankAccountNumber = 'Číslo účtu je nevalidní.'
    }
  }

  if (isEmpty(values.clientBankCode)) {
    errors.clientBankCode = 'Kód banky je povinný.'
  }

  return errors
}

const isValidBankAccount = (accNumber: string) => {
  const matches: any = String(accNumber).match(/^(?:([0-9]{1,6})-)?([0-9]{2,10})$/)
  if (matches && matches.length === 3)  {
    const weights = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1]
    const prefix = matches[1] !== undefined ? matches[1].padStart(10, '0') : '0'
    const number = matches[2].padStart(10, '0')
  
    // Check prefix
    let checkSum = 0
    for (let i = 0; i < String(prefix).length; i++) {
      checkSum += weights[i] * parseInt(prefix[i], 10)
    }

    if (checkSum % 11 !== 0) {
      return false
    }
  
    // Check main part
    checkSum = 0
    for (let i = 0; i < String(number).length; i++) {
      checkSum += weights[i] * parseInt(number[i], 10)
    }

    if (checkSum % 11 !== 0) {
      return false
    }
  
    return true
  }

  return false
}

export default validate
