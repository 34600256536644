import gql from 'graphql-tag'

const query = gql`
query LIST_PAYMENTS_QUERY (
  $filter: ModelPaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      __typename
      date
      transactionType
      reference
      bankReference
      amount
      currency
      bankAccountName
      bankAccountNumber
      bankAccountNumberWithBankCode
      bankCode
      variableSymbol
      constantSymbol
      specificSymbol
      message
      notes
      partClient
      partCommission
      partGoldCoins
      commissionExportedAt
      commissionsRelatedInvoice {
        id
        __typename
        invoiceNumber
      }
      status
    }
    nextToken
  }
}
`

export default query
