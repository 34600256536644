import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import ClientActions from 'Stores/Client/Actions'
import * as ClientSelect from 'Stores/Client/Select'

import Loading from 'Components/Loading'
import List from 'Components/Client/List/List'

const ClientList = () => {
  const clientsLoading = useSelector(ClientSelect.clientsLoading)
  const clientsResult = useSelector(ClientSelect.clientsResult)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(ClientActions.listClients())
  }, [])

  return (
    <>
      <List rows={clientsResult || []} />
      {clientsLoading && <Loading />} 
    </>
  )
}

export default ClientList
