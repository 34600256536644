import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import DefaultLayout from 'Components/_Common/Layout/Default'
import Loading from 'Components/Loading'

import ClientsMyContractsList from 'Components/_Client/MyContracts/index'

import { API, graphqlOperation } from 'aws-amplify'

import * as ROUTES from 'routes'

const query = `query MyContracts($benefitId: String!) {
  listContracts (filter: { clientBenefitId: { eq: $benefitId }, deleted: { ne: true }, signedAt: { ne: null} }, limit: 10000) {
    items {
      id
      __typename
      contractNumber
      signedAt
      monthlyAmount
      totalYears
      numberOfCoins
      qualityOfCoins
      entryPriceIncrease
      entryPriceIncreaseOneTimePayment
      lastPaymentReceivedAt
      status
    }
  }

  listClients (filter: { benefitId: { eq: $benefitId } }, limit: 10000) {
    items {
      id
      __typename
      cognitoUserId
      contracts (filter: { deleted: { ne: true }, signedAt: { ne: null} }, limit: 10000) {
        items {
          id
          __typename
          contractNumber
          signedAt
          monthlyAmount
          totalYears
          numberOfCoins
          qualityOfCoins
          entryPriceIncrease
          entryPriceIncreaseOneTimePayment
          lastPaymentReceivedAt
          status
        }
      }
    }
  }
}`

const MyContracts = (props: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)

      const result = (await API.graphql(graphqlOperation(query, { benefitId: `${props.user.benefitId}` }))) as any

      const contracts: any[] = []
      if (result && 'data' in result && 'listClients' in result.data && 'items' in result.data.listClients &&
        result.data.listClients.items instanceof Array && result.data.listClients.items.length > 0) {
        result.data.listClients.items.map((client: any) => {
          client.contracts.items.map((contract: any) => {
            contracts[contract.contractNumber] = contract
            return contract
          })
          return client
        })
      }

      if (result && 'data' in result && 'listContracts' in result.data && 'items' in result.data.listContracts &&
        result.data.listContracts.items instanceof Array && result.data.listContracts.items.length > 0) {
        result.data.listContracts.items.map((contract: any) => {
          contracts[contract.contractNumber] = contract
          return contract
        })
      }
  
      setIsLoading(false)
      setData(Object.values(contracts))
    }

    loadData()
  }, [props.user.benefitId])

  return (
    <DefaultLayout
      title={`Moje smlouvy`}
      tabs={[{
        route: ROUTES.CLIENT_MY_CONTRACTS, title: 'Moje smlouvy'
      }]}
      activeTab={0}
    >
      {isLoading ? <Loading /> : <ClientsMyContractsList data={data} />}
    </DefaultLayout>
  )
}

const mapStateToProps = (state: any): any => ({
  user: state.user
})

export default connect(mapStateToProps)(MyContracts)
