import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  setStep: ['step'],
  close: null
})

export const CloseContractDialogTypes = Types

export default Creators
