import React from 'react'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { Link as RouterLink } from 'react-router-dom'

const ContractListColumnAction = ({ row, column }: any): any => {
  const contractId = column.getContractId(row)

  if (contractId === null) {
    return <span>-</span>
  }

  return (
    <Link to={`/smlouvy/detail/${contractId}`} component={RouterLink}>
      <Typography variant='caption'>Detail</Typography>
    </Link>
  )
}

export default ContractListColumnAction
