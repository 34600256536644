export const INVOICE_DATA_TYPE_0_INVOICE = '0-invoice' // Automatická faktura (BI)
export const INVOICE_DATA_TYPE_1_PROFORMA = '1-proforma' // Zálohová faktura
export const INVOICE_DATA_TYPE_2_INVOICE = '2-invoice' // Řádná faktura (Jednorázový obchod)
export const INVOICE_DATA_TYPE_3_INVOICE = '3-invoice' // Řádná faktura (Pravidelné platby)

const invoiceDataType = (value: any): any => {
  switch (value) {
    case INVOICE_DATA_TYPE_0_INVOICE:
      return `BI`

    case INVOICE_DATA_TYPE_1_PROFORMA:
      return 'Proforma'

    case INVOICE_DATA_TYPE_2_INVOICE:
      return `Prodej`

    case INVOICE_DATA_TYPE_3_INVOICE:
      return `Pravidelná`
  }
}

export const getAvailableInvoiceTypes = () => {
  return {
    INVOICE_DATA_TYPE_0_INVOICE: invoiceDataType(INVOICE_DATA_TYPE_0_INVOICE),
    INVOICE_DATA_TYPE_1_PROFORMA: invoiceDataType(INVOICE_DATA_TYPE_1_PROFORMA),
    INVOICE_DATA_TYPE_2_INVOICE: invoiceDataType(INVOICE_DATA_TYPE_2_INVOICE),
    INVOICE_DATA_TYPE_3_INVOICE: invoiceDataType(INVOICE_DATA_TYPE_3_INVOICE)
  }
}

export default invoiceDataType
