import INITIAL_STATE_FORM from './InitialStateForm'

const INITIAL_STATE = {
  listContractsLoading: false,
  listContractsResult: [],
  listContractsError: null,
  //
  getContractLoading: false,
  getContractResult: null,
  getContractError: null,
  //
  form: INITIAL_STATE_FORM,
  //
  setStatusLoading: false,
  setStatusError: null,
  //
  sendContractLoading: false,
  sendContractError: null,
  //
  downloadContractLoading: false,
  downloadContractError: null,
  //
  changeEmailDialogOpened: false,
  changeEmailLoading: false,
  changeEmailError: null,
  //
  changeAddressDialogOpened: false,
  changeAddressLoading: false,
  changeAddressError: null,
  //
  regenerateContractLoading: false,
  regenerateContractError: null,
  //
  deleteContractLoading: false,
  deleteContractError: null,
  //
  exportContractLoading: false,
  exportContractError: null,
}

export default INITIAL_STATE
