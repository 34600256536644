export const STOCK_DATA_STATUS_FREE = 'free'
export const STOCK_DATA_STATUS_ORDERED = 'ordered'
export const STOCK_DATA_STATUS_BLOCKED = 'blocked'
export const STOCK_DATA_STATUS_SOLD = 'sold'

const stockDataStatus = (value: any): any => {
  switch (value) {
    case STOCK_DATA_STATUS_FREE:
      return 'K prodeji'

    case STOCK_DATA_STATUS_ORDERED:
      return `Objednáno`

    case STOCK_DATA_STATUS_BLOCKED:
        return `Blokováno`

    case STOCK_DATA_STATUS_SOLD:
      return `Prodáno`
  }
}

export const getAvailableStockStatuses = () => {
  return {
    STOCK_DATA_STATUS_FREE: stockDataStatus(STOCK_DATA_STATUS_FREE),
    STOCK_DATA_STATUS_ORDERED: stockDataStatus(STOCK_DATA_STATUS_ORDERED),
    STOCK_DATA_STATUS_BLOCKED: stockDataStatus(STOCK_DATA_STATUS_BLOCKED),
    STOCK_DATA_STATUS_SOLD: stockDataStatus(STOCK_DATA_STATUS_SOLD)
  }
}

export default stockDataStatus
