import { put, select } from 'redux-saga/effects'

import PredictionActions from 'Stores/Prediction/Actions'

export function * getData () {
  try {
    yield put(PredictionActions.getDataLoading())

    const lambdaFunction = `get-predictions-data`
    // const url = `${process.env.REACT_APP_EXECUTE_API_URL}/${lambdaFunction}`
    const url = `https://5ivrmis6e7.execute-api.eu-west-1.amazonaws.com/prod/${lambdaFunction}`
    const idToken = yield select(state => state.user.idToken)

    const response = yield fetch(url, {
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        "Accept-Encoding": "gzip, deflate, br",
      },
      method: 'GET',
      mode: 'cors'
    })

    if (response.ok && response.status === 200) {
      const data: any = yield response.text()
      const dataJSON: any = JSON.parse(data);
      yield put(PredictionActions.getDataSuccess(dataJSON))
    } else {
      const error = new Error('Invalid response.')
      yield put(PredictionActions.getDataFailure(error))
    }

    
  } catch (error) {
    yield put(PredictionActions.getDataFailure(error))
  }
}

export default getData
