import thousandsSeparator from 'Helpers/thousandsSeparator'

const listCallbackFormatBankAccount = (row: any, columnName: string): any => {
  const value = row[columnName]

  if (row.transactionType === 'FTRF') {
    const splitted1 = `${value}`.split('/')
    const accountNumber = splitted1[0]
    const bankCode = splitted1[1]
    const splitted2 = `${accountNumber}`.split('-')
    const accountNumberPrefix = parseInt(splitted2[0], 10)
    const accountNumberItself = parseInt(splitted2[1], 10)
    return `${accountNumberPrefix !== 0 ? `${accountNumberPrefix}-` : ``}${accountNumberItself}/${bankCode}`
  }

  return ` - `
}

export default listCallbackFormatBankAccount
