export const PAYMENT_DATA_STATUS_NEW = 'new'
export const PAYMENT_DATA_STATUS_RETRY = 'retry'
export const PAYMENT_DATA_STATUS_MISMATCH = 'mismatch'
export const PAYMENT_DATA_STATUS_MISMATCH_CONFIRMED = 'mismatch-confirmed'
export const PAYMENT_DATA_STATUS_PROCESSED = 'processed'
export const PAYMENT_DATA_STATUS_ONE_TIME = 'one-time'
export const PAYMENT_DATA_STATUS_INTERNAL = 'internal'

const paymentDataStatus = (value: any): any => {
  switch (value) {
    case PAYMENT_DATA_STATUS_NEW:
      return 'Nová'

    case PAYMENT_DATA_STATUS_RETRY:
      return 'Chyba - opakovat párování'

    case PAYMENT_DATA_STATUS_MISMATCH:
      return 'Upozornění - neodpovídá účet'

    case PAYMENT_DATA_STATUS_MISMATCH_CONFIRMED:
      return 'Neodpovídající účet potvrzen'

    case PAYMENT_DATA_STATUS_PROCESSED:
      return 'Smlouva' // @todo

    case PAYMENT_DATA_STATUS_ONE_TIME:
        return 'Jednorázový prodej'

    case PAYMENT_DATA_STATUS_INTERNAL:
        return 'Interní'
  }
}

export const getAvailablePaymentStatuses = () => {
  return {
    PAYMENT_DATA_STATUS_NEW: paymentDataStatus(PAYMENT_DATA_STATUS_NEW),
    PAYMENT_DATA_STATUS_RETRY: paymentDataStatus(PAYMENT_DATA_STATUS_RETRY),
    PAYMENT_DATA_STATUS_MISMATCH: paymentDataStatus(PAYMENT_DATA_STATUS_MISMATCH),
    PAYMENT_DATA_STATUS_MISMATCH_CONFIRMED: paymentDataStatus(PAYMENT_DATA_STATUS_MISMATCH_CONFIRMED),
    PAYMENT_DATA_STATUS_PROCESSED: paymentDataStatus(PAYMENT_DATA_STATUS_PROCESSED),
    PAYMENT_DATA_STATUS_ONE_TIME: paymentDataStatus(PAYMENT_DATA_STATUS_ONE_TIME),
    PAYMENT_DATA_STATUS_INTERNAL: paymentDataStatus(PAYMENT_DATA_STATUS_INTERNAL)
  }
}

export default paymentDataStatus
