import React, { ReactNode } from 'react'
import { connect } from 'react-redux'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

const styles = (theme: Theme) => createStyles({
  paper: {
    borderRadius: 0,
    margin: 'auto',
    marginBottom: 114,
    overflow: 'hidden'
  },
  root: {
    marginTop: theme.spacing(3),
    width: '100%'
  }
})

interface Props extends WithStyles<typeof styles> {
  title: string,
  dimensions: any,
  children: ReactNode
}

const FormLayout = (props: Props) => {
  const { classes, title, children } = props

  return (
    <Paper elevation={2} className={classes.paper} style={{ width: `${props.dimensions.width - 72}px` }}>
      <Toolbar className={classes.root}>
        <Typography variant='h6' id='tableTitle'>
          {title}
        </Typography>
      </Toolbar>

      {children}
    </Paper>
  )
}

const mapStateToProps = (state: any) => ({
  dimensions: state.app.dimensions
})

export default connect(mapStateToProps)(
  withStyles(styles)(FormLayout)
)
