import { put, select } from 'redux-saga/effects'

import StockActions from 'Stores/Stock/Actions'

import { format } from 'date-fns'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations from 'Graphql/mutations'

import { history } from '../../../index'

export function * saveStock () {
  try {
    yield put(StockActions.saveStockLoading())

    const stockForm = yield select(state => state.stock.form)
    const data = Object.assign({}, stockForm)

    const username = yield select(state => state.user.username)

    const fieldsToDelete = [
      'loading', 'prefill', 'dirty',
      'createdAt', 'createdBy', 'updatedAt', 'updatedBy'
    ]

    let action = 'CREATE'
    if (data.id) {
      action = 'UPDATE'
    }

    fieldsToDelete.map(field => delete data[field])

    if (data.series === true) {
      delete data.stockCoinId
    }

    data.purchasedAt = format(new Date(data.purchasedAt), 'RRRR-MM-dd')
    if (data.purchasedAt instanceof Date) {
      data.purchasedAt = data.purchasedAt.toISOString().substr(0, 10)
    }

    let mutation
    let selector

    if (action === 'UPDATE') {
      mutation = mutations.updateStock
      selector = `updateStock`

      data.updatedAt = (new Date()).toISOString()
      data.updatedBy = username

      data.expectedVersion = data.version
      delete data.version
    } else {
      mutation = mutations.createStock
      selector = `createStock`

      data.createdAt = (new Date()).toISOString()
      data.createdBy = username
    }

    // Create the Client first
    if (action === 'CREATE') {
      // Create
      console.log(data)
      const stockResult = yield API.graphql(graphqlOperation(mutation, { input: data }))
      yield put(StockActions.saveStockSuccess(stockResult.data[selector]))
      history.replace(`/sklad/polozka/${stockResult.data[selector].id}`)
    } else {
      delete data.emission
      delete data.coin

      delete data.__typename

      // Update
      const stockResult = yield API.graphql(graphqlOperation(mutation, { input: data }))
      yield put(StockActions.saveStockSuccess(stockResult.data[selector]))
    }
  } catch (error) {
    console.log(error) // tslint:disable-line
    yield put(StockActions.saveStockFailure(error))
  }
}

export default saveStock
