import { all, takeLatest } from 'redux-saga/effects'

import { EmailTypes } from 'Stores/Email/Actions'
import Email from 'Sagas/Email'

export default function * root() {
  yield all([
    takeLatest(EmailTypes.LIST_EMAILS, Email.listEmails),
    takeLatest(EmailTypes.LIST_EMAILS_SUCCESS, Email.listEmailsSuccess),
    takeLatest(EmailTypes.LIST_EMAILS_FAILURE, Email.listEmailsFailure),
    //
    takeLatest(EmailTypes.GET_EMAIL, Email.getEmail),
    takeLatest(EmailTypes.GET_EMAIL_SUCCESS, Email.getEmailSuccess),
    takeLatest(EmailTypes.GET_EMAIL_FAILURE, Email.getEmailFailure)
  ])
}
