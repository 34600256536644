import React from 'react'

import DefaultLayout from 'Components/_Common/Layout/Default'

import * as ROUTES from 'routes'

import InvoiceList from 'Components/Invoice/List'

const Invoices = () => {
  return (
    <DefaultLayout
      title={`Faktury`}
      tabs={[{
        route: ROUTES.ADMIN_INVOICES, title: 'Seznam faktur'
      }, {
        route: '/faktury/nova', title: 'Nová faktura'
      }, {
        route: '/faktury/napoveda', title: 'Nápověda'
      }]}
      activeTab={0}
    >
      <InvoiceList />
    </DefaultLayout>
  )
}

export default Invoices
