import React from 'react'

import DefaultLayout from 'Components/_Common/Layout/Default'

import * as ROUTES from 'routes'

import StockList from 'Components/Stock/List'

const Stock = () => {
  return (
    <DefaultLayout
      title={`Sklad`}
      tabs={[{
        route: ROUTES.ADMIN_STOCK, title: 'Seznam položek'
      }, {
        route: ROUTES.ADMIN_STOCK_ITEM_NEW, title: 'Nová položka'
      }]}
      activeTab={0}
    >
      <StockList />
    </DefaultLayout>
  )
}

export default Stock
