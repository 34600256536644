import React from 'react'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'

import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme => ({
  copyright: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: 0,
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#000000',
    height: '66px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const Copyright = () => {
  const classes = useStyles()

  return (
    <div className={classes.copyright}>
      <Typography variant='caption' color='textSecondary' align='center'>
        {'Copyright © '}
        {new Date().getFullYear()}
        {' '}
        <Link color='inherit' href='https://goldcoins.as'>
          Gold Coins a.s.
        </Link>{' '}
      </Typography>

      {!isMobile && <Typography variant='caption' color='textSecondary' align='center' style={{ color: 'rgba(255, 255, 255, 0.33)' }}>
        Pražákova 1008/69, Štýřice, 639 00 Brno&nbsp;•&nbsp;IČ 071 75 698&nbsp;•&nbsp;B 8195 vedená u Krajského soudu v Brně
      </Typography>}
    </div>
  )
}

export default Copyright
