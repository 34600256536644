export const cs: any = {
  'Incorrect username or password.': 'Nesprávné uživatelské jméno nebo heslo.',
  //
  'Account recovery requires verified contact information': 'Případná obnova účtu vyžaduje ověřený údaj:',
  'Temporary password has expired and must be reset by an administrator' : 'Dočasné heslo vypršelo a musí být resetováno Administrátorem.'
}

const dictionary = { cs }

export default dictionary
