import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import PaymentActions from 'Stores/Payment/Actions'
import * as PaymentSelect from 'Stores/Payment/Select'

import Loading from 'Components/Loading'
import List from 'Components/Payment/List/List'

const PaymentList = () => {
  const paymentsLoading = useSelector(PaymentSelect.paymentsLoading)
  const paymentsResult = useSelector(PaymentSelect.paymentsResult)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(PaymentActions.listPayments())
  }, [])

  return (
    <>
      <List rows={paymentsResult || []} />
      {paymentsLoading && <Loading />}
    </>
  )
}

export default PaymentList
