import gql from 'graphql-tag'

const query = gql`
  query GET_CLIENT_QUERY($id: ID!) {
    getClient(id: $id) {
      id
      benefitId
      isFs
      cognitoUserId
      owner
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      titleBefore
      firstname
      lastname
      titleAfter
      personalNumber
      birthDate
      email
      phone
      addressFormatted
      addressStreet
      addressStreetNumber
      addressZip
      addressTown
      hasDifferentDeliveryAddress
      deliveryTitleBefore
      deliveryFirstname
      deliveryLastname
      deliveryTitleAfter
      deliveryCompanyName
      deliveryPhone
      deliveryBirthYear
      deliveryAddressFormatted
      deliveryAddressStreet
      deliveryAddressStreetNumber
      deliveryAddressZip
      deliveryAddressTown
      bankAccountNumber
      bankCode
      status
      contracts (limit: 1000) {
        items {
          id
          __typename
          contractNumber
          owner
          createdAt
          createdBy
          updatedAt
          updatedBy
          signedAt
          contractSentAt
          exportedAt
          lastPaymentReceivedAt
          deleted
          deletedAt
          deletedBy
          monthlyAmount
          totalYears
          client {
            id
            __typename
          }
          clientBenefitId
          clientTitleBefore
          clientFirstname
          clientLastname
          clientTitleAfter
          clientPersonalNumber
          clientBirthDate
          clientEmail
          clientPhone
          clientAddressFormatted
          clientAddressStreet
          clientAddressStreetNumber
          clientAddressZip
          clientAddressTown
          clientHasDifferentDeliveryAddress
          clientDeliveryTitleBefore
          clientDeliveryFirstname
          clientDeliveryLastname
          clientDeliveryTitleAfter
          clientDeliveryCompanyName
          clientDeliveryPhone
          clientDeliveryBirthYear
          clientDeliveryAddressFormatted
          clientDeliveryAddressStreet
          clientDeliveryAddressStreetNumber
          clientDeliveryAddressZip
          clientDeliveryAddressTown
          clientBankAccountNumber
          clientBankCode
          clientBankAccountOwner
          clientBankAccountOwnerRelation
          defaultPriceOfCoin
          salesMargin
          entryFeePerCoin
          priceOfCoin
          estimatedValue
          numberOfCoins
          qualityOfCoins
          entryPriceIncrease
          entryPriceIncreaseOneTimePayment
          entryPriceIncreaseIsPaid
          entryPriceIncreaseWasPaidAt
          entryPriceIncreasePaidAmount
          entryPriceIncreaseInvoiceId
          entryPriceIncreaseInvoiceNumber
          indicativeTargetAmount
          paymentsAmount
          distributionsAmount
          savedAmount
          status
          version
        }
        nextToken
      }
      emails (limit: 1000) {
        items {
          id
          receivedAt
          from
          to
          cc
          bcc
          recipients
          subject
          body
          bodyText
          bodyHtml
          attachments
          status
          version
        }
        nextToken
      }
      invoices (limit: 1000) {
        items {
          id
          __typename
          invoiceNumber
          owners
          createdAt
          createdBy
          updatedAt
          updatedBy
          deleted
          deletedAt
          deletedBy
          client {
            id
            __typename
          }
          clientTitleBefore
          clientFirstname
          clientLastname
          clientTitleAfter
          clientPersonalNumber
          clientBirthDate
          clientCompanyName
          clientCompanyNumber
          clientCompanyNumberVat
          clientEmail
          clientPhone
          clientAddressFormatted
          clientAddressStreet
          clientAddressStreetNumber
          clientAddressZip
          clientAddressTown
          clientHasDifferentDeliveryAddress
          clientDeliveryTitleBefore
          clientDeliveryFirstname
          clientDeliveryLastname
          clientDeliveryTitleAfter
          clientDeliveryCompanyName
          clientDeliveryPhone
          clientDeliveryBirthYear
          clientDeliveryAddressFormatted
          clientDeliveryAddressStreet
          clientDeliveryAddressStreetNumber
          clientDeliveryAddressZip
          clientDeliveryAddressTown
          clientBankAccountNumber
          clientBankCode
          textAboveItems
          textBelowItems
          price
          dateOfIssue
          dueDate
          variableSymbol
          constantSymbol
          specificSymbol
          status
          version
        }
        nextToken
      }
      payments (limit: 1000) {
        items {
          id
          owners
          createdAt
          createdBy
          updatedAt
          updatedBy
          deleted
          deletedAt
          deletedBy
          statement
          date
          transactionType
          reference
          bankReference
          amount
          currency
          details
          extraDetails
          bankAccountName
          bankAccountNumber
          bankAccountNumberWithBankCode
          bankCode
          variableSymbol
          constantSymbol
          specificSymbol
          message
          notes
          status
          partClient
          partCommission
          partGoldCoins
          commissionExportedAt
          version
        }
        nextToken
      }
      distributions (limit: 1000) {
        items {
          id
          owners
          createdAt
          createdBy
          updatedAt
          updatedBy
          deleted
          deletedAt
          deletedBy
          price
          status
          version
        }
        nextToken
      }
      version
    }
  }
`

export default query
