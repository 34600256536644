import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import DraggablePaper from 'Components/_Common/Dialogs/DraggablePaper'

import Loading from 'Components/Loading'

interface Props {
  title?: string,
  open: boolean,
  handleClose: () => any,
  loading: boolean,
  loadingText?: string,
  doneText?: string,
  buttonText?: string,
  url: string | null
  error: string | null
}

const GenerateContractPdfDialog = (props: Props) => {
  const {
    title = `Smlouva`,
    loading,
    loadingText = `Smlouva se připravuje ...`,
    doneText = `Smlouva je připravena`,
    buttonText = `Stáhnout smlouvu v PDF`,
    url,
    error
  } = props

  return (
    <Dialog
      PaperComponent={DraggablePaper}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby='form-dialog-title'
      maxWidth='sm'
      fullWidth={true}
    >
      <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        {loading && error === null && <DialogContentText>{loadingText}</DialogContentText>}
        {!loading && error === null  && <DialogContentText>{doneText} (odkaz pro stažení je platný 10 minut).</DialogContentText>}
        {error !== null &&  <DialogContentText style={{ color: `red` }}>{error}</DialogContentText>}
        {loading && <Loading />}
        {!loading && url !== null && <div style={{ display: `flex`, width: `100%`, marginTop: `30px`, justifyContent: `center`, alignItems: `center` }}>
          <Button href={url} target="_blank" variant="outlined" size="large" color="secondary">
            {buttonText}
          </Button>
        </div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GenerateContractPdfDialog
