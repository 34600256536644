import { getContext, put } from 'redux-saga/effects'

import ContractActions from 'Stores/Contract/Actions'

import getMyContractQuery from 'Graphql/Queries/getMyContractQuery'

export function * getMyContract ({ id }: any) {
  try {
    yield put(ContractActions.getMyContractLoading())

    const graphql = yield getContext('graphql')
    const variables = { id } as any

    const result = yield graphql.query(getMyContractQuery, variables)
    console.log(result)

    // @todo
    if (result && result.data && result.data.getContract) {
      yield put(ContractActions.getMyContractSuccess(result.data.getContract))
    }
  } catch (error) {
    yield put(ContractActions.getMyContractFailure(error))
  }
}

export default getMyContract
