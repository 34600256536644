import { createReducer } from 'reduxsauce'

import { DistributionTypes } from './Actions'
import INITIAL_STATE from './InitialState'

// LIST_DISTRIBUTIONS_LOADING
export const listDistributionsLoading = (state: any): any => ({
  ...state,
  listDistributionsLoading: true,
  listDistributionsResult: [],
  listDistributionsError: null
})

// LIST_DISTRIBUTIONS_SUCCESS
export const listDistributionsSuccess = (state: any, { data }: any): any => ({
  ...state,
  listDistributionsLoading: false,
  listDistributionsResult: data,
  listDistributionsError: null
})

// LIST_DISTRIBUTIONS_FAILURE
export const listDistributionsFailure = (state: any, { error }: any): any => ({
  ...state,
  listDistributionsLoading: false,
  listDistributionsError: error
})

//

export const reducer = createReducer(INITIAL_STATE, {
  [DistributionTypes.LIST_DISTRIBUTIONS_LOADING]: listDistributionsLoading,
  [DistributionTypes.LIST_DISTRIBUTIONS_SUCCESS]: listDistributionsSuccess,
  [DistributionTypes.LIST_DISTRIBUTIONS_FAILURE]: listDistributionsFailure
})
