import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import CoinActions from 'Stores/Coin/Actions'
import * as CoinSelect from 'Stores/Coin/Select'

import useReactRouter from 'use-react-router'

import { Storage } from 'aws-amplify'

import * as ROUTES from 'routes'

import DefaultLayout from 'Components/_Common/Layout/Default'
import Loading from 'Components/Loading'

import { format } from 'date-fns'

import StockList from 'Components/Stock/List/List'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { fade, makeStyles } from '@material-ui/core/styles'

import ts from 'Helpers/thousandsSeparator'

import { DropzoneArea } from 'material-ui-dropzone'

const placeholder = require('./Assets/_placeholder.png') // tslint:disable-line

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: 0,
    margin: 'auto',
    marginBottom: 114,
    overflow: 'hidden',
    padding: theme.spacing(3)
  },
  root: {
    marginTop: theme.spacing(3),
    width: '100%'
  },
  margin: {
    margin: 0,
  },
  button: {
    margin: theme.spacing(1),
  },

  dropzone: {
    height: `400px`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    backgroundColor: `transparent`,
    borderWidth: `1px`,
    borderColor: 'rgba(186, 164, 54, 0.5)',
    marginTop: `10px`
  }
}))

const Coin = (props: any) => {
  const {
    match: {
      params: { id }
    }
  }: any = useReactRouter()

  useEffect(() => {
    props.getCoin(id)
  }, [id])

  const [tab, setTab] = React.useState(0)

  const [upload, setUpload] = React.useState(false)

  const [dropzoneKey, setDropzoneKey] = React.useState(0)
  
  const [images, setImages] = React.useState<string[]>([])
  const [isUploading, setIsUploading] = React.useState(false)
  const [uploadProgress, setUploadProgress] = React.useState(0)

  const [pricesOpen, setPricesOpen] = React.useState(false)

  const [loading, setLoading] = React.useState(true)
  const counter = React.useRef(0)
  const imageLoaded = () => {
    counter.current += 1;
    if (counter.current >= images.length) {
      setLoading(false)
    }
  }

  const handlePricesClick = () => {
    setPricesOpen(!pricesOpen)
  }

  const classes = useStyles()

  const loadImages = React.useCallback(() => {
    Storage.list(`coins/${id}/`)
      .then(result => {
        const promises: any[] = []

        result.map((item: any) => {
          promises.push(Storage.get(item.key))
          return item
        })

        Promise.all(promises)
          .then((response: any) => {
            const i = response.sort()
            if (i instanceof Array && i.length > 0) {
              setImages(i)
            } else {
              setLoading(false)
            }
          })
      })
      .catch((error: any) => {
        // console.log(error) // tslint:disable-line
      })
  }, [id])

  useEffect(() => {
    loadImages()
  }, [loadImages])

  function handleSwitchTab(event: any, newValue: number): void {
    setTab(newValue)
  }

  const onDrop = React.useCallback((file: any) => {
    // console.log(`file`, file) // tslint:disable-line

    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted') // tslint:disable-line
    reader.onerror = () => console.log('file reading has failed') // tslint:disable-line
    reader.onload = () => {
      setIsUploading(true)
      Storage.put(`coins/${id}/${file.name}`, reader.result, {
        progressCallback(progress: any) {
          const progressValue = Math.round((progress.loaded / progress.total) * 100)
          console.log(`Uploaded: ${progressValue}`) // tslint:disable-line
          setUploadProgress(progressValue)
          if (progress.loaded === progress.total) {
            setIsUploading(false)
            setUploadProgress(0)
          }
        }
      }).then(() => {
        setDropzoneKey(prevKey => prevKey + 1)
        loadImages()
      })
    }

    reader.readAsArrayBuffer(file)
  }, [])

  return (
    <DefaultLayout
      title={`Mince`}
      tabs={[{
        route: ROUTES.ADMIN_EMISSIONS, title: 'Přehled emisí'
      }, {
        route: window.location.pathname, title: 'Detail mince'
      }]}
      activeTab={1}
    >
      {props.coinLoading && <Loading />}
      {!props.coinLoading && props.coinResult && (
        <>
          <AppBar position='static' color='default' style={{ width: `${props.dimensions.width - 72}px`, margin: 'auto' }}>
            <Tabs
              value={tab}
              onChange={handleSwitchTab}
              variant='scrollable'
              scrollButtons='off'
              indicatorColor='primary'
              textColor='primary'
            >
              <Tab label='Mince' icon={<FontAwesomeIcon icon={['fal', 'coins']} size='2x' />} />
              {props.user.group === 'Admins' && <Tab label='Sklad' icon={<FontAwesomeIcon icon={['fal', 'warehouse-alt']} size='2x' />} />}
            </Tabs>
          </AppBar>

          {tab === 0 && <Paper elevation={2} className={classes.paper} style={{ width: `${props.dimensions.width - 72}px` }}>
            <Grid container={true} direction='row'>
              <Grid item={true} xs={6}>
                {/* {props.user.group === 'Admins' && upload && <DropzoneArea
                  key={dropzoneKey}
                  dropzoneClass={classes.dropzone}
                  dropzoneText={` `}
                  acceptedFiles={['image/jpeg', 'image/png']}
                  filesLimit={1}
                  maxFileSize={100 * 1024578}
                  showPreviews={false}
                  showPreviewsInDropzone={false}
                  showAlerts={false}
                  onDrop={onDrop}
                />}

                {upload && isUploading && <LinearProgress color='primary' variant='determinate' value={uploadProgress} />} */}

                {!upload && loading && <Loading />}

                {!upload && images.length > 0 && <div className='flip-container'>
                  <div className='flipper'>
                    {images.map((image: any, i: any) => (
                      <div key={i} className={i === 0 ? 'front' : 'back'} style={{ display: loading ? 'none' : 'block' }}>
                        <img src={image} style={{ width: '100%' }} alt='' onLoad={imageLoaded} />
                      </div>
                    ))}
                  </div>
                </div>}

                {!upload && !loading && images.length === 0 && <div className='flip-container'>
                  <div className='flipper'>
                    <div className='front'>
                      <img src={placeholder} style={{ width: '100%' }} alt='Náhled není k dispozici' />
                    </div>
                    <div className='back'>
                      <img src={placeholder} style={{ width: '100%' }} alt='Náhled není k dispozici' />
                    </div>
                  </div>
                </div>}
              </Grid>

              <Grid item={true} xs={6}>
                <List component='nav' disablePadding={true}>
                  <ListItem>
                    <ListItemText
                      primary={props.coinResult.denominationMotif}
                      primaryTypographyProps={{ variant: 'h6', color: 'secondary' }}
                    />
                    {/* <Button
                      variant='contained'
                      color='secondary'
                      size='small'
                      onClick={() => setUpload(!upload)}
                    >
                      Upload
                    </Button>
                    &nbsp; */}
                    {props.user.group === 'Admins' && <Button
                      variant='contained'
                      color='secondary'
                      size='small'
                      style={{ width: `150px`, float: `right` }}
                      disabled={true}
                    >
                      Prodat
                    </Button>}
                  </ListItem>
                  <Divider />

                  <ListItem>
                    <ListItemText
                      primary={`${props.coinResult.emission.name}`}
                      secondary='Cyklus / Sada'
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary={`${ts(props.coinResult.value)} Kč`}
                      secondary='Hodnota'
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary={props.coinResult.designer ? props.coinResult.designer : `-`}
                      secondary='Autor'
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary={`${props.coinResult.dateOfIssue ? format(new Date(props.coinResult.dateOfIssue), 'dd.MM.yyyy') : ` - `}${props.coinResult.decreeNo ? `, vyhláška č. ${props.coinResult.decreeNo}` : ``}`}
                      secondary='Datum vydání'
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary={`${props.coinResult.weightFine} oz / ${props.coinResult.weightGross} g`}
                      secondary='Hmotnost (ryzí / hrubá)'
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary={`${props.coinResult.fineness} / ${props.coinResult.diameter} mm / ${props.coinResult.thickness ? `${props.coinResult.thickness} mm` : `-`}`}
                      secondary='Ryzost / Průměr / Síla'
                    />
                  </ListItem>
                  <Divider />

                  {props.user.group === 'Admins' && <React.Fragment>
                    <ListItem button={true} onClick={handlePricesClick}>
                      <ListItemText primary='Ceny' primaryTypographyProps={{ color: 'primary' }} />
                      <FontAwesomeIcon icon={['fal', pricesOpen ? 'chevron-up' : 'chevron-down']} size='xs' />
                    </ListItem>

                    <Collapse in={pricesOpen} timeout='auto' unmountOnExit={true}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell align='center'>BK</TableCell>
                            <TableCell align='center'>PROOF</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell component='th' scope='row' align='right'>hrana</TableCell>
                            <TableCell align='center'>vroubkovaná</TableCell>
                            <TableCell align='center'>hladká</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component='th' scope='row' align='right'>limit. náklad</TableCell>
                            {(props.coinResult.mintageLimitNormal || props.coinResult.mintageLimitProof) 
                              ? <React.Fragment>
                                  <TableCell align='center'>{props.coinResult.mintageLimitNormal ? `${ts(props.coinResult.mintageLimitNormal)} ks` : ``}</TableCell>
                                  <TableCell align='center'>{props.coinResult.mintageLimitProof ? `${ts(props.coinResult.mintageLimitProof)} ks` : ``}</TableCell>
                                </React.Fragment>
                              : 
                                <TableCell align='center' colSpan={2}>{props.coinResult.mintageLimitTotal ? `${ts(props.coinResult.mintageLimitTotal)} ks` : ``}</TableCell>
                            }
                          </TableRow>
                          <TableRow>
                            <TableCell component='th' scope='row' align='right'><sup>1</sup> cena <small>(pořizovací)</small></TableCell>
                            <TableCell align='center'>{props.coinResult.normalPriceCalculated ? `${ts(props.coinResult.normalPriceCalculated)} Kč` : ` - `}</TableCell>
                            <TableCell align='center'>{props.coinResult.proofPriceCalculated ? `${ts(props.coinResult.proofPriceCalculated)} Kč` : ` - `}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component='th' scope='row' align='right'><sup>2</sup> cena <small>(aktuální)</small></TableCell>
                            <TableCell align='center'>{props.coinResult.normalPriceActual ? `${ts(props.coinResult.normalPriceActual)} Kč` : ` - `}</TableCell>
                            <TableCell align='center'>{props.coinResult.proofPriceActual ? `${ts(props.coinResult.proofPriceActual)} Kč` : ` - `}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Collapse>
                  </React.Fragment>}
                </List>
              </Grid>
            </Grid>

            <Grid container={true} direction='row'>
              <Grid item={true} xs={12} style={{ paddingTop: `24px`, alignContent: 'center' }}>
                <div style={{ paddingTop: `14px` }}>
                  <Typography variant='button' color='primary'>Popis</Typography>
                </div>
              </Grid>

              <Grid item={true} xs={12} style={{ paddingTop: `8px` }}>
                <Typography variant='body2' align='justify' style={{whiteSpace: 'pre-line'}}>
                  {props.coinResult.description}
                </Typography>
              </Grid>
            </Grid>
          </Paper>}

          {/* @todo SORT */}
          {tab === 1 && props.user.group === 'Admins' && props.coinResult.stock.items && <StockList
            rows={props.coinResult.stock.items}
            dimensions={{ ...props.dimensions, height: props.dimensions.height - 72 }}
          />}
        </>
      )}
    </DefaultLayout>
  )
}

const mapStateToProps = (state: any) => ({
  coinLoading: CoinSelect.coinLoading(state),
  coinResult: CoinSelect.coinResult(state),
  //
  dimensions: state.app.dimensions,
  user: state.user
})

const mapDispatchToProps = (dispatch: any) => ({
  getCoin: (id: any) => dispatch(CoinActions.getCoin(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Coin)
