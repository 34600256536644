import React, { createRef, useEffect, useState } from 'react'

import { gsap, Power3 } from 'gsap'

import { useDispatch, useSelector } from 'react-redux'
import UserActions from 'Stores/User/Actions'

import { Field, Form } from 'react-final-form'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { InputComponent as TextField } from 'Components/_Common/Form/Inputs/TextField'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  input: {
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px #000 inset !important',
      WebkitTextFillColor: '#baa436 !important',
      boxShadow: '0 0 0 1000px #000 inset !important',
      '&:hover': {
        WebkitBoxShadow: '0 0 0 1000px #000 inset !important',
        boxShadow: '0 0 0 1000px #000 inset !important',
      },
      '&:focus': {
        WebkitBoxShadow: '0 0 0 1000px #000 inset !important',
        boxShadow: '0 0 0 1000px #000 inset !important',
      },
      '&:active': {
        WebkitBoxShadow: '0 0 0 1000px #000 inset !important',
        boxShadow: '0 0 0 1000px #000 inset !important',
      }
    }
  },
  inputContainer: {
    opacity: 0,
    margin: 0,
    padding: 0
  },
  submitButton: {
    height: 50,
    margin: theme.spacing(1, 0, 2),
    opacity: 0,
  }
}))

const ForgotPasswordForm = (props: any) => {
  const user = useSelector((state: any) => state.user)
  const dispatch = useDispatch()

  const classes = useStyles()

  const inputUsername = createRef()
  const inputCode = createRef()
  const inputPassword = createRef()
  const submitButton = createRef()

  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    gsap.to((inputUsername.current as any), { opacity: 1, duration: 2, delay: 0.5, ease: Power3.easeOut })
    gsap.to((submitButton.current as any), { opacity: 1, duration: 2, delay: 1.0, ease: Power3.easeOut })
  }, [inputUsername, submitButton])

  useEffect(() => {
    gsap.to((inputCode.current as any), { opacity: 1, duration: 2, delay: 0.5, ease: Power3.easeOut })
    gsap.to((inputPassword.current as any), { opacity: 1, duration: 2, delay: 1.0, ease: Power3.easeOut })
  }, [inputCode, inputPassword])

  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: any): void => {
    event.preventDefault()
  }

  const handleValidate = (values: any): object => {
    const errors = {} as any

    if (user.forgotPasswordState === 'REQUEST' && 
      (!('username' in values) || values.username === '')) {
      errors.username = 'Zadejte prosím Uživatelské jméno.'
    }

    if (user.forgotPasswordState === 'CONFIRM' && 
      (!('code' in values) || values.code === '')) {
      errors.username = 'Zadejte prosím Ověřovací kód.'
    }

    if (user.forgotPasswordState === 'CONFIRM' && 
      (!('password' in values) || values.password === '')) {
      errors.username = 'Zadejte prosím nové Heslo.'
    }

    return errors
  }

  const handleOnSubmit = ({ username, code, password }: any): void => {
    if (user.forgotPasswordState === 'REQUEST') {
      dispatch(UserActions.forgotPasswordRequest(username))
    }
    
    if (user.forgotPasswordState === 'CONFIRM') {
      const callback = props.onSuccess
      dispatch(UserActions.forgotPasswordConfirm(code, password, callback))
    }
  }

  const handleRender = ({ handleSubmit }: any) => (
    <form onSubmit={handleSubmit} className={classes.form}>
      {user.forgotPasswordState === 'REQUEST' &&
        <div ref={(inputUsername as any)} className={classes.inputContainer}>
          <Field
            name='username'
            component={TextField as React.SFC<any>}
            label='Uživatelské jméno'
            margin='normal'
            fullWidth={true}
            variant='outlined'
            autoComplete='off'
            disabled={user.forgotPasswordIsLoading}
            InputProps={{
              type: 'text'
            }}
            inputProps={{
              className: classes.input,
              autoCapitalize: 'none'
            }}
          />
        </div>
      }

      {/* @todo */}
      {user.forgotPasswordDeliveryMedium && user.forgotPasswordDestination
        ? <p style={{ textAlign: 'center' }}>{`Ověřovací kód byl odeslán "${user.forgotPasswordDeliveryMedium}" na ${user.forgotPasswordDestination}.`}</p>
        : null}

      {user.forgotPasswordState === 'CONFIRM' &&
        <div ref={(inputCode as any)} className={classes.inputContainer}>
          <Field
            name='code'
            component={TextField as React.SFC<any>}
            label='Ověřovací kód'
            margin='normal'
            fullWidth={true}
            variant='outlined'
            autoComplete='off'
            disabled={user.forgotPasswordIsLoading}
            InputProps={{
              type: 'text'
            }}
            inputProps={{
              className: classes.input,
              autoCapitalize: 'none'
            }}
          />
        </div>
      }

      {user.forgotPasswordState === 'CONFIRM' &&
        <div ref={(inputPassword as any)} className={classes.inputContainer}>
          <Field
            name='password'
            component={TextField as React.SFC<any>}
            label='Heslo'
            margin='normal'
            fullWidth={true}
            variant='outlined'
            autoComplete='off'
            disabled={user.forgotPasswordIsLoading}
            InputProps={{
              type: showPassword ? 'text' : 'password',
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                    disabled={user.forgotPasswordIsLoading}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            inputProps={{
              className: classes.input,
              autoCapitalize: 'none'
            }}
          />
        </div>
      }

      {/* @todo */}
      {user.forgotPasswordErrorMessage
        ? <p style={{ textAlign: 'center', color: 'red' }}>{user.forgotPasswordErrorMessage}</p>
        : null}

      <Button
        type='submit'
        ref={(submitButton as any)}
        fullWidth={true}
        variant='contained'
        color='primary'
        className={classes.submitButton}
        disabled={user.forgotPasswordIsLoading}
      >
        {user.forgotPasswordIsLoading
          ? <CircularProgress color='secondary' size={20} />
          : (<span>{user.forgotPasswordState === 'REQUEST' ? 'Pokračovat' : 'Nastavit heslo'}</span>)
        }
      </Button>
    </form>
  )

  return (
    <Form
      onSubmit={handleOnSubmit}
      validate={handleValidate}
      render={handleRender}
    />
  )
}

export default ForgotPasswordForm
