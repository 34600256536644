import React from 'react'

import Grid from '@material-ui/core/Grid'

const Row = (props: any) => (
  <Grid container={true} direction='row'>
    <Grid container={true} xs={4} alignContent='center' style={{ minHeight: `50px` }}>
      <span>{props.label}</span>
    </Grid>
    <Grid container={true} xs={8} alignContent='center' style={{ minHeight: `50px` }}>
      <Grid item={true} style={{ display: 'flex', alignItems: 'center' }}>
        {props.children}
      </Grid>
    </Grid>
  </Grid>
)

export default Row
