// @todo
import personalNumberValidator from 'Components/_Common/Form/validators/personalNumber'

const getBirthDateFromPersonalNumber = (personalNumber: any): string | undefined => {
  const pn = String(personalNumber)

  if (personalNumberValidator(pn)) {
    let year = parseInt(pn.substr(0, 2), 10)
    // The contract can be closed with a person older than 18-years,
    // therefore we're switching the century based on CURRENT_YEAR - 18.
    const centurySwitchYear = parseInt(String((new Date()).getFullYear() - 18).substr(2, 2), 10)
    year += (year <= centurySwitchYear) ? 2000 : 1900

    let month = parseInt(pn.substr(2, 2), 10)
    if (month > 70 && year > 2003) {
      month -= 70
    }  else if (month > 50) {
      month -= 50
    } else if (month > 20 && year > 2003) {
      month -= 20
    }

    const day = parseInt(pn.substr(4, 2), 10)

    if (month >= 1 && month <= 12 && day >= 0 && day <= 31) {
      const birthDate = `${year}-${("0" + month).slice(-2)}-${("0" + day).slice(-2)}`
      return birthDate
    }
  }

  return undefined
}

export default getBirthDateFromPersonalNumber
