import React from 'react'
import GoogleAnalytics from 'react-ga'

GoogleAnalytics.initialize(process.env.REACT_APP_GA_TRACKING_CODE)

const withGa = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    if (process.env.NODE_ENV === 'production') {
      GoogleAnalytics.set({
        page,
        ...options
      })
      GoogleAnalytics.pageview(page)
    }
  }

  // eslint-disable-next-line
  const HOC = class extends React.Component {
    componentDidMount () {
      const page = this.props.location.pathname + this.props.location.search
      trackPage(page)
    }

    componentDidUpdate (prevProps) {
      const currentPage = prevProps.location.pathname + prevProps.location.search
      const nextPage = this.props.location.pathname + this.props.location.search

      if (currentPage !== nextPage) {
        trackPage(nextPage)
      }
    }

    render () {
      return <WrappedComponent {...this.props} />
    }
  }

  return HOC
}

export default withGa
