import React from 'react'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import format from 'date-fns/format'

import ts from 'Helpers/thousandsSeparator'

const styles = (theme: Theme) => createStyles({
  paper: {
    margin: 'auto',
    marginBottom: 48,
    overflow: 'hidden'
  },
  table: {
    minWidth: 700
  }
})

interface Props extends WithStyles<typeof styles> {
  data: any
}

// @todo
const paymentSymbolCallback = (value: string): string => {
  const result = parseInt(value, 10)
  return isNaN(result) || result === 0 ? ` - ` : `${result}`
}


const ClientsMyPayments = (props: Props) => {
  const { classes, data } = props

  return (
    <Paper elevation={2} className={classes.paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Datum</TableCell>
            <TableCell align="right">Částka</TableCell>
            <TableCell align="right">VS</TableCell>
            <TableCell align="right">KS</TableCell>
            <TableCell align="right">SS</TableCell>
            {/* <TableCell align="right">&nbsp;</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item: any) => (
            <TableRow key={item.id} hover={true}>
              <TableCell align='left'>{format(new Date(item.date), 'dd.MM.yyyy')}</TableCell>
              <TableCell align='right'><strong>{ts(item.amount)}&nbsp;Kč</strong></TableCell>
              <TableCell align='right'>{paymentSymbolCallback(item.variableSymbol)}</TableCell>
              <TableCell align='right'>{paymentSymbolCallback(item.constantSymbol)}</TableCell>
              <TableCell align='right'>{paymentSymbolCallback(item.specificSymbol)}</TableCell>
              {/* <TableCell align='right'>
                <Button component={DetailLink} href={`/k/moje-smlouva/${item.id}`} variant='outlined' size='small' color='secondary'>
                  Detail
                </Button>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default withStyles(styles)(ClientsMyPayments)
