import React from 'react'

import { connect } from 'react-redux'

import { FilteringState, IntegratedFiltering, IntegratedSummary, SummaryState } from '@devexpress/dx-react-grid';
import { Grid, TableFilterRow, TableFixedColumns, TableHeaderRow, TableSummaryRow, VirtualTable } from '@devexpress/dx-react-grid-material-ui'
import Paper from '@material-ui/core/Paper'

import { columnExtensions, columns } from './Settings'

import Cell from 'Components/_Common/List/Cell'
import FilterCell from 'Components/_Common/List/FilterCell'

import { filterRowMessages, summaryRowMessages, tableMessages } from 'Components/_Common/List/I18n/messages'

const getRowId = (row: any): string => row.id

const ClientList_List = (props: any) => {
  const { rows, dimensions, heightOffset = 0 } = props

  return (
    <Paper elevation={2} style={{ width: `${dimensions.width - 72}px`, overflow: `hidden` }}>
      <Grid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
      >
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />

        <SummaryState
          totalItems={[
            { columnName: 'client.name', type: 'count' }
          ]}
        />
        <IntegratedSummary />

        <VirtualTable
          cellComponent={Cell}
          columnExtensions={columnExtensions}
          height={dimensions.height - 96 + heightOffset}
          estimatedRowHeight={50}
          messages={tableMessages}
        />
        <TableHeaderRow />
        <TableFilterRow
          showFilterSelector
          cellComponent={FilterCell}
          messages={filterRowMessages}
        />
        <TableSummaryRow
          messages={summaryRowMessages}
        />
        <TableFixedColumns
          leftColumns={['client.action', 'client.name']}
          rightColumns={['client.status']}
        />
      </Grid>
    </Paper>
  )
}

const mapStateToProps = (state: any) => ({
  dimensions: state.app.dimensions
})

export default connect(mapStateToProps)(
  ClientList_List
)
